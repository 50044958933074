import React, { useState, useEffect } from 'react';
import {
    FlatList,
    ScrollView,
    Modal,
    Platform,
    View
} from 'react-native';

import {
    ButtonView,
    FlatlistWrapper,
    InputWrapper,
    VolumeContainer,
    HelpTemperatureContainer,
    HelpContainer,
    VolumeSelectorWrapper,
    VolumeSelectorContainer,
    PressureSelectorWrapper,
    PressureContainer,
    Content,
    Footer,
} from './styles';

import { useTheme } from 'styled-components/native';

import { temperatures, averagePressures } from '../../utils/TemperatureDataPlus/index';

import { TemperatureSelector } from '../../components/TemperatureSelector';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { VolumeSelector } from '../../components/VolumeSelector';
import { PressureSelector } from '../../components/PressureSelector';
import { ClearButton } from '../../components/ClearButton';
import { Header } from '../../components/Header';
import { HelpButton } from '../../components/HelpButton';

import { useNavigation, useRoute } from '@react-navigation/native';
import { useIsFocused } from '@react-navigation/native';

import {useTranslation} from 'react-i18next';

import * as Clipboard from 'expo-clipboard';
import { PressurizationScreenModal } from '../../components/Modals/PressurizationScreenModal';
import { FlowMessage, PressureMessage, PressureMessageFinal, TemperatureMessage, VolumeMessage, VolumeMessageFinal } from '../../components/Modals/PressurizationScreenModal/message';
import { BackButton } from '../../components/BackButton';
interface TemperatureProps {
    key: string;
    title: string;
    constant: number[];
}
interface AnswerProps {
    totalVolumeGas: number;
    totalVolumeLiquid: number;
    totalVolumeLiquidWithLost: number;
    timeForPressurizationHour: number;
    timeForPressurizationMinutes: number;
}

interface HydrostaticProps {
    answerHeadPressure: string;
}
interface VolumeSingleProps {
    volumeSingleColumn: string;
}

interface VolumeAnularProps {
    volumeAnularColumn: string;
}

interface VolumeSinglePlusAnularProps {
    volumeSinglePlusAnularColumn: string;
}

export function SurfacePressurizationCalculationPlus() {

    const [temperatureSelected, setTemperatureSelected] = useState('60');
    const [volumeTypeSelected, setVolumeTypeSelected] = useState<'bbl' | 'm³'>('bbl');
    const [pressureTypeSelected, setPressureTypeSelected] = useState<'psi' | 'kgf/cm²' | 'bar'>('psi');

    const [volume, setVolume] = useState('');
    const [pressure, setPressure] = useState('');
    const [flow, setFlow] = useState('');

    const pressureFormatted = pressureTypeSelected === 'psi' ? Number(pressure.replace(',', '.')) :
        (pressureTypeSelected === 'kgf/cm²') ? Number(pressure.replace(',', '.')) * 14.2233 : Number(pressure.replace(',', '.')) * 14.5038

    const [pasted, setPasted] = useState(true);

    const theme = useTheme();

    const { navigate } = useNavigation();
    const navigation = useNavigation();
    const route = useRoute();

    const screenIsFocus = useIsFocused();

    const {t} = useTranslation();

    const [openModalVolume, setOpenModalVolume] = useState(false);
    const [openModalTemperature, setOpenModalTemperature] = useState(false);
    const [openModalPressure, setOpenModalPressure] = useState(false);
    const [openModalFlow, setOpenModalFlow] = useState(false);


    function handleTemperatureSelected(temperature: string) {
        setTemperatureSelected(temperature);
    };

    function handleVolumeTypeSelected(volumeType: 'bbl' | 'm³') {
        setVolumeTypeSelected(volumeType);
    };

    function handlePressureTypeSelected(pressureType: 'psi' | 'kgf/cm²' | 'bar') {
        setPressureTypeSelected(pressureType);
    };


    function handleCalculation() {

        if (volume === '') {
            alert(t('Obrigatório inserir volume!'));
            return;
        } else if (pressure === '') {
            alert(t('Obrigatório inserir pressão!'));
            return;
        } else if (pressureFormatted > 15000) {
            alert(t('Pressão máxima excedida! Máximo 15.000 psi'));
            return;
        }


        function handleConstantFiltering() {
            const temperatureConstantSelected = temperatures.find(temperature =>
                temperature.key === temperatureSelected)

            if (!temperatureConstantSelected)
                return;
            const constantsFormatted = temperatureConstantSelected.constant;

            function findLiquidHeightValues() {
                const inchesWaterFormatted = pressureFormatted;

                function closest(value, array) {
                    var result = [];
                    array.some(function (a) {
                        if (a > value) {
                            return result.push(a);
                        }
                        result = [a];
                        return a === value;
                    });
                    return result;
                }

                const close = closest(inchesWaterFormatted, averagePressures);
                return close;

            }

            const liquidHeightValues = findLiquidHeightValues();
            const liquidHeightDown = liquidHeightValues[0];
            const liquidHeightUp = liquidHeightValues[1] ? liquidHeightValues[1] : liquidHeightValues[0]
            console.log('liquidHeightDown', liquidHeightDown);

            console.log('liquidHeightUp', liquidHeightUp);

            function getIndex(array, value) {

                const positionPressureHeadDownDown = array.indexOf(value);
                //console.log(positionPressureHeadDownDown);
                return positionPressureHeadDownDown
            }

            const positionLiquidDown = getIndex(averagePressures, liquidHeightDown);
            console.log('positionLiquidDown', positionLiquidDown);

            const volumeLiquidDown = constantsFormatted[positionLiquidDown];
            console.log('volumeLiquidDown', volumeLiquidDown);


            const positionLiquidUp = getIndex(averagePressures, liquidHeightUp);
            console.log('positionLiquidUp', positionLiquidUp);

            const volumeLiquidUp = constantsFormatted[positionLiquidUp];
            console.log('volumeLiquidUp', volumeLiquidUp);

            function findResultInterpolated(x, x1, x2, y1, y2) {
                const y = y1 + (((x - x1) / (x2 - x1)) * (y2 - y1))
                return y
            };

            if (liquidHeightDown === liquidHeightUp) {
                const answer = volumeLiquidDown;
                return answer
            } else {
                const inchesWaterFormatted = pressureFormatted;
                const answer = findResultInterpolated(inchesWaterFormatted, liquidHeightDown, liquidHeightUp, volumeLiquidDown, volumeLiquidUp)
                console.log('answer', answer);
                return answer

            }

        }

        const constantFilteredForCalc = handleConstantFiltering();

        if (!constantFilteredForCalc) {
            return;
        }
        console.log(constantFilteredForCalc);
        let volumeFormattedforCalc: number = Number(volume.replace(',', '.'));
        if (volumeTypeSelected === 'bbl') {
            volumeFormattedforCalc = Number(volume.replace(',', '.'));
        } else if (volumeTypeSelected === 'm³') {
            volumeFormattedforCalc = Number(volume.replace(',', '.')) * 6.2898;
        }

        let pressureFormattedforCalc: number = Number(pressure.replace(',', '.'));
        if (pressureTypeSelected === 'psi') {
            pressureFormattedforCalc = Number(pressure.replace(',', '.'));
        } else if (pressureTypeSelected === 'kgf/cm²') {
            pressureFormattedforCalc = Number(pressure.replace(',', '.')) * 14.2233
        } else if (pressureTypeSelected === 'bar') {
            pressureFormattedforCalc = Number(pressure.replace(',', '.')) * 14.5038
        }
        const flowFormattedforCalc = Number(flow.replace(',', '.'));

        const totalVolumeGas = volumeFormattedforCalc * constantFilteredForCalc;

        const totalVolumeLiquid = totalVolumeGas / 93.11;

        const totalVolumeLiquidWithLost = (totalVolumeLiquid * 1.2) + 70;

        const timeForPressurizationTotalMinutes = totalVolumeGas / flowFormattedforCalc;
        //console.log('Tempo de pressurização', timeForPressurizationHour);

        const timeForPressurizationMinutes = timeForPressurizationTotalMinutes % 60;

        const timeForPressurizationHour = (timeForPressurizationTotalMinutes - timeForPressurizationMinutes) / 60;

        const result: AnswerProps = {
            totalVolumeGas,
            totalVolumeLiquid,
            totalVolumeLiquidWithLost,
            timeForPressurizationHour,
            timeForPressurizationMinutes
        }

        navigate('PressurizationResultPlus' as never, {
            result,
            volume,
            pressure,
            flow,
            volumeTypeSelected,
            pressureTypeSelected,
            temperatureSelected
        } as never);

    };

    async function handlePasteVolume() {
        try {
            const fetchCopiedText = async () => {
                const text = await Clipboard.getStringAsync();
                setVolume(text);
            }
            fetchCopiedText();
        } catch (error) {
            console.log(error);
        }

    }

    async function handlePastePressure() {
        try {
            const fetchCopiedText = async () => {
                const text = await Clipboard.getStringAsync();
                setPressure(text);
            }
            fetchCopiedText();
        } catch (error) {
            console.log(error)
        }

    }

    function handleOpenModalVolume() {
        setOpenModalVolume(true);
    }

    function handleNavigationVolume() {
        setOpenModalVolume(false);
        navigate('ColumnVolumeCalculation' as never);
    }

    function handleNavigationPressure() {
        setOpenModalPressure(false);
        navigate('HydrostaticPressureCalculation' as never)
    }

    function handleClearAll() {
        setTemperatureSelected('60');
        setVolumeTypeSelected('bbl');
        setPressureTypeSelected('psi');
        setVolume('');
        setPressure('');
        setFlow('');
    }

    useEffect(() => {
        const fetchCopiedText = async () => {
            const text = await Clipboard.getStringAsync();
            if (text) {
                setPasted(true);
            }
        }
        fetchCopiedText();
    }, [screenIsFocus]);

    useEffect(() => {
        try {
            const { volumeSingleColumn } = route.params as VolumeSingleProps;
            if (volumeSingleColumn === '') {
                return
            } else if (volumeSingleColumn) {
                setVolume(volumeSingleColumn);
            }



        } catch (error) {
            console.log(error)
        }
    }, [screenIsFocus]);

    useEffect(() => {
        try {
            const { volumeAnularColumn } = route.params as VolumeAnularProps;
            if (volumeAnularColumn === '') {
                return
            } else if (volumeAnularColumn) {
                setVolume(volumeAnularColumn);
            }



        } catch (error) {
            console.log(error)
        }
    }, [screenIsFocus])

    useEffect(() => {
        try {
            const { volumeSinglePlusAnularColumn } = route.params as VolumeSinglePlusAnularProps;
            if (volumeSinglePlusAnularColumn === '') {
                return
            } else if (volumeSinglePlusAnularColumn) {
                setVolume(volumeSinglePlusAnularColumn);
            }



        } catch (error) {
            console.log(error)
        }
    }, [screenIsFocus])


    useEffect(() => {
        try {
            const { answerHeadPressure } = route.params as HydrostaticProps;
            if (answerHeadPressure === '') {
                return
            } else if (answerHeadPressure) {
                setPressure(answerHeadPressure);
                console.log('Problem', answerHeadPressure)
            }



        } catch (error) {
            console.log(error)
        }
    }, [screenIsFocus])


    useEffect(() => {
        const clearParams = () => {
            navigation.setParams({ answerHeadPressure: '' })
        }
        clearParams();
    }, [pressure])

    useEffect(() => {
        const clearParams = () => {
            navigation.setParams({ volumeSingleColumn: '' })
        }
        clearParams();
    }, [volume])

    useEffect(() => {
        const clearParams = () => {
            navigation.setParams({ volumeAnularColumn: '' })
        }
        clearParams();
    }, [volume])

    useEffect(() => {
        const clearParams = () => {
            navigation.setParams({ volumeSinglePlusAnularColumn: '' })
        }
        clearParams();
    }, [volume])



    return (
        <ScrollView
            style={{ backgroundColor: theme.colors.backgroud_primary }}
            showsVerticalScrollIndicator={false}
        >
            <Header
                title={t('Operações N2')}
            />
            <ButtonView>
                <BackButton
                    color={theme.colors.main}
                    onPress={() => navigation.goBack()} />
                <ClearButton
                    onPress={handleClearAll}
                />
            </ButtonView>
            <FlatlistWrapper>
                <FlatList
                    data={temperatures}
                    keyExtractor={(item: TemperatureProps) => item.key}
                    renderItem={({ item }) => (
                        <TemperatureSelector
                            title={item.title}
                            active={item.key === temperatureSelected}
                            onPress={() => { handleTemperatureSelected(item.key) }}
                        />)
                    }
                    horizontal
                    showsHorizontalScrollIndicator={false}
                />
                <HelpTemperatureContainer>
                    <HelpButton
                        helpButton={() => setOpenModalTemperature(true)}
                    />
                </HelpTemperatureContainer>
            </FlatlistWrapper>
            <Content>
                <InputWrapper>
                    <VolumeContainer>
                        <Input
                            iconName='cube-outline'
                            placeholder={t('Digite o volume')}
                            keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                            value={volume}
                            onChangeText={(text) => setVolume(text)}
                            pasteButton={pasted}
                            handlePaste={handlePasteVolume}
                        />

                        <VolumeSelectorWrapper>
                            <VolumeSelectorContainer>
                                <VolumeSelector
                                    title='bbl'
                                    active={'bbl' === volumeTypeSelected}
                                    onPress={() => { handleVolumeTypeSelected('bbl') }}
                                />
                                <VolumeSelector
                                    title='m³'
                                    active={'m³' === volumeTypeSelected}
                                    onPress={() => { handleVolumeTypeSelected('m³') }}
                                />
                            </VolumeSelectorContainer>
                            <HelpContainer>
                                <HelpButton
                                    helpButton={handleOpenModalVolume}
                                />
                            </HelpContainer>

                        </VolumeSelectorWrapper>
                    </VolumeContainer>
                    <PressureContainer>
                        <Input
                            iconName='gauge'
                            placeholder={t('Digite a pressão')}
                            keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                            value={pressure}
                            onChangeText={(text) => setPressure(text)}
                            pasteButton={pasted}
                            handlePaste={handlePastePressure}
                        />
                        <PressureSelectorWrapper>
                            <VolumeSelectorContainer>
                                <PressureSelector
                                    title='psi'
                                    active={'psi' === pressureTypeSelected}
                                    onPress={() => { handlePressureTypeSelected('psi') }}
                                />
                                <PressureSelector
                                    title='kgf/cm²'
                                    active={'kgf/cm²' === pressureTypeSelected}
                                    onPress={() => { handlePressureTypeSelected('kgf/cm²') }}
                                />
                                <PressureSelector
                                    title='bar'
                                    active={'bar' === pressureTypeSelected}
                                    onPress={() => { handlePressureTypeSelected('bar') }}
                                />
                            </VolumeSelectorContainer>
                            <HelpContainer>
                                <HelpButton
                                    helpButton={() => setOpenModalPressure(true)}
                                />
                            </HelpContainer>
                        </PressureSelectorWrapper>
                    </PressureContainer>
                    <Input
                        iconName='weather-windy'
                        placeholder={t('Digite a vazão em scfm')}
                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                        value={flow}
                        onChangeText={(text) => setFlow(text)}
                    />
                    <HelpContainer>
                        <HelpButton
                            helpButton={() => setOpenModalFlow(true)}
                        />
                    </HelpContainer>
                </InputWrapper>

                <Footer>
                    <Button
                        title={t('CALCULAR')}
                        onPress={handleCalculation}
                    />
                </Footer>
            </Content>

            <Modal
                visible={openModalTemperature}
                animationType={'slide'}
                onRequestClose={() => setOpenModalTemperature(false)}
                transparent
            >
                <PressurizationScreenModal
                    title={t('Temperatura')}
                    iconCollection={'FontAwesome'}
                    iconName={'thermometer'}
                    text={`${t('Escolha a temperatura do local a ser pressurizado.')}${'\n'}${'\n'}${t('Para desalagamento de DPR é comum utilizar a temperatura de 60° F e para vasos de superfície 80° F, porém avalie sua operação para realizar a melhor escolha.')}${'\n'}${'\n'}${t('Caso não escolha nenhuma opção, será utilizado a temperatura de 60° F!')}`}
                    handleCloseModal={() => setOpenModalTemperature(false)}
                />
            </Modal>


            <Modal
                visible={openModalVolume}
                animationType={'slide'}
                onRequestClose={() => setOpenModalVolume(false)}
                transparent
            >
                <PressurizationScreenModal
                    title={t('Volume')}
                    iconName={'cube-outline'}
                    text={t(VolumeMessage)}
                    textFinal={`${'\n'}${t('O volume pode ser inserido em barril (bbl) ou metro cúbido (m³), selecione no botão embaixo da caixa de texto a unidade que deseja utilizar.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                    handleCloseModal={() => setOpenModalVolume(false)}
                    handleNavigation={handleNavigationVolume}
                    textNavigation={t('Caso queira calcular o volume de uma coluna, clique aqui!     >>>')}
                />
            </Modal>

            <Modal
                visible={openModalPressure}
                animationType={'slide'}
                onRequestClose={() => setOpenModalPressure(false)}
                transparent
            >
                <PressurizationScreenModal
                    title={t('Pressão')}
                    iconName={'gauge'}
                    text={ `${t('Nesse campo deve ser inserido a pressão final da operação.')}${'\n'}${'\n'}${t('Para operações em poços, utilize a pressão hidrostática de cabeça.')}`}
                    textFinal={`${'\n'}${t('O pressão pode ser inserida em psi, kgf/cm² ou bar, selecione no botão embaixo da caixa de texto a unidade que deseja utilizar.')}${'\n'}${'\n'}${t('Pressão máxima permitida: 15.000 psi.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                    handleCloseModal={() => setOpenModalPressure(false)}
                    handleNavigation={handleNavigationPressure}
                    textNavigation={t(`Caso queira calcular uma pressão hidrostática, clique aqui!     >>>`)}
                />
            </Modal>

            <Modal
                visible={openModalFlow}
                animationType={'slide'}
                onRequestClose={() => setOpenModalFlow(false)}
                transparent
            >
                <PressurizationScreenModal
                    title={t('Vazão')}
                    iconName={'weather-windy'}
                    text={`${t('Nesse campo deve ser inserido a vazão de nitrogênio em scfm. Com esse dado será possível prever a duração da operação.')}${'\n'}${'\n'}${t('Caso o campo não seja preenchido, os demais cálculos serão realizados, porém nenhuma estimativa de tempo de bombeio será fornecida.')}${'\n'}${'\n'}${t('Preenchimento opcional!')}`}
                    handleCloseModal={() => setOpenModalFlow(false)}
                />
            </Modal>
        </ScrollView>

    );
}