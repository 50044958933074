import React from 'react';

import {
    Container,
    Header,
    Title,
    TitleView,
    IconView,
    Icon,
    Footer,
    PasteCopy,
    PasteCopyButton,
    IconPaste,
    Amount,
} from './styles';

import { MaterialCommunityIcons } from '@expo/vector-icons';

import { useTheme } from 'styled-components/native';
import { DeleteCard } from '../DeleteCard';

interface Props {
    title: string;
    depthType: string;
    density?: string;
    answer: string;
    color: string;
    iconName: React.ComponentProps<typeof MaterialCommunityIcons>['name'];
    pasted: boolean;
    copyButton: () => void;
    deleteButton?: () => void;

}

export function AnswerCard({
    title,
    depthType,
    density,
    answer,
    color,
    iconName,
    pasted = false,
    copyButton,
    deleteButton
}: Props) {

    const theme = useTheme();

    return (
        <Container >
            <Header>

                <Title>
                    {title}
                </Title>

                <IconView>
                    <Icon
                        name={iconName}
                        color={color}
                    />
                </IconView>
            </Header>
            <Footer>

                <Amount>
                    {answer}
                </Amount>
            </Footer>
            <PasteCopy>
                <DeleteCard
                    onPress={deleteButton}
                />
                <PasteCopyButton onPress={copyButton}>

                    {
                        pasted
                            ?
                            <IconPaste
                                name='clipboard-check-multiple-outline'
                                color={theme.colors.site_700}
                            />
                            :
                            <IconPaste
                                name='clipboard-multiple-outline'
                                color={theme.colors.site_700}
                            />
                    }
                </PasteCopyButton>
            </PasteCopy>

        </Container>
    );
}