import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

const { Navigator, Screen } = createStackNavigator();

import { MyCalculations } from "../screens/MyCalculations";
import { CalculationsFromJob } from "../screens/CalculationsFromJob";
import { PressurizationResultSaved } from "../screens/PressurizationResultSaved";
import { ColumVolumeResultSaved } from "../screens/ColumVolumeResultSaved";

export function StackMyCalculation() {
    return (
        <Navigator screenOptions={{ headerShown: false }} initialRouteName="SurfacePressurizationCalculationPlus">
            <Screen
                name="MyCalculations"
                component={MyCalculations}
            />
            <Screen
                name="CalculationsFromJob"
                component={CalculationsFromJob}
            />
            <Screen
                name="PressurizationResultSaved"
                component={PressurizationResultSaved}
            />
            <Screen
                name="ColumVolumeResultSaved"
                component={ColumVolumeResultSaved}
            />
        </Navigator>
    )
}