import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { TouchableOpacityProps, Dimensions } from 'react-native';
import {
    ButtonTemperature
} from './styles';

import { RFValue } from 'react-native-responsive-fontsize';

import { useTheme } from 'styled-components/native';

interface Props extends TouchableOpacityProps {
    active?: boolean;
}

export function ClearTypeColumnButton({
    active,
    ...rest
}: Props) {

    const theme = useTheme();

    const maxWidth = 750;

    return (
        <ButtonTemperature
            active={active}
            {...rest}>
            <MaterialCommunityIcons
                name='backspace'
                color={theme.colors.site_600}
                size={Dimensions.get('window').width < maxWidth ? RFValue(18) : 18}
            />
        </ButtonTemperature>
    );
}