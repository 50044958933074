import React from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { useTheme } from 'styled-components/native';
import { RectButtonProps } from 'react-native-gesture-handler';
import {
    Container
} from './styles';
import { RFValue } from 'react-native-responsive-fontsize';
import { Dimensions } from 'react-native';
interface Props extends RectButtonProps {
    color?: string;
}

export function SaveButton({ color, ...rest }: Props) {

    const theme = useTheme();

    const maxWidth = 750;

    return (
        <Container {...rest}>
            <MaterialIcons
                name="save"
                size={Dimensions.get('window').width < maxWidth ? RFValue(24) : 24}
                color={color ? color : theme.colors.text}
            />
        </Container>
    );
}