import React from 'react';
import { TouchableOpacityProps } from 'react-native';
import {
    ButtonTemperature,
    TitleTemperature
} from './styles';



interface Props extends TouchableOpacityProps {
    title: string;
    active?: boolean;
}

export function TemperatureSelectorConvert({ title,
    active,
    ...rest
}: Props) {
    return (
        <ButtonTemperature
            active={active}
            {...rest}>
            <TitleTemperature active={active}>
                {title}
            </TitleTemperature>
        </ButtonTemperature>
    );
}