import React from 'react';
import AppLoading from 'expo-app-loading';
import { ThemeProvider } from 'styled-components/native';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

import './src/utils/Language/i18n';


import {
  useFonts,
  Rubik_400Regular,
  Rubik_600SemiBold

} from '@expo-google-fonts/rubik';


import theme from './src/styles/theme';

import { Routes } from './src/routes';

export default function App() {

  const [fontsLoaded] = useFonts({
    Rubik_400Regular,
    Rubik_600SemiBold

  });

  if (!fontsLoaded) {
    return <AppLoading />
  }

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

serviceWorkerRegistration.register();