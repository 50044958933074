
interface PressureHeadProps {
    key: number;
    title: string;
    constant: number[];
}

const bottomPressureTable: PressureHeadProps[] = [
    {
        key: 0,
        title: '0',
        constant: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ]
    },
    {
        key: 500,
        title: '500',
        constant: [
            0, 509, 1017, 1527, 2036, 2547, 3059, 3572, 4060, 488, 4548, 5036
        ]
    },
    {
        key: 1000,
        title: '1000',
        constant: [
            0, 517, 1035, 1553, 2073, 2594, 3117, 3644, 4121, 477, 4598, 5075
        ]
    },
    {
        key: 1500,
        title: '1500',
        constant: [
            0, 526, 1052, 1580, 2109, 2641, 3175, 3725, 4180, 455, 4635, 5090
        ]
    },
    {
        key: 2000,
        title: '2000',
        constant: [
            0, 534, 1070, 1606, 2145, 2687, 3233, 3785, 4240, 455, 4695, 5150
        ]
    },
    {
        key: 2500,
        title: '2500',
        constant: [
            0, 534, 1087, 1633, 2181, 2733, 3291, 3855, 4299, 444, 4743, 5187
        ]
    },
    {
        key: 3000,
        title: '3000',
        constant: [
            0, 552, 1104, 1659, 2217, 2780, 3348, 3925, 4358, 433, 4791, 5224
        ]
    },
    {
        key: 3500,
        title: '3500',
        constant: [
            0, 560, 1122, 1685, 2253, 2825, 3405, 3994, 4417, 423, 4840, 5263
        ]
    },
    {
        key: 4000,
        title: '4000',
        constant: [
            0, 569, 1139, 1712, 2289, 2871, 3462, 4049, 4475, 426, 4901, 5327
        ]
    },
    {
        key: 4500,
        title: '4500',
        constant: [
            0, 577, 1156, 1738, 2324, 2917, 3518, 4102, 4533, 431, 4964, 5395
        ]
    },
    {
        key: 5000,
        title: '5000',
        constant: [
            0, 586, 1174, 1764, 2360, 2962, 3574, 4156, 4591, 435, 5026, 5461
        ]
    },
    {
        key: 5500,
        title: '5500',
        constant: [
            0, 595, 1191, 1791, 2395, 3008, 3630, 4209, 4649, 440, 5089, 5529
        ]
    },
    {
        key: 6000,
        title: '6000',
        constant: [
            0, 603, 1208, 1817, 2431, 3053, 3686, 4263, 4706, 443, 5149, 5592
        ]
    },
    {
        key: 6500,
        title: '6500',
        constant: [
            0, 612, 1226, 1843, 2466, 3098, 3742, 4316, 4763, 447, 5210, 5657
        ]
    },
    {
        key: 7000,
        title: '7000',
        constant: [
            0, 621, 1243, 1869, 2501, 3143, 3797, 4368, 4820, 452, 5272, 5724
        ]
    },
    {
        key: 7500,
        title: '7500',
        constant: [
            0, 629, 1260, 1895, 2537, 3187, 3852, 4421, 4877, 456, 5333, 5789
        ]
    },
    {
        key: 8000,
        title: '8000',
        constant: [
            0, 638, 1278, 1921, 2572, 3232, 3906, 4474, 4933, 459, 5392, 5851
        ]
    },
    {
        key: 8500,
        title: '8500',
        constant: [
            0, 647, 1295, 1947, 2607, 3276, 3961, 4526, 4989, 463, 5452, 5915
        ]
    },
    {
        key: 9000,
        title: '9000',
        constant: [
            0, 655, 1312, 1974, 2642, 3321, 4008, 4578, 5045, 467, 5512, 5979
        ]
    },
    {
        key: 9500,
        title: '9500',
        constant: [
            0, 664, 1329, 2000, 2677, 3365, 4055, 4630, 5101, 471, 5572, 6043
        ]
    },
    {
        key: 10000,
        title: '10000',
        constant: [
            0, 673, 1347, 2026, 2712, 3409, 4101, 4681, 5157, 476, 5633, 6109
        ]
    },
    {
        key: 10500,
        title: '10500',
        constant: [
            0, 682, 1364, 2051, 2746, 3453, 4148, 4733, 5213, 480, 5693, 6173
        ]
    },
    {
        key: 11000,
        title: '11000',
        constant: [
            0, 690, 1381, 2077, 2781, 3497, 4194, 4784, 5268, 484, 5752, 6236
        ]
    },
    {
        key: 11500,
        title: '11500',
        constant: [
            0, 699, 1399, 2103, 2816, 3541, 4240, 4836, 5323, 487, 5810, 6297
        ]
    },
    {
        key: 12000,
        title: '12000',
        constant: [
            0, 707, 1416, 2129, 2851, 3585, 4286, 4887, 5378, 491, 5869, 6360
        ]
    },
    {
        key: 12500,
        title: '12500',
        constant: [
            0, 716, 1433, 2155, 2885, 3629, 4332, 4938, 5433, 495, 5928, 6423
        ]
    },
    {
        key: 13000,
        title: '13000',
        constant: [
            0, 725, 1451, 2181, 2920, 3672, 4378, 4989, 5488, 499, 5987, 6486
        ]
    },
    {
        key: 13500,
        title: '13500',
        constant: [
            0, 734, 1468, 2207, 2954, 3716, 4424, 5039, 5542, 503, 6045, 6548
        ]
    },
    {
        key: 14000,
        title: '14000',
        constant: [
            0, 742, 1485, 2232, 2989, 3759, 4470, 5090, 5597, 507, 6104, 6611
        ]
    },
    {
        key: 14500,
        title: '14500',
        constant: [
            0, 751, 1502, 2258, 3023, 3802, 4515, 5141, 5651, 510, 6161, 6671
        ]
    },
    {
        key: 15000,
        title: '15000',
        constant: [
            0, 760, 1520, 2284, 3057, 3845, 4561, 5191, 5705, 514, 6219, 6733
        ]
    },
    {
        key: 15500,
        title: '15500',
        constant: [
            0, 769, 1537, 2310, 3092, 3888, 4606, 5241, 5760, 519, 6279, 6798
        ]
    },
    {
        key: 16000,
        title: '16000',
        constant: [
            0, 777, 1554, 2335, 3126, 3931, 4651, 5291, 5813, 522, 6335, 6857
        ]
    },
    {
        key: 16500,
        title: '16500',
        constant: [
            0, 786, 1572, 2361, 3160, 3974, 4696, 5341, 5867, 526, 6393, 6919
        ]
    },
    {
        key: 17000,
        title: '17000',
        constant: [
            0, 796, 1589, 2387, 3194, 4013, 4742, 5391, 5921, 530, 6451, 6981
        ]
    },


]

const depthTable = [
    0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 7500,
    8000, 8500, 9000, 9500, 10000, 10500, 11000, 11500, 12000, 12500, 13000, 13500, 14000,
    14500, 15000, 15500, 16000, 16500, 17000
]

const pressureHeadTable = [
    0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000
]


export { bottomPressureTable, pressureHeadTable, PressureHeadProps, depthTable }