import React, { useEffect } from 'react';

import { useTheme } from 'styled-components/native';

import { useNavigation, useRoute } from '@react-navigation/native';

import {useTranslation} from 'react-i18next';

import {
    Container,
    Header,
    BackButtonWrapper,
    TemperatureCalculated,
    PressureCalculated,
    VolumeCalculated,
    TimeCalculated,
    HeaderTitleParameters,
    HeaderTitle,
    ResultWrapper,
    CalculationResultGas,
    CalculationResultLiquid,
    CalculationResultGasLost,
    CalculationResultTime,
    Result,
    Separator,
    EmptyView,
    ContainerModal,
    ScrollContainer,
    ScrollWrapper,
    PressableContainer,
    PressableText,
    IconContainer,
    Title,
    Message,
    PressableContainerJobs,
    TextDataWrapper,
    TextWrapper,
    PressableTextBold,
    PressableTextTotal,
    PressableTextTotalBold,
    Divider,
    PressableTextTitle
} from './styles';

import { BackButton } from '../../components/BackButton';
import { SaveButton } from '../../components/SaveButton';
import { FlatList } from 'react-native';

import { Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';

interface PropsList {
    id: string;
    answerItem: number;
    depthItem: string;
    depthSelectedItem: string;
    diameterItem: string;
    diameterIn?: string;
    typeColumn: string;
    typeColumnIn?: string;
}

interface CalculationColumnProps {
    id: string;
    nameJob: string;
    nameCalc: string;
    singleDepth: string;
    singleDepthType: string;
    singleDiameter: string;
    singleAnswer: string;
    singleCardsAnswer: PropsList[];
    anularDepth: string;
    anularDepthType: string;
    anularDiameterOutside: string;
    anularDiameterInside: string;
    anularAnswer: string;
    anularCardsAnswer: PropsList[];
    totalVolume: string;
    date: string;
}

interface Params {
    CalcSelected: CalculationColumnProps;
}

export function ColumVolumeResultSaved() {


    const theme = useTheme();

    const navigation = useNavigation();
    const route = useRoute();
    const {
        CalcSelected
    } = route.params as Params;

    const {t} = useTranslation();

    function handleGoBack() {
        navigation.goBack();
    }

    useEffect(() => {
        console.log('CalcSelected.anularCardsAnswer', CalcSelected.anularCardsAnswer)
    }, [])

    const ItemDivider = () => {
        return (
            <Divider>
                <Feather
                    name='plus'
                    color={theme.colors.site_600}
                    size={24}
                />
            </Divider>

        );
    }

    return (
        <Container>
            <Header>
                <BackButtonWrapper>
                    <BackButton
                        onPress={handleGoBack}
                        color={theme.colors.backgroud_secondary}
                    />
                    <SaveButton
                        onPress={() => { }}
                        color={theme.colors.main}

                    />
                </BackButtonWrapper>
                <HeaderTitleParameters>{t('Dados do cálculo de')} {CalcSelected.nameCalc}:</HeaderTitleParameters>

            </Header>
            {
                CalcSelected.singleCardsAnswer.length !== 0 &&
                <ResultWrapper>

                    <TextWrapper>
                        <TextDataWrapper>
                            <PressableTextTitle>{t('Coluna Simples')}</PressableTextTitle>
                            <FlatList
                                data={CalcSelected.singleCardsAnswer}
                                keyExtractor={(item) => item.id}
                                renderItem={({ item }) => (
                                    <TextDataWrapper>
                                        <PressableText>{t('Comprimento da coluna')}: <PressableTextBold>{item.depthItem} {item.depthSelectedItem}</PressableTextBold></PressableText>
                                        <PressableText>{t('Tipo')}: <PressableTextBold>{item.typeColumn === 'Tipo de coluna' ? `${t('tubo de ID')} ${item.diameterItem}"` : item.typeColumn}</PressableTextBold></PressableText>
                                        <PressableText>{t('ID da coluna')}: <PressableTextBold>{item.diameterItem}"</PressableTextBold></PressableText>
                                        <PressableText>{t('Volume da coluna')}: <PressableTextBold>{item.answerItem.toFixed(2).replace('.', ',')} bbl</PressableTextBold></PressableText>
                                    </TextDataWrapper>


                                )}
                                ItemSeparatorComponent={ItemDivider}


                            />
                            <Divider>
                                <MaterialCommunityIcons
                                    name='equal'
                                    color={theme.colors.site_600}
                                    size={24}
                                />
                            </Divider>
                            <PressableText>{t('Volume total')}: <PressableTextBold>{CalcSelected.singleAnswer} bbl</PressableTextBold></PressableText>
                        </TextDataWrapper>
                    </TextWrapper>
                    {CalcSelected.anularCardsAnswer.length !== 0 && <Separator />}
                </ResultWrapper>
            }

            {
                CalcSelected.anularCardsAnswer.length !== 0 &&
                <ResultWrapper>



                    <TextWrapper>
                        <TextDataWrapper>
                            <PressableTextTitle>{t('Coluna Anular')}</PressableTextTitle>
                            <FlatList
                                data={CalcSelected.anularCardsAnswer}
                                keyExtractor={(item) => item.id}
                                renderItem={({ item }) => (
                                    <TextDataWrapper>
                                        <PressableText>{t('Comprimento do anular')}: <PressableTextBold>{item.depthItem} {item.depthSelectedItem}</PressableTextBold></PressableText>
                                        <PressableText>{t('Tipo da coluna externa')}: <PressableTextBold>{item.typeColumn === 'Tipo de coluna externa' ? `${t('coluna de ID')} ${item.diameterItem}"` : item.typeColumn}</PressableTextBold></PressableText>
                                        <PressableText>{t('Tipo da coluna interna')}: <PressableTextBold>{item.typeColumnIn === 'Tipo de coluna interna' ? `${t('coluna de OD')} ${item.diameterIn}"` : item.typeColumnIn}</PressableTextBold></PressableText>
                                        <PressableText>{t('ID da coluna externa')}: <PressableTextBold>{item.diameterItem}"</PressableTextBold></PressableText>
                                        <PressableText>{t('OD da coluna interna')}: <PressableTextBold>{item.diameterIn}"</PressableTextBold></PressableText>
                                        <PressableText>{t('Volume do anular')}: <PressableTextBold>{item.answerItem.toFixed(2).replace('.', ',')} bbl</PressableTextBold></PressableText>
                                    </TextDataWrapper>


                                )}
                                ItemSeparatorComponent={ItemDivider}
                            />
                            <Divider>
                                <MaterialCommunityIcons
                                    name='equal'
                                    color={theme.colors.site_600}
                                    size={24}
                                />
                            </Divider>

                            <PressableText>{t('Volume total anular')}: <PressableTextBold>{CalcSelected.anularAnswer} bbl</PressableTextBold></PressableText>
                        </TextDataWrapper>
                    </TextWrapper>

                </ResultWrapper>

            }

            <Separator />

            <ResultWrapper>
                <PressableTextTotal>{'\n'}{t('Volume total')}: <PressableTextTotalBold>{CalcSelected.totalVolume} bbl</PressableTextTotalBold></PressableTextTotal>

            </ResultWrapper>

        </Container>
    );
}

