import React from 'react';
import { RectButtonProps } from 'react-native-gesture-handler';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
    ButtonTemperature
} from './styles';

import { RFValue } from 'react-native-responsive-fontsize';

import { useTheme } from 'styled-components/native';

interface Props extends RectButtonProps {
    active?: boolean;
}

export function DeleteAllButton({
    active,
    ...rest
}: Props) {

    const theme = useTheme();

    return (
        <ButtonTemperature
            active={active}
            {...rest}>
            <MaterialCommunityIcons
                name='trash-can-outline'
                color={theme.colors.backgroud_secondary}
                size={24}
            />
        </ButtonTemperature>
    );
}