import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import { Dimensions } from 'react-native';

const maxWidth = 750;
interface OptionsProps {
    active?: boolean;
}

export const ButtonTemperature = styled.TouchableOpacity <OptionsProps>`
    background-color: ${({ theme, active }) =>
        active ? theme.colors.site_600 : theme.colors.site_600};
    width: ${Dimensions.get('window').width < maxWidth ? RFValue(40) : 40}px;
    height: ${Dimensions.get('window').width < maxWidth ? RFValue(40) : 40}px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin: 5px 5px;
`;
