import React from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { useTheme } from 'styled-components/native';
import { RectButtonProps } from 'react-native-gesture-handler';
import {
    Container
} from './styles';
import { RFValue } from 'react-native-responsive-fontsize';
interface Props extends RectButtonProps {
    color?: string;
}

export function CancelButton({ color, ...rest }: Props) {

    const theme = useTheme();

    return (
        <Container {...rest}>
            <MaterialIcons
                name="cancel"
                size={36}
                color={color ? color : theme.colors.text}
            />
        </Container>
    );
}