import React from "react";

import { NavigationContainer } from "@react-navigation/native";

import { AppDrawerRoutes } from "./app.drawer.routes";

export function Routes() {
    return (
        <NavigationContainer>

            <AppDrawerRoutes />


        </NavigationContainer>

    )
}