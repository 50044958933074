import React, { useState, useEffect } from 'react';
import {
    Keyboard,
    ScrollView,
    Modal,
    FlatList,
    View,
    Platform,
} from 'react-native';

import {
    ButtonView,
    InputWrapper,
    Empty,
    PressureSelectorWrapper,
    DepthContainer,
    Content,
    AnswerView,
    ButtonNavigation,
    TextNavigation,
    MessageWrapper,
    Footer,
    VolumeSelectorContainer,
    HelpContainer,
    ContainerModal,
    ScrollContainer,
    ScrollWrapper,
    PressableContainer,
    PressableText,
    IconContainer,
    Title,
    Message,
    PressableContainerJobs,
    ContainerModalView,
    EmptyMessageWrapper,
    EmptyMessage,
    PressableContainerSave,
    PressableTextSave,
    AskWrapper,
    AskText,
    CheckBoxTouch,
    HelpWrapper
} from './styles';

import { MaterialIcons } from '@expo/vector-icons';

import { useTheme } from 'styled-components/native';

import { useIsFocused } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';

import uuid from 'react-native-uuid';

import AsyncStorage from '@react-native-async-storage/async-storage';

import {useTranslation} from 'react-i18next';

import * as Clipboard from 'expo-clipboard';

import { Feather } from '@expo/vector-icons';

import { pressureHeadTable, depthTable, bottomPressureTable } from '../../utils/WellHeadPressureN2';
import { pressureHeadTable10, depthTable10, bottomPressureTable10 } from '../../utils/WellHeadPressure';


import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { PressureSelector } from '../../components/PressureSelector';
import { AnswerCard } from '../../components/AnswerCard';
import { ClearButton } from '../../components/ClearButton';
import { HelpButton } from '../../components/HelpButton';
import { PressurizationScreenModal } from '../../components/Modals/PressurizationScreenModal';
import { DeepMessage, DensityMessage, DepthMessage } from '../../components/Modals/HydrostaticPressureScreenModal/message';
import { HeaderHydrostaticPressure } from '../../components/HeaderHydrostaticPressure';
import { BlurView } from 'expo-blur';
import { BackButton } from '../../components/BackButton';
import { ForwardArrow } from '../../components/ForwardArrow';
import { Load } from '../../components/Load';
interface CalculationSaved {
    id: string;
    nameJob: string;
    nameCalc: string;
    depth: string;
    density: string;
    answerBottom: string;
    depthTypeSelected: string;
    answerHeadPressure: string;
    date: string;
}

export function HydrostaticPressureCalculation() {

    const [density, setDensity] = useState('');
    const [depth, setDepth] = useState('');
    const [answer, setAnswer] = useState('');

    const [answerHeadPressure, setAnswerHeadPressure] = useState('');

    const [depthTypeSelected, setDepthTypeSelected] = useState<'m' | 'ft'>('m');
    const [pressureTypeSelected, setPressureTypeSelected] = useState<'psi' | 'bar'>('psi');


    const [pasted, setPasted] = useState(false);
    const [copiedText, setCopiedText] = useState('');

    const [openModalDepth, setOpenModalDepth] = useState(false);
    const [openModalDensity, setOpenModalDensity] = useState(false);

    const [activeSaveButton, setActiveSaveButton] = useState(false);

    const [openModalSave, setOpenModalSave] = useState(false);
    const [openChooseJob, setOpenChooseJob] = useState(false);
    const [openChooseCalc, setOpenChooseCalc] = useState(false);


    const [newJob, setNewJob] = useState('');
    const [newCalc, setNewCalc] = useState('');

    const [calculations, setCalculations] = useState([]);

    const [checkBox, setCheckBox] = useState(false);
    const [bottomPressure, setBottomPressure] = useState('');

    const theme = useTheme();

    const { navigate } = useNavigation();

    const navigation = useNavigation();

    const screenIsFocus = useIsFocused();

    const {t} = useTranslation();

    function handleDepthTypeSelected(depthType: 'm' | 'ft') {
        setDepthTypeSelected(depthType);
    };

    function handlePressureTypeSelected(pressureType: 'psi' | 'bar') {
        setPressureTypeSelected(pressureType);
    };

    // function handleCalculationDirect() {

    //     if (!bottomPressure) {
    //         alert('Insira a pressão de fundo!')
    //         return;
    //     } else if (!depth) {
    //         alert('Insira a profundidade!')
    //         return;
    //     } else if (pressureTypeSelected === 'psi' && Number(bottomPressure) > 22000) {
    //         alert('Pressão maior que 22.000 psi! Cálculo não permitido')
    //         return;
    //     } else if (pressureTypeSelected === 'bar' && Number(bottomPressure) > 1520) {
    //         alert('Pressão maior que 1520 bar! Cálculo não permitido')
    //         return;
    //     }
    //     else if (Number(depth) > 15000) {
    //         alert('Profundidade acima de 15.000 m não permitida!')
    //         return;
    //     }

    //     setLoading(true);

    //     const depthNew = depthTypeSelected === 'ft' ? Number((Number(depth.replace(',', '.')) / 3.2808).toFixed(0)) : Number(depth.replace(',', '.'));
    //     //const depthNew = 1774
    //     const concentrationO2 = 1;

    //     function getDepthArray() {
    //         let depthConstant = 1;
    //         let arrayDepth = [];

    //         for (let index = 0; index < 1000; index++) {
    //             const depthLine = depthConstant * (depthNew / 1000);
    //             arrayDepth[index] = depthLine;
    //             depthConstant++;
    //         }
    //         //console.log('arrayDepth', arrayDepth)
    //         return arrayDepth;
    //     };

    //     //head

    //     function getArrayHead() {
    //         const arrayDepth = getDepthArray();
    //         let arrayHead = [];

    //         for (let index = 0; index < 1000; index++) {
    //             const headLine = (999 * 9.82 * arrayDepth[index]) / 100000;
    //             arrayHead[index] = headLine;
    //         }
    //         return arrayHead
    //     };
    //     const getarrayHeadLog = getArrayHead();
    //     //console.log('getarrayHeadLog', getarrayHeadLog)
    //     //Perfil de pressão e Densidade

    //     function getArrayPressureProfileAndDensity() {

    //         const arrayDepth = getDepthArray();

    //         let arrayDensity = [];

    //         let arrayPressureProfile = [];
    //         const target = pressureTypeSelected === 'psi' ? Number((Number(bottomPressure.replace(',', '.')) / 14.5038).toFixed(1)) : Number(bottomPressure.replace(',', '.'));

    //         arrayPressureProfile[0] = target - 1;
    //         arrayPressureProfile[999] = 10000000;
    //         //const target = Number(Number(Number(density.replace(',', '.')) / 14.5038).toFixed(1));

    //         while (arrayPressureProfile[999] >= target) {

    //             arrayDensity[0] = (((concentrationO2 / 100) * 32 + (1 - (concentrationO2 / 100)) * 28) * (arrayPressureProfile[0] / 1.01325 + 1)) / (0.082 * (273.15 + 10));


    //             for (let index = 1; index < 1000; index++) {
    //                 arrayPressureProfile[index] = ((9.82 * (arrayDepth[index] - arrayDepth[index - 1]) * arrayDensity[index - 1]) / 100000) + arrayPressureProfile[index - 1];
    //                 arrayDensity[index] = ((0.05 * 32 + 0.95 * 28) * (arrayPressureProfile[index] / 1.01325 + 1)) / (0.082 * (273.15 + 10))
    //             }

    //             arrayPressureProfile[999] = Number(arrayPressureProfile[999].toFixed(2))

    //             arrayPressureProfile[0] -= 0.01;


    //         }

    //         return arrayPressureProfile[0] as number

    //     }
    //     const answer = getArrayPressureProfileAndDensity();
    //     const answerFormatted = pressureTypeSelected === 'psi' ? answer * 14.5038 : answer;
    //     setAnswer(String(bottomPressure).replace('.', ','));
    //     setAnswerHeadPressure(answerFormatted.toFixed(2).replace('.', ','));
    //     setActiveSaveButton(true);
    //     setLoading(false);
    //     return

    // }


    // function handleCalculation() {

    //     if (!density) {
    //         alert('Insira a densidade do fluido!')
    //         return;
    //     } else if (!depth) {
    //         alert('Insira a profundidade!')
    //         return;
    //     } else if (Number(density) > 30) {
    //         alert('Densidade do fluido não permitida!')
    //         return;
    //     } else if (Number(depth) > 15000) {
    //         alert('Profundidade acima de 15.000 m não permitida!')
    //         return;
    //     }
    //     setLoading(true);

    //     const depthFormatted = Number(depth.replace(',', '.'));
    //     const densityFormatted = Number(density.replace(',', '.'));
    //     const constantFormatted = depthTypeSelected === 'm' ? 0.1706 : 0.052;
    //     const answerForCalc = depthFormatted * densityFormatted * constantFormatted;
    //     const answerFormat = answerForCalc.toFixed(1);
    //     setAnswer(String(answerFormat).replace('.', ','));

    //     if (answerForCalc > 22000) {
    //         alert('Pressão maior que 22.000! Cálculo não permitido')
    //         return;
    //     }

    //     Keyboard.dismiss();


    //     const depthNew = depthTypeSelected === 'ft' ? Number((Number(depth.replace(',', '.')) / 3.2808).toFixed(0)) : Number(depth.replace(',', '.'));
    //     //const depthNew = 1774
    //     const concentrationO2 = 1;

    //     function getDepthArray() {
    //         let depthConstant = 1;
    //         let arrayDepth = [];

    //         for (let index = 0; index < 1000; index++) {
    //             const depthLine = depthConstant * (depthNew / 1000);
    //             arrayDepth[index] = depthLine;
    //             depthConstant++;
    //         }
    //         //console.log('arrayDepth', arrayDepth)
    //         return arrayDepth;
    //     };

    //     //head

    //     function getArrayHead() {
    //         const arrayDepth = getDepthArray();
    //         let arrayHead = [];

    //         for (let index = 0; index < 1000; index++) {
    //             const headLine = (999 * 9.82 * arrayDepth[index]) / 100000;
    //             arrayHead[index] = headLine;
    //         }
    //         return arrayHead
    //     };
    //     const getarrayHeadLog = getArrayHead();
    //     //console.log('getarrayHeadLog', getarrayHeadLog)
    //     //Perfil de pressão e Densidade

    //     function getArrayPressureProfileAndDensity() {

    //         const arrayDepth = getDepthArray();

    //         let arrayDensity = [];

    //         let arrayPressureProfile = [];
    //         const target = pressureTypeSelected === 'psi' ? Number((Number(answerFormat.replace(',', '.')) / 14.5038).toFixed(1)) : Number(bottomPressure.replace(',', '.'));

    //         arrayPressureProfile[0] = target - 1;
    //         arrayPressureProfile[999] = 10000000;
    //         //const target = Number(Number(Number(density.replace(',', '.')) / 14.5038).toFixed(1));

    //         while (arrayPressureProfile[999] >= target) {

    //             arrayDensity[0] = (((concentrationO2 / 100) * 32 + (1 - (concentrationO2 / 100)) * 28) * (arrayPressureProfile[0] / 1.01325 + 1)) / (0.082 * (273.15 + 10));


    //             for (let index = 1; index < 1000; index++) {
    //                 arrayPressureProfile[index] = ((9.82 * (arrayDepth[index] - arrayDepth[index - 1]) * arrayDensity[index - 1]) / 100000) + arrayPressureProfile[index - 1];
    //                 arrayDensity[index] = ((0.05 * 32 + 0.95 * 28) * (arrayPressureProfile[index] / 1.01325 + 1)) / (0.082 * (273.15 + 10))
    //             }

    //             arrayPressureProfile[999] = Number(arrayPressureProfile[999].toFixed(2))

    //             arrayPressureProfile[0] -= 0.01;


    //         }

    //         return arrayPressureProfile[0] as number

    //     }
    //     const answer = getArrayPressureProfileAndDensity();
    //     const answerFormatted = pressureTypeSelected === 'psi' ? answer * 14.5038 : answer;
    //     setAnswer(String(answerFormat).replace('.', ','));
    //     setAnswerHeadPressure(answerFormatted.toFixed(2).replace('.', ','));
    //     setActiveSaveButton(true);
    //     setLoading(false);
    //     return

    // }

    // function handleCalculationDirectOld() {
    //     if (!bottomPressure) {
    //         alert('Insira a pressão no fundo!')
    //         return;
    //     } else if (!depth) {
    //         alert('Insira a profundidade!')
    //         return;
    //     }

    //     const depthFormatted = Number(depth.replace(',', '.'));
    //     const answerForCalc = Number(bottomPressure.replace(',', '.'))
    //     const answerFormatted = answerForCalc.toFixed(1);
    //     setAnswer(String(answerFormatted).replace('.', ','));

    //     Keyboard.dismiss();

    //     function findPressureValues() {
    //         const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;


    //         const HidroStaticPressure = Number(bottomPressure.replace(',', '.'));


    //         function closest(value, array) {
    //             var result = [];
    //             array.some(function (a) {
    //                 if (a > value) {
    //                     return result.push(a);
    //                 }
    //                 result = [a];
    //                 return a === value;
    //             });
    //             return result;
    //         }

    //         const close = closest(HidroStaticPressure, bottomPressureTable);
    //         return close;

    //     }

    //     const pressureValues = findPressureValues();
    //     const pressureDown = pressureValues[0];
    //     const pressureUp = pressureValues[1] ? pressureValues[1] : pressureValues[0]

    //     console.log('pressureDown', pressureDown);
    //     console.log('HydrostaticReal', Number(bottomPressure.replace(',', '.')));
    //     console.log('pressureUp', pressureUp);



    //     function findDepthValues() {
    //         const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

    //         function closest(value, array) {
    //             var result = [];
    //             array.some(function (a) {
    //                 if (a > value) {
    //                     return result.push(a);
    //                 }
    //                 result = [a];
    //                 return a === value;
    //             });
    //             return result;
    //         }

    //         const close = closest(depthFormatted, depthTable);
    //         return close;

    //     }

    //     const depthValues = findDepthValues();
    //     const depthDown = depthValues[0];
    //     const depthUp = depthValues[1] ? depthValues[1] : depthValues[0]
    //     console.log('depthDown', depthDown);

    //     console.log('depthUp', depthUp);


    //     function getIndex(array, value) {

    //         const positionPressureHeadDownDown = array.indexOf(value);
    //         //console.log(positionPressureHeadDownDown);
    //         const tableValuePressureHeadDownDown = bottomPressureTable[positionPressureHeadDownDown];
    //         //console.log(tableValuePressureHeadDownDown);
    //         return positionPressureHeadDownDown

    //     }

    //     function getArray(headPressure) {
    //         const pressureConstantSelected = pressureHeadTable.find(depth =>
    //             depth.key === headPressure)

    //         if (!pressureConstantSelected)
    //             return;
    //         const constantsFormatted = pressureConstantSelected.constant;
    //         return constantsFormatted;
    //     }
    //     const arrayPressureDown = getArray(pressureDown);

    //     const positionDepthDownDown = getIndex(depthTable, depthDown);
    //     //console.log('Posição de depthDownDown no array', positionDepthDownDown)


    //     const pressureWellHeadDownDown = arrayPressureDown[positionDepthDownDown]; // valor será usado
    //     console.log('pressureWellHeadDownDown', pressureWellHeadDownDown)


    //     const positionDepthDownUp = getIndex(depthTable, depthUp);
    //     //console.log('Posição de depthDownUp no array', positionDepthDownUp);

    //     const pressureWellHeadDownUp = arrayPressureDown[positionDepthDownUp]; // valor será usado
    //     console.log('pressureWellHeadDownUp', pressureWellHeadDownUp)


    //     const arrayPressureUp = getArray(pressureUp);

    //     const positionDepthUpDown = getIndex(depthTable, depthDown);
    //     //console.log('Posição de depthDownDown no array', positionDepthUpDown)


    //     const pressureWellHeadUpDown = arrayPressureUp[positionDepthUpDown]; // valor será usado
    //     console.log('pressureWellHeadUpDown', pressureWellHeadUpDown)


    //     const positionDepthUpUp = getIndex(depthTable, depthUp);
    //     //console.log('Posição de depthDownUp no array', positionDepthUpUp);

    //     const pressureWellHeadUpUp = arrayPressureUp[positionDepthUpUp]; // valor será usado
    //     console.log('pressureWellHeadUpUp', pressureWellHeadUpUp)

    //     const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));


    //     if (hydrostaticPressureFormatted === 0) {

    //         const pressureWellHead = 0;

    //         setAnswerHeadPressure(pressureWellHead.toFixed(2).replace('.', ','))
    //         setActiveSaveButton(true);
    //         return
    //     }
    //     if (hydrostaticPressureFormatted > 10000) {
    //         alert('Pressão de fundo acima de 10.000 psi! Impossível calcular pressão de fundo!')
    //         setAnswerHeadPressure('');
    //         return
    //     }
    //     if (pressureUp === pressureDown && depthDown === depthUp) {
    //         const pressureWellHead = pressureWellHeadDownDown;
    //         console.log('Resultado Final', pressureWellHead);
    //         setAnswerHeadPressure(pressureWellHead.toFixed(2).replace('.', ','));
    //         setActiveSaveButton(true);
    //         return
    //     }
    //     else if (pressureUp === pressureDown) {
    //         const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));
    //         const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

    //         const pressureWellHeadDown = pressureWellHeadDownDown;
    //         console.log(pressureWellHeadDown);

    //         const pressureWellHeadUp = pressureWellHeadDownUp;
    //         console.log(pressureWellHeadUp);

    //         const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
    //         console.log('Resultado Final', pressureWellHead);
    //         setAnswerHeadPressure(pressureWellHead.toFixed(2).replace('.', ','));
    //         setActiveSaveButton(true);
    //         return
    //     }
    //     else if (depthDown === depthUp) {
    //         const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));

    //         const pressureWellHead = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadUpDown - pressureWellHeadDownDown) + pressureWellHeadDownDown;
    //         console.log(pressureWellHead);

    //         setAnswerHeadPressure(pressureWellHead.toFixed(2).replace('.', ','))
    //         setActiveSaveButton(true);
    //         return

    //     } else {
    //         const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));
    //         const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

    //         const pressureWellHeadDown = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadUpDown - pressureWellHeadDownDown) + pressureWellHeadDownDown;
    //         console.log(pressureWellHeadDown);

    //         const pressureWellHeadUp = (((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadUpUp - pressureWellHeadDownUp)) + pressureWellHeadDownUp;
    //         console.log(pressureWellHeadUp);

    //         const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
    //         console.log('Resultado Final', pressureWellHead);
    //         setAnswerHeadPressure(pressureWellHead.toFixed(2).replace('.', ','));
    //         setActiveSaveButton(true);
    //         return
    //     }

    // }
    // Calculation less then 5000 psi
    function handleCalculation() {
        if (!density) {
            alert(t('Insira a densidade do fluido!'))
            return;
        } else if (!depth) {
            alert(t('Insira a profundidade!'))
            return;
        }

        const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;
        const densityFormatted = Number(density.replace(',', '.'));
        const answerForCalc = depthFormatted * densityFormatted * 0.052;

        const answerFormatted = answerForCalc.toFixed(1);
        setAnswer(String(answerFormatted).replace('.', ','));

        Keyboard.dismiss();

        function findDepthValues() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(depthFormatted, depthTable);
            return close;


        }

        const depthValues = findDepthValues();
        const depthDown = depthValues[0];
        const depthUp = depthValues[1] ? depthValues[1] : depthValues[0]
        console.log('depthDown', depthDown);

        console.log('depthUp', depthUp);

        function getArray(headPressure) {
            const pressureConstantSelected = bottomPressureTable.find(depth =>
                depth.key === headPressure)

            if (!pressureConstantSelected)
                return;
            const constantsFormatted = pressureConstantSelected.constant;
            return constantsFormatted;
        }
        const arrayPressureDown = getArray(depthDown);
        //console.log('arrayPressureDown', arrayPressureDown);

        function findPressureValues() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;



            const HidroStaticPressure = answerForCalc;


            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(HidroStaticPressure, arrayPressureDown);
            return close;

        }

        const pressureValues = findPressureValues();
        const pressureDown = pressureValues[0];
        const pressureUp = pressureValues[1] ? pressureValues[1] : pressureValues[0]

        console.log('pressureDown', pressureDown);
        //console.log('HydrostaticReal', Number(bottomPressure.replace(',', '.')));
        console.log('pressureUp', pressureUp);

        function getIndex(array, value) {

            const positionPressureHeadDownDown = array.indexOf(value);
            //console.log(positionPressureHeadDownDown);
            return positionPressureHeadDownDown

        }

        //Down

        const positionDepthDownDown = getIndex(arrayPressureDown, pressureDown);
        //console.log('Posição de depthDownDown no array', positionDepthDownDown)


        const pressureWellHeadDownDown = pressureHeadTable[positionDepthDownDown]; // valor será usado
        console.log('pressureWellHeadDownDown', pressureWellHeadDownDown)


        const positionDepthDownUp = getIndex(arrayPressureDown, pressureUp);
        //console.log('Posição de depthDownUp no array', positionDepthDownUp);

        const pressureWellHeadDownUp = pressureHeadTable[positionDepthDownUp]; // valor será usado
        console.log('pressureWellHeadDownUp', pressureWellHeadDownUp)

        //Up

        const arrayPressureUp = getArray(depthUp);

        function findPressureValuesUp() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;


            const HidroStaticPressure = answerForCalc;


            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(HidroStaticPressure, arrayPressureUp);
            return close;

        }

        const pressureValuesUp = findPressureValuesUp();
        const pressureDownUp = pressureValuesUp[0];
        const pressureUpUp = pressureValuesUp[1] ? pressureValuesUp[1] : pressureValuesUp[0]

        console.log('pressureDownUp', pressureDownUp);
        console.log('HydrostaticReal', answerForCalc);
        console.log('pressureUpUp', pressureUpUp);

        const positionDepthUpDown = getIndex(arrayPressureUp, pressureDownUp);
        //console.log('Posição de depthDownDown no array', positionDepthUpDown)


        const pressureWellHeadUpDown = pressureHeadTable[positionDepthUpDown]; // valor será usado
        console.log('pressureWellHeadUpDown', pressureWellHeadUpDown)


        const positionDepthUpUp = getIndex(arrayPressureUp, pressureUpUp);
        //console.log('Posição de depthDownUp no array', positionDepthUpUp);

        const pressureWellHeadUpUp = pressureHeadTable[positionDepthUpUp]; // valor será usado
        console.log('pressureWellHeadUpUp', pressureWellHeadUpUp)

        const hydrostaticPressureFormatted = answerForCalc;

        if (hydrostaticPressureFormatted === 0) {

            const pressureWellHead = 0;

            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','))
            setActiveSaveButton(true);
            return
        }
        if (hydrostaticPressureFormatted > 5000) {
            alert(t('Pressão de fundo acima de 5.000 psi! Impossível calcular pressão de cabeça!'))
            setAnswerHeadPressure('');
            return
        }
        if (pressureUp === pressureDown && depthDown === depthUp) {
            const pressureWellHead = pressureWellHeadDownDown;
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }
        else if (pressureUp === pressureDown) {
            const hydrostaticPressureFormatted = answerForCalc;
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = pressureWellHeadUpUp;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = (((hydrostaticPressureFormatted - pressureDownUp) / (pressureUpUp - pressureDownUp)) * (pressureWellHeadUpUp - pressureWellHeadUpDown)) + pressureWellHeadUpDown;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }
        else if (depthDown === depthUp) {
            const hydrostaticPressureFormatted = answerForCalc;

            const pressureWellHead = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadDownUp - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHead);

            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','))
            setActiveSaveButton(true);
            return

        } else if (pressureDownUp === pressureUpUp) {
            const hydrostaticPressureFormatted = answerForCalc;
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadDownUp - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = pressureWellHeadUpDown;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return

        } else {
            const hydrostaticPressureFormatted = answerForCalc;
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadDownUp - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = (((hydrostaticPressureFormatted - pressureDownUp) / (pressureUpUp - pressureDownUp)) * (pressureWellHeadUpUp - pressureWellHeadUpDown)) + pressureWellHeadUpDown;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }




    }

    function handleCalculationDirect() {
        if (!bottomPressure) {
            alert(t('Insira a pressão no fundo!'))
            return;
        } else if (!depth) {
            alert(t('Insira a profundidade!'))
            return;
        }

        const depthFormatted = Number(depth.replace(',', '.'));
        const answerForCalc = Number(bottomPressure.replace(',', '.'))
        const answerFormatted = answerForCalc.toFixed(1);
        setAnswer(String(answerFormatted).replace('.', ','));

        Keyboard.dismiss();

        function findDepthValues() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(depthFormatted, depthTable);
            return close;


        }

        const depthValues = findDepthValues();
        const depthDown = depthValues[0];
        const depthUp = depthValues[1] ? depthValues[1] : depthValues[0]
        console.log('depthDown', depthDown);

        console.log('depthUp', depthUp);

        function getArray(headPressure) {
            const pressureConstantSelected = bottomPressureTable.find(depth =>
                depth.key === headPressure)

            if (!pressureConstantSelected)
                return;
            const constantsFormatted = pressureConstantSelected.constant;
            return constantsFormatted;
        }
        const arrayPressureDown = getArray(depthDown);
        //console.log('arrayPressureDown', arrayPressureDown);

        function findPressureValues() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;


            const HidroStaticPressure = Number(bottomPressure.replace(',', '.'));


            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(HidroStaticPressure, arrayPressureDown);
            return close;

        }

        const pressureValues = findPressureValues();
        const pressureDown = pressureValues[0];
        const pressureUp = pressureValues[1] ? pressureValues[1] : pressureValues[0]

        console.log('pressureDown', pressureDown);
        console.log('HydrostaticReal', Number(bottomPressure.replace(',', '.')));
        console.log('pressureUp', pressureUp);

        function getIndex(array, value) {

            const positionPressureHeadDownDown = array.indexOf(value);
            //console.log(positionPressureHeadDownDown);
            return positionPressureHeadDownDown

        }

        //Down

        const positionDepthDownDown = getIndex(arrayPressureDown, pressureDown);
        //console.log('Posição de depthDownDown no array', positionDepthDownDown)


        const pressureWellHeadDownDown = pressureHeadTable[positionDepthDownDown]; // valor será usado
        console.log('pressureWellHeadDownDown', pressureWellHeadDownDown)


        const positionDepthDownUp = getIndex(arrayPressureDown, pressureUp);
        //console.log('Posição de depthDownUp no array', positionDepthDownUp);

        const pressureWellHeadDownUp = pressureHeadTable[positionDepthDownUp]; // valor será usado
        console.log('pressureWellHeadDownUp', pressureWellHeadDownUp)

        //Up

        const arrayPressureUp = getArray(depthUp);

        function findPressureValuesUp() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;


            const HidroStaticPressure = Number(bottomPressure.replace(',', '.'));


            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(HidroStaticPressure, arrayPressureUp);
            return close;

        }

        const pressureValuesUp = findPressureValuesUp();
        const pressureDownUp = pressureValuesUp[0];
        const pressureUpUp = pressureValuesUp[1] ? pressureValuesUp[1] : pressureValuesUp[0]

        console.log('pressureDownUp', pressureDownUp);
        console.log('HydrostaticReal', Number(bottomPressure.replace(',', '.')));
        console.log('pressureUpUp', pressureUpUp);

        const positionDepthUpDown = getIndex(arrayPressureUp, pressureDownUp);
        //console.log('Posição de depthDownDown no array', positionDepthUpDown)


        const pressureWellHeadUpDown = pressureHeadTable[positionDepthUpDown]; // valor será usado
        console.log('pressureWellHeadUpDown', pressureWellHeadUpDown)


        const positionDepthUpUp = getIndex(arrayPressureUp, pressureUpUp);
        //console.log('Posição de depthDownUp no array', positionDepthUpUp);

        const pressureWellHeadUpUp = pressureHeadTable[positionDepthUpUp]; // valor será usado
        console.log('pressureWellHeadUpUp', pressureWellHeadUpUp)

        const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));

        if (hydrostaticPressureFormatted === 0) {

            const pressureWellHead = 0;

            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','))
            setActiveSaveButton(true);
            return
        }
        if (hydrostaticPressureFormatted > 5000) {
            alert(t('Pressão de fundo acima de 5.000 psi! Impossível calcular pressão de cabeça!'))
            setAnswerHeadPressure('');
            return
        }
        if (pressureUp === pressureDown && depthDown === depthUp) {
            const pressureWellHead = pressureWellHeadDownDown;
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }
        else if (pressureUp === pressureDown) {
            const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = pressureWellHeadUpUp;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = (((hydrostaticPressureFormatted - pressureDownUp) / (pressureUpUp - pressureDownUp)) * (pressureWellHeadUpUp - pressureWellHeadUpDown)) + pressureWellHeadUpDown;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }
        else if (depthDown === depthUp) {
            const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));

            const pressureWellHead = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadDownUp - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHead);

            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','))
            setActiveSaveButton(true);
            return

        } else if (pressureDownUp === pressureUpUp) {
            const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadDownUp - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = pressureWellHeadUpDown;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return

        } else {
            const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadDownUp - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = (((hydrostaticPressureFormatted - pressureDownUp) / (pressureUpUp - pressureDownUp)) * (pressureWellHeadUpUp - pressureWellHeadUpDown)) + pressureWellHeadUpDown;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }



    }

    // Calculation between 5000 anda 10000 psi
    function handleCalculationOld() {
        if (!density) {
            alert(t('Insira a densidade do fluido!'))
            return;
        } else if (!depth) {
            alert(t('Insira a profundidade!'))
            return;
        }

        const depthFormatted = Number(depth.replace(',', '.'));
        const densityFormatted = Number(density.replace(',', '.'));
        const constantFormatted = depthTypeSelected === 'm' ? 0.1706 : 0.052;
        const answerForCalc = depthFormatted * densityFormatted * constantFormatted;
        const answerFormatted = answerForCalc.toFixed(1);
        setAnswer(String(answerFormatted).replace('.', ','));

        Keyboard.dismiss();

        function findPressureValues() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;


            const HidroStaticPressure = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.052 : Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.1706;


            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(HidroStaticPressure, bottomPressureTable10);
            return close;

        }

        const pressureValues = findPressureValues();
        const pressureDown = pressureValues[0];
        const pressureUp = pressureValues[1] ? pressureValues[1] : pressureValues[0]

        console.log('pressureDown', pressureDown);
        console.log('HydrostaticReal', Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.1706);
        console.log('pressureUp', pressureUp);



        function findDepthValues() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(depthFormatted, depthTable10);
            return close;

        }

        const depthValues = findDepthValues();
        const depthDown = depthValues[0];
        const depthUp = depthValues[1] ? depthValues[1] : depthValues[0]
        console.log('depthDown', depthDown);

        console.log('depthUp', depthUp);


        function getIndex(array, value) {

            const positionPressureHeadDownDown = array.indexOf(value);
            //console.log(positionPressureHeadDownDown);
            return positionPressureHeadDownDown

        }

        function getArray(headPressure) {
            const pressureConstantSelected = pressureHeadTable10.find(depth =>
                depth.key === headPressure)

            if (!pressureConstantSelected)
                return;
            const constantsFormatted = pressureConstantSelected.constant;
            return constantsFormatted;
        }
        const arrayPressureDown = getArray(pressureDown);

        const positionDepthDownDown = getIndex(depthTable10, depthDown);
        //console.log('Posição de depthDownDown no array', positionDepthDownDown)


        const pressureWellHeadDownDown = arrayPressureDown[positionDepthDownDown]; // valor será usado
        console.log('pressureWellHeadDownDown', pressureWellHeadDownDown)


        const positionDepthDownUp = getIndex(depthTable10, depthUp);
        //console.log('Posição de depthDownUp no array', positionDepthDownUp);

        const pressureWellHeadDownUp = arrayPressureDown[positionDepthDownUp]; // valor será usado
        console.log('pressureWellHeadDownUp', pressureWellHeadDownUp)


        const arrayPressureUp = getArray(pressureUp);

        const positionDepthUpDown = getIndex(depthTable10, depthDown);
        //console.log('Posição de depthDownDown no array', positionDepthUpDown)


        const pressureWellHeadUpDown = arrayPressureUp[positionDepthUpDown]; // valor será usado
        console.log('pressureWellHeadUpDown', pressureWellHeadUpDown)


        const positionDepthUpUp = getIndex(depthTable10, depthUp);
        //console.log('Posição de depthDownUp no array', positionDepthUpUp);

        const pressureWellHeadUpUp = arrayPressureUp[positionDepthUpUp]; // valor será usado
        console.log('pressureWellHeadUpUp', pressureWellHeadUpUp)

        const hydrostaticPressureFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.052 : Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.1706;


        if (hydrostaticPressureFormatted === 0) {

            const pressureWellHead = 0;

            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','))
            setActiveSaveButton(true);
            return
        }
        if (hydrostaticPressureFormatted > 10000) {
            alert(t('Pressão de fundo acima de 10.000 psi! Impossível calcular pressão de cabeça!'))
            setAnswerHeadPressure('');
            return
        }
        if (pressureUp === pressureDown && depthDown === depthUp) {
            const pressureWellHead = pressureWellHeadDownDown;
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }
        else if (pressureUp === pressureDown) {
            const hydrostaticPressureFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.052 : Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.1706;
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = pressureWellHeadDownDown;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = pressureWellHeadDownUp;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }
        else if (depthDown === depthUp) {
            const hydrostaticPressureFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.052 : Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.1706;

            const pressureWellHead = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadUpDown - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHead);

            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','))
            setActiveSaveButton(true);
            return

        } else {
            const hydrostaticPressureFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.052 : Number(depth.replace(',', '.')) * Number(density.replace(',', '.')) * 0.1706;
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadUpDown - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = (((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadUpUp - pressureWellHeadDownUp)) + pressureWellHeadDownUp;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }

    }

    function handleCalculationDirectOld() {
        if (!bottomPressure) {
            alert(t('Insira a pressão no fundo!'))
            return;
        } else if (!depth) {
            alert(t('Insira a profundidade!'))
            return;
        }


        const depthFormatted = Number(depth.replace(',', '.'));
        const answerForCalc = Number(bottomPressure.replace(',', '.'))
        const answerFormatted = answerForCalc.toFixed(1);
        setAnswer(String(answerFormatted).replace('.', ','));

        Keyboard.dismiss();

        function findPressureValues() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;


            const HidroStaticPressure = Number(bottomPressure.replace(',', '.'));


            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(HidroStaticPressure, bottomPressureTable10);
            return close;

        }

        const pressureValues = findPressureValues();
        const pressureDown = pressureValues[0];
        const pressureUp = pressureValues[1] ? pressureValues[1] : pressureValues[0]

        console.log('pressureDown', pressureDown);
        console.log('HydrostaticReal', Number(bottomPressure.replace(',', '.')));
        console.log('pressureUp', pressureUp);



        function findDepthValues() {
            const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            function closest(value, array) {
                var result = [];
                array.some(function (a) {
                    if (a > value) {
                        return result.push(a);
                    }
                    result = [a];
                    return a === value;
                });
                return result;
            }

            const close = closest(depthFormatted, depthTable10);
            return close;

        }

        const depthValues = findDepthValues();
        const depthDown = depthValues[0];
        const depthUp = depthValues[1] ? depthValues[1] : depthValues[0]
        console.log('depthDown', depthDown);

        console.log('depthUp', depthUp);


        function getIndex(array, value) {

            const positionPressureHeadDownDown = array.indexOf(value);
            //console.log(positionPressureHeadDownDown);
            const tableValuePressureHeadDownDown = bottomPressureTable10[positionPressureHeadDownDown];
            //console.log(tableValuePressureHeadDownDown);
            return positionPressureHeadDownDown

        }

        function getArray(headPressure) {
            const pressureConstantSelected = pressureHeadTable10.find(depth =>
                depth.key === headPressure)

            if (!pressureConstantSelected)
                return;
            const constantsFormatted = pressureConstantSelected.constant;
            return constantsFormatted;
        }
        const arrayPressureDown = getArray(pressureDown);

        const positionDepthDownDown = getIndex(depthTable10, depthDown);
        //console.log('Posição de depthDownDown no array', positionDepthDownDown)


        const pressureWellHeadDownDown = arrayPressureDown[positionDepthDownDown]; // valor será usado
        console.log('pressureWellHeadDownDown', pressureWellHeadDownDown)


        const positionDepthDownUp = getIndex(depthTable10, depthUp);
        //console.log('Posição de depthDownUp no array', positionDepthDownUp);

        const pressureWellHeadDownUp = arrayPressureDown[positionDepthDownUp]; // valor será usado
        console.log('pressureWellHeadDownUp', pressureWellHeadDownUp)


        const arrayPressureUp = getArray(pressureUp);

        const positionDepthUpDown = getIndex(depthTable10, depthDown);
        //console.log('Posição de depthDownDown no array', positionDepthUpDown)


        const pressureWellHeadUpDown = arrayPressureUp[positionDepthUpDown]; // valor será usado
        console.log('pressureWellHeadUpDown', pressureWellHeadUpDown)


        const positionDepthUpUp = getIndex(depthTable10, depthUp);
        //console.log('Posição de depthDownUp no array', positionDepthUpUp);

        const pressureWellHeadUpUp = arrayPressureUp[positionDepthUpUp]; // valor será usado
        console.log('pressureWellHeadUpUp', pressureWellHeadUpUp)

        const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));


        if (hydrostaticPressureFormatted === 0) {

            const pressureWellHead = 0;

            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','))
            setActiveSaveButton(true);
            return
        }
        if (hydrostaticPressureFormatted > 10000) {
            alert(t('Pressão de fundo acima de 10.000 psi! Impossível calcular pressão de cabeça!'))
            setAnswerHeadPressure('');
            return
        }
        if (pressureUp === pressureDown && depthDown === depthUp) {
            const pressureWellHead = pressureWellHeadDownDown;
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }
        else if (pressureUp === pressureDown) {
            const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = pressureWellHeadDownDown;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = pressureWellHeadDownUp;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }
        else if (depthDown === depthUp) {
            const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));

            const pressureWellHead = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadUpDown - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHead);

            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','))
            setActiveSaveButton(true);
            return

        } else {
            const hydrostaticPressureFormatted = Number(bottomPressure.replace(',', '.'));
            const depthFormat = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;

            const pressureWellHeadDown = ((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadUpDown - pressureWellHeadDownDown) + pressureWellHeadDownDown;
            console.log(pressureWellHeadDown);

            const pressureWellHeadUp = (((hydrostaticPressureFormatted - pressureDown) / (pressureUp - pressureDown)) * (pressureWellHeadUpUp - pressureWellHeadDownUp)) + pressureWellHeadDownUp;
            console.log(pressureWellHeadUp);

            const pressureWellHead = (((depthFormat - depthDown) / (depthUp - depthDown)) * (pressureWellHeadUp - pressureWellHeadDown)) + pressureWellHeadDown
            console.log('Resultado Final', pressureWellHead);
            setAnswerHeadPressure(pressureWellHead.toFixed(1).replace('.', ','));
            setActiveSaveButton(true);
            return
        }

    }

    function handleAllCalculation() {
        const depthFormatted = depthTypeSelected === 'ft' ? Number(depth.replace(',', '.')) : Number(depth.replace(',', '.')) * 3.2808;
        const densityFormatted = Number(density.replace(',', '.'));
        const answerForCalc = depthFormatted * densityFormatted * 0.052;

        if (answerForCalc > 5000 || Number(bottomPressure) > 5000 || depthFormatted > 17000) {
            checkBox ? handleCalculationDirectOld() : handleCalculationOld();
        } else {
            checkBox ? handleCalculationDirect() : handleCalculation();
        }
    }


    async function handleCopyToClipboard(answerCopied: string) {
        Clipboard.setString(answerCopied);

        const text = await Clipboard.getStringAsync();
        setCopiedText(text);

        setPasted(true);
    }

    function handleNavigationPressurization() {
        navigate('AppStackThreeRoutes' as never, {
            screen: 'SurfacePressurizationCalculationPlus',
            params: { answerHeadPressure }
        } as never);
    }

    function handleOpenModalDepth() {
        setOpenModalDepth(true);
    }

    function handleOpenModalDensity() {
        setOpenModalDensity(true);
    }

    function handleClearAll() {
        setDensity('');
        setDepth('');
        setAnswer('');
        setAnswerHeadPressure('');
        setDepthTypeSelected('m');
        setActiveSaveButton(false);
        setBottomPressure('');
        setCheckBox(false);
    }

    async function handleSaveCalculation() {
        if (!newJob) {
            return alert(t('Selecione ou crie um trabalho!'))
        }
        if (!newCalc) {
            return alert(t('Crie um nome para o cálculo!'))
        }

        function getDateFomatted() {
            const data = new Date(),
                dia = data.getDate().toString(),
                diaF = (dia.length == 1) ? '0' + dia : dia,
                mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
                mesF = (mes.length == 1) ? '0' + mes : mes,
                anoF = data.getFullYear();
            return diaF + "/" + mesF + "/" + anoF;
        }

        const dateFormatted = getDateFomatted();
        console.log('dateFormatted', dateFormatted)

        const newCalculation = {
            id: String(uuid.v4()),
            nameJob: newJob,
            nameCalc: newCalc,
            depth: depth,
            density: density,
            answerBottom: answer,
            depthTypeSelected: depthTypeSelected,
            answerHeadPressure: answerHeadPressure,
            date: dateFormatted
        }

        try {
            const dataKey = `@nitrogencalculations:hydrostatic_calc:admin`;
            const data = await AsyncStorage.getItem(dataKey);
            const currentData = data ? JSON.parse(data) : [];

            const dataFormatted = [
                ...currentData,
                newCalculation
            ]

            await AsyncStorage.setItem(dataKey, JSON.stringify(dataFormatted));
            console.log('Armazenamento realizado com sucesso', dataFormatted);
            setNewJob('');
            setNewCalc('');
            setOpenModalSave(false);
            alert(t('Salvo com sucesso!'));

        } catch (error) {
            console.log('Erro ao armazenar dados');

        }

    }

    async function loadJobNames() {
        const dataKey = `@nitrogencalculations:pressurization_calc:admin`;

        const response = await AsyncStorage.getItem(dataKey);

        const calculations = response ? JSON.parse(response) : [];

        const jobNames = calculations.map(item => item.nameJob)
        //console.log('map', jobNames);
        const jobNamesPressurization = [...new Set(jobNames)];
        //console.log('newSet', uniqueJobNames)


        const dataKeyHydro = `@nitrogencalculations:hydrostatic_calc:admin`;

        const responseHydro = await AsyncStorage.getItem(dataKeyHydro);

        const calculationsHydro = responseHydro ? JSON.parse(responseHydro) : [];

        const jobNamesHydro = calculationsHydro.map(item => item.nameJob)
        //console.log('map', jobNames);
        const jobNamesHydrostatic = [...new Set(jobNamesHydro)];
        //console.log('newSet', uniqueJobNames)

        const dataKeyColumn = `@nitrogencalculations:column_calc:admin`;

        const responseColumn = await AsyncStorage.getItem(dataKeyColumn);

        const calculationsColumn = responseColumn ? JSON.parse(responseColumn) : [];

        const jobNamesColumn = calculationsColumn.map(item => item.nameJob)
        //console.log('map', jobNames);
        const jobNamesVolumeColumn = [...new Set(jobNamesColumn)];
        //console.log('newSet', uniqueJobNames)

        const uniqueJobNames = [...new Set([...jobNamesPressurization, ...jobNamesHydrostatic, ...jobNamesVolumeColumn])];

        setCalculations(uniqueJobNames);
        setOpenChooseJob(true);

    }

    async function loadCalcNames() {
        const dataKey = `@nitrogencalculations:hydrostatic_calc:admin`;

        const response = await AsyncStorage.getItem(dataKey);

        const calculations = response ? JSON.parse(response) : [];

        const calcNames = calculations.map(item => item.nameCalc);
        const uniqueCalcNames = [...new Set(calcNames)];

        setCalculations(uniqueCalcNames);
        setOpenChooseCalc(true);

    }

    function handleOpenSaveModal() {
        setOpenModalSave(true);
    }

    useEffect(() => {
        setAnswer('');
        setAnswerHeadPressure('');
        setPasted(false);
        setActiveSaveButton(false);
    }, [screenIsFocus, depth, density, bottomPressure, checkBox, pressureTypeSelected]);

    return (
        <ScrollView
            style={{ backgroundColor: theme.colors.backgroud_primary }}

        >

            <HeaderHydrostaticPressure
                title={t(`Pressão Hidrostática`)}
                handleSave={handleOpenSaveModal}
                saveActive={activeSaveButton}
            />
            <ButtonView>
                <BackButton
                    color={theme.colors.main}
                    onPress={() => navigation.goBack()} />
                <ClearButton
                    onPress={handleClearAll}
                />
            </ButtonView>
            <Content>

                <InputWrapper>
                    <DepthContainer>
                        <Input
                            iconName='ruler'
                            placeholder={t('Digite a profundidade')}
                            keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                            value={depth}
                            onChangeText={(text) => setDepth(text)}
                        />

                        <PressureSelectorWrapper>
                            <VolumeSelectorContainer>
                                <PressureSelector
                                    title='m'
                                    active={'m' === depthTypeSelected}
                                    onPress={() => { handleDepthTypeSelected('m') }}
                                />
                                <PressureSelector
                                    title='ft'
                                    active={'ft' === depthTypeSelected}
                                    onPress={() => { handleDepthTypeSelected('ft') }}
                                />
                            </VolumeSelectorContainer>
                            <HelpContainer>
                                <HelpButton
                                    helpButton={handleOpenModalDepth}
                                />
                            </HelpContainer>
                        </PressureSelectorWrapper>

                    </DepthContainer>
                    {
                        checkBox
                            ?
                            <Input
                                iconName='gauge'
                                placeholder={t('Digite a pressão no fundo')}
                                keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                value={bottomPressure}
                                onChangeText={(text) => setBottomPressure(text)}
                            />
                            :
                            <Input
                                iconName='water-outline'
                                placeholder={t('Digite a densidade do fluido')}
                                keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                value={density}
                                onChangeText={(text) => setDensity(text)}
                            />
                    }
                    {/* {
                        checkBox
                            ?
                            <HelpContainer>
                                <VolumeSelectorContainer>
                                    <PressureSelector
                                        title='psi'
                                        active={'psi' === pressureTypeSelected}
                                        onPress={() => { handlePressureTypeSelected('psi') }}
                                    />
                                    <PressureSelector
                                        title='bar'
                                        active={'bar' === pressureTypeSelected}
                                        onPress={() => { handlePressureTypeSelected('bar') }}
                                    />
                                </VolumeSelectorContainer>

                                <HelpButton
                                    helpButton={handleOpenModalDensity}
                                />
                            </HelpContainer>
                            :
                            <HelpWrapper>
                                <HelpButton
                                    helpButton={handleOpenModalDensity}
                                />
                            </HelpWrapper>


                    } */}
                    <HelpWrapper>
                        <HelpButton
                            helpButton={handleOpenModalDensity}
                        />
                    </HelpWrapper>

                    <AskWrapper >
                        <CheckBoxTouch onPress={() => {
                            setCheckBox(!checkBox)
                            setDensity('');
                            setBottomPressure('');
                        }}>
                            {
                                checkBox
                                    ?
                                    <Feather
                                        name='check-square'
                                        color={theme.colors.site_700}
                                        size={20}
                                    />
                                    :
                                    <Feather
                                        name='square'
                                        color={theme.colors.site_700}
                                        size={20}
                                    />
                            }
                        </CheckBoxTouch>

                        <AskText>
                            {t('Já sei a pressão no fundo')}
                        </AskText>

                    </AskWrapper>
                </InputWrapper>
                {answer ?
                    <AnswerView>

                        <AnswerCard
                            iconName='gauge'
                            title={t(`Pressão no fundo`)}
                            depthType={depthTypeSelected}
                            answer={pressureTypeSelected === 'psi' ? `${answer} psi` : `${answer} bar`}
                            color={theme.colors.backgroud_secondary}
                            copyButton={() => handleCopyToClipboard(answer)}
                            pasted={pasted && copiedText === answer}
                            deleteButton={handleClearAll}
                        />


                    </AnswerView>
                    :
                    <Empty />
                }
                {
                    answerHeadPressure ?
                        <AnswerView>
                            <AnswerCard
                                iconName='gauge'
                                title={t(`Pressão na cabeça`)}
                                depthType={depthTypeSelected}
                                answer={pressureTypeSelected === 'psi' ? `${answerHeadPressure} psi` : `${answerHeadPressure} bar`}
                                color={theme.colors.backgroud_secondary}
                                copyButton={() => handleCopyToClipboard(answerHeadPressure)}
                                pasted={pasted && copiedText === answerHeadPressure}
                                deleteButton={handleClearAll}
                            />
                            <MessageWrapper>
                                <ButtonNavigation onPress={handleNavigationPressurization}>
                                    <TextNavigation>{t('Clique aqui para exportar a pressão de cabeça para a tela de Operações N2!')}          {'>>>'} </TextNavigation>
                                </ButtonNavigation>
                            </MessageWrapper>
                        </AnswerView>
                        :
                        <Empty />
                }
                <Footer>
                    <Button
                        title={t('CALCULAR')}
                        onPress={handleAllCalculation}
                    />
                </Footer>
            </Content>
            <Modal
                visible={openModalDepth}
                animationType={'slide'}
                onRequestClose={() => setOpenModalDepth(false)}
                transparent
            >
                <PressurizationScreenModal
                    title={t('Profundidade')}
                    iconName={'ruler'}
                    text={`${t('Nesse campo deve ser inserida a profundidade desejada.')}${'\n'}${'\n'}${t('A profundidade pode ser inserida em metro (m) ou pés (ft), selecione no botão embaixo da caixa de texto a unidade que deseja utilizar.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                    handleCloseModal={() => setOpenModalDepth(false)}
                />
            </Modal>

            <Modal
                visible={openModalDensity}
                animationType={'slide'}
                onRequestClose={() => setOpenModalDensity(false)}
                transparent
            >
                {
                    checkBox
                        ?
                        <PressurizationScreenModal
                            title={t('Pressão no fundo')}
                            iconName={'gauge'}
                            text={`${t('Nesse campo deve ser inserida a pressão no fundo.')}${'\n'}${'\n'}${t('Caso não saiba a pressão no fundo desmarque o campo Já sei a pressão no fundo.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                            handleCloseModal={() => setOpenModalDensity(false)}
                        />
                        :
                        <PressurizationScreenModal
                            title={t('Densidade')}
                            iconName={'water-outline'}
                            text={`${t('Nesse campo deve ser inserida a densidade do fluido em libras por galão (ppg).')}${'\n'}${'\n'}${t('A densidade da água do mar utilizada em muitos dos cálculos é de 8,6 ppg.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                            handleCloseModal={() => setOpenModalDensity(false)}
                        />
                }

            </Modal>

            <Modal
                visible={openModalSave}
                animationType={'slide'}
                onRequestClose={() => setOpenModalSave(false)}

            >


                <ContainerModal showsVerticalScrollIndicator={false}>

                    <ScrollWrapper>
                        <IconContainer >
                            <MaterialIcons
                                name='save'
                                size={50}
                                color={theme.colors.site_500}

                            />
                        </IconContainer>
                        <Title>{t('Salvar')}</Title>
                        <Message>{t('Escolha ou crie um trabalho para salvar seu cálculo')}</Message>
                        <PressableContainerSave onPress={loadJobNames}>
                            <PressableTextSave>{t('Escolher trabalho existente')}</PressableTextSave>
                            <ForwardArrow />
                        </PressableContainerSave>
                        <Input
                            iconName='label'
                            placeholder={t('Nome trabalho')}
                            keyboardType='default'
                            value={newJob}
                            onChangeText={(text) => setNewJob(text)}
                        />

                        <Message>{t('Dê um nome para esse cálculo (Ex: Deslocamento de DPR):')}</Message>
                        <PressableContainerSave onPress={loadCalcNames}>
                            <PressableTextSave>{t('Escolher nome existente')}</PressableTextSave>
                            <ForwardArrow />
                        </PressableContainerSave>
                        <Input
                            iconName='label'
                            placeholder={t('Nome do cálculo')}
                            keyboardType='default'
                            value={newCalc}
                            onChangeText={(text) => setNewCalc(text)}
                        />



                        <PressableContainer onPress={handleSaveCalculation}>
                            <PressableText>{t('SALVAR E SAIR')}</PressableText>
                        </PressableContainer>

                        <PressableContainerJobs onPress={() => setOpenModalSave(false)}>
                            <PressableText>{t('SAIR SEM SALVAR')}</PressableText>
                        </PressableContainerJobs>
                    </ScrollWrapper>

                </ContainerModal>


                <Modal
                    visible={openChooseJob}
                    animationType={'fade'}
                    onRequestClose={() => setOpenChooseJob(false)}
                    transparent
                >
                    <BlurView intensity={200} style={{ flex: 1 }}>

                        <ContainerModalView >
                            <View>
                                <ScrollWrapper>
                                    <IconContainer >
                                        <MaterialIcons
                                            name='save'
                                            size={50}
                                            color={theme.colors.site_500}

                                        />
                                    </IconContainer>
                                    <Title>{t('Trabalhos')}</Title>
                                    <Message>{t('Escolha um dos trabalhos abaixo para vincular seu cálculo')}</Message>

                                    <FlatList
                                        data={calculations}
                                        keyExtractor={(item) => item}
                                        renderItem={({ item }) => (
                                            <PressableContainerJobs onPress={() => {
                                                setNewJob(item)
                                                setOpenChooseJob(false)
                                            }}>
                                                <PressableText>{item}</PressableText>
                                            </PressableContainerJobs>
                                        )
                                        }
                                        showsVerticalScrollIndicator={false}
                                        ListEmptyComponent={(
                                            <EmptyMessageWrapper>
                                                <EmptyMessage>{t('Nenhum trabalho criado')}</EmptyMessage>
                                            </EmptyMessageWrapper>
                                        )}
                                        inverted={true}
                                    />
                                    <PressableContainer onPress={() => setOpenChooseJob(false)}>
                                        <PressableText>{t('VOLTAR')}</PressableText>
                                    </PressableContainer>
                                </ScrollWrapper>
                            </View>
                        </ContainerModalView>

                    </BlurView>

                </Modal>

                <Modal
                    visible={openChooseCalc}
                    animationType={'fade'}
                    onRequestClose={() => setOpenChooseCalc(false)}
                    transparent
                >
                    <BlurView intensity={200} style={{ flex: 1 }}>

                        <ContainerModalView >
                            <View >
                                <ScrollWrapper>
                                    <IconContainer >
                                        <MaterialIcons
                                            name='save'
                                            size={50}
                                            color={theme.colors.site_500}

                                        />
                                    </IconContainer>
                                    <Title>{t('Operações')}</Title>
                                    <Message>{t('Escolha um dos nomes de operações abaixo para o seu cálculo')}</Message>

                                    <FlatList
                                        data={calculations}
                                        keyExtractor={(item) => item}
                                        renderItem={({ item }) => (
                                            <PressableContainerJobs onPress={() => {
                                                setNewCalc(item)
                                                setOpenChooseCalc(false)
                                            }}>
                                                <PressableText>{item}</PressableText>
                                            </PressableContainerJobs>
                                        )
                                        }
                                        showsVerticalScrollIndicator={false}
                                        ListEmptyComponent={(
                                            <EmptyMessageWrapper>
                                                <EmptyMessage>{t('Não há nomes criados')}</EmptyMessage>
                                            </EmptyMessageWrapper>
                                        )}
                                        inverted={true}
                                    />
                                    <PressableContainer onPress={() => setOpenChooseCalc(false)}>
                                        <PressableText>{t('VOLTAR')}</PressableText>
                                    </PressableContainer>
                                </ScrollWrapper>
                            </View>
                        </ContainerModalView>

                    </BlurView>

                </Modal>
            </Modal>

        </ScrollView>

    );
}