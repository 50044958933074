import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import { Dimensions } from 'react-native';

const maxWidth = 750;
interface ButtonProps {
    color: string;
}

interface ButtonTextProps {
    light: boolean;
}

export const Container = styled.TouchableOpacity <ButtonProps>`
   
    width: 100%;

    padding: 19px;
    align-items: center;
    justify-content: center;
 
    background-color: ${({ color }) => color};

    margin-bottom: 8px;
    border-radius: 5px;


`;

export const Title = styled.Text<ButtonTextProps>`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;
color: ${({ theme, light }) => light ? theme.colors.header : theme.colors.backgroud_secondary};
`;

