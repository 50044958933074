import React from 'react';

import {
    PasteCopy,
    PasteCopyButton,
    IconPaste
} from './styles';

import { useTheme } from 'styled-components/native';
interface Props {
    helpButton: () => void;
}

export function HelpButton({
    helpButton
}: Props) {

    const theme = useTheme();

    return (

        <PasteCopy>
            <PasteCopyButton onPress={helpButton}>

                <IconPaste>
                    ?
                </IconPaste>


            </PasteCopyButton>
        </PasteCopy>


    );
}