import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';

interface OptionsProps {
    active?: boolean;
}

export const ButtonTemperature = styled.TouchableOpacity <OptionsProps>`
    background-color: ${({ theme, active }) =>
        active ? theme.colors.backgroud_primary : theme.colors.backgroud_primary};
    width: ${RFValue(50)}px;
    height: ${RFValue(50)}px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin: 5px 5px;
`;

export const TitleTemperature = styled.Text<OptionsProps>`
    font-family: ${({ theme, active }) =>
        active ? theme.fonts.primary_500 : theme.fonts.primary_400};
    color: ${({ theme, active }) =>
        active ? theme.colors.line : theme.colors.text};
`;