import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

const { Navigator, Screen } = createStackNavigator();

import { SurfacePressurizationCalculationPlus } from "../screens/SurfacePressurizationCalculationPlus";
import { PressurizationResultPlus } from "../screens/PressurizationResultPlus";

export function AppStackThreeRoutes() {
    return (
        <Navigator screenOptions={{ headerShown: false }} initialRouteName="SurfacePressurizationCalculationPlus">
            <Screen
                name="SurfacePressurizationCalculationPlus"
                component={SurfacePressurizationCalculationPlus}
            />
            <Screen
                name="PressurizationResultPlus"
                component={PressurizationResultPlus}
            />
        </Navigator>
    )
}