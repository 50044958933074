import React, { useState, useEffect } from 'react';
import {
    FlatList,
    Modal,
    ScrollView
} from 'react-native';

import {
    Content,
    TitleView,
    Title,
    PressableContainerJobs,
    PressableText,
    BackButtonWrapper,
    EmptyView,
    PressableTextBold,
    TextDataWrapper,
    TextWrapper,
    IconsWrapper,
    PressableContainerJobsErase,
    PressableTextErase,
    PressableTextBoldErase,
    PressableTextDate,
    Option,
    Options,
    OptionTitle,
    PressableTextTitle,
    CardFooter,
    EmptyMessage,
    EmptyMessageWrapper
} from './styles';

import { useTheme } from 'styled-components/native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { BlurView } from 'expo-blur';

import { useIsFocused } from '@react-navigation/native';
import { useNavigation, useRoute } from '@react-navigation/native';

import {useTranslation} from 'react-i18next';

import { BackButton } from '../../components/BackButton';
import { ForwardArrow } from '../../components/ForwardArrow';
import { ConfirmButton } from '../../components/ConfirmButton';
import { CancelButton } from '../../components/CancelButton';
import { HeaderMyCalculations } from '../../components/HeaderMyCalculations';
import { DeleteAllCalcModal } from '../../components/Modals/DeleteAllCalcModal';
import { DeleteCard } from '../../components/DeleteCard';

interface CalculationProps {
    id: string;
    nameJob: string;
    nameCalc: string;
    totalVolumeGas: string;
    totalVolumeLiquid: string;
    totalVolumeLiquidWithLost: string;
    timeForPressurizationHour: string;
    timeForPressurizationMinutes: string;
    volume: string;
    pressure: string;
    flow: string;
    volumeTypeSelected: string;
    pressureTypeSelected: string;
    temperatureSelected: string;
    date: string;
}

interface CalculationHydroProps {
    id: string;
    nameJob: string;
    nameCalc: string;
    depth: string;
    density: string;
    answerBottom: string;
    depthTypeSelected: string;
    answerHeadPressure: string;
    date: string;
}

interface PropsList {
    id: string;
    answerItem: number;
    depthItem: string;
    depthSelectedItem: string;
    diameterItem: string;
    diameterIn?: string;
    typeColumn: string;
    typeColumnIn?: string;
}

interface CalculationColumnProps {
    id: string;
    nameJob: string;
    nameCalc: string;
    singleDepth: string;
    singleDepthType: string;
    singleDiameter: string;
    singleAnswer: string;
    singleCardsAnswer: PropsList[];
    anularDepth: string;
    anularDepthType: string;
    anularDiameterOutside: string;
    anularDiameterInside: string;
    anularAnswer: string;
    anularCardsAnswer: PropsList[];
    totalVolume: string;
    date: string;
}
interface Params {
    jobSelected: string;
}


export function CalculationsFromJob() {

    const [calculationsFiltered, setCalculationsFiltered] = useState<CalculationProps[]>([]);
    const [eraseCalc, setEraseCalc] = useState(false);
    const [calcSelected, setCalcSelected] = useState('');

    const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false);

    const [deletedAll, setDeletedAll] = useState(false);

    const [option, setOption] = useState<'pressurization' | 'hydrostatic' | 'column'>('pressurization');

    const [calculationsFilteredHydro, setCalculationsFilteredHydro] = useState<CalculationHydroProps[]>([]);
    const [eraseCalcHydro, setEraseCalcHydro] = useState(false);
    const [calcSelectedHydro, setCalcSelectedHydro] = useState('');


    const [calculationsFilteredColumn, setCalculationsFilteredColumn] = useState<CalculationColumnProps[]>([]);
    const [eraseCalcColumn, setEraseCalcColumn] = useState(false);
    const [calcSelectedColumn, setCalcSelectedColumn] = useState('');
    const [deletedAllColumn, setDeletedAllColumn] = useState(false);

    const [language, setLanguage] = useState('pt');

    const {t} = useTranslation();

    const theme = useTheme();

    const navigation = useNavigation();

    const route = useRoute();

    const {
        jobSelected
    } = route.params as Params;


    const screenIsFocus = useIsFocused();


    function handleNavigationCalc(item) {
        const CalcSelected = item;
        navigation.navigate('PressurizationResultSaved' as never, {
            CalcSelected,
        } as never);
    }

    function handleNavigationCalcColumn(item) {
        const CalcSelected = item;
        navigation.navigate('ColumVolumeResultSaved' as never, {
            CalcSelected,
        } as never);
    }

    function showEraseButton(calcId) {
        setEraseCalc(true);
        setCalcSelected(calcId);
    }

    function showEraseButtonHydro(calcId) {
        setEraseCalcHydro(true);
        setCalcSelectedHydro(calcId);
    }

    function showEraseButtonColumn(calcId) {
        setEraseCalcColumn(true);
        setCalcSelectedColumn(calcId);
    }

    function hideEraseButton() {
        setEraseCalc(false);
        setCalcSelected('');
    }

    function hideEraseButtonHydro() {
        setEraseCalcHydro(false);
        setCalcSelectedHydro('');
    }

    function hideEraseButtonColumn() {
        setEraseCalcColumn(false);
        setCalcSelectedColumn('');
    }

    function changeDateFormat(date: string) {
        const [day, month, year] = date.split('/');
        const result = [month, day, year].join('/');
        return result;
    }

    async function handleEraseCalc(calcId) {
        try {
            const dataKey = `@nitrogencalculations:pressurization_calc:admin`;

            const response = await AsyncStorage.getItem(dataKey);

            const calculations = response ? JSON.parse(response) : [];


            const calcErased = calculations.filter(item => item.id !== calcId);


            await AsyncStorage.setItem(dataKey, JSON.stringify(calcErased));


            setDeletedAll(true)

        } catch (error) {
            console.log(error)
        }
    }

    async function handleEraseCalcHydro(calcId) {
        try {
            const dataKey = `@nitrogencalculations:hydrostatic_calc:admin`;

            const response = await AsyncStorage.getItem(dataKey);

            const calculations = response ? JSON.parse(response) : [];


            const calcErased = calculations.filter(item => item.id !== calcId);


            await AsyncStorage.setItem(dataKey, JSON.stringify(calcErased));


            setDeletedAll(true)


        } catch (error) {
            console.log(error)
        }
    }

    async function handleEraseCalcColumn(calcId) {
        try {
            const dataKey = `@nitrogencalculations:column_calc:admin`;

            const response = await AsyncStorage.getItem(dataKey);

            const calculations = response ? JSON.parse(response) : [];


            const calcErased = calculations.filter(item => item.id !== calcId);


            await AsyncStorage.setItem(dataKey, JSON.stringify(calcErased));


            setDeletedAll(true)

        } catch (error) {
            console.log(error)
        }
    }


    function handleGoBack() {
        navigation.goBack();
    }

    function deleteAllCalculation(jobSelected) {
        const jobToBeErased = jobSelected;
        try {
            async function handleEraseCalc(calcNameJob) {
                try {
                    const dataKey = `@nitrogencalculations:pressurization_calc:admin`;

                    const response = await AsyncStorage.getItem(dataKey);

                    const calculations = response ? JSON.parse(response) : [];


                    const calcErased = calculations.filter(item => item.nameJob !== calcNameJob);


                    await AsyncStorage.setItem(dataKey, JSON.stringify(calcErased));

                    setCalculationsFiltered(calcErased);


                } catch (error) {
                    console.log(error)
                }
            }
            async function handleEraseCalcHydro(calcNameJob) {
                try {
                    const dataKey = `@nitrogencalculations:hydrostatic_calc:admin`;

                    const response = await AsyncStorage.getItem(dataKey);

                    const calculations = response ? JSON.parse(response) : [];

                    const calcErased = calculations.filter(item => item.nameJob !== calcNameJob);

                    await AsyncStorage.setItem(dataKey, JSON.stringify(calcErased));

                    setCalculationsFilteredHydro(calcErased);

                } catch (error) {
                    console.log(error)
                }
            }

            async function handleEraseCalcColumn(calcNameJob) {
                try {
                    const dataKey = `@nitrogencalculations:column_calc:admin`;

                    const response = await AsyncStorage.getItem(dataKey);

                    const calculations = response ? JSON.parse(response) : [];


                    const calcErased = calculations.filter(item => item.nameJob !== calcNameJob);


                    await AsyncStorage.setItem(dataKey, JSON.stringify(calcErased));

                    setCalculationsFilteredColumn(calcErased);


                } catch (error) {
                    console.log(error)
                }
            }

            handleEraseCalc(jobToBeErased);
            handleEraseCalcHydro(jobToBeErased);
            handleEraseCalcColumn(jobToBeErased);
            setDeletedAll(true)
            setOpenDeleteAllModal(false)
            navigation.goBack();
        } catch (error) {
            console.log(error)
        }

    }


    function handleOptionChange(optionSelected: 'pressurization' | 'hydrostatic' | 'column') {
        setOption(optionSelected)
    }

    useEffect(() => {
        console.log('jobSelected', jobSelected);
        async function calculationsSelected(jobSelected) {
            const dataKey = `@nitrogencalculations:pressurization_calc:admin`;

            const response = await AsyncStorage.getItem(dataKey);

            const calculations = response ? JSON.parse(response) : [];

            setCalculationsFiltered(calculations);
            console.log('AllCalculationsSaved', calculationsFiltered);
            console.log('jobSelectedInsideFunction', jobSelected);

            setCalculationsFiltered(oldState => oldState.filter(item => item.nameJob === jobSelected))
            setDeletedAll(false)

        }

        calculationsSelected(jobSelected)

    }, [screenIsFocus, deletedAll])

    useEffect(() => {
        console.log('jobSelected', jobSelected);
        async function calculationsSelected(jobSelected) {
            const dataKey = `@nitrogencalculations:hydrostatic_calc:admin`;

            const response = await AsyncStorage.getItem(dataKey);

            const calculations = response ? JSON.parse(response) : [];

            setCalculationsFilteredHydro(calculations);
            console.log('AllCalculationsSaved', calculationsFiltered);
            console.log('jobSelectedInsideFunction', jobSelected);

            setCalculationsFilteredHydro(oldState => oldState.filter(item => item.nameJob === jobSelected))
            setDeletedAll(false)

        }

        calculationsSelected(jobSelected)

    }, [screenIsFocus, deletedAll])


    useEffect(() => {
        console.log('jobSelected', jobSelected);
        async function calculationsSelected(jobSelected) {
            const dataKey = `@nitrogencalculations:column_calc:admin`;

            const response = await AsyncStorage.getItem(dataKey);

            const calculations = response ? JSON.parse(response) : [];

            setCalculationsFilteredColumn(calculations);
            console.log('AllCalculationsSaved', calculationsFiltered);
            console.log('jobSelectedInsideFunction', jobSelected);

            setCalculationsFilteredColumn(oldState => oldState.filter(item => item.nameJob === jobSelected))
            setDeletedAllColumn(false)

        }

        calculationsSelected(jobSelected)

    }, [screenIsFocus, deletedAll])

    useEffect(() => {
        async function changeDate() {
            try {
                const languageResponse = await AsyncStorage.getItem('language');
            
                setLanguage(languageResponse);
            } catch (error) {
                setLanguage('pt');
                console.log(error);
            }
        }
        changeDate();
    },[screenIsFocus])

    return (
        <ScrollView
            style={{ backgroundColor: theme.colors.backgroud_primary }}
            nestedScrollEnabled={true}
            showsVerticalScrollIndicator={false}
        >

            <HeaderMyCalculations
                title={t(`Meus Cálculos`)}
                handleDeleteAll={() => setOpenDeleteAllModal(true)}
            />
            <BackButtonWrapper>
                <BackButton
                    onPress={handleGoBack}
                    color={theme.colors.main}
                />
                <TitleView>
                    <Title>
                        {jobSelected}
                    </Title>
                </TitleView>
                <EmptyView>

                </EmptyView>
            </BackButtonWrapper>
            <Options>
                <Option
                    active={option === 'pressurization'}
                    onPress={() => handleOptionChange('pressurization')}
                >
                    <OptionTitle active={option === 'pressurization'}>
                        {t('Operações N2')}
                    </OptionTitle>
                </Option>
                <Option
                    active={option === 'hydrostatic'}
                    onPress={() => handleOptionChange('hydrostatic')}
                >
                    <OptionTitle active={option === 'hydrostatic'}>
                        {t('Hidrostática')}
                    </OptionTitle>
                </Option>
                <Option
                    active={option === 'column'}
                    onPress={() => handleOptionChange('column')}
                >
                    <OptionTitle active={option === 'column'}>
                        {t('Coluna')}
                    </OptionTitle>
                </Option>
            </Options>
            {
                option === 'pressurization'
                    ?
                    <Content>

                        <FlatList
                            data={calculationsFiltered}
                            keyExtractor={(item) => item.id}
                            renderItem={({ item }) => (
                                eraseCalc && calcSelected === item.id
                                    ?

                                    <PressableContainerJobsErase >
                                        <PressableTextErase>{item.nameCalc}</PressableTextErase>
                                        <PressableTextBoldErase>{t('Apagar cálculo?')}</PressableTextBoldErase>
                                        <IconsWrapper>

                                            <TextDataWrapper>
                                                <ConfirmButton
                                                    color={theme.colors.success}
                                                    onPress={() => handleEraseCalc(item.id)}
                                                />

                                            </TextDataWrapper>
                                            <TextDataWrapper>
                                                <CancelButton
                                                    color={theme.colors.main}
                                                    onPress={() => hideEraseButton()}
                                                />
                                            </TextDataWrapper>


                                        </IconsWrapper>
                                    </PressableContainerJobsErase>

                                    :

                                    <PressableContainerJobs onPress={() => handleNavigationCalc(item)}>
                                        <PressableTextTitle>{item.nameCalc}</PressableTextTitle>
                                        <TextWrapper>
                                            <TextDataWrapper>
                                                <PressableText>{t('Volume')}:<PressableTextBold> {item.volume} {item.volumeTypeSelected}</PressableTextBold></PressableText>
                                                <PressableText>{t('Pressão Final')}:<PressableTextBold> {item.pressure} {item.pressureTypeSelected}</PressableTextBold></PressableText>
                                                {item.flow === 'Vazão não fornecida' ? <PressableText>{t('Vazão não fornecida')}</PressableText> : <PressableText>{t('Vazão')}:<PressableTextBold> {item.flow} {item.flow === 'Vazão não fornecida' ? '' : 'scfm'}</PressableTextBold></PressableText>}
                                                <PressableText>{'\n'}{t('Volume a bombear')}:<PressableTextBold> {item.totalVolumeGas} scf</PressableTextBold></PressableText>
                                                <PressableText>{t('Volume LN2')}:<PressableTextBold> {item.totalVolumeLiquid} gal</PressableTextBold></PressableText>
                                                <PressableText>{t('Volume LN2 com perdas')}:<PressableTextBold> {item.totalVolumeLiquidWithLost} gal</PressableTextBold></PressableText>
                                                {item.flow === 'Vazão não fornecida' ? <PressableText></PressableText> : <PressableText>{t('Tempo de bombeio')}: <PressableTextBold>{item.timeForPressurizationHour === 'Vazão não fornecida' && item.timeForPressurizationMinutes === 'Vazão não fornecida' ? 'vazão não fornecida' : item.timeForPressurizationHour === 'Vazão não fornecida' && item.timeForPressurizationMinutes !== 'Vazão não fornecida' ? `${item.timeForPressurizationMinutes} min` : item.timeForPressurizationHour !== 'Vazão não fornecida' && item.timeForPressurizationMinutes === 'Vazão não fornecida' ? `${item.timeForPressurizationHour} h` : `${item.timeForPressurizationHour} h e ${item.timeForPressurizationMinutes} min`}</PressableTextBold></PressableText>}

                                            </TextDataWrapper>
                                            <ForwardArrow
                                                color={theme.colors.backgroud_secondary}
                                            />
                                        </TextWrapper>
                                        <CardFooter>
                                            <DeleteCard onPress={() => showEraseButton(item.id)} />
                                            <PressableTextDate>{language === 'en' ? changeDateFormat(item.date) : item.date}</PressableTextDate>
                                        </CardFooter>
                                    </PressableContainerJobs>

                            )
                            }
                            showsVerticalScrollIndicator={false}
                            inverted={true}
                            ListEmptyComponent={(
                                <EmptyMessageWrapper>
                                    <EmptyMessage>{t('Nenhum cálculo de operação N2 para este trabalho')}</EmptyMessage>
                                </EmptyMessageWrapper>
                            )}
                        />


                    </Content>
                    :
                    option === 'hydrostatic'
                        ?
                        <Content>

                            <FlatList
                                data={calculationsFilteredHydro}
                                keyExtractor={(item) => item.id}
                                renderItem={({ item }) => (
                                    eraseCalcHydro && calcSelectedHydro === item.id
                                        ?

                                        <PressableContainerJobsErase >
                                            <PressableTextErase>{item.nameCalc}</PressableTextErase>
                                            <PressableTextBoldErase>{t('Apagar cálculo?')}</PressableTextBoldErase>
                                            <IconsWrapper>

                                                <TextDataWrapper>
                                                    <ConfirmButton
                                                        color={theme.colors.success}
                                                        onPress={() => handleEraseCalcHydro(item.id)}
                                                    />

                                                </TextDataWrapper>
                                                <TextDataWrapper>
                                                    <CancelButton
                                                        color={theme.colors.main}
                                                        onPress={() => hideEraseButtonHydro()}
                                                    />
                                                </TextDataWrapper>


                                            </IconsWrapper>
                                        </PressableContainerJobsErase>

                                        :

                                        <PressableContainerJobs>
                                            <PressableTextTitle>{item.nameCalc}</PressableTextTitle>
                                            <TextWrapper>
                                                <TextDataWrapper>
                                                    <PressableText>{t('Profundidade')}:<PressableTextBold> {item.depth} {item.depthTypeSelected}</PressableTextBold></PressableText>
                                                    {item.density && <PressableText>{t('Densidade do fluido')}:<PressableTextBold> {item.density} ppg</PressableTextBold></PressableText>}
                                                    <PressableText>{t('Pressão no fundo')}:<PressableTextBold> {item.answerBottom} psi</PressableTextBold></PressableText>
                                                    <PressableText>{t('Pressão na cabeça')}:<PressableTextBold> {item.answerHeadPressure} psi</PressableTextBold></PressableText>


                                                </TextDataWrapper>

                                            </TextWrapper>
                                            <CardFooter>
                                                <DeleteCard onPress={() => showEraseButtonHydro(item.id)} />
                                                <PressableTextDate>{language === 'en' ? changeDateFormat(item.date) : item.date}</PressableTextDate>
                                            </CardFooter>
                                        </PressableContainerJobs>

                                )
                                }
                                showsVerticalScrollIndicator={false}
                                inverted={true}
                                ListEmptyComponent={(
                                    <EmptyMessageWrapper>
                                        <EmptyMessage>{t('Nenhum cálculo de pressão hidrostática para este trabalho')}</EmptyMessage>
                                    </EmptyMessageWrapper>
                                )}
                                nestedScrollEnabled={true}
                            />


                        </Content>
                        :
                        <Content>

                            <FlatList
                                data={calculationsFilteredColumn}
                                keyExtractor={(item) => item.id}
                                renderItem={({ item }) => (
                                    eraseCalcColumn && calcSelectedColumn === item.id
                                        ?

                                        <PressableContainerJobsErase >
                                            <PressableTextErase>{item.nameCalc}</PressableTextErase>
                                            <PressableTextBoldErase>{t('Apagar cálculo?')}</PressableTextBoldErase>
                                            <IconsWrapper>

                                                <TextDataWrapper>
                                                    <ConfirmButton
                                                        color={theme.colors.success}
                                                        onPress={() => handleEraseCalcColumn(item.id)}
                                                    />

                                                </TextDataWrapper>
                                                <TextDataWrapper>
                                                    <CancelButton
                                                        color={theme.colors.main}
                                                        onPress={() => hideEraseButtonColumn()}
                                                    />
                                                </TextDataWrapper>


                                            </IconsWrapper>
                                        </PressableContainerJobsErase>

                                        :

                                        <PressableContainerJobs onPress={() => handleNavigationCalcColumn(item)}>
                                            <PressableTextTitle>{item.nameCalc}</PressableTextTitle>
                                            <TextWrapper>
                                                <TextDataWrapper>
                                                    {
                                                        item.singleCardsAnswer.length !== 0 &&
                                                        <TextDataWrapper>
                                                            <PressableTextBold>{t('Coluna Simples')}</PressableTextBold>
                                                            <FlatList
                                                                data={item.singleCardsAnswer}
                                                                keyExtractor={(item) => item.id}
                                                                nestedScrollEnabled={true}
                                                                renderItem={({ item }) => (
                                                                    <TextDataWrapper>
                                                                        <PressableText>{t('Volume da coluna')}: <PressableTextBold>{item.answerItem.toFixed(2).replace('.', ',')} bbl</PressableTextBold></PressableText>
                                                                    </TextDataWrapper>


                                                                )}
                                                            />
                                                            <PressableText>{t('Volume total')}: <PressableTextBold>{item.singleAnswer} bbl</PressableTextBold>{'\n'}</PressableText>


                                                        </TextDataWrapper>
                                                    }
                                                    {
                                                        item.anularCardsAnswer.length !== 0 &&
                                                        <TextDataWrapper>
                                                            <PressableTextBold>{t('Anular')}</PressableTextBold>
                                                            <FlatList
                                                                data={item.anularCardsAnswer}
                                                                keyExtractor={(item) => item.id}
                                                                nestedScrollEnabled={true}
                                                                renderItem={({ item }) => (
                                                                    <TextDataWrapper>

                                                                        <PressableText>{t('Volume do anular')}: <PressableTextBold>{item.answerItem.toFixed(2).replace('.', ',')} bbl</PressableTextBold></PressableText>
                                                                    </TextDataWrapper>


                                                                )}
                                                            />
                                                            <PressableText>{t('Volume total anular')}: <PressableTextBold>{item.anularAnswer} bbl</PressableTextBold></PressableText>


                                                        </TextDataWrapper>
                                                    }
                                                    <TextDataWrapper>
                                                        {
                                                            item.singleCardsAnswer.length !== 0 && item.anularCardsAnswer.length !== 0 &&
                                                            <PressableText>{'\n'}{t('Volume total')}: <PressableTextBold>{item.totalVolume} bbl</PressableTextBold></PressableText>

                                                        }

                                                    </TextDataWrapper>
                                                </TextDataWrapper>
                                                <ForwardArrow
                                                    color={theme.colors.backgroud_secondary}
                                                />
                                            </TextWrapper>
                                            <CardFooter>
                                                <DeleteCard onPress={() => showEraseButtonColumn(item.id)} />
                                                <PressableTextDate>{language === 'en' ? changeDateFormat(item.date) : item.date}</PressableTextDate>
                                            </CardFooter>
                                        </PressableContainerJobs>

                                )
                                }
                                showsVerticalScrollIndicator={false}
                                inverted={true}
                                ListEmptyComponent={(
                                    <EmptyMessageWrapper>
                                        <EmptyMessage>{t('Nenhum cálculo de volume de coluna para este trabalho')}</EmptyMessage>
                                    </EmptyMessageWrapper>
                                )}
                            />


                        </Content>
            }
            <Modal
                visible={openDeleteAllModal}
                animationType={'slide'}
                onRequestClose={() => setOpenDeleteAllModal(false)}
                transparent
            >
                <BlurView intensity={200} style={{ flex: 1 }}>

                    <DeleteAllCalcModal
                        title={`${t('Deletar todos os cálculos de')} ${jobSelected}`}
                        iconName={'trash-can-outline'}
                        text={`${t('Todos os cálculos de')} ${jobSelected} ${t('serão deletados')}!${'\n'}${'\n'}${t('Tem certeza que deseja continuar?')}`}
                        handleCloseModal={() => setOpenDeleteAllModal(false)}
                        handleNavigation={() => deleteAllCalculation(jobSelected)}
                    />

                </BlurView>

            </Modal>


        </ScrollView>

    );
}