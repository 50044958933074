import styled from 'styled-components/native';

import { RFValue } from 'react-native-responsive-fontsize';
import { RectButton } from 'react-native-gesture-handler';
import { Platform, Dimensions } from 'react-native';

const maxWidth = 750;

export const Container = styled.View`
`;

export const IconView = styled.View`
align-items:center ;
justify-content:center ;
`;

export const ButtonIcon = styled(RectButton)`
`;

export const HeaderView = styled.View`
width: 100%;
padding-top:  ${Platform.OS === 'web' ? 44 : 10}px;
padding-bottom: 10px;
padding-left: 10px;
padding-right: 10px;
background-color: ${({ theme }) => theme.colors.main};
flex-direction: row;
align-items:center ;
justify-content:space-between ;
`;

export const HeaderTitle = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(24) : 24}px;
color: ${({ theme }) => theme.colors.backgroud_secondary}; 
text-align: center;
align-items:center ;
justify-content:center ;
max-width: 300px;
`;

export const TitleView = styled.View`
align-items:center ;
justify-content: center ;
padding-top:5px ;
`;

export const Empty = styled.View`
align-items:center ;
justify-content: center ;
height:30px ;
width:30px ;

`;