

interface TemperaturePlusProps {
    key: string;
    title: string;
    constant: number[];
}

const temperatures: TemperaturePlusProps[] = [
    {
        key: '0',
        title: '0°F',
        constant: [
            0, 45, 88, 132, 176, 221, 265, 309, 354, 398, 442, 486, 530, 574, 617, 660, 703, 745, 787, 829, 869, 910, 950, 989, 1027, 1065, 1102, 1138, 1173, 1208, 1242, 1275, 1307, 1338, 1368, 1397, 1426, 1454, 1480, 1506, 1545, 1609, 1635, 1661, 1686, 1711, 1735, 1759, 1782, 1806, 1829, 1851, 1873, 1895, 1917, 1938, 1960, 1971, 2001, 2021, 2041, 2060, 2080, 2098, 2117, 2135, 2154, 2172, 2190, 2208, 2225, 2242, 2259, 2275, 2291, 2307, 2324, 2339, 2355, 2371, 2389, 2411, 2429, 2444, 2458, 2470, 2486, 2499, 2512, 2524, 2536, 2548, 2560, 2571, 2583, 2595, 2607, 2618, 2629, 2640, 2651, 2655, 2666, 2677, 2687, 2697, 2707, 2717, 2727, 2737, 2747, 2756, 2765, 2774, 2783, 2792, 2801, 2810, 2819, 2828, 2837, 2846, 2855, 2863, 2871, 2880, 2895, 2903, 2911, 2919, 2926, 2934, 2942, 2949, 2957, 2964, 2971, 2979, 2986, 2993, 3000, 3006, 3014, 3021, 3027, 3035, 3040, 3046, 3053, 3060, 3067
        ]
    },
    {
        key: '20',
        title: '20°F',
        constant: [
            0, 43, 85, 127, 169, 211, 253, 295, 338, 380, 422, 464, 506, 547, 588, 629, 670, 710, 750, 790, 828, 867, 905, 943, 980, 1016, 1052, 1087, 1121, 1155, 1187, 1220, 1251, 1281, 1311, 1340, 1369, 1396, 1423, 1449, 1484, 1534, 1559, 1584, 1609, 1633, 1657, 1680, 1703, 1726, 1748, 1772, 1792, 1814, 1835, 1856, 1878, 1898, 1918, 1938, 1958, 1977, 1997, 2015, 2033, 2051, 2069, 2087, 2104, 2122, 2139, 2157, 2174, 2190, 2207, 2223, 2239, 2254, 2270, 2286, 2301, 2327, 2341, 2355, 2369, 2382, 2396, 2409, 2422, 2435, 2447, 2459, 2471, 2483, 2495, 2506, 2517, 2528, 2540, 2551, 2561, 2572, 2585, 2597, 2600, 2611, 2622, 2633, 2644, 2655, 2666, 2677, 2688, 2699, 2710, 2721, 2730, 2739, 2748, 2757, 2766, 2775, 2784, 2793, 2802, 2811, 2808, 2816, 2824, 2832, 2840, 2848, 2856, 2864, 2872, 2880, 2887, 2892, 2900, 2908, 2916, 2923, 2930, 2937, 2943, 2950, 2956, 2963, 2970, 2976, 2983,
        ]
    },
    {
        key: '40',
        title: '40°F',
        constant: [
            0, 41, 81, 121, 162, 202, 242, 283, 323, 363, 403, 443, 483, 522, 562, 600, 640, 678, 716, 754, 791, 828, 865, 900, 935, 971, 1005, 1039, 1072, 1105, 1137, 1168, 1198, 1228, 1258, 1286, 1314, 1341, 1368, 1394, 1429, 1465, 1490, 1514, 1538, 1562, 1585, 1608, 1631, 1653, 1675, 1698, 1719, 1740, 1761, 1782, 1802, 1821, 1840, 1860, 1880, 1900, 1919, 1937, 1955, 1974, 1991, 2009, 2027, 2044, 2061, 2078, 2095, 2111, 2127, 2143, 2160, 2176, 2192, 2207, 2222, 2246, 2259, 2274, 2287, 2299, 2315, 2327, 2339, 2352, 2364, 2376, 2389, 2401, 2413, 2424, 2435, 2446, 2457, 2469, 2480, 2490, 2501, 2512, 2523, 2534, 2545, 2556, 2566, 2575, 2584, 2593, 2602, 2611, 2620, 2631, 2642, 2653, 2664, 2675, 2686, 2695, 2704, 2713, 2722, 2731, 2728, 2736, 2745, 2753, 2760, 2768, 2776, 2785, 2790, 2798, 2806, 2813, 2820, 2827, 2834, 2841, 2848, 2855, 2861, 2867, 2873, 2880, 2887, 2893, 2900,
        ]
    },
    {
        key: '60',
        title: '60°F',
        constant: [
            0, 39, 78, 116, 155, 194, 232, 271, 309, 348, 386, 423, 462, 500, 537, 575, 612, 648, 685, 721, 756, 792, 827, 861, 895, 929, 962, 995, 1027, 1058, 1089, 1119, 1149, 1178, 1207, 1235, 1262, 1289, 1315, 1341, 1376, 1402, 1426, 1450, 1473, 1497, 1519, 1542, 1564, 1586, 1608, 1630, 1651, 1672, 1692, 1712, 1732, 1752, 1771, 1790, 1810, 1829, 1848, 1866, 1884, 1902, 1920, 1937, 1955, 1972, 1989, 2006, 2023, 2039, 2055, 2071, 2090, 2106, 2121, 2136, 2150, 2165, 2186, 2199, 2213, 2226, 2239, 2251, 2264, 2276, 2289, 2301, 2312, 2325, 2337, 2348, 2360, 2371, 2383, 2394, 2405, 2415, 2424, 2433, 2442, 2451, 2460, 2471, 2482, 2493, 2504, 2515, 2524, 2533, 2542, 2551, 2560, 2511, 2582, 2593, 2604, 2615, 2624, 2633, 2642, 2651, 2655, 2663, 2670, 2679, 2685, 2692, 2700, 2707, 2714, 2721, 2730, 2742, 2750, 2758, 2765, 2772, 2780, 2788, 2795, 2802, 2810, 2817, 2826, 2832, 2840
        ]
    },
    {
        key: '80',
        title: '80°F',
        constant: [
            0, 38, 75, 112, 149, 186, 223, 260, 297, 334, 370, 407, 443, 480, 515, 551, 587, 621, 657, 691, 726, 760, 794, 826, 860, 892, 924, 957, 987, 1017, 1048, 1077, 1106, 1135, 1163, 1190, 1217, 1244, 1270, 1295, 1320, 1344, 1368, 1391, 1414, 1437, 1459, 1481, 1502, 1525, 1546, 1568, 1588, 1609, 1629, 1649, 1669, 1688, 1707, 1726, 1745, 1764, 1782, 1800, 1818, 1836, 1853, 1871, 1888, 1905, 1922, 1938, 1954, 1971, 1987, 2003, 2022, 2037, 2052, 2067, 2085, 2101, 2115, 2128, 2141, 2155, 2167, 2180, 2192, 2205, 2218, 2230, 2242, 2254, 2265, 2277, 2290, 2301, 2312, 2324, 2335, 2344, 2354, 2365, 2376, 2386, 2397, 2407, 2417, 2428, 2438, 2448, 2458, 2468, 2478, 2488, 2499, 2508, 2518, 2528, 2537, 2546, 2556, 2565, 2574, 2583, 2585, 2593, 2601, 2609, 2617, 2625, 2633, 2641, 2650, 2658, 2666, 2674, 2682, 2690, 2698, 2706, 2713, 2720, 2727, 2735, 2742, 2749, 2756, 2763, 2770
        ]
    },
    {
        key: '100',
        title: '100°F',
        constant: [
            0, 37, 72, 108, 143, 179, 215, 250, 285, 320, 356, 390, 425, 460, 494, 528, 562, 596, 629, 662, 695, 727, 760, 791, 822, 854, 885, 915, 945, 973, 1003, 1032, 1060, 1087, 1114, 1141, 1167, 1193, 1218, 1243, 1268, 1291, 1314, 1337, 1360, 1382, 1404, 1425, 1447, 1468, 1489, 1510, 1531, 1551, 1570, 1590, 1610, 1627, 1645, 1665, 1685, 1703, 1721, 1739, 1757, 1774, 1792, 1809, 1826, 1843, 1860, 1876, 1893, 1909, 1925, 1939, 1958, 1973, 1986, 2003, 2022, 2035, 2049, 2062, 2076, 2089, 2102, 2114, 2127, 2139, 2152, 2164, 2176, 2189, 2200, 2211, 2223, 2235, 2246, 2257, 2268, 2279, 2290, 2301, 2312, 2323, 2334, 2345, 2355, 2366, 2376, 2386, 2396, 2406, 2416, 2426, 2436, 2446, 2455, 2464, 2473, 2482, 2491, 2500, 2509, 2518, 2520, 2530, 2538, 2546, 2554, 2562, 2570, 2578, 2586, 2594, 2602, 2610, 2617, 2625, 2633, 2640, 2647, 2654, 2661, 2668, 2675, 2682, 2690, 2697, 2704
        ]
    },
    {
        key: '120',
        title: '120°F',
        constant: [
            0, 35, 70, 104, 138, 172, 207, 241, 275, 308, 344, 375, 409, 442, 475, 507, 540, 572, 604, 635, 667, 698, 729, 760, 790, 820, 849, 878, 907, 935, 963, 990, 1017, 1044, 1070, 1096, 1121, 1146, 1171, 1195, 1220, 1243, 1265, 1287, 1309, 1331, 1352, 1374, 1395, 1415, 1436, 1457, 1477, 1497, 1516, 1535, 1555, 1573, 1592, 1611, 1629, 1647, 1665, 1682, 1700, 1717, 1734, 1751, 1768, 1785, 1801, 1818, 1834, 1850, 1866, 1881, 1900, 1915, 1930, 1945, 1960, 1975, 1988, 2003, 2015, 2029, 2041, 2054, 2067, 2079, 2092, 2104, 2116, 2128, 2140, 2151, 2162, 2173, 2185, 2196, 2207, 2216, 2225, 2234, 2243, 2252, 2261, 2270, 2280, 2291, 2302, 2313, 2324, 2335, 2346, 2357, 2368, 2379, 2390, 2400, 2409, 2418, 2427, 2436, 2445, 2454, 2460, 2470, 2478, 2486, 2494, 2502, 2510, 2517, 2525, 2532, 2540, 2548, 2556, 2563, 2570, 2578, 2586, 2594, 2602, 2610, 2617, 2625, 2632, 2640, 2647
        ]
    },
    {
        key: '140',
        title: '140°F',
        constant: [
            0, 34, 67, 100, 133, 166, 199, 232, 265, 297, 330, 360, 395, 425, 457, 488, 519, 550, 581, 611, 641, 671, 700, 730, 758, 786, 815, 844, 872, 899, 925, 952, 979, 1004, 1030, 1055, 1079, 1104, 1127, 1151, 1175, 1198, 1220, 1241, 1263, 1284, 1305, 1326, 1346, 1367, 1387, 1408, 1427, 1447, 1466, 1485, 1504, 1522, 1540, 1559, 1577, 1594, 1612, 1630, 1647, 1664, 1681, 1698, 1715, 1731, 1747, 1764, 1780, 1796, 1810, 1826, 1845, 1860, 1875, 1890, 1905, 1918, 1932, 1945, 1958, 1972, 1985, 1996, 2008, 2021, 2034, 2046, 2058, 2070, 2081, 2093, 2105, 2117, 2128, 2140, 2151, 2160, 2170, 2181, 2191, 2202, 2213, 2222, 2231, 2240, 2249, 2260, 2271, 2280, 2291, 2300, 2309, 2320, 2330, 2339, 2348, 2357, 2366, 2375, 2384, 2393, 2405, 2413, 2421, 2429, 2437, 2445, 2454, 2466, 2474, 2483, 2491, 2499, 2507, 2515, 2523, 2530, 2538, 2545, 2553, 2560, 2568, 2575, 2582, 2590, 2597
        ]
    },
    {
        key: '160',
        title: '160°F',
        constant: [
            0, 33, 65, 97, 129, 161, 192, 224, 255, 287, 318, 348, 379, 410, 440, 470, 500, 530, 559, 589, 618, 646, 675, 704, 731, 759, 785, 813, 840, 866, 891, 917, 943, 968, 993, 1017, 1041, 1064, 1087, 1110, 1134, 1156, 1177, 1199, 1220, 1240, 1261, 1281, 1302, 1321, 1341, 1362, 1381, 1400, 1419, 1437, 1456, 1474, 1482, 1510, 1528, 1546, 1563, 1580, 1597, 1614, 1631, 1647, 1664, 1680, 1697, 1713, 1728, 1743, 1758, 1774, 1793, 1807, 1823, 1838, 1852, 1865, 1878, 1891, 1904, 1917, 1930, 1943, 1955, 1968, 1980, 1992, 2005, 2017, 2029, 2040, 2051, 2062, 2074, 2085, 2096, 2105, 2116, 2127, 2138, 2149, 2160, 2169, 2179, 2189, 2200, 2209, 2219, 2229, 2239, 2249, 2258, 2269, 2280, 2289, 2300, 2309, 2318, 2327, 2336, 2345, 2350, 2360, 2368, 2375, 2383, 2391, 2399, 2407, 2415, 2422, 2430, 2438, 2446, 2454, 2462, 2470, 2477, 2485, 2493, 2500, 2508, 2516, 2524, 2532, 2540
        ]
    },
    {
        key: '180',
        title: '180°F',
        constant: [
            0, 32, 63, 94, 125, 156, 186, 217, 246, 277, 307, 337, 367, 396, 425, 454, 483, 512, 540, 568, 596, 624, 651, 679, 705, 733, 758, 785, 810, 835, 860, 885, 910, 934, 958, 982, 1005, 1028, 1050, 1073, 1096, 1117, 1138, 1159, 1180, 1200, 1220, 1240, 1260, 1279, 1299, 1319, 1338, 1356, 1375, 1393, 1412, 1430, 1448, 1465, 1482, 1500, 1517, 1534, 1551, 1567, 1584, 1600, 1617, 1633, 1649, 1665, 1681, 1696, 1711, 1726, 1744, 1759, 1774, 1789, 1803, 1815, 1828, 1841, 1854, 1867, 1880, 1893, 1905, 1917, 1930, 1942, 1955, 1966, 1977, 1989, 2000, 2012, 2024, 2035, 2046, 2057, 2067, 2078, 2088, 2098, 2110, 2120, 2130, 2140, 2150, 2161, 2171, 2181, 2191, 2200, 2209, 2218, 2228, 2237, 2246, 2256, 2266, 2275, 2285, 2295, 2300, 2309, 2317, 2325, 2333, 2340, 2348, 2357, 2365, 2373, 2381, 2390, 2398, 2406, 2414, 2423, 2431, 2440, 2448, 2456, 2464, 2472, 2480, 2488, 2496
        ]
    },
    {
        key: '200',
        title: '200°F',
        constant: [
            0, 31, 61, 91, 121, 151, 180, 210, 237, 268, 297, 327, 355, 383, 411, 439, 467, 495, 522, 548, 576, 603, 630, 655, 681, 708, 732, 759, 783, 807, 831, 855, 880, 903, 927, 949, 972, 994, 1016, 1038, 1061, 1081, 1102, 1122, 1142, 1162, 1182, 1202, 1221, 1240, 1259, 1279, 1298, 1316, 1334, 1352, 1370, 1387, 1404, 1422, 1440, 1458, 1474, 1490, 1507, 1523, 1540, 1557, 1573, 1588, 1604, 1620, 1636, 1651, 1666, 1681, 1698, 1713, 1727, 1742, 1755, 1768, 1782, 1795, 1807, 1820, 1833, 1845, 1858, 1870, 1882, 1895, 1906, 1918, 1930, 1942, 1953, 1964, 1975, 1987, 1998, 2010, 2020, 2031, 2042, 2053, 2062, 2071, 2080, 2090, 2101, 2112, 2113, 2124, 2135, 2146, 2157, 2168, 2179, 2190, 2200, 2209, 2218, 2227, 2236, 2245, 2255, 2263, 2271, 2280, 2288, 2296, 2304, 2312, 2320, 2328, 2336, 2345, 2353, 2361, 2369, 2377, 2385, 2393, 2401, 2409, 2417, 2424, 2432, 2440, 2448
        ]
    },
    {
        key: '220',
        title: '220°F',
        constant: [
            0, 30, 59, 88, 117, 146, 174, 203, 228, 259, 287, 318, 344, 371, 398, 425, 452, 479, 503, 529, 558, 583, 610, 632, 658, 684, 705, 735, 762, 780, 804, 827, 853, 874, 898, 920, 942, 962, 985, 1006, 1030, 1057, 1068, 1087, 1105, 1126, 1145, 1166, 1184, 1203, 1220, 1242, 1261, 1279, 1296, 1314, 1290, 1346, 1362, 1381, 1402, 1420, 1434, 1448, 1465, 1482, 1498, 1517, 1532, 1546, 1558, 1573, 1587, 1600, 1614, 1629, 1655, 1670, 1684, 1699, 1712, 1725, 1738, 1752, 1765, 1778, 1790, 1802, 1814, 1827, 1839, 1852, 1864, 1877, 1890, 1901, 1912, 1923, 1934, 1946, 1957, 1966, 1976, 1985, 1995, 2004, 2014, 2023, 2033, 2042, 2052, 2061, 2070, 2081, 2090, 2101, 2110, 2119, 2128, 2137, 2146, 2155, 2165, 2176, 2187, 2195, 2200, 2208, 2216, 2224, 2232, 2240, 2247, 2255, 2263, 2271, 2280, 2287, 2295, 2303, 2311, 2320, 2329, 2337, 2345, 2352, 2360, 2368, 2375, 2383, 2391
        ]
    },
    {
        key: '240',
        title: '240°F',
        constant: [
            0, 29, 56, 85, 113, 141, 169, 197, 220, 251, 278, 309, 334, 360, 386, 412, 439, 464, 487, 511, 541, 564, 591, 610, 636, 661, 681, 713, 742, 755, 779, 800, 828, 867, 870, 893, 914, 932, 958, 976, 1000, 1027, 1037, 1059, 1070, 1092, 1110, 1131, 1148, 1167, 1182, 1206, 1227, 1245, 1260, 1279, 1252, 1307, 1322, 1342, 1365, 1385, 1397, 1408, 1429, 1441, 1458, 1480, 1495, 1507, 1518, 1530, 1532, 1543, 1555, 1567, 1615, 1630, 1644, 1659, 1673, 1684, 1697, 1712, 1725, 1738, 1751, 1764, 1778, 1791, 1802, 1813, 1824, 1836, 1849, 1860, 1871, 1883, 1894, 1855, 1866, 1877, 1888, 1899, 1910, 1921, 1931, 1940, 1949, 1958, 1967, 1976, 1985, 1994, 2005, 2015, 2025, 2036, 2047, 2058, 2069, 2078, 2087, 2096, 2106, 2117, 2145, 2152, 2160, 2167, 2175, 2182, 2190, 2197, 2205, 2212, 2220, 2227, 2235, 2242, 2250, 2257, 2265, 2273, 2280, 2287, 2295, 2303, 2310, 2318, 2325
        ]
    },
]

const averagePressures = [
    0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000, 3100, 3200, 3300, 3400, 3500, 3600, 3700, 3800, 3900, 4000, 4100, 4200, 4300, 4400, 4500, 4600, 4700, 4800, 4900, 5000, 5100, 5200, 5300, 5400, 5500, 5600, 5700, 5800, 5900, 6000, 6100, 6200, 6300, 6400, 6500, 6600, 6700, 6800, 6900, 7000, 7100, 7200, 7300, 7400, 7500, 7600, 7700, 7800, 7900, 8000, 8100, 8200, 8300, 8400, 8500, 8600, 8700, 8800, 8900, 9000, 9100, 9200, 9300, 9400, 9500, 9600, 9700, 9800, 9900, 10000, 10100, 10200, 10300, 10400, 10500, 10600, 10700, 10800, 10900, 11000, 11100, 11200, 11300, 11400, 11500, 11600, 11700, 11800, 11900, 12000, 12100, 12200, 12300, 12400, 12500, 12600, 12700, 12800, 12900, 13000, 13100, 13200, 13300, 13400, 13500, 13600, 13700, 13800, 13900, 14000, 14100, 14200, 14300, 14400, 14500, 14600, 14700, 14800, 14900, 15000
]

export { temperatures, TemperaturePlusProps, averagePressures }