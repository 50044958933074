const inchesWater = [
    0.0,
    2.0,
    4.0,
    6.0,
    8.0,
    10.0,
    12.0,
    14.0,
    16.0,
    18.0,
    20.0,
    22.0,
    24.0,
    26.0,
    28.0,
    30.0,
    32.0,
    34.0,
    36.0,
    38.0,
    40.0,
    42.0,
    44.0,
    46.0,
    48.0,
    50.0,
    52.0,
    54.0,
    56.0,
    58.0,
    60.0,
    62.0,
    64.0,
    66.0,
    68.0
]

const volumeGallon = [
    0.0,
    13.5,
    39.6,
    76.0,
    118.4,
    168.9,
    223.2,
    284.4,
    347.8,
    417.3,
    487.7,
    563.4,
    639.0,
    719.2,
    798.3,
    881.3,
    962.3,
    1046.4,
    1127.8,
    1211.5,
    1291.7,
    1370.7,
    1450.9,
    1526.4,
    1602.0,
    1672.3,
    1741.7,
    1804.9,
    1866.0,
    1920.1,
    1970.4,
    2011.6,
    2047.8,
    2074.5,
    2087.2
]

export { volumeGallon, inchesWater }