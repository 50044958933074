import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';
import { Dimensions } from 'react-native';

const maxWidth = 750;
export const Container = styled.ScrollView`
    
margin: 80px 15px;
   
    
background-color: ${({ theme }) => theme.colors.backgroud_primary};
border-width: 3px;
border-color: ${({ theme }) => theme.colors.site_900};
border-radius: 5px;

    
`;

export const ScrollContainer = styled.ScrollView`

`;

export const ScrollWrapper = styled.View` 
padding: 5px;
margin-top: 10px;
margin-left: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
margin-right: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
background-color: ${({ theme }) => theme.colors.backgroud_primary};
`;

export const Message = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
color: ${({ theme }) => theme.colors.shape_dark};
margin-top: 20px;    
`;

export const PressableContainer = styled.TouchableOpacity`
 width: 100%;

padding: 19px;
align-items: center;
justify-content: center;

background-color: ${({ theme }) => theme.colors.main};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableText = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
`;

export const IconContainer = styled.View`
height: 56px;
width: 100%;
justify-content: center;
align-items: center;


background-color: ${({ theme }) => theme.colors.backgroud_primary};

`;


export const Title = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(32) : 32}px;
color: ${({ theme }) => theme.colors.main};
text-align: center;
`;

export const TextNavigation = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
color: ${({ theme }) => theme.colors.shape_dark};  
`;

export const ButtonNavigation = styled.TouchableOpacity`
border-radius: 5px;
background-color: ${({ theme }) => theme.colors.main_light};
`;

export const MessageFinal = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
color: ${({ theme }) => theme.colors.shape_dark};
`;

export const MessageWrapper = styled.View`
margin-top: 10px;
margin-bottom: 10px;
`;

export const ListContainerInch = styled.View`
margin: 0 -10px;
`;