import { Dimensions } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

const maxWidth = 750;

export const Header = styled.View`
width: 100%;
height: 113px;
justify-content: center;
align-items: center;
padding: 20px 20px;
background-color: ${({ theme }) => theme.colors.main};
`;

export const HeaderTitle = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(30) : 30}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
`;

export const ButtonView = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const FlatlistWrapper = styled.View`
padding-top: 10px;
`;

export const Content = styled.View`
    margin-top: ${Dimensions.get('window').width < maxWidth ? 10 : 10}px;
    margin-bottom: ${Dimensions.get('window').width < maxWidth ? 10 : 10}px;
    margin-left: ${Dimensions.get('window').width < maxWidth ? 10 : 100}px;
    margin-right: ${Dimensions.get('window').width < maxWidth ? 10 : 100}px;
`;

export const InputWrapper = styled.View`
margin-top: 10px;
margin-bottom: 20px;
`;

export const DepthContainer = styled.View`
margin-bottom: 10px;
`;

export const VolumeSelectorWrapper = styled.View`
flex-direction: row;
`;

export const PressureSelectorWrapper = styled.View`
flex-direction: row;
justify-content: space-between;
`;

export const Footer = styled.View`
margin-top: 10px;
margin-bottom: 10px;
`;

export const Empty = styled.Text``;

export const AnswerView = styled.View`
justify-content: center;
align-items: center;
margin-bottom: 5px;
`;

export const TextNavigation = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.shape_dark};  
`;

export const ButtonNavigation = styled.TouchableOpacity`
padding: 5px;
border-radius: 5px;
background-color: ${({ theme }) => theme.colors.main_light};
`;

export const MessageWrapper = styled.View`
margin-top: 10px;
`;

export const VolumeSelectorContainer = styled.View`
flex-direction: row;
`;


export const HelpContainer = styled.View`
margin-right: 10px;
align-items: center;
justify-content: space-between;
flex-direction: row;
`;

export const ContainerModal = styled.ScrollView`
    
margin: 10px 10px;
   
    
background-color: ${({ theme }) => theme.colors.backgroud_primary};
border-width: 3px;
border-color: ${({ theme }) => theme.colors.site_900};
border-radius: 5px;

    
`;

export const ContainerModalView = styled.ScrollView`
    
margin: 80px 15px;
   
    
background-color: ${({ theme }) => theme.colors.backgroud_primary};
border-width: 3px;
border-color: ${({ theme }) => theme.colors.site_900};
border-radius: 5px;

    
`;

export const ScrollContainer = styled.ScrollView`

`;

export const ScrollWrapper = styled.View`
padding: 10px;
margin-left: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
margin-right: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;

background-color: ${({ theme }) => theme.colors.backgroud_primary};
`;


export const PressableContainer = styled.TouchableOpacity`
 width: 100%;

padding: 19px;
align-items: center;
justify-content: center;

background-color: ${({ theme }) => theme.colors.main};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableContainerJobs = styled.TouchableOpacity`
 width: 100%;

padding: 19px;
align-items: center;
justify-content: center;

background-color: ${({ theme }) => theme.colors.site_500};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableText = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: justify;
`;

export const IconContainer = styled.View`
justify-content: center;
align-items: center;


background-color: ${({ theme }) => theme.colors.backgroud_primary};

`;


export const Title = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(32) : 32}px;
color: ${({ theme }) => theme.colors.main};
text-align: center;
`;


export const Message = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.shape_dark};
margin-top: 20px;    
`;

export const EmptyMessage = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.site_500};
text-align: center;
`;

export const EmptyMessageWrapper = styled.View`
height: 300px;
width: 100%;
align-items: center;
justify-content: center;
`;

export const PressableContainerSave = styled.TouchableOpacity`
 width: 100%;
 flex-direction: row;
padding: 19px;
align-items: center;
justify-content: space-between;

background-color: ${({ theme }) => theme.colors.site_300};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableTextSave = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;
color: ${({ theme }) => theme.colors.site_600};

`;

export const AskWrapper = styled.View`
flex-direction: row;
align-items: center;
justify-content: flex-start;
`;

export const CheckBoxTouch = styled.TouchableOpacity`
align-items: center;
justify-content: center;
padding: 10px;
`;




export const AskText = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(18) : 18}px;
color: ${({ theme }) => theme.colors.shape_dark};
margin-left: 5px;
`;

export const HelpWrapper = styled.View`
align-items: flex-end;
justify-content: center;
margin-right: 10px;
`;
