import React from 'react';
import { RectButtonProps } from 'react-native-gesture-handler';

import {
    ButtonTemperature,
    TitleTemperature
} from './styles';



interface Props extends RectButtonProps {
    title: string;
    active?: boolean;
}

export function VolumeSelector({ title,
    active,
    ...rest
}: Props) {
    return (
        <ButtonTemperature
            active={active}
            {...rest}>
            <TitleTemperature active={active}>
                {title}
            </TitleTemperature>
        </ButtonTemperature>
    );
}