import React from 'react';

import { StatusBar, useWindowDimensions } from 'react-native';

import { useTheme } from 'styled-components/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';

import {
    Container,
    HeaderView,
    TitleView,
    HeaderTitle,
    IconView,
    ButtonIcon,
    Empty
} from './styles';

import { useNavigation } from '@react-navigation/native';
import { DrawerActions } from '@react-navigation/native';

interface Props {
    title: string;
}

export function Header({
    title
}: Props) {

    const theme = useTheme();

    const navigation = useNavigation();

    const { width } = useWindowDimensions();
    const maxWidth = 750;

    function handleOpenDrawer() {
        navigation.dispatch(DrawerActions.openDrawer());
    }

    return (
        <Container>
            <StatusBar
                barStyle="light-content"
                backgroundColor={theme.colors.main}
            />
            <HeaderView>
                <IconView>
                    <ButtonIcon onPress={handleOpenDrawer}>
                        <MaterialCommunityIcons
                            name='menu'
                            size={width < maxWidth ? RFValue(35) : 35}
                            color={theme.colors.backgroud_secondary}
                        />
                    </ButtonIcon>
                </IconView>
                <TitleView>
                    <HeaderTitle style={{paddingLeft:35, paddingRight:35 }}>{title}</HeaderTitle>
                </TitleView>
                <Empty></Empty>
            </HeaderView>
        </Container>
    );
}