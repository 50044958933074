import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
    ButtonTemperature
} from './styles';

import { TouchableOpacityProps, Dimensions } from 'react-native';

import { RFValue } from 'react-native-responsive-fontsize';

import { useTheme } from 'styled-components/native';

const maxWidth = 750;
interface Props extends TouchableOpacityProps {
    active?: boolean;
}

export function DeleteCard({
    active,
    ...rest
}: Props) {

    const theme = useTheme();

    return (
        <ButtonTemperature
            active={active}
            {...rest}>
            <MaterialCommunityIcons
                name='trash-can-outline'
                color={theme.colors.backgroud_secondary}
                size={Dimensions.get('window').width < maxWidth ? RFValue(24) : 24}
            />
        </ButtonTemperature>
    );
}