import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en.json';
import pt from './pt.json';
import es from './es.json';

import AsyncStorage from '@react-native-async-storage/async-storage';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async callback => {
    const storedLanguage = await AsyncStorage.getItem('language');
    if (storedLanguage) {
      return callback(storedLanguage);
    } 
    else {
      return callback('pt');
    }
  },
  init: () => {},
  cacheUserLanguage: language => {
    AsyncStorage.setItem('language', language);
  },
};
  
i18n
.use(languageDetector)
.use(initReactI18next)
.init({
  compatibilityJSON:'v3',
  fallbackLng: 'pt',
  resources: {
    en: en,
    pt: pt,
    es: es,
  },
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false
  }
});
  
export default i18n;