import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import { Dimensions } from 'react-native';

const maxWidth = 750;

export const PasteCopy = styled.View`
background-color: ${({ theme }) => theme.colors.site_300};
height: 30px;
width: 30px;
border-radius: 15px;
align-items: center;
justify-content: center;
margin-top: 4px;

`;
export const PasteCopyButton = styled.TouchableOpacity`
height: 30px;
width: 30px;
border-radius: 15px;
align-items: center;
justify-content: center;
`;

export const IconPaste = styled.Text`
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
    font-family: ${({ theme }) => theme.fonts.primary_400};
    color: ${({ theme }) => theme.colors.text};
    `;

