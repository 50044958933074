import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import { Platform, Dimensions } from 'react-native';

const maxWidth = 750;

export const Container = styled.ScrollView`
flex: 1;
`;

export const Header = styled.View`
width: 100%;


background-color: ${({ theme }) => theme.colors.main};

justify-content: center;
padding: 15px;
padding-top:  ${Platform.OS === 'web' ? 44 : 5}px;
`;

export const BackButtonWrapper = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;
margin-left: -15px;
`;

export const TemperatureCalculated = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size:${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
margin-top: 10px;
`;

export const PressureCalculated = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
margin-top: 10px;
`;

export const VolumeCalculated = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
margin-top: 10px;
`;

export const TimeCalculated = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
margin-top: 10px;
`;


export const HeaderTitleParameters = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(25) : 25}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
margin-top: 10px;
`;

export const HeaderTitle = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(25) : 25}px;
color: ${({ theme }) => theme.colors.site_500};
margin-top: 5px;
margin-left: 15px;
`;

export const ResultWrapper = styled.View`
margin-top: 5px;
justify-content: center;
`;
export const ResultContainer = styled.View`
flex-direction: row;
justify-content: space-between ;
align-items: flex-end;
`;

export const IconWrapper = styled.View`
margin-right: 50px;

`;

export const CalculationResultGas = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.text};
margin: 10px 15px;

`;
export const Result = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(25) : 25}px;
color: ${({ theme }) => theme.colors.site_500};

`;

export const CalculationResultLiquid = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.text};
margin: 10px 15px;
`;

export const CalculationResultGasLost = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.text};
margin: 10px 15px;
`;

export const CalculationResultTime = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.text};
margin: 10px 15px;
`;

export const Separator = styled.View`
height: 1px;
width: 100%;
background-color: ${({ theme }) => theme.colors.text};
`;

export const EmptyView = styled.View``;

export const ContainerModal = styled.ScrollView`
    
margin: 80px 15px;
   
    
background-color: ${({ theme }) => theme.colors.backgroud_primary};
border-width: 3px;
border-color: ${({ theme }) => theme.colors.site_900};
border-radius: 5px;

    
`;

export const ScrollContainer = styled.ScrollView`

`;

export const ScrollWrapper = styled.View`
 padding: 10px;
 align-items: center;

background-color: ${({ theme }) => theme.colors.backgroud_primary};
`;


export const PressableContainer = styled.TouchableOpacity`
 width: 100%;

padding: 19px;
align-items: center;
justify-content: center;

background-color: ${({ theme }) => theme.colors.main};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableContainerJobs = styled.TouchableOpacity`
 width: 100%;

padding: 19px;
align-items: center;
justify-content: center;

background-color: ${({ theme }) => theme.colors.site_500};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableText = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: justify;
`;

export const IconContainer = styled.View`
height: 56px;
width: 55px;
justify-content: center;
align-items: center;


background-color: ${({ theme }) => theme.colors.backgroud_primary};

`;


export const Title = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(32) : 32}px;
color: ${({ theme }) => theme.colors.main};
text-align: center;
`;


export const Message = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
color: ${({ theme }) => theme.colors.shape_dark};
margin-top: 20px;    
`;