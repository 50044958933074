import React from 'react';

import { StatusBar } from 'react-native';

import { useTheme } from 'styled-components/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';

import {
    Container,
    HeaderView,
    TitleView,
    HeaderTitle,
    IconView,
    ButtonIcon,
    Empty
} from './styles';

import { DeleteAllButton } from '../DeleteAllButton';

import { useNavigation } from '@react-navigation/native';
import { DrawerActions } from '@react-navigation/native';

interface Props {
    title: string;
    handleDeleteAll: () => void;
}

export function HeaderMyCalculations({
    title,
    handleDeleteAll
}: Props) {

    const theme = useTheme();

    const navigation = useNavigation();

    function handleOpenDrawer() {
        navigation.dispatch(DrawerActions.openDrawer());
    }

    return (
        <Container>
            <StatusBar
                barStyle="light-content"
                backgroundColor={theme.colors.main}
            />
            <HeaderView>
                <IconView>
                    <ButtonIcon onPress={handleOpenDrawer}>
                        <MaterialCommunityIcons
                            name='menu'
                            size={35}
                            color={theme.colors.backgroud_secondary}
                        />
                    </ButtonIcon>
                </IconView>
                <TitleView>
                    <HeaderTitle>{title}</HeaderTitle>
                </TitleView>
                <IconView>
                    <DeleteAllButton
                        onPress={handleDeleteAll}
                    />
                </IconView>

            </HeaderView>
        </Container>
    );
}