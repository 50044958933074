import React, { useEffect, useState } from 'react';

import { useTheme } from 'styled-components/native';

import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native';

import {
    Container,
    Header,
    BackButtonWrapper,
    TemperatureCalculated,
    PressureCalculated,
    VolumeCalculated,
    TimeCalculated,
    HeaderTitleParameters,
    HeaderTitle,
    ResultWrapper,
    CalculationResultGas,
    CalculationResultLiquid,
    CalculationResultGasLost,
    CalculationResultTime,
    Result,
    Separator,
    EmptyView,
    ContainerModal,
    ScrollContainer,
    ScrollWrapper,
    PressableContainer,
    PressableText,
    IconContainer,
    Title,
    Message,
    PressableContainerJobs
} from './styles';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { BackButton } from '../../components/BackButton';
import { SaveButton } from '../../components/SaveButton';
import {useTranslation} from 'react-i18next';

interface AnswerProps {
    id: string;
    nameJob: string;
    nameCalc: string;
    totalVolumeGas: string;
    totalVolumeLiquid: string;
    totalVolumeLiquidWithLost: string;
    timeForPressurizationHour: string;
    timeForPressurizationMinutes: string;
    volume: string;
    pressure: string;
    flow: string;
    volumeTypeSelected: string;
    pressureTypeSelected: string;
    temperatureSelected: string;
    date: string
}

interface Params {
    CalcSelected: AnswerProps;
}

export function PressurizationResultSaved() {


    const theme = useTheme();
    const screenIsFocus = useIsFocused();

    const navigation = useNavigation();
    const route = useRoute();
    const {
        CalcSelected
    } = route.params as Params;

    const {t} = useTranslation();

    function handleGoBack() {
        navigation.goBack();
    }

    // useEffect(() => {
    //     console.log('CalcSelected', CalcSelected);
    //     async function calculationsSelected(CalcSelected) {
    //         const dataKey = `@nitrogencalculations:pressurization_calc:admin`;

    //         const response = await AsyncStorage.getItem(dataKey);

    //         const calculations = response ? JSON.parse(response) : [];

    //         setCalculationsFiltered(calculations);
    //         console.log('AllCalculationsSaved', calculationsFiltered);
    //         console.log('jobSelectedInsideFunction', CalcSelected);

    //         setCalculationsFiltered(oldState => oldState.filter(item => item.nameJob === CalcSelected))


    //     }

    //     calculationsSelected(CalcSelected)

    // }, [screenIsFocus])




    return (
        <Container>
            <Header>
                <BackButtonWrapper>
                    <BackButton
                        onPress={handleGoBack}
                        color={theme.colors.backgroud_secondary}
                    />
                    <SaveButton
                        onPress={() => { }}
                        color={theme.colors.main}

                    />
                </BackButtonWrapper>
                <HeaderTitleParameters>{t('Dados da operação de')} {CalcSelected.nameCalc}:</HeaderTitleParameters>
                <TemperatureCalculated>
                    {'\u21D2'}{t('Temperatura de')} {CalcSelected.temperatureSelected}° F
                </TemperatureCalculated>
                <VolumeCalculated>
                    {'\u21D2'}{t('Volume do local pressurizado sendo de')} {CalcSelected.volume.replace('.', ',')} {CalcSelected.volumeTypeSelected}
                </VolumeCalculated>
                <PressureCalculated>
                    {'\u21D2'}{t('Pressão final de bombeio sendo de')} {CalcSelected.pressure.replace('.', ',')} {CalcSelected.pressureTypeSelected}
                </PressureCalculated>
                {
                    CalcSelected.flow === 'Vazão não fornecida'
                        ?
                        <TimeCalculated>
                            {'\u21D2'}{t('Vazão não fornecida')}!
                        </TimeCalculated>
                        :
                        <TimeCalculated>
                            {'\u21D2'}{t('Vazão de')} {CalcSelected.flow.replace('.', ',')} scfm
                        </TimeCalculated>
                }

            </Header>
            <ResultWrapper>
                <HeaderTitle>{t('Resultado: ')}</HeaderTitle>
                <CalculationResultGas
                    selectable
                >
                    {'\u21D2'}{t('O volume para realizar a operação será de ')}<Result>{CalcSelected.totalVolumeGas} scf</Result>
                </CalculationResultGas>
                <Separator />
                <CalculationResultLiquid
                    selectable
                >
                    {'\u21D2'}{t('O volume de nitrogênio líquido bombeado será de')} <Result>{CalcSelected.totalVolumeLiquid} {t('galões')}</Result>
                </CalculationResultLiquid>
                <Separator />
                <CalculationResultGasLost
                    selectable
                >
                    {'\u21D2'}{t('Valor de LN2 necessário considerando 20% de perda e refrigeração da UN será de')} <Result>{CalcSelected.totalVolumeLiquidWithLost} {t('galões')}</Result>
                </CalculationResultGasLost>
                <Separator />
                {CalcSelected.flow === 'Vazão não fornecida'
                    ?
                    <CalculationResultTime
                        selectable
                    >
                        {'\u21D2'}{t('Vazão não fornecida')}!
                    </CalculationResultTime>
                    :
                    <CalculationResultTime
                        selectable
                    >
                        {'\u21D2'}{t('Tempo de bombeio com a vazão de')} {CalcSelected.flow.replace('.', ',')} {t('scfm será de')} {Number(CalcSelected.timeForPressurizationHour) >= 1 && <Result>{Number(CalcSelected.timeForPressurizationHour).toFixed(0).replace('.', ',')} {Number(CalcSelected.timeForPressurizationHour) >= 2 ? t('horas') : t('hora')} {t('e')} </Result>}<Result>{Number(CalcSelected.timeForPressurizationMinutes).toFixed(0).replace('.', ',')} {Number(CalcSelected.timeForPressurizationMinutes) >= 2 ? t('minutos') : t('minuto')}</Result>
                    </CalculationResultTime>

                }

            </ResultWrapper>
        </Container>
    );
}

