import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import { MaterialCommunityIcons, Feather, FontAwesome5 } from '@expo/vector-icons';
import { Dimensions } from 'react-native';

const maxWidth = 750;

export const Container = styled.View`
    width: ${Dimensions.get('window').width < maxWidth ? `49%` : `200px`};
    border-radius: 5px;
    border-color: ${({ theme }) => theme.colors.site_900};
    border-width: 1px;
    background-color: ${({ theme }) => theme.colors.backgroud_secondary};
    margin-top: ${Dimensions.get('window').width < maxWidth ? 0 : 5}px;
    margin-bottom: ${Dimensions.get('window').width < maxWidth ? 5 : 5}px;
    margin-left: ${Dimensions.get('window').width < maxWidth ? 0 : 5}px;
    margin-right: ${Dimensions.get('window').width < maxWidth ? 0 : 5}px;
    `;

export const ButtonWrapper = styled.TouchableOpacity`
 padding: 5px 5px;
 justify-content: center;
 align-items: center;
`;
export const Header = styled.View`
    margin: 5px;
    `;
export const Title = styled.Text`
    font-family: ${({ theme }) => theme.fonts.primary_500};
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
    color: ${({ theme }) => theme.colors.site_500};
    text-align:center ;
    `;

export const IconMaterial = styled(MaterialCommunityIcons)`
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(30) : 30}px;
    `;

export const IconAntDesign = styled(Feather)`
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(26) : 26}px;
    `;

export const IconFontAwesome = styled(FontAwesome5)`
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(26) : 26}px;
    `;

export const Footer = styled.View``;

export const Amount = styled.Text`
    font-family: ${({ theme }) => theme.fonts.primary_400};
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(14) : 14}px;
    color: ${({ theme }) => theme.colors.text}; 
    padding: 5px;
    text-align: center;
    `;
