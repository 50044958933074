import { Platform, Dimensions } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import styled, { css } from 'styled-components/native';

const maxWidth = 750;

interface OptionsProps {
    active: boolean;
}


export const Header = styled.View`
width: 100%;
height: 113px;
justify-content: center;
align-items: center;
padding: 20px 20px;
background-color: ${({ theme }) => theme.colors.main};
`;

export const HeaderTitle = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(30) : 30}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
`;

export const ButtonView = styled.View`
justify-content: center;
align-items: flex-start;
`;

export const ButtonViewBack = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const FlatlistWrapper = styled.View`
padding-top: 10px;
`;

export const ListContainer = styled.View`
margin: 0 -10px;
`;

export const Content = styled.View`
margin: 5px 10px;
margin-bottom: ${Platform.OS === 'web' ? 40 : 20}px;
`;

export const InputWrapper = styled.View`
margin-top: 20px;
margin-bottom: 20px;
`;

export const VolumeSelectorWrapper = styled.View`

`;

export const PressureSelectorWrapper = styled.ScrollView.attrs({
    contentContainerStyle: {
        flexDirection: 'row'
    }
})`

`;
export const ExchangeIconView = styled.View`
align-items: center ;
justify-content:center ;
`;

export const Footer = styled.View`
margin-top: 80px;
margin-bottom: 10px;
`;

export const Empty = styled.Text``;

export const AnswerView = styled.View`
justify-content: center;
align-items: center;
`;

export const Options = styled.View`
border-bottom-width: 1px;
border-bottom-color: ${({ theme }) => theme.colors.line};

flex-direction: row;
justify-content: space-between;



`;

export const Option = styled.TouchableOpacity<OptionsProps>`
padding-bottom: 14px;
padding-top:14px ;
width: 50%;
align-items: center;
justify-content:center ;
${({ active }) => active && css`
border-bottom-width: 3px;
border-bottom-color: ${({ theme }) => theme.colors.main};

`}
`;

export const OptionTitle = styled.Text<OptionsProps>`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
font-family: ${({ theme, active }) =>
        active ? theme.fonts.primary_500 : theme.fonts.primary_400};
color: ${({ theme, active }) =>
        active ? theme.colors.site_500 : theme.colors.header};
`;

export const OptionContainer = styled.View`
margin-left: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
margin-right: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
`;

export const InformationBox = styled.View`
border-width: ${Dimensions.get('window').width < maxWidth ? RFValue(1) : 1}px;
border-color: ${({ theme }) => theme.colors.site_600};
`;

export const InformationText = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(14) : 14}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: center;
`;


export const InformationTextData = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(14) : 14}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.site_500};
text-align: center;
`;



export const InformationBoxHeader = styled.View`
flex-direction: row;
align-items: center;
justify-content: center;
background-color: ${({ theme }) => theme.colors.site_900};
width: 100%;
border-width: ${Dimensions.get('window').width < maxWidth ? RFValue(1) : 1}px;
border-color: ${({ theme }) => theme.colors.site_600};
padding: ${Dimensions.get('window').width < maxWidth ? RFValue(10) : 10}px;
height: ${Dimensions.get('window').width < maxWidth ? RFValue(50) : 50}px;

`;

export const InformationTextBox = styled.View`
border-width: ${Dimensions.get('window').width < maxWidth ? RFValue(1) : 1}px;
border-color: ${({ theme }) => theme.colors.site_600};
padding: ${Dimensions.get('window').width < maxWidth ? RFValue(10) : 10}px;
height: ${Dimensions.get('window').width < maxWidth ? RFValue(55) : 55}px;
width: 50%;
align-items: center;
justify-content: center;
`;

export const InformationBoxData = styled.View`
flex-direction: row;
align-items: center;
justify-content: center;
background-color: ${({ theme }) => theme.colors.backgroud_secondary};
width: 100%;

`;

