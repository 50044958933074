import styled from 'styled-components/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Dimensions } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';

const maxWidth = 750;

export const Container = styled.View`
    background-color: ${({ theme }) => theme.colors.site_500};
    width: ${Dimensions.get('window').width < maxWidth ? RFValue(310) : 310}px;
    height: ${Dimensions.get('window').width < maxWidth ? RFValue(200) : 200}px;
    border-radius: 5px;
    padding: 5px 5px;
    justify-content: space-between;
    
    `;
export const Header = styled.View`
    margin: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    `;

export const TitleView = styled.View``;

export const IconView = styled.View``;

export const Title = styled.Text`
    font-family: ${({ theme }) => theme.fonts.primary_400};
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
    color: ${({ theme }) => theme.colors.backgroud_secondary};
    max-width: ${Dimensions.get('window').width < maxWidth ? RFValue(250) : 250}px
    `;
export const Icon = styled(MaterialCommunityIcons)`
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(40) : 40}px;
    `;

export const PasteCopy = styled.View`
flex-direction: row;
align-items: center;
justify-content: space-between;
`;
export const PasteCopyButton = styled.TouchableOpacity`
padding:10px;
`;

export const IconPaste = styled(MaterialCommunityIcons)`
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(30) : 30}px;
    `;

export const Footer = styled.View`
    align-items: center;
    justify-content: center;
    `;
export const Amount = styled.Text`
    font-family: ${({ theme }) => theme.fonts.primary_500};
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(25) : 25}px;
    color: ${({ theme }) => theme.colors.backgroud_secondary}; 
    padding: 5px;
    text-align: center;
    `;
