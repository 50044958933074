import React, { useState, useEffect } from 'react';
import {
    Platform,
    ScrollView
} from 'react-native';

import {
    ButtonView,
    InputWrapper,
    PressureSelectorWrapper,
    Content,
    ExchangeIconView
} from './styles';

import { useTheme } from 'styled-components/native';

import { useIsFocused } from '@react-navigation/native';

import { useNavigation } from '@react-navigation/native';

import * as Clipboard from 'expo-clipboard';

import { InputConverter } from '../../components/InputConverter';

import { TemperatureSelectorConvert } from '../../components/TemperatureSelectorConvert';
import { ClearButton } from '../../components/ClearButton';
import { Header } from '../../components/Header';

import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';

import configureMeasurements, { temperature } from 'convert-units';
import { RFValue } from 'react-native-responsive-fontsize';
import { BackButton } from '../../components/BackButton';
import { t } from 'i18next';

const convert = configureMeasurements({
    temperature
});

export function TemperatureConverter() {

    const [temperatureFirst, setTemperatureFirst] = useState('0');
    const [temperatureSecond, setTemperatureSecond] = useState('');

    const [temperatureTypeFirstSelected, setTemperatureTypeFirstSelected] = useState<'C' | 'F' | 'K'>('C');
    const [temperatureTypeSecondSelected, setTemperatureTypeSecondSelected] = useState<'C' | 'F' | 'K'>('F');

    const [onFocusFirstInput, setOnFocusFirstInput] = useState(true);
    const [onFocusSecondInput, setOnFocusSecondInput] = useState(false);

    const theme = useTheme();

    const screenIsFocus = useIsFocused();

    const navigation = useNavigation();

    function handleFirstTemperatureTypeSelected(temperatureType: 'C' | 'F' | 'K') {
        setTemperatureTypeFirstSelected(temperatureType);
    };

    function handleSecondTemperatureTypeSelected(temperatureType: 'C' | 'F' | 'K') {
        setTemperatureTypeSecondSelected(temperatureType);
    };


    function handleCopyToClipboardFirstInput() {
        Clipboard.setString(temperatureFirst);
    };

    function handleCopyToClipboardSecondInput() {
        Clipboard.setString(temperatureSecond);
    }

    async function handlePasteToClipboardFirstInput() {
        const fetchCopiedText = async () => {
            const text = await Clipboard.getStringAsync();
            setTemperatureFirst(text);
        }
        fetchCopiedText();
    }

    async function handlePasteToClipboardSecondInput() {
        const fetchCopiedText = async () => {
            const text = await Clipboard.getStringAsync();
            setTemperatureSecond(text);
        }
        fetchCopiedText();
    }

    function handleClearAll() {
        setTemperatureFirst('0');
        setTemperatureSecond('');
        setTemperatureTypeFirstSelected('C');
        setTemperatureTypeSecondSelected('F');
        setOnFocusFirstInput(true);
        setOnFocusSecondInput(false);

    }

    useEffect(() => {
        function handleConvertTemperature() {
            if (onFocusFirstInput) {
                const temperatureFirstFormatted = Number(temperatureFirst.replace(',', '.'));

                const answerFormatted = convert(temperatureFirstFormatted).from(temperatureTypeFirstSelected).to(temperatureTypeSecondSelected);

                const suport = Number(answerFormatted.toFixed(4))

                setTemperatureSecond(String(suport).replace('.', ','))
                return;
            } else if (onFocusSecondInput) {
                const temperatureSecondFormatted = Number(temperatureSecond.replace(',', '.'));

                const answerFormatted = convert(temperatureSecondFormatted).from(temperatureTypeSecondSelected).to(temperatureTypeFirstSelected);

                const suport = Number(answerFormatted.toFixed(4))

                setTemperatureFirst(String(suport).replace('.', ','))
                return;
            } else {
                return;
            }

        }
        handleConvertTemperature();
    }, [screenIsFocus, temperatureFirst, temperatureSecond, temperatureTypeFirstSelected, temperatureTypeSecondSelected]);


    return (
        <ScrollView
            style={{ backgroundColor: theme.colors.backgroud_primary }}
        >
            <Header
                title={t(`Conversor de Unidades`)}
            />
            <Content>
                <ButtonView>
                    <BackButton
                        color={theme.colors.main}
                        onPress={() => navigation.navigate('Home')} />
                    <ClearButton
                        onPress={handleClearAll}
                    />
                </ButtonView>
                <InputWrapper>
                    <InputConverter
                        iconName='thermometer'
                        iconCollection='FontAwesome'
                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                        value={temperatureFirst}
                        onChangeText={(text) => setTemperatureFirst(text)}
                        onFocus={() => {
                            setOnFocusSecondInput(false)
                            setOnFocusFirstInput(true)
                        }}
                        pasteButton={Platform.OS === 'web' ? false : true}
                        handleCopy={handleCopyToClipboardFirstInput}
                        handlePaste={handlePasteToClipboardFirstInput}
                        textAlign='center'
                    />
                    <PressureSelectorWrapper>
                        <TemperatureSelectorConvert
                            title='Celsius'
                            active={'C' === temperatureTypeFirstSelected}
                            onPress={() => { handleFirstTemperatureTypeSelected('C') }}
                        />
                        <TemperatureSelectorConvert
                            title='Fahrenheit'
                            active={'F' === temperatureTypeFirstSelected}
                            onPress={() => { handleFirstTemperatureTypeSelected('F') }}
                        />
                        <TemperatureSelectorConvert
                            title='Kelvin'
                            active={'K' === temperatureTypeFirstSelected}
                            onPress={() => { handleFirstTemperatureTypeSelected('K') }}
                        />
                    </PressureSelectorWrapper>

                </InputWrapper>
                <ExchangeIconView>
                    <MaterialCommunityIcons
                        name='swap-vertical-bold'
                        size={40}
                        color={theme.colors.site_600}
                    />
                </ExchangeIconView>

                <InputWrapper>
                    <InputConverter
                        iconName='thermometer'
                        iconCollection='FontAwesome'
                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                        value={temperatureSecond}
                        onChangeText={(text) => setTemperatureSecond(text)}
                        onFocus={() => {
                            setOnFocusFirstInput(false)
                            setOnFocusSecondInput(true)
                        }}
                        pasteButton={Platform.OS === 'web' ? false : true}
                        handleCopy={handleCopyToClipboardSecondInput}
                        handlePaste={handlePasteToClipboardSecondInput}
                        textAlign='center'
                    />
                    <PressureSelectorWrapper>
                        <TemperatureSelectorConvert
                            title='Celsius'
                            active={'C' === temperatureTypeSecondSelected}
                            onPress={() => { handleSecondTemperatureTypeSelected('C') }}
                        />
                        <TemperatureSelectorConvert
                            title='Fahrenheit'
                            active={'F' === temperatureTypeSecondSelected}
                            onPress={() => { handleSecondTemperatureTypeSelected('F') }}
                        />
                        <TemperatureSelectorConvert
                            title='Kelvin'
                            active={'K' === temperatureTypeSecondSelected}
                            onPress={() => { handleSecondTemperatureTypeSelected('K') }}
                        />
                    </PressureSelectorWrapper>

                </InputWrapper>
            </Content>
        </ScrollView>
    );
}