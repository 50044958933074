import styled, { css } from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import { Dimensions } from 'react-native';
interface OptionsProps {
    active?: boolean;
}

const maxWidth = 750;

export const ButtonTemperature = styled.TouchableOpacity <OptionsProps>`
    background-color: ${({ theme, active }) =>
        active ? theme.colors.site_900 : theme.colors.site_300};
    width: ${Dimensions.get('window').width < maxWidth ? RFValue(76) : 76}px;
    height: ${Dimensions.get('window').width < maxWidth ? RFValue(40) : 40}px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin: 5px 5px;

    
    
`;

export const TitleTemperature = styled.Text <OptionsProps>`
    font-family: ${({ theme, active }) =>
        active ? theme.fonts.primary_500 : theme.fonts.primary_400};
    color: ${({ theme, active }) =>
        active ? theme.colors.backgroud_secondary : theme.colors.text};
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(12) : 12}px;
`;
