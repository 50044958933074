import React from 'react';

import { StatusBar, Dimensions } from 'react-native';

import { useTheme } from 'styled-components/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';

import {
    Container,
    HeaderView,
    TitleView,
    HeaderTitle,
    IconView,
    ButtonIcon,
} from './styles';

import { useNavigation } from '@react-navigation/native';
import { DrawerActions } from '@react-navigation/native';
import { SaveButton } from '../SaveButton';

interface Props {
    title: string;
    handleSave: () => void;
    saveActive: boolean;
}

export function HeaderHydrostaticPressure({
    title,
    handleSave,
    saveActive
}: Props) {

    const theme = useTheme();

    const maxWidth = 750;

    const navigation = useNavigation();

    function handleOpenDrawer() {
        navigation.dispatch(DrawerActions.openDrawer());
    }

    return (
        <Container>
            <StatusBar
                barStyle="light-content"
                backgroundColor={theme.colors.main}
            />
            <HeaderView>
                <IconView>
                    <ButtonIcon onPress={handleOpenDrawer}>
                        <MaterialCommunityIcons
                            name='menu'
                            size={Dimensions.get('window').width < maxWidth ? RFValue(35) : 35}
                            color={theme.colors.backgroud_secondary}
                        />
                    </ButtonIcon>
                </IconView>
                <TitleView>
                    <HeaderTitle>{title}</HeaderTitle>
                </TitleView>
                <IconView>
                    {
                        saveActive ?
                            <SaveButton
                                color={theme.colors.backgroud_secondary}
                                onPress={handleSave}
                            />
                            :
                            <MaterialCommunityIcons
                                name='menu'
                                size={Dimensions.get('window').width < maxWidth ? RFValue(35) : 35}
                                color={theme.colors.main}
                            />
                    }

                </IconView>

            </HeaderView>

        </Container>
    );
}