import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';
import { Dimensions } from 'react-native';

const maxWidth = 750;

export const Header = styled.View`
width: 100%;
height: 113px;
justify-content: center;
align-items: center;
padding: 32px 32px;

background-color: ${({ theme }) => theme.colors.main};
`;

export const HeaderTitle = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(30) : 30}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
margin-top: 20px;    
`;

export const ButtonView = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;

`;

export const FlatlistWrapper = styled.View`
padding-top: 10px;
margin-left: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
margin-right: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
`;

export const Content = styled.View`
margin-top: ${Dimensions.get('window').width < maxWidth ? 10 : 10}px;
    margin-bottom: ${Dimensions.get('window').width < maxWidth ? 10 : 10}px;
    margin-left: ${Dimensions.get('window').width < maxWidth ? 10 : 100}px;
    margin-right: ${Dimensions.get('window').width < maxWidth ? 10 : 100}px;
`;

export const InputWrapper = styled.View`
margin-top: 20px;
margin-bottom: 40px;
`;


export const VolumeContainer = styled.View`
margin-bottom:10px ;
`;

export const HelpTemperatureContainer = styled.View`
margin-right: 20px;
align-items: flex-end;
`;

export const HelpContainer = styled.View`
margin-right: 10px;
align-items: flex-end;
`;

export const VolumeSelectorContainer = styled.View`
flex-direction: row;
`;



export const VolumeSelectorWrapper = styled.View`
flex-direction: row;
justify-content: space-between;
`;

export const PressureSelectorWrapper = styled.View`
flex-direction: row;
justify-content: space-between;
`;


export const PressureContainer = styled.View`
margin-bottom:10px;
`;

export const Footer = styled.View`
margin-top: 30px;
margin-bottom: 10px;
`;

