import React, { useState, useEffect } from 'react';
import {
    FlatList,
    Modal,
    Platform,
    ScrollView,
    View,
} from 'react-native';

import {
    Content,
    TitleView,
    Title,
    PressableContainerJobs,
    PressableText,
    TextWrapper,
    TextDataWrapper,
    BackButtonWrapper,
    EmptyView,
    EmptyMessageWrapper,
    EmptyMessage,
    PressableContainerTypeColumn,
    PressableTextTypeColumnLineBreake
} from './styles';

import { useTheme } from 'styled-components/native';

import { BlurView } from 'expo-blur';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { useIsFocused } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';

import { Header } from '../../components/Header';
import { ForwardArrow } from '../../components/ForwardArrow';
import { ChangeLanguageModal } from '../../components/Modals/ChangeLanguageModal';
import { BackButton } from '../../components/BackButton';

import {useTranslation} from 'react-i18next';
import '../../utils/Language/i18n';
import { UpdateModal } from '../../components/Modals/UpdateModal';


export function Settings() {

    const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    
    const [currentLanguage,setCurrentLanguage] =useState('pt');

    const theme = useTheme();

    const { navigate } = useNavigation();

    const navigation = useNavigation();

    const screenIsFocus = useIsFocused();

    const {t, i18n} = useTranslation();

    const changeLanguage = value => {
        i18n
          .changeLanguage(value)
          .then(() => setCurrentLanguage(value))
          .catch(err => console.log(err));
      };

  async  function handleChangeLanguage(language) {
        if (language === 'en') {
            changeLanguage('en')
            try {
                await AsyncStorage.setItem('language', 'en');
                console.log('salvo com sucesso')
            } catch (error) {
                console.log(error)
            }
            setOpenDeleteAllModal(false)
        } else if (language === 'es') {
            changeLanguage('es')
            try {
                await AsyncStorage.setItem('language', 'es');
                console.log('salvo com sucesso')
            } catch (error) {
                console.log(error)
            }
            setOpenDeleteAllModal(false)
        } 
        else {
            changeLanguage('pt')
            try {
                await AsyncStorage.setItem('language', 'pt');
                console.log('salvo com sucesso')
            } catch (error) {
                console.log(error)
            }
            
            setOpenDeleteAllModal(false)
        }

    }

    useEffect(() => {
        async function LoadLanguage() {
           
                const storedLanguage = await AsyncStorage.getItem('language');

                if (storedLanguage) {
                    setCurrentLanguage(storedLanguage);
                } 
                else {
                    setCurrentLanguage('pt');
                }

                
        }

       LoadLanguage();
       
    },[screenIsFocus])


    return (
        <ScrollView
            style={{ backgroundColor: theme.colors.backgroud_primary }}
            showsVerticalScrollIndicator={false}
        >

            <Header
                title={t('Configurações')}
            />
            <BackButtonWrapper>
                <BackButton
                    onPress={() => navigation.goBack()}
                    color={theme.colors.main}
                />
                <TitleView>
                    <Title>
                      
                    </Title>
                </TitleView>
                <EmptyView>

                </EmptyView>
            </BackButtonWrapper>
            <Content>

            <PressableContainerTypeColumn onPress={() => setOpenDeleteAllModal(true)}>
                                    <PressableTextTypeColumnLineBreake>{t('Idioma')}</PressableTextTypeColumnLineBreake>
                                    <PressableTextTypeColumnLineBreake style={{fontFamily: theme.fonts.primary_500}}>{currentLanguage === 'en' ? t('Inglês') : currentLanguage === 'es' ? t('Espanhol') : t('Português')}</PressableTextTypeColumnLineBreake>
                                </PressableContainerTypeColumn>
                            
            </Content>
            <Modal
                visible={openDeleteAllModal}
                animationType={'slide'}
                onRequestClose={() => setOpenDeleteAllModal(false)}
                transparent
            >
                <BlurView intensity={200} style={{ flex: 1 }}>

                    <ChangeLanguageModal
                        title={t('Selecione Idioma')}
                        iconName={'language'}
                        iconCollection={'FontAwesome'}
                        text={t(`Selecione um idioma abaixo:`)}
                        handleCloseModal={() => setOpenDeleteAllModal(false)}
                        handleNavigation={handleChangeLanguage}
                    />

                </BlurView>

            </Modal>

        </ScrollView>

    );
}