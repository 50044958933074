import React, { useState } from 'react';
import { TextInputProps } from 'react-native';

import { useTheme } from 'styled-components/native';

import { MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';

import {
    Container,
    InputText,
    IconContainer,
    PasteCopy,
    PasteCopyButton,
} from './styles';

interface Props extends TextInputProps {
    iconName: React.ComponentProps<typeof MaterialCommunityIcons>['name'] | React.ComponentProps<typeof FontAwesome>['name'];
    iconCollection?: 'MaterialCommunityIcons' | 'FontAwesome';
    value?: string;
    handleCopy?: () => void;
    handlePaste?: () => void;
    pasteButton?: boolean;
}

export function InputConverter(
    { iconName,
        iconCollection,
        value,
        handleCopy,
        handlePaste,
        pasteButton = false,
        ...rest

    }: Props
) {

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);


    const theme = useTheme();

    function handleInputFocus() {
        setIsFocused(true)
    }

    function handleInputBlur() {
        setIsFocused(false)
        setIsFilled(!!value)
    }

    return (
        <Container >
            <IconContainer isFocused={isFocused}>
                {
                    iconCollection === 'FontAwesome'
                        ?
                        <FontAwesome
                            name={iconName}
                            size={32}
                            color={(isFocused || isFilled) ? theme.colors.site_500 : theme.colors.site_500}

                        />
                        :
                        <MaterialCommunityIcons
                            name={iconName}
                            size={32}
                            color={(isFocused || isFilled) ? theme.colors.site_500 : theme.colors.site_500}

                        />

                }
            </IconContainer>
            <InputText
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                isFocused={isFocused}
                value={value}
                {...rest}
            />
            {
                pasteButton &&
                <PasteCopy isFocused={isFocused}>
                    <PasteCopyButton onPress={handleCopy}>
                        <MaterialCommunityIcons
                            name='clipboard-multiple-outline'
                            size={25}
                            color={theme.colors.site_700}
                        />
                    </PasteCopyButton>
                    <PasteCopyButton onPress={handlePaste}>
                        <MaterialCommunityIcons
                            name='content-paste'
                            size={25}
                            color={theme.colors.site_700}
                        />
                    </PasteCopyButton>
                </PasteCopy>
            }

        </Container>
    );
}