interface CasingAPI5CTProps {
    titleSize: string;
    titleWeight: string;
    outsideDiameter: number;
    weigth?: number;
    wallThickness?: number;
    insideDiameter: number;
}

interface TubingAPI5CTProps {
    titleSize: string;
    titleWeightNU: string;
    titleWeightEU?: string;
    titleWeightIJ?: string;
    outsideDiameter: number;
    weigthNU?: number;
    weigthEU?: number;
    weigthIJ?: number;
    wallThickness?: number;
    insideDiameter: number;
}

interface DrillPipeAPI5DPProps {
    titleSize: string;
    titleWeight: string;
    outsideDiameter: number;
    wallThickness?: number;
    insideDiameter: number;
    nonAPI?: boolean;
}

interface CoilTubeProps {
    titleSize: string;
    titleWeight: string;
    outsideDiameter: number;
    insideDiameter: number;
    nonAPI?: boolean;
}

const casingAPI5CT: CasingAPI5CTProps[] = [
    {
        titleSize: "4 1/2",
        titleWeight: "9.5",
        outsideDiameter: 4.5,
        weigth: 9.7,
        wallThickness: 0.205,
        insideDiameter: 4.09
    },
    {
        titleSize: "4 1/2",
        titleWeight: "10.5",
        outsideDiameter: 4.5,
        weigth: 10.6,
        wallThickness: 0.224,
        insideDiameter: 4.052
    },
    {
        titleSize: "4 1/2",
        titleWeight: "11.6",
        outsideDiameter: 4.5,
        weigth: 11.7,
        wallThickness: 0.25,
        insideDiameter: 4
    },
    {
        titleSize: "4 1/2",
        titleWeight: "13.5",
        outsideDiameter: 4.5,
        weigth: 13.3,
        wallThickness: 0.29,
        insideDiameter: 3.92
    },
    {
        titleSize: "4 1/2",
        titleWeight: "15.1",
        outsideDiameter: 4.5,
        weigth: 15.3,
        wallThickness: 0.337,
        insideDiameter: 3.826
    },
    {
        titleSize: "5",
        titleWeight: "11.5",
        outsideDiameter: 5,
        weigth: 11.6,
        wallThickness: 0.22,
        insideDiameter: 4.56
    },
    {
        titleSize: "5",
        titleWeight: "13",
        outsideDiameter: 5,
        weigth: 13.2,
        wallThickness: 0.253,
        insideDiameter: 4.494
    },
    {
        titleSize: "5",
        titleWeight: "15",
        outsideDiameter: 5,
        weigth: 15.3,
        wallThickness: 0.296,
        insideDiameter: 4.408
    },
    {
        titleSize: "5",
        titleWeight: "18",
        outsideDiameter: 5,
        weigth: 18.3,
        wallThickness: 0.362,
        insideDiameter: 4.276
    },
    {
        titleSize: "5",
        titleWeight: "21.4",
        outsideDiameter: 5,
        weigth: 21.6,
        wallThickness: 0.437,
        insideDiameter: 4.126
    },
    {
        titleSize: "5",
        titleWeight: "23.2",
        outsideDiameter: 5,
        weigth: 23.4,
        wallThickness: 0.478,
        insideDiameter: 4.044
    },
    {
        titleSize: "5",
        titleWeight: "24.1",
        outsideDiameter: 5,
        weigth: 24.3,
        wallThickness: 0.5,
        insideDiameter: 4
    },
    {
        titleSize: "5 1/2",
        titleWeight: "14",
        outsideDiameter: 5.5,
        weigth: 14,
        wallThickness: 0.244,
        insideDiameter: 5.012
    },
    {
        titleSize: "5 1/2",
        titleWeight: "15.5",
        outsideDiameter: 5.5,
        weigth: 15.8,
        wallThickness: 0.275,
        insideDiameter: 4.95
    },
    {
        titleSize: "5 1/2",
        titleWeight: "17",
        outsideDiameter: 5.5,
        weigth: 17.3,
        wallThickness: 0.304,
        insideDiameter: 4.892
    },
    {
        titleSize: "5 1/2",
        titleWeight: "20",
        outsideDiameter: 5.5,
        weigth: 20.2,
        wallThickness: 0.361,
        insideDiameter: 4.778
    },
    {
        titleSize: "5 1/2",
        titleWeight: "23",
        outsideDiameter: 5.5,
        weigth: 22.9,
        wallThickness: 0.415,
        insideDiameter: 4.67
    },
    {
        titleSize: "5 1/2",
        titleWeight: "26.8",
        outsideDiameter: 5.5,
        weigth: 27,
        wallThickness: 0.5,
        insideDiameter: 4.5
    },
    {
        titleSize: "5 1/2",
        titleWeight: "29.7",
        outsideDiameter: 5.5,
        weigth: 29.9,
        wallThickness: 0.562,
        insideDiameter: 4.376
    },
    {
        titleSize: "5 1/2",
        titleWeight: "32.6",
        outsideDiameter: 5.5,
        weigth: 32.7,
        wallThickness: 0.625,
        insideDiameter: 4.25
    },
    {
        titleSize: "5 1/2",
        titleWeight: "35.3",
        outsideDiameter: 5.5,
        weigth: 35.5,
        wallThickness: 0.687,
        insideDiameter: 4.126
    },
    {
        titleSize: "5 1/2",
        titleWeight: "38",
        outsideDiameter: 5.5,
        weigth: 38.2,
        wallThickness: 0.75,
        insideDiameter: 4
    },
    {
        titleSize: "5 1/2",
        titleWeight: "40.5",
        outsideDiameter: 5.5,
        weigth: 40.8,
        wallThickness: 0.812,
        insideDiameter: 3.876
    },
    {
        titleSize: "5 1/2",
        titleWeight: "43.1",
        outsideDiameter: 5.5,
        weigth: 43.3,
        wallThickness: 0.875,
        insideDiameter: 3.75
    },
    {
        titleSize: "6 5/8",
        titleWeight: "20",
        outsideDiameter: 6.625,
        weigth: 20,
        wallThickness: 0.288,
        insideDiameter: 6.049
    },
    {
        titleSize: "6 5/8",
        titleWeight: "24",
        outsideDiameter: 6.625,
        weigth: 24,
        wallThickness: 0.352,
        insideDiameter: 5.921
    },
    {
        titleSize: "6 5/8",
        titleWeight: "28",
        outsideDiameter: 6.625,
        weigth: 28,
        wallThickness: 0.417,
        insideDiameter: 5.791
    },
    {
        titleSize: "6 5/8",
        titleWeight: "32",
        outsideDiameter: 6.625,
        weigth: 32,
        wallThickness: 0.475,
        insideDiameter: 5.675
    },
    {
        titleSize: "7",
        titleWeight: "17",
        outsideDiameter: 7,
        weigth: 17.2,
        wallThickness: 0.231,
        insideDiameter: 6.538
    },
    {
        titleSize: "7",
        titleWeight: "20",
        outsideDiameter: 7,
        weigth: 20.1,
        wallThickness: 0.272,
        insideDiameter: 6.456
    },
    {
        titleSize: "7",
        titleWeight: "23",
        outsideDiameter: 7,
        weigth: 23.3,
        wallThickness: 0.317,
        insideDiameter: 6.366
    },
    {
        titleSize: "7",
        titleWeight: "26",
        outsideDiameter: 7,
        weigth: 26.3,
        wallThickness: 0.362,
        insideDiameter: 6.276
    },
    {
        titleSize: "7",
        titleWeight: "29",
        outsideDiameter: 7,
        weigth: 29.3,
        wallThickness: 0.408,
        insideDiameter: 6.184
    },
    {
        titleSize: "7",
        titleWeight: "32",
        outsideDiameter: 7,
        weigth: 32.2,
        wallThickness: 0.453,
        insideDiameter: 6.094
    },
    {
        titleSize: "7",
        titleWeight: "35",
        outsideDiameter: 7,
        weigth: 35,
        wallThickness: 0.498,
        insideDiameter: 6.004
    },
    {
        titleSize: "7",
        titleWeight: "38",
        outsideDiameter: 7,
        weigth: 37.7,
        wallThickness: 0.54,
        insideDiameter: 5.92
    },
    {
        titleSize: "7",
        titleWeight: "42.7",
        outsideDiameter: 7,
        weigth: 42.9,
        wallThickness: 0.625,
        insideDiameter: 5.75
    },
    {
        titleSize: "7",
        titleWeight: "46.4",
        outsideDiameter: 7,
        weigth: 46.6,
        wallThickness: 0.687,
        insideDiameter: 5.626
    },
    {
        titleSize: "7",
        titleWeight: "50.1",
        outsideDiameter: 7,
        weigth: 50.3,
        wallThickness: 0.75,
        insideDiameter: 5.5
    },
    {
        titleSize: "7",
        titleWeight: "53.6",
        outsideDiameter: 7,
        weigth: 53.9,
        wallThickness: 0.812,
        insideDiameter: 5.376
    },
    {
        titleSize: "7",
        titleWeight: "57.1",
        outsideDiameter: 7,
        weigth: 57.4,
        wallThickness: 0.875,
        insideDiameter: 5.25
    },
    {
        titleSize: "7 5/8",
        titleWeight: "24",
        outsideDiameter: 7.625,
        weigth: 24,
        wallThickness: 0.3,
        insideDiameter: 7.025
    },
    {
        titleSize: "7 5/8",
        titleWeight: "26.4",
        outsideDiameter: 7.625,
        weigth: 26.4,
        wallThickness: 0.328,
        insideDiameter: 6.969
    },
    {
        titleSize: "7 5/8",
        titleWeight: "29.7",
        outsideDiameter: 7.625,
        weigth: 29.7,
        wallThickness: 0.375,
        insideDiameter: 6.875
    },
    {
        titleSize: "7 5/8",
        titleWeight: "33.7",
        outsideDiameter: 7.625,
        weigth: 33.7,
        wallThickness: 0.43,
        insideDiameter: 6.765
    },
    {
        titleSize: "7 5/8",
        titleWeight: "39",
        outsideDiameter: 7.625,
        weigth: 39,
        wallThickness: 0.5,
        insideDiameter: 6.625
    },
    {
        titleSize: "7 5/8",
        titleWeight: "42.8",
        outsideDiameter: 7.625,
        weigth: 42.8,
        wallThickness: 0.562,
        insideDiameter: 6.501
    },
    {
        titleSize: "7 5/8",
        titleWeight: "45.3",
        outsideDiameter: 7.625,
        weigth: 45.3,
        wallThickness: 0.595,
        insideDiameter: 6.435
    },
    {
        titleSize: "7 5/8",
        titleWeight: "47.1",
        outsideDiameter: 7.625,
        weigth: 47.1,
        wallThickness: 0.625,
        insideDiameter: 6.375
    },
    {
        titleSize: "7 5/8",
        titleWeight: "51.2",
        outsideDiameter: 7.625,
        weigth: 51.2,
        wallThickness: 0.687,
        insideDiameter: 6.251
    },
    {
        titleSize: "7 5/8",
        titleWeight: "55.3",
        outsideDiameter: 7.625,
        weigth: 55.3,
        wallThickness: 0.75,
        insideDiameter: 6.125
    },
    {
        titleSize: "7 3/4",
        titleWeight: "46.1",
        outsideDiameter: 7.75,
        weigth: 46.1,
        wallThickness: 0.595,
        insideDiameter: 6.56
    },
    {
        titleSize: "8 5/8",
        titleWeight: "24",
        outsideDiameter: 8.625,
        weigth: 24,
        wallThickness: 0.264,
        insideDiameter: 8.097
    },
    {
        titleSize: "8 5/8",
        titleWeight: "28",
        outsideDiameter: 8.625,
        weigth: 28,
        wallThickness: 0.304,
        insideDiameter: 8.017
    },
    {
        titleSize: "8 5/8",
        titleWeight: "32",
        outsideDiameter: 8.625,
        weigth: 32,
        wallThickness: 0.352,
        insideDiameter: 7.921
    },
    {
        titleSize: "8 5/8",
        titleWeight: "36",
        outsideDiameter: 8.625,
        weigth: 36,
        wallThickness: 0.4,
        insideDiameter: 7.825
    },
    {
        titleSize: "8 5/8",
        titleWeight: "40",
        outsideDiameter: 8.625,
        weigth: 40,
        wallThickness: 0.45,
        insideDiameter: 7.725
    },
    {
        titleSize: "8 5/8",
        titleWeight: "44",
        outsideDiameter: 8.625,
        weigth: 44,
        wallThickness: 0.5,
        insideDiameter: 7.625
    },
    {
        titleSize: "8 5/8",
        titleWeight: "49",
        outsideDiameter: 8.625,
        weigth: 49,
        wallThickness: 0.557,
        insideDiameter: 7.511
    },
    {
        titleSize: "9 5/8",
        titleWeight: "32.3",
        outsideDiameter: 9.625,
        weigth: 32.3,
        wallThickness: 0.312,
        insideDiameter: 9.001
    },
    {
        titleSize: "9 5/8",
        titleWeight: "36",
        outsideDiameter: 9.625,
        weigth: 36,
        wallThickness: 0.352,
        insideDiameter: 8.921
    },
    {
        titleSize: "9 5/8",
        titleWeight: "40",
        outsideDiameter: 9.625,
        weigth: 40,
        wallThickness: 0.395,
        insideDiameter: 8.835
    },
    {
        titleSize: "9 5/8",
        titleWeight: "43.5",
        outsideDiameter: 9.625,
        weigth: 43.5,
        wallThickness: 0.435,
        insideDiameter: 8.755
    },
    {
        titleSize: "9 5/8",
        titleWeight: "47",
        outsideDiameter: 9.625,
        weigth: 47,
        wallThickness: 0.472,
        insideDiameter: 8.681
    },
    {
        titleSize: "9 5/8",
        titleWeight: "53.5",
        outsideDiameter: 9.625,
        weigth: 53.5,
        wallThickness: 0.545,
        insideDiameter: 8.535
    },
    {
        titleSize: "9 5/8",
        titleWeight: "58.4",
        outsideDiameter: 9.625,
        weigth: 58.4,
        wallThickness: 0.595,
        insideDiameter: 8.435
    },
    {
        titleSize: "9 5/8",
        titleWeight: "59.4",
        outsideDiameter: 9.625,
        weigth: 59.4,
        wallThickness: 0.609,
        insideDiameter: 8.407
    },
    {
        titleSize: "9 5/8",
        titleWeight: "64.9",
        outsideDiameter: 9.625,
        weigth: 64.9,
        wallThickness: 0.672,
        insideDiameter: 8.281
    },
    {
        titleSize: "9 5/8",
        titleWeight: "70.3",
        outsideDiameter: 9.625,
        weigth: 70.3,
        wallThickness: 0.734,
        insideDiameter: 8.157
    },
    {
        titleSize: "9 5/8",
        titleWeight: "75.6",
        outsideDiameter: 9.625,
        weigth: 75.6,
        wallThickness: 0.797,
        insideDiameter: 8.031
    },
    {
        titleSize: "10 3/4",
        titleWeight: "32.75",
        outsideDiameter: 10.75,
        weigth: 32.75,
        wallThickness: 0.279,
        insideDiameter: 10.192
    },
    {
        titleSize: "10 3/4",
        titleWeight: "40.5",
        outsideDiameter: 10.75,
        weigth: 40.5,
        wallThickness: 0.35,
        insideDiameter: 10.05
    },
    {
        titleSize: "10 3/4",
        titleWeight: "45.5",
        outsideDiameter: 10.75,
        weigth: 45.5,
        wallThickness: 0.4,
        insideDiameter: 9.95
    },
    {
        titleSize: "10 3/4",
        titleWeight: "51",
        outsideDiameter: 10.75,
        weigth: 51,
        wallThickness: 0.45,
        insideDiameter: 9.85
    },
    {
        titleSize: "10 3/4",
        titleWeight: "55.5",
        outsideDiameter: 10.75,
        weigth: 55.5,
        wallThickness: 0.495,
        insideDiameter: 9.76
    },
    {
        titleSize: "10 3/4",
        titleWeight: "60.7",
        outsideDiameter: 10.75,
        weigth: 60.7,
        wallThickness: 0.545,
        insideDiameter: 9.66
    },
    {
        titleSize: "10 3/4",
        titleWeight: "65.7",
        outsideDiameter: 10.75,
        weigth: 65.7,
        wallThickness: 0.595,
        insideDiameter: 9.56
    },
    {
        titleSize: "10 3/4",
        titleWeight: "73.2",
        outsideDiameter: 10.75,
        weigth: 73.2,
        wallThickness: 0.672,
        insideDiameter: 9.406
    },
    {
        titleSize: "10 3/4",
        titleWeight: "79.2",
        outsideDiameter: 10.75,
        weigth: 79.2,
        wallThickness: 0.734,
        insideDiameter: 9.282
    },
    {
        titleSize: "10 3/4",
        titleWeight: "85.3",
        outsideDiameter: 10.75,
        weigth: 85.3,
        wallThickness: 0.797,
        insideDiameter: 9.156
    },
    {
        titleSize: "11 3/4",
        titleWeight: "42",
        outsideDiameter: 11.75,
        weigth: 42,
        wallThickness: 0.333,
        insideDiameter: 11.084
    },
    {
        titleSize: "11 3/4",
        titleWeight: "47",
        outsideDiameter: 11.75,
        weigth: 47,
        wallThickness: 0.375,
        insideDiameter: 11
    },
    {
        titleSize: "11 3/4",
        titleWeight: "54",
        outsideDiameter: 11.75,
        weigth: 54,
        wallThickness: 0.435,
        insideDiameter: 10.88
    },
    {
        titleSize: "11 3/4",
        titleWeight: "60",
        outsideDiameter: 11.75,
        weigth: 60,
        wallThickness: 0.489,
        insideDiameter: 10.772
    },
    {
        titleSize: "11 3/4",
        titleWeight: "65",
        outsideDiameter: 11.75,
        weigth: 65,
        wallThickness: 0.534,
        insideDiameter: 10.682
    },
    {
        titleSize: "11 3/4",
        titleWeight: "71",
        outsideDiameter: 11.75,
        weigth: 71,
        wallThickness: 0.582,
        insideDiameter: 10.586
    },
    {
        titleSize: "13 3/8",
        titleWeight: "48",
        outsideDiameter: 13.375,
        weigth: 48,
        wallThickness: 0.33,
        insideDiameter: 12.715
    },
    {
        titleSize: "13 3/8",
        titleWeight: "54.5",
        outsideDiameter: 13.375,
        weigth: 54.5,
        wallThickness: 0.38,
        insideDiameter: 12.615
    },
    {
        titleSize: "13 3/8",
        titleWeight: "61",
        outsideDiameter: 13.375,
        weigth: 61,
        wallThickness: 0.43,
        insideDiameter: 12.515
    },
    {
        titleSize: "13 3/8",
        titleWeight: "68",
        outsideDiameter: 13.375,
        weigth: 68,
        wallThickness: 0.48,
        insideDiameter: 12.415
    },
    {
        titleSize: "13 3/8",
        titleWeight: "72",
        outsideDiameter: 13.375,
        weigth: 72,
        wallThickness: 0.514,
        insideDiameter: 12.347
    },
    {
        titleSize: "16",
        titleWeight: "65",
        outsideDiameter: 16,
        weigth: 65,
        wallThickness: 0.375,
        insideDiameter: 15.25
    },
    {
        titleSize: "16",
        titleWeight: "75",
        outsideDiameter: 16,
        weigth: 75,
        wallThickness: 0.438,
        insideDiameter: 15.124
    },
    {
        titleSize: "16",
        titleWeight: "84",
        outsideDiameter: 16,
        weigth: 84,
        wallThickness: 0.495,
        insideDiameter: 15.01
    },
    {
        titleSize: "16",
        titleWeight: "109",
        outsideDiameter: 16,
        weigth: 109,
        wallThickness: 0.656,
        insideDiameter: 14.688
    },
    {
        titleSize: "18 5/8",
        titleWeight: "87.5",
        outsideDiameter: 18.625,
        weigth: 87.5,
        wallThickness: 0.435,
        insideDiameter: 17.755
    },
    {
        titleSize: "20",
        titleWeight: "94",
        outsideDiameter: 20,
        weigth: 94,
        wallThickness: 0.438,
        insideDiameter: 19.124
    },
    {
        titleSize: "20",
        titleWeight: "106.5",
        outsideDiameter: 20,
        weigth: 106.5,
        wallThickness: 0.5,
        insideDiameter: 19
    },
    {
        titleSize: "20",
        titleWeight: "133",
        outsideDiameter: 20,
        weigth: 133,
        wallThickness: 0.635,
        insideDiameter: 18.73
    }
]

const tubingAPI5CT: TubingAPI5CTProps[] = [
    {
        titleSize: "1,050",
        titleWeightNU: "1.14",
        titleWeightEU: "1.20",
        outsideDiameter: 1.05,
        weigthNU: 1.14,
        weigthEU: 1.20,
        wallThickness: 0.113,
        insideDiameter: 0.824
    },
    {
        titleSize: "1,050",
        titleWeightNU: "1.48",
        titleWeightEU: "1.54",
        outsideDiameter: 1.05,
        weigthNU: 1.48,
        weigthEU: 1.54,
        wallThickness: 0.154,
        insideDiameter: 0.742
    },
    {
        titleSize: "1,315",
        titleWeightNU: "1.70",
        titleWeightEU: "1.80",
        titleWeightIJ: "1.72",
        outsideDiameter: 1.315,
        weigthNU: 1.7,
        weigthEU: 1.80,
        weigthIJ: 1.72,
        wallThickness: 0.133,
        insideDiameter: 1.049
    },
    {
        titleSize: "1,315",
        titleWeightNU: "2.19",
        titleWeightEU: "2.24",
        outsideDiameter: 1.315,
        weigthNU: 2.19,
        weigthEU: 2.24,
        wallThickness: 0.179,
        insideDiameter: 0.957
    },
    {
        titleSize: "1,660",
        titleWeightNU: "2.09",
        titleWeightIJ: "2.1",
        outsideDiameter: 1.66,
        weigthIJ: 2.1,
        wallThickness: 0.125,
        insideDiameter: 1.41
    },
    {
        titleSize: "1,660",
        titleWeightNU: "2.30",
        titleWeightEU: "2.40",
        titleWeightIJ: "2.33",
        outsideDiameter: 1.66,
        weigthNU: 2.3,
        weigthEU: 2.40,
        weigthIJ: 2.33,
        wallThickness: 0.14,
        insideDiameter: 1.38
    },
    {
        titleSize: "1,660",
        titleWeightNU: "3.03",
        titleWeightEU: "3.07",
        outsideDiameter: 1.66,
        weigthNU: 3.03,
        weigthEU: 3.07,
        wallThickness: 0.191,
        insideDiameter: 1.278
    },
    {
        titleSize: "1,900",
        titleWeightNU: "2.40",
        titleWeightIJ: "2.40",
        outsideDiameter: 1.9,
        weigthIJ: 2.4,
        wallThickness: 0.125,
        insideDiameter: 1.65
    },
    {
        titleSize: "1,900",
        titleWeightNU: "2.75",
        titleWeightEU: "2.90",
        titleWeightIJ: "2.76",
        outsideDiameter: 1.9,
        weigthNU: 2.75,
        weigthEU: 2.90,
        weigthIJ: 2.76,
        wallThickness: 0.145,
        insideDiameter: 1.61
    },
    {
        titleSize: "1,900",
        titleWeightNU: "3.65",
        titleWeightEU: "3.73",
        outsideDiameter: 1.9,
        weigthNU: 3.65,
        weigthEU: 3.73,
        wallThickness: 0.2,
        insideDiameter: 1.5
    },
    {
        titleSize: "1,900",
        titleWeightNU: "4.42",
        outsideDiameter: 1.9,
        weigthNU: 4.42,
        wallThickness: 0.25,
        insideDiameter: 1.4
    },
    {
        titleSize: "1,900",
        titleWeightNU: "5.15",
        outsideDiameter: 1.9,
        weigthNU: 5.15,
        wallThickness: 0.3,
        insideDiameter: 1.3
    },
    {
        titleSize: "2,063",
        titleWeightNU: "3.24",
        titleWeightIJ: "3.25",
        outsideDiameter: 2.063,
        weigthIJ: 3.25,
        wallThickness: 0.156,
        insideDiameter: 1.751
    },
    {
        titleSize: "2,063",
        titleWeightNU: "4.50",
        outsideDiameter: 2.063,
        weigthNU: 4.5,
        wallThickness: 0.225,
        insideDiameter: 1.613
    },
    {
        titleSize: "2 3/8",
        titleWeightNU: "4.00",
        outsideDiameter: 2.375,
        weigthNU: 4,
        wallThickness: 0.167,
        insideDiameter: 2.041
    },
    {
        titleSize: "2 3/8",
        titleWeightNU: "4.60",
        titleWeightEU: "4.70",
        outsideDiameter: 2.375,
        weigthNU: 4.6,
        weigthEU: 4.70,
        wallThickness: 0.19,
        insideDiameter: 1.995
    },
    {
        titleSize: "2 3/8",
        titleWeightNU: "5.80",
        titleWeightEU: "5.95",
        outsideDiameter: 2.375,
        weigthNU: 5.8,
        weigthEU: 5.95,
        wallThickness: 0.254,
        insideDiameter: 1.867
    },
    {
        titleSize: "2 3/8",
        titleWeightNU: "6.60",
        outsideDiameter: 2.375,
        weigthNU: 6.6,
        wallThickness: 0.295,
        insideDiameter: 1.785
    },
    {
        titleSize: "2 3/8",
        titleWeightNU: "7.35",
        titleWeightEU: "7.45",
        outsideDiameter: 2.375,
        weigthNU: 7.35,
        weigthEU: 7.45,
        wallThickness: 0.336,
        insideDiameter: 1.703
    },
    {
        titleSize: "2 7/8",
        titleWeightNU: "6.40",
        titleWeightEU: "6.50",
        outsideDiameter: 2.875,
        weigthNU: 6.4,
        weigthEU: 6.50,
        wallThickness: 0.217,
        insideDiameter: 2.441
    },
    {
        titleSize: "2 7/8",
        titleWeightNU: "7.80",
        titleWeightEU: "7.90",
        outsideDiameter: 2.875,
        weigthNU: 7.8,
        weigthEU: 7.90,
        wallThickness: 0.276,
        insideDiameter: 2.323
    },
    {
        titleSize: "2 7/8",
        titleWeightNU: "8.60",
        titleWeightEU: "8.70",
        outsideDiameter: 2.875,
        weigthNU: 8.6,
        weigthEU: 8.70,
        wallThickness: 0.308,
        insideDiameter: 2.259
    },
    {
        titleSize: "2 7/8",
        titleWeightNU: "9.35",
        titleWeightEU: "9.45",
        outsideDiameter: 2.875,
        weigthNU: 9.35,
        weigthEU: 9.45,
        wallThickness: 0.34,
        insideDiameter: 2.195
    },
    {
        titleSize: "2 7/8",
        titleWeightNU: "10.50",
        outsideDiameter: 2.875,
        weigthNU: 10.5,
        wallThickness: 0.392,
        insideDiameter: 2.091
    },
    {
        titleSize: "2 7/8",
        titleWeightNU: "11.50",
        outsideDiameter: 2.875,
        weigthNU: 11.5,
        wallThickness: 0.44,
        insideDiameter: 1.995
    },
    {
        titleSize: "3 1/2",
        titleWeightNU: "7.70",
        outsideDiameter: 3.5,
        weigthNU: 7.7,
        wallThickness: 0.216,
        insideDiameter: 3.068
    },
    {
        titleSize: "3 1/2",
        titleWeightNU: "9.20",
        titleWeightEU: "9.30",
        outsideDiameter: 3.5,
        weigthNU: 9.2,
        weigthEU: 9.30,
        wallThickness: 0.254,
        insideDiameter: 2.992
    },
    {
        titleSize: "3 1/2",
        titleWeightNU: "10.20",
        outsideDiameter: 3.5,
        weigthNU: 10.2,
        wallThickness: 0.289,
        insideDiameter: 2.922
    },
    {
        titleSize: "3 1/2",
        titleWeightNU: "12.70",
        titleWeightEU: "12.95",
        outsideDiameter: 3.5,
        weigthNU: 12.7,
        weigthEU: 12.9,
        wallThickness: 0.375,
        insideDiameter: 2.75
    },
    {
        titleSize: "3 1/2",
        titleWeightNU: "14.30",
        outsideDiameter: 3.5,
        weigthNU: 14.3,
        wallThickness: 0.43,
        insideDiameter: 2.64
    },
    {
        titleSize: "3 1/2",
        titleWeightNU: "15.50",
        outsideDiameter: 3.5,
        weigthNU: 15.5,
        wallThickness: 0.476,
        insideDiameter: 2.548
    },
    {
        titleSize: "3 1/2",
        titleWeightNU: "17.00",
        outsideDiameter: 3.5,
        weigthNU: 17,
        wallThickness: 0.53,
        insideDiameter: 2.44
    },
    {
        titleSize: "4",
        titleWeightNU: "9.50",
        outsideDiameter: 4,
        weigthNU: 9.5,
        wallThickness: 0.226,
        insideDiameter: 3.548
    },
    {
        titleSize: "4",
        titleWeightNU: "10.70",
        titleWeightEU: "11.00",
        outsideDiameter: 4,
        weigthEU: 11.00,
        wallThickness: 0.262,
        insideDiameter: 3.476
    },
    {
        titleSize: "4",
        titleWeightNU: "13.20",
        outsideDiameter: 4,
        weigthNU: 13.2,
        wallThickness: 0.33,
        insideDiameter: 3.34
    },
    {
        titleSize: "4",
        titleWeightNU: "16.10",
        outsideDiameter: 4,
        weigthNU: 16.1,
        wallThickness: 0.415,
        insideDiameter: 3.17
    },
    {
        titleSize: "4",
        titleWeightNU: "18.90",
        outsideDiameter: 4,
        weigthNU: 18.9,
        wallThickness: 0.5,
        insideDiameter: 3
    },
    {
        titleSize: "4",
        titleWeightNU: "22.20",
        outsideDiameter: 4,
        weigthNU: 22.2,
        wallThickness: 0.61,
        insideDiameter: 2.78
    },
    {
        titleSize: "4 1/2",
        titleWeightNU: "12.60",
        titleWeightEU: "12.75",
        outsideDiameter: 4.5,
        weigthNU: 12.6,
        weigthEU: 12.75,
        wallThickness: 0.271,
        insideDiameter: 3.958
    },
    {
        titleSize: "4 1/2",
        titleWeightNU: "15.20",
        outsideDiameter: 4.5,
        weigthNU: 15.2,
        wallThickness: 0.337,
        insideDiameter: 3.826
    },
    {
        titleSize: "4 1/2",
        titleWeightNU: "17.00",
        outsideDiameter: 4.5,
        weigthNU: 17,
        wallThickness: 0.38,
        insideDiameter: 3.74
    },
    {
        titleSize: "4 1/2",
        titleWeightNU: "18.90",
        outsideDiameter: 4.5,
        weigthNU: 18.9,
        wallThickness: 0.43,
        insideDiameter: 3.64
    },
    {
        titleSize: "4 1/2",
        titleWeightNU: "21.50",
        outsideDiameter: 4.5,
        weigthNU: 21.5,
        wallThickness: 0.5,
        insideDiameter: 3.5
    },
    {
        titleSize: "4 1/2",
        titleWeightNU: "23.70",
        outsideDiameter: 4.5,
        weigthNU: 23.7,
        wallThickness: 0.56,
        insideDiameter: 3.38
    },
    {
        titleSize: "4 1/2",
        titleWeightNU: "26.10",
        outsideDiameter: 4.5,
        weigthNU: 26.1,
        wallThickness: 0.63,
        insideDiameter: 3.24
    }
]

const drillPipeAPI5DP: DrillPipeAPI5DPProps[] = [
    {
        titleSize: "2 3/8",
        titleWeight: "4.85",
        outsideDiameter: 2.375,
        insideDiameter: 1.995,
        nonAPI: true

    },
    {
        titleSize: "2 3/8",
        titleWeight: "6.65",
        outsideDiameter: 2.375,
        wallThickness: 0.28,
        insideDiameter: 1.815

    },
    {
        titleSize: "2 7/8",
        titleWeight: "10.40",
        outsideDiameter: 2.875,
        wallThickness: 0.362,
        insideDiameter: 2.151

    },
    {
        titleSize: "2 7/8",
        titleWeight: "6.85",
        outsideDiameter: 2.875,
        insideDiameter: 2.441,
        nonAPI: true

    },
    {
        titleSize: "3 1/2",
        titleWeight: "9.50",
        outsideDiameter: 3.500,
        wallThickness: 0.254,
        insideDiameter: 2.992

    },
    {
        titleSize: "3 1/2",
        titleWeight: "13.30",
        outsideDiameter: 3.500,
        wallThickness: 0.368,
        insideDiameter: 2.764,

    },
    {
        titleSize: "3 1/2",
        titleWeight: "15.50",
        outsideDiameter: 3.500,
        wallThickness: 0.449,
        insideDiameter: 2.602,

    },
    {
        titleSize: "4",
        titleWeight: "11.85",
        outsideDiameter: 4.000,
        insideDiameter: 3.476,
        nonAPI: true

    },
    {
        titleSize: "4",
        titleWeight: "14.00",
        outsideDiameter: 4.000,
        wallThickness: 0.33,
        insideDiameter: 3.34

    },
    {
        titleSize: "4",
        titleWeight: "15.7",
        outsideDiameter: 4.000,
        insideDiameter: 3.24,
        nonAPI: true

    },
    {
        titleSize: "4 1/2",
        titleWeight: "13.75",
        outsideDiameter: 4.500,
        wallThickness: 0.271,
        insideDiameter: 3.958

    },

    {
        titleSize: "4 1/2",
        titleWeight: "16.60",
        outsideDiameter: 4.500,
        wallThickness: 0.337,
        insideDiameter: 3.826

    },

    {
        titleSize: "4 1/2",
        titleWeight: "20",
        outsideDiameter: 4.500,
        wallThickness: 0.43,
        insideDiameter: 3.64

    },
    {
        titleSize: "5",
        titleWeight: "19.5",
        outsideDiameter: 5.000,
        wallThickness: 0.362,
        insideDiameter: 4.276,

    },
    {
        titleSize: "5",
        titleWeight: "25.60",
        outsideDiameter: 5.000,
        wallThickness: 0.5,
        insideDiameter: 4

    },
    {
        titleSize: "5 1/2",
        titleWeight: "21.90",
        outsideDiameter: 5.500,
        wallThickness: 0.361,
        insideDiameter: 4.778

    },
    {
        titleSize: "5 1/2",
        titleWeight: "24.70",
        outsideDiameter: 5.500,
        wallThickness: 0.415,
        insideDiameter: 4.67,

    },
    {
        titleSize: "5 9/16",
        titleWeight: "22.2",
        outsideDiameter: 5.563,
        insideDiameter: 4.859,
        nonAPI: true

    },
    {
        titleSize: "5 9/16",
        titleWeight: "25.25",
        outsideDiameter: 5.563,
        insideDiameter: 4.733,
        nonAPI: true

    },
    {
        titleSize: "6 5/8",
        titleWeight: "25.20",
        outsideDiameter: 6.625,
        wallThickness: 0.33,
        insideDiameter: 5.965

    },
    {
        titleSize: "6 5/8",
        titleWeight: "27.70",
        outsideDiameter: 6.625,
        wallThickness: 0.362,
        insideDiameter: 5.901

    },

]

const coiltube: CoilTubeProps[] = [
    {
        titleSize: "1 1/2",
        titleWeight: "1.426",
        outsideDiameter: 1.5,
        insideDiameter: 1.31,
        nonAPI: true
    },
    {
        titleSize: "2",
        titleWeight: "2.372",
        outsideDiameter: 2,
        insideDiameter: 1.764,
        nonAPI: true
    },
    {
        titleSize: "3 1/2",
        titleWeight: "6.214",
        outsideDiameter: 3.5,
        insideDiameter: 3.15,
        nonAPI: true
    },

]

const mappedCasingSizes = casingAPI5CT.map(item => item.titleSize);

const casingSizes = [...new Set(mappedCasingSizes)];


const mappedTubingSizes = tubingAPI5CT.map(item => item.titleSize);

const tubingSizes = [...new Set(mappedTubingSizes)];


const mappedDrillPipeSizes = drillPipeAPI5DP.map(item => item.titleSize);

const drillPipeSizes = [...new Set(mappedDrillPipeSizes)];


const mappedCoilTubeSizes = coiltube.map(item => item.titleSize);

const coilTubeSizes = [...new Set(mappedCoilTubeSizes)];

export { casingAPI5CT, tubingAPI5CT, drillPipeAPI5DP, coiltube, casingSizes, tubingSizes, drillPipeSizes, coilTubeSizes }




























