import styled, { css } from 'styled-components/native';

import { RFValue } from 'react-native-responsive-fontsize';
import { TextInput, Dimensions } from 'react-native';

const maxWidth = 750;
interface Props {
   isFocused: boolean;
}

export const Container = styled.View`
   flex-direction: row;
`;

export const IconContainer = styled.View<Props>`
height: ${Dimensions.get('window').width < maxWidth ? RFValue(56) : 56}px;
width: ${Dimensions.get('window').width < maxWidth ? RFValue(55) : 55}px;
justify-content: center;
align-items: center;


background-color: ${({ theme }) => theme.colors.backgroud_secondary};

${({ theme, isFocused }) => isFocused && css`
border-bottom-width: 2px;
border-bottom-color: ${({ theme }) => theme.colors.main};
`}
`;

export const InputText = styled(TextInput) <Props>`
flex: 1;

background-color: ${({ theme }) => theme.colors.backgroud_secondary};
color: ${({ theme }) => theme.colors.text};
font-family: ${({ theme }) => theme.fonts.primary_400};

font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;

padding: 0 23px;

${({ theme, isFocused }) => isFocused && css`
border-bottom-width: 2px;
border-bottom-color: ${({ theme }) => theme.colors.main};
`}
`;

export const PasteCopy = styled.View<Props>`
height: ${Dimensions.get('window').width < maxWidth ? RFValue(56) : 56}px;
width: ${Dimensions.get('window').width < maxWidth ? RFValue(55) : 55}px;
justify-content: center;
align-items: center;


background-color: ${({ theme }) => theme.colors.backgroud_secondary};

${({ theme, isFocused }) => isFocused && css`
border-bottom-width: 2px;
border-bottom-color: ${({ theme }) => theme.colors.main};
`}

`;
export const PasteCopyButton = styled.TouchableOpacity`
padding:5px;

`;
