import React, { useState, useEffect } from 'react';
import {
    ScrollView,
    FlatList,
    Platform
} from 'react-native';

import {
    ButtonView,
    InputWrapper,
    Content,
    ListContainer,
    ExchangeIconView
} from './styles';

import { useTheme } from 'styled-components/native';

import { useIsFocused, useNavigation } from '@react-navigation/native';

import * as Clipboard from 'expo-clipboard';

import { InputConverter } from '../../components/InputConverter';

import { TemperatureSelectorConvert } from '../../components/TemperatureSelectorConvert';
import { ClearButton } from '../../components/ClearButton';
import { Header } from '../../components/Header';

import { MaterialCommunityIcons } from '@expo/vector-icons';

interface PressureTypes {
    id: 'nm' | 'μm' | 'mm' | 'cm' | 'm' | 'in' | 'km' | 'yd' | 'ft-us' | 'ft' | 'fathom' | 'mi' | 'nMi';
    name: string;
}

import configureMeasurements, { length } from 'convert-units';
import { RFValue } from 'react-native-responsive-fontsize';
import { BackButton } from '../../components/BackButton';
import { t } from 'i18next';

const convert = configureMeasurements({
    length
});


export function LengthConverter() {

    const [volumeFirst, setVolumeFirst] = useState('1');
    const [volumeSecond, setVolumeSecond] = useState('');

    const [volumeTypeFirstSelected, setVolumeTypeFirstSelected] = useState<'nm' | 'μm' | 'mm' | 'cm' | 'm' | 'in' | 'in' | 'yd' | 'ft-us' | 'ft' | 'fathom' | 'mi' | 'nMi' | 'km'>('in');
    const [volumeTypeSecondSelected, setVolumeTypeSecondSelected] = useState<'nm' | 'μm' | 'mm' | 'cm' | 'm' | 'in' | 'km' | 'yd' | 'ft-us' | 'ft' | 'fathom' | 'mi' | 'nMi' | 'km'>('cm');

    const [onFocusFirstInput, setOnFocusFirstInput] = useState(true);
    const [onFocusSecondInput, setOnFocusSecondInput] = useState(false);

    const navigation = useNavigation();


    const volumes: PressureTypes[] = [
        {
            id: 'mm',
            name: 'mm'
        },
        {
            id: 'cm',
            name: 'cm'
        },
        {
            id: 'm',
            name: 'm'
        },
        {
            id: 'in',
            name: 'in'
        },
        {
            id: 'ft',
            name: 'ft'
        },
        {
            id: 'ft-us',
            name: 'ft-us'
        },
        {
            id: 'mi',
            name: 'mi'
        },
        {
            id: 'km',
            name: 'km'
        },
        {
            id: 'yd',
            name: 'yd'
        },
        {
            id: 'nm',
            name: 'nm'
        },
        {
            id: 'μm',
            name: 'μm'
        },
        {
            id: 'nMi',
            name: 'nMi'
        },
        {
            id: 'fathom',
            name: 'fathom'
        },
    ]

    const theme = useTheme();

    const screenIsFocus = useIsFocused();

    function handleFirstVolumeTypeSelected(volumeType: 'nm' | 'μm' | 'mm' | 'cm' | 'm' | 'in' | 'in' | 'yd' | 'ft-us' | 'ft' | 'fathom' | 'mi' | 'nMi' | 'km') {
        setVolumeTypeFirstSelected(volumeType);
    };

    function handleSecondVolumeTypeSelected(volumeType: 'nm' | 'μm' | 'mm' | 'cm' | 'm' | 'in' | 'in' | 'yd' | 'ft-us' | 'ft' | 'fathom' | 'mi' | 'nMi' | 'km') {
        setVolumeTypeSecondSelected(volumeType);
    };


    function handleCopyToClipboardFirstInput() {
        Clipboard.setString(volumeFirst);
    };

    function handleCopyToClipboardSecondInput() {
        Clipboard.setString(volumeSecond);
    }

    async function handlePasteToClipboardFirstInput() {
        const fetchCopiedText = async () => {
            const text = await Clipboard.getStringAsync();
            setVolumeFirst(text);
        }
        fetchCopiedText();
    }

    async function handlePasteToClipboardSecondInput() {
        const fetchCopiedText = async () => {
            const text = await Clipboard.getStringAsync();
            setVolumeSecond(text);
        }
        fetchCopiedText();
    }

    function handleClearAll() {
        setVolumeFirst('1');
        setVolumeSecond('');
        setVolumeTypeFirstSelected('in');
        setVolumeTypeSecondSelected('cm');
        setOnFocusFirstInput(true);
        setOnFocusSecondInput(false);

    }

    useEffect(() => {
        function handleConvertVolume() {
            if (onFocusFirstInput) {
                const volumeFirstFormatted = Number(volumeFirst.replace(',', '.'));

                const answerFormatted = convert(volumeFirstFormatted).from(volumeTypeFirstSelected).to(volumeTypeSecondSelected);

                const suport = Number(answerFormatted.toFixed(4))

                setVolumeSecond(String(suport).replace('.', ','))
                return;
            } else if (onFocusSecondInput) {
                const volumeSecondFormatted = Number(volumeSecond.replace(',', '.'));

                const answerFormatted = convert(volumeSecondFormatted).from(volumeTypeSecondSelected).to(volumeTypeFirstSelected);

                const suport = Number(answerFormatted.toFixed(4))

                setVolumeFirst(String(suport).replace('.', ','))
                return;
            } else {
                return;
            }
        }
        handleConvertVolume();
    }, [screenIsFocus, volumeFirst, volumeSecond, volumeTypeFirstSelected, volumeTypeSecondSelected]);


    return (
        <ScrollView
            style={{ backgroundColor: theme.colors.backgroud_primary }}
        >
            <Header
                title={t(`Conversor de Unidades`)}
            />
            <Content>
                <ButtonView>
                    <BackButton
                        color={theme.colors.main}
                        onPress={() => navigation.navigate('Home')} />
                    <ClearButton
                        onPress={handleClearAll}
                    />
                </ButtonView>
                <InputWrapper>
                    <InputConverter
                        iconName='ruler'
                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                        value={volumeFirst}
                        onChangeText={(text) => setVolumeFirst(text)}
                        onFocus={() => {
                            setOnFocusSecondInput(false)
                            setOnFocusFirstInput(true)
                        }}
                        pasteButton={Platform.OS === 'web' ? false : true}
                        handleCopy={handleCopyToClipboardFirstInput}
                        handlePaste={handlePasteToClipboardFirstInput}
                        textAlign='center'
                    />
                    <ListContainer>
                        <FlatList
                            data={volumes}
                            keyExtractor={(item) => item.id}
                            renderItem={({ item }) => (
                                <TemperatureSelectorConvert
                                    title={item.name}
                                    active={item.id === volumeTypeFirstSelected}
                                    onPress={() => { handleFirstVolumeTypeSelected(item.id) }}
                                />)
                            }
                            horizontal
                            showsHorizontalScrollIndicator={false}
                        />
                    </ListContainer>
                </InputWrapper>
                <ExchangeIconView>
                    <MaterialCommunityIcons
                        name='swap-vertical-bold'
                        size={40}
                        color={theme.colors.site_600}
                    />
                </ExchangeIconView>

                <InputWrapper>
                    <InputConverter
                        iconName='ruler'
                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                        value={volumeSecond}
                        onChangeText={(text) => setVolumeSecond(text)}
                        onFocus={() => {
                            setOnFocusFirstInput(false)
                            setOnFocusSecondInput(true)
                        }}
                        pasteButton={Platform.OS === 'web' ? false : true}
                        handleCopy={handleCopyToClipboardSecondInput}
                        handlePaste={handlePasteToClipboardSecondInput}
                        textAlign='center'
                    />
                    <ListContainer>
                        <FlatList
                            data={volumes}
                            keyExtractor={(item) => item.id}
                            renderItem={({ item }) => (
                                <TemperatureSelectorConvert
                                    title={item.name}
                                    active={item.id === volumeTypeSecondSelected}
                                    onPress={() => { handleSecondVolumeTypeSelected(item.id) }}
                                />)
                            }
                            horizontal
                            showsHorizontalScrollIndicator={false}
                        />
                    </ListContainer>
                </InputWrapper>
            </Content>
        </ScrollView>
    );
}