import { RFValue } from 'react-native-responsive-fontsize';
import styled, { css } from 'styled-components/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Platform, Dimensions } from 'react-native';

const maxWidth = 750;
interface OptionsProps {
    active: boolean;
}

export const Container = styled.ScrollView`
flex: 1;
background-color: ${({ theme }) => theme.colors.backgroud_primary};
`;

export const ButtonView = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const FlatlistWrapper = styled.View`
padding-top: 10px;
`;

export const Content = styled.View`
margin: 5px 10px;
`;

export const InputWrapper = styled.View`
margin-top: 10px;
margin-bottom: 10px;
`;

export const VolumeSelectorWrapper = styled.View`
flex-direction: row;
`;

export const PressureSelectorWrapper = styled.View`
flex-direction: row;
justify-content: space-between;
`;

export const DepthContainer = styled.View`
margin-bottom: 10px ;
`;

export const Footer = styled.View`
margin: 10px 10px;
`;

export const TotalVolumeWrapper = styled.View`
flex-direction: row;
justify-content: flex-end;
align-items: center;
margin-right:5px ;
`;

export const AnswerView = styled.View`
justify-content: center;
align-items: center;
margin: 5px;
margin-bottom: 5px;
`;
export const ListContainer = styled.View`
flex-direction: row;
align-items: center ;
justify-content:center ;
`;
export const PasteCopy = styled.View`
align-items: flex-end;
justify-content: center;
`;
export const PasteCopyButton = styled.TouchableOpacity`
padding:5px;
`;

export const InputView = styled.View`
margin-top:15px ;
`;

export const IconPaste = styled(MaterialCommunityIcons)`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(30) : 30}px;
`;

export const TotalVolume = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
    color: ${({ theme }) => theme.colors.site_900}; 
    margin-right: 5px;
    `;

export const Options = styled.View`
border-bottom-width: 1px;
border-bottom-color: ${({ theme }) => theme.colors.line};

flex-direction: row;
justify-content: space-between;



`;

export const Option = styled.TouchableOpacity<OptionsProps>`
padding-bottom: 14px;
padding-top:14px ;
width: 33%;
align-items: center;
justify-content:center ;
${({ active }) => active && css`
border-bottom-width: 3px;
border-bottom-color: ${({ theme }) => theme.colors.main};

`}
`;

export const OptionTitle = styled.Text<OptionsProps>`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
font-family: ${({ theme, active }) =>
        active ? theme.fonts.primary_500 : theme.fonts.primary_400};
color: ${({ theme, active }) =>
        active ? theme.colors.site_500 : theme.colors.header};
`;

export const OptionContainer = styled.View`
margin-bottom: ${Platform.OS === 'web' ? 30 : 10}px;
margin-left: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
margin-right: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
`;

export const TextNavigation = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.shape_dark};  
`;

export const ButtonNavigation = styled.TouchableOpacity`
padding: 5px;
border-radius: 5px;
background-color: ${({ theme }) => theme.colors.main_light};
`;

export const MessageWrapper = styled.View`
margin: 10px;
`;

export const TotalWrapper = styled.View`
margin-top: 10px;
`;


export const ListContainerInch = styled.View`
margin: 0 -10px;
`;

export const VolumeSelectorContainer = styled.View`
flex-direction: row;
`;


export const HelpContainer = styled.View`
margin-right: 10px;
align-items: flex-end;
`;

export const OptionContainerTotal = styled.View``;


export const ContainerModal = styled.ScrollView`
    
margin: 80px 15px;
   
    
background-color: ${({ theme }) => theme.colors.backgroud_primary};
border-width: 3px;
border-color: ${({ theme }) => theme.colors.site_900};
border-radius: 5px;

    
`;

export const ContainerModalTop = styled.ScrollView`
    
margin: 10px 10px;
   
    
background-color: ${({ theme }) => theme.colors.backgroud_primary};
border-width: 3px;
border-color: ${({ theme }) => theme.colors.site_900};
border-radius: 5px;

    
`;

export const ScrollContainer = styled.ScrollView`

`;

export const ScrollWrapper = styled.View`
 padding: 10px;

background-color: ${({ theme }) => theme.colors.backgroud_primary};
`;


export const PressableContainer = styled.TouchableOpacity`
 width: 100%;

padding: 19px;
align-items: center;
justify-content: center;

background-color: ${({ theme }) => theme.colors.main};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableContainerJobs = styled.TouchableOpacity`
 width: 100%;

padding: 19px;
align-items: center;
justify-content: center;

background-color: ${({ theme }) => theme.colors.site_500};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableText = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: justify;
`;

export const IconContainer = styled.View`
justify-content: center;
align-items: center;


background-color: ${({ theme }) => theme.colors.backgroud_primary};

`;


export const Title = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(32) : 32}px;
color: ${({ theme }) => theme.colors.main};
text-align: center;
`;


export const Message = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
color: ${({ theme }) => theme.colors.shape_dark};
margin-top: 20px;    
`;


export const Divider = styled.View`
align-items: center;
justify-content: center;
height: ${Dimensions.get('window').width < maxWidth ? RFValue(200) : 200}px;
`;

export const PressableContainerTypeColumn = styled.TouchableOpacity`
width: 100%;
flex-direction: row;
padding: 10px;
align-items: center;
justify-content: space-between;

background-color: ${({ theme }) => theme.colors.site_300};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const Wrapper = styled.View`

`;

export const TextID = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(14) : 14}px;
`;


export const PressableContainerTypeColumnBack = styled.TouchableOpacity`
 width: 100%;

padding: 19px;
align-items: center;
justify-content: center;

background-color: ${({ theme }) => theme.colors.site_500};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableTextTypeColumnLineBreake = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.site_500};
text-align: justify;
max-width: ${Dimensions.get('window').width < maxWidth ? RFValue(280) : 280}px;
`;

export const PressableTextTypeColumn = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.site_500};
text-align: center;
`;

export const OptionTubeType = styled.TouchableOpacity<OptionsProps>`
padding-bottom: 14px;
padding-top:14px ;
width: 25%;
align-items: center;
justify-content:center ;
${({ active }) => active && css`
border-bottom-width: 3px;
border-bottom-color: ${({ theme }) => theme.colors.main};

`}
`;

export const OptionTitleTubeType = styled.Text<OptionsProps>`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(14) : 14}px;
font-family: ${({ theme, active }) =>
        active ? theme.fonts.primary_500 : theme.fonts.primary_400};
color: ${({ theme, active }) =>
        active ? theme.colors.site_500 : theme.colors.header};
`;

export const ButtonViewTypeColumn = styled.View`
justify-content: center;
align-items: flex-start;
margin-left: -10px;
`;

export const EmptyMessage = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.site_500};
text-align: center;
`;

export const EmptyMessageWrapper = styled.View`
height: 300px;
width: 100%;
align-items: center;
justify-content: center;
`;

export const PressableContainerSave = styled.TouchableOpacity`
 width: 100%;
 flex-direction: row;
padding: 19px;
align-items: center;
justify-content: space-between;

background-color: ${({ theme }) => theme.colors.site_300};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableTextSave = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;
color: ${({ theme }) => theme.colors.site_600};

`;