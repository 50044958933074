
import React, { useEffect, useState } from 'react';
import {
    FlatList,
    Keyboard,
    Alert,
    Modal,
    Platform
} from 'react-native';

import {
    ButtonView,
    Container,
    Content,
    Option,
    Options,
    OptionTitle,
    OptionContainer,
    InformationBox,
    InformationText,
    InformationBoxHeader,
    InformationTextBox,
    InformationBoxData,
    InchWaterContainer,
    InchWaterWrapper,
    InchWaterText,
    InchWaterTitle,
    InchWaterInput,
    InchWaterResult,
    InchWaterResultBold,
    InchWaterResultWrapper,
    Separator,
    InchWaterTitleWrapper,
    InformationTextData
} from './styles';

import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { useIsFocused } from '@react-navigation/native';

import { Button } from '../../components/Button';
import { ClearButton } from '../../components/ClearButton';
import { Header } from '../../components/Header';

import { volumeGallon, inchesWater } from '../../utils/TanksTable/TwoThousand';
import { volumeGallonThree, inchesWaterThree } from '../../utils/TanksTable/ThreeThousand';
import { BackButton } from '../../components/BackButton';
import {useTranslation} from 'react-i18next';

export function NitrogenTanks() {

    const [option, setOption] = useState<'twoThousand' | 'threeThousand'>('twoThousand');

    const [inchWaterInput, setInchWaterInput] = useState('68');

    const [inchWaterResult, setInchWaterResult] = useState('');
    const [inchWaterLitersResult, setInchWaterLitersResult] = useState('');

    const [weightInput, setWeightInput] = useState('11674');
    const [weightResult, setWeightResult] = useState('');

    const [inchWaterInputThree, setInchWaterInputThree] = useState('68');

    const [inchWaterResultThree, setInchWaterResultThree] = useState('');
    const [inchWaterResultlitersThree, setInchWaterResultlitersThree] = useState('');

    const [weightInputThree, setWeightInputThree] = useState('15461');
    const [weightResultThree, setWeightResultThree] = useState('');

    const {t} = useTranslation();

    const theme = useTheme();

    const { navigate } = useNavigation();

    const navigation = useNavigation();

    const screenIsFocus = useIsFocused();





    function handleOptionChange(optionSelected: 'twoThousand' | 'threeThousand') {
        setOption(optionSelected)
    }


    function handleCalculationThree() {

        Keyboard.dismiss();
    }


    function handleClearTwo() {
        setInchWaterInput('68');
        setWeightInput('11674');
    }

    function handleClearThree() {
        setInchWaterInputThree('68');
        setWeightInputThree('15461');
    }

    useEffect(() => {

        function handleCalculationInchWater() {

            function findLiquidHeightValues() {
                const inchesWaterFormatted = Number(inchWaterInput.replace(',', '.'));

                function closest(value, array) {
                    var result = [];
                    array.some(function (a) {
                        if (a > value) {
                            return result.push(a);
                        }
                        result = [a];
                        return a === value;
                    });
                    return result;
                }

                const close = closest(inchesWaterFormatted, inchesWater);
                return close;

            }

            const liquidHeightValues = findLiquidHeightValues();
            const liquidHeightDown = liquidHeightValues[0];
            const liquidHeightUp = liquidHeightValues[1] ? liquidHeightValues[1] : liquidHeightValues[0]
            console.log('liquidHeightDown', liquidHeightDown);

            console.log('liquidHeightUp', liquidHeightUp);

            function getIndex(array, value) {

                const positionPressureHeadDownDown = array.indexOf(value);
                //console.log(positionPressureHeadDownDown);
                return positionPressureHeadDownDown
            }

            const positionLiquidDown = getIndex(inchesWater, liquidHeightDown);
            console.log('positionLiquidDown', positionLiquidDown);

            const volumeLiquidDown = volumeGallon[positionLiquidDown];
            console.log('volumeLiquidDown', volumeLiquidDown);


            const positionLiquidUp = getIndex(inchesWater, liquidHeightUp);
            console.log('positionLiquidUp', positionLiquidUp);

            const volumeLiquidUp = volumeGallon[positionLiquidUp];
            console.log('volumeLiquidUp', volumeLiquidUp);

            function findResultInterpolated(x, x1, x2, y1, y2) {
                const y = y1 + (((x - x1) / (x2 - x1)) * (y2 - y1))
                return y
            };

            if (liquidHeightDown === liquidHeightUp) {
                const answer = volumeLiquidDown;
                setInchWaterResult(answer.toFixed(2).replace('.', ','));
                const answerLiters = answer * 3.7854;
                setInchWaterLitersResult(answerLiters.toFixed(2).replace('.', ','))
                return
            } else {
                const inchesWaterFormatted = Number(inchWaterInput.replace(',', '.'));
                const answer = findResultInterpolated(inchesWaterFormatted, liquidHeightDown, liquidHeightUp, volumeLiquidDown, volumeLiquidUp)
                setInchWaterResult(answer.toFixed(2).replace('.', ','));
                const answerLiters = answer * 3.7854;
                setInchWaterLitersResult(answerLiters.toFixed(2).replace('.', ','));
                //console.log('answer', answer);
            }
        }
        handleCalculationInchWater();
    }, [screenIsFocus, inchWaterInput, inchWaterResult])

    useEffect(() => {
        function calculationWeightGallons() {
            const weightInputFormatted = Number(weightInput.replace(',', '.'));
            const answerWeight = (weightInputFormatted - 5500) * 0.32656;
            setWeightResult(answerWeight.toFixed(2).replace('.', ','))
        }
        calculationWeightGallons();
    }, [screenIsFocus, weightInput])

    useEffect(() => {

        function handleCalculationInchWaterThree() {

            function findLiquidHeightValues() {
                const inchesWaterFormatted = Number(inchWaterInputThree.replace(',', '.'));

                function closest(value, array) {
                    var result = [];
                    array.some(function (a) {
                        if (a > value) {
                            return result.push(a);
                        }
                        result = [a];
                        return a === value;
                    });
                    return result;
                }

                const close = closest(inchesWaterFormatted, inchesWaterThree);
                return close;

            }

            const liquidHeightValues = findLiquidHeightValues();
            const liquidHeightDown = liquidHeightValues[0];
            const liquidHeightUp = liquidHeightValues[1] ? liquidHeightValues[1] : liquidHeightValues[0]
            console.log('liquidHeightDown', liquidHeightDown);

            console.log('liquidHeightUp', liquidHeightUp);

            function getIndex(array, value) {

                const positionPressureHeadDownDown = array.indexOf(value);
                //console.log(positionPressureHeadDownDown);
                return positionPressureHeadDownDown
            }

            const positionLiquidDown = getIndex(inchesWaterThree, liquidHeightDown);
            console.log('positionLiquidDown', positionLiquidDown);

            const volumeLiquidDown = volumeGallonThree[positionLiquidDown];
            console.log('volumeLiquidDown', volumeLiquidDown);


            const positionLiquidUp = getIndex(inchesWaterThree, liquidHeightUp);
            console.log('positionLiquidUp', positionLiquidUp);

            const volumeLiquidUp = volumeGallonThree[positionLiquidUp];
            console.log('volumeLiquidUp', volumeLiquidUp);

            function findResultInterpolated(x, x1, x2, y1, y2) {
                const y = y1 + (((x - x1) / (x2 - x1)) * (y2 - y1))
                return y
            };

            if (liquidHeightDown === liquidHeightUp) {
                const answer = volumeLiquidDown;
                setInchWaterResultThree(answer.toFixed(2).replace('.', ','))
                const answerLiters = answer * 3.7854;
                setInchWaterResultlitersThree(answerLiters.toFixed(2).replace('.', ','))
                return
            } else {
                const inchesWaterFormatted = Number(inchWaterInputThree.replace(',', '.'));
                const answer = findResultInterpolated(inchesWaterFormatted, liquidHeightDown, liquidHeightUp, volumeLiquidDown, volumeLiquidUp)
                setInchWaterResultThree(answer.toFixed(2).replace('.', ','))
                const answerLiters = answer * 3.7854;
                setInchWaterResultlitersThree(answerLiters.toFixed(2).replace('.', ','))
                //console.log('answer', answer);
            }
        }
        handleCalculationInchWaterThree();
    }, [screenIsFocus, inchWaterInputThree, inchWaterResultThree])

    useEffect(() => {
        function calculationWeightGallons() {
            const weightInputFormatted = Number(weightInputThree.replace(',', '.'));
            const answerWeight = (weightInputFormatted - 6200) * 0.32656;
            setWeightResultThree(answerWeight.toFixed(2).replace('.', ','))
        }
        calculationWeightGallons();
    }, [screenIsFocus, weightInputThree])

    return (
        <Container
        >
            <Header
                title={t(`Tanques Criogênicos`)}
            />
            <Options>

                <Option
                    active={option === 'twoThousand'}
                    onPress={() => handleOptionChange('twoThousand')}
                >
                    <OptionTitle active={option === 'twoThousand'}>
                        2000
                    </OptionTitle>
                </Option>
                <Option
                    active={option === 'threeThousand'}
                    onPress={() => handleOptionChange('threeThousand')}
                >
                    <OptionTitle active={option === 'threeThousand'}>
                        3000
                    </OptionTitle>
                </Option>
            </Options>

            {
                option === 'twoThousand'
                    ?
                    <OptionContainer>
                        <ButtonView>
                            <BackButton
                                color={theme.colors.main}
                                onPress={() => navigation.goBack()} />
                            <ClearButton
                                onPress={handleClearTwo}
                            />
                        </ButtonView>
                        <Content>
                            <InformationBox>
                                <InformationBoxHeader>
                                    <InformationTextBox>
                                        <InformationText>
                                            {t('PESO')}{'\n'}(kg)
                                        </InformationText>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationText>
                                            {t('COMP.')}{'\n'}(m)
                                        </InformationText>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationText>
                                            {t('LARG.')}{'\n'}(m)
                                        </InformationText>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationText>
                                            {t('ALT.')}{'\n'}(m)
                                        </InformationText>
                                    </InformationTextBox>
                                </InformationBoxHeader>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            11674
                                        </InformationTextData>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            3,00
                                        </InformationTextData>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            2,44
                                        </InformationTextData>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            2,60
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                            </InformationBox>

                            <Separator />

                            <InchWaterContainer>
                                <InchWaterTitleWrapper>
                                    <InchWaterTitle>
                                        {t('Peso do tanque')}
                                    </InchWaterTitle>
                                    <InchWaterTitle>
                                        X
                                    </InchWaterTitle>
                                    <InchWaterTitle>
                                        {t('Volume')}
                                    </InchWaterTitle>
                                </InchWaterTitleWrapper>

                                <InchWaterWrapper>
                                    <InchWaterInput
                                        onChangeText={(text) => setWeightInput(text)}
                                        value={weightInput}
                                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}

                                    />
                                    <InchWaterText>
                                        kg
                                    </InchWaterText>
                                    <InchWaterText>
                                        =
                                    </InchWaterText>
                                    <InchWaterResult>
                                        <InchWaterResultBold>{weightResult}</InchWaterResultBold> {t('galões')}
                                    </InchWaterResult>

                                </InchWaterWrapper>

                            </InchWaterContainer>
                            <Separator />
                            <InchWaterContainer>
                                <InchWaterTitleWrapper>
                                    <InchWaterTitle>
                                        {t("Polegada d'água")}
                                    </InchWaterTitle>
                                    <InchWaterTitle>
                                        X
                                    </InchWaterTitle>
                                    <InchWaterTitle>
                                        Volume
                                    </InchWaterTitle>
                                </InchWaterTitleWrapper>
                                <InchWaterWrapper>
                                    <InchWaterInput
                                        onChangeText={(text) => setInchWaterInput(text)}
                                        value={inchWaterInput}
                                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}

                                    />
                                    <InchWaterText>
                                        {t('Polegadas')}{'\n'}{t("d'água")}
                                    </InchWaterText>
                                    <InchWaterText>
                                        =
                                    </InchWaterText>
                                    <InchWaterResultWrapper>
                                        <InchWaterResult>
                                            <InchWaterResultBold>{inchWaterResult}</InchWaterResultBold> {t('galões')}
                                        </InchWaterResult>
                                        <InchWaterResult>
                                            <InchWaterResultBold>{inchWaterLitersResult}</InchWaterResultBold> {t('litros')}
                                        </InchWaterResult>
                                    </InchWaterResultWrapper>

                                </InchWaterWrapper>

                            </InchWaterContainer>
                            <Separator />
                        </Content>

                    </OptionContainer>

                    :

                    <OptionContainer>
                        <ButtonView>
                            <BackButton
                                color={theme.colors.main}
                                onPress={() => navigation.goBack()} />
                            <ClearButton
                                onPress={handleClearThree}
                            />
                        </ButtonView>
                        <Content>
                            <InformationBox>
                                <InformationBoxHeader>
                                    <InformationTextBox>
                                        <InformationText>
                                            {t('PESO')}{'\n'}(kg)
                                        </InformationText>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationText>
                                            {t('COMP.')}{'\n'}(m)
                                        </InformationText>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationText>
                                            {t('LARG.')}{'\n'}(m)
                                        </InformationText>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationText>
                                            {t('ALT.')}{'\n'}(m)
                                        </InformationText>
                                    </InformationTextBox>
                                </InformationBoxHeader>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            15461
                                        </InformationTextData>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            4,00
                                        </InformationTextData>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            2,44
                                        </InformationTextData>
                                    </InformationTextBox>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            2,60
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                            </InformationBox>
                            <Separator />
                            <InchWaterContainer>
                                <InchWaterTitleWrapper>
                                    <InchWaterTitle>
                                        {t('Peso do tanque')}
                                    </InchWaterTitle>
                                    <InchWaterTitle>
                                        X
                                    </InchWaterTitle>
                                    <InchWaterTitle>
                                        {t('Volume')}
                                    </InchWaterTitle>
                                </InchWaterTitleWrapper>
                                <InchWaterWrapper>
                                    <InchWaterInput
                                        onChangeText={(text) => setWeightInputThree(text)}
                                        value={weightInputThree}
                                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}

                                    />
                                    <InchWaterText>
                                        kg
                                    </InchWaterText>
                                    <InchWaterText>
                                        =
                                    </InchWaterText>
                                    <InchWaterResult>
                                        <InchWaterResultBold>{weightResultThree}</InchWaterResultBold> {t('galões')}
                                    </InchWaterResult>

                                </InchWaterWrapper>

                            </InchWaterContainer>
                            <Separator />
                            <InchWaterContainer>
                                <InchWaterTitleWrapper>
                                    <InchWaterTitle>
                                        {t("Polegada d'água")}
                                    </InchWaterTitle>
                                    <InchWaterTitle>
                                        X
                                    </InchWaterTitle>
                                    <InchWaterTitle>
                                        {t('Volume')}
                                    </InchWaterTitle>
                                </InchWaterTitleWrapper>

                                <InchWaterWrapper>
                                    <InchWaterInput
                                        onChangeText={(text) => setInchWaterInputThree(text)}
                                        value={inchWaterInputThree}
                                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}

                                    />
                                    <InchWaterText>
                                        {t('Polegadas')}{'\n'}{t("d'água")}
                                    </InchWaterText>
                                    <InchWaterText>
                                        =
                                    </InchWaterText>
                                    <InchWaterResultWrapper>
                                        <InchWaterResult>
                                            <InchWaterResultBold>{inchWaterResultThree}</InchWaterResultBold> {t('galões')}
                                        </InchWaterResult>
                                        <InchWaterResult>
                                            <InchWaterResultBold>{inchWaterResultlitersThree}</InchWaterResultBold> {t('litros')}
                                        </InchWaterResult>
                                    </InchWaterResultWrapper>

                                </InchWaterWrapper>

                            </InchWaterContainer>
                            <Separator />
                        </Content>


                    </OptionContainer>

            }

        </Container>
    );
}