import React, { useState, useEffect } from 'react';
import {
    ScrollView,
    FlatList,
    Platform
} from 'react-native';

import {
    ButtonView,
    InputWrapper,
    Content,
    ListContainer,
    ExchangeIconView,
    Option,
    Options,
    OptionTitle,
    OptionContainer,
    InformationBox,
    InformationText,
    InformationBoxHeader,
    InformationTextBox,
    InformationBoxData,
    InformationTextData,
    ButtonViewBack
} from './styles';

import { useTheme } from 'styled-components/native';

import { useIsFocused, useNavigation } from '@react-navigation/native';

import * as Clipboard from 'expo-clipboard';

import { InputConverter } from '../../components/InputConverter';
import { TemperatureSelectorConvert } from '../../components/TemperatureSelectorConvert';
import { ClearButton } from '../../components/ClearButton';
import { Header } from '../../components/Header';

import { MaterialCommunityIcons } from '@expo/vector-icons';

import {useTranslation} from 'react-i18next';

interface VolumesTypes {
    id: 'm3' | 'kg' | 'scf' | 'ft3' | 'gal' | 'lb' | 'bbl' | 'l' | 'ml';
    name: string;
}

import configureMeasurements, {
    volume,
    VolumeSystems,
    VolumeUnits,
    Measure
} from 'convert-units';
import { RFValue } from 'react-native-responsive-fontsize';
import { BackButton } from '../../components/BackButton';

type NewVolumeUnits = VolumeUnits | 'bbl' | 'kg' | 'scf' | 'lb';

const extendedVolume: Measure<VolumeSystems, NewVolumeUnits> = {
    systems: {
        metric: {
            ...volume.systems.metric,
            bbl: {
                name: {
                    singular: 'bbl',
                    plural: 'bbl',
                },
                to_anchor: 158.987,
            },
            kg: {
                name: {
                    singular: 'kg',
                    plural: 'kg',
                },
                to_anchor: 1.237,
            },
            scf: {
                name: {
                    singular: 'scf',
                    plural: 'scf',
                },
                to_anchor: 0.040655,
            },
            lb: {
                name: {
                    singular: 'lb',
                    plural: 'lb',
                },
                to_anchor: 0.561,
            },
        },
        imperial: {
            ...volume.systems.imperial,
        },
    },
    anchors: {
        ...volume.anchors,
    },
};

const convert = configureMeasurements<'volume', VolumeSystems, NewVolumeUnits>(
    { volume: extendedVolume }
);

export function N2Converter() {

    const [volumeFirst, setVolumeFirst] = useState('1');
    const [volumeSecond, setVolumeSecond] = useState('');

    const [volumeTypeFirstSelected, setVolumeTypeFirstSelected] = useState<'m3' | 'kg' | 'scf' | 'ft3' | 'gal' | 'lb' | 'bbl' | 'l' | 'ml'>('gal');
    const [volumeTypeSecondSelected, setVolumeTypeSecondSelected] = useState<'m3' | 'kg' | 'scf' | 'ft3' | 'gal' | 'lb' | 'bbl' | 'l' | 'ml'>('scf');

    const [onFocusFirstInput, setOnFocusFirstInput] = useState(true);
    const [onFocusSecondInput, setOnFocusSecondInput] = useState(false);

    const [option, setOption] = useState<'information' | 'converter'>('information');

    const navigation = useNavigation();

    const {t} = useTranslation();

    const volumes: VolumesTypes[] = [
        {
            id: 'm3',
            name: 'm³ L'
        },
        {
            id: 'kg',
            name: 'kg L'
        },
        {
            id: 'scf',
            name: 'scf G'
        },
        {
            id: 'gal',
            name: 'gal L'
        },
        {
            id: 'lb',
            name: 'lb L'
        },
        {
            id: 'bbl',
            name: 'bbl L'
        },
        {
            id: 'l',
            name: 'litro L'
        },
        {
            id: 'ft3',
            name: 'ft³ L'
        },
        {
            id: 'ml',
            name: 'mililitro L'
        }
    ]

    const theme = useTheme();

    const screenIsFocus = useIsFocused();

    function handleFirstVolumeTypeSelected(volumeType: 'm3' | 'kg' | 'scf' | 'ft3' | 'gal' | 'lb' | 'bbl' | 'l' | 'ml') {
        setVolumeTypeFirstSelected(volumeType);
    };

    function handleSecondVolumeTypeSelected(volumeType: 'm3' | 'kg' | 'scf' | 'ft3' | 'gal' | 'lb' | 'bbl' | 'l' | 'ml') {
        setVolumeTypeSecondSelected(volumeType);
    };


    function handleCopyToClipboardFirstInput() {
        Clipboard.setString(volumeFirst);
    };

    function handleCopyToClipboardSecondInput() {
        Clipboard.setString(volumeSecond);
    }


    async function handlePasteToClipboardFirstInput() {
        const fetchCopiedText = async () => {
            const text = await Clipboard.getStringAsync();
            setVolumeFirst(text);
        }
        fetchCopiedText();
    }

    async function handlePasteToClipboardSecondInput() {
        const fetchCopiedText = async () => {
            const text = await Clipboard.getStringAsync();
            setVolumeSecond(text);
        }
        fetchCopiedText();
    }


    function handleOptionChange(optionSelected: 'information' | 'converter') {
        setOption(optionSelected)
    }

    function handleClearAll() {
        setVolumeFirst('1');
        setVolumeSecond('');
        setVolumeTypeFirstSelected('gal');
        setVolumeTypeSecondSelected('scf');
        setOnFocusFirstInput(true);
        setOnFocusSecondInput(false);

    }

    useEffect(() => {
        function handleConvertVolume() {
            if (onFocusFirstInput) {
                const volumeFirstFormatted = Number(volumeFirst.replace(',', '.'));

                const answerFormatted = convert(volumeFirstFormatted).from(volumeTypeFirstSelected).to(volumeTypeSecondSelected);

                const suport = Number(answerFormatted.toFixed(3))

                setVolumeSecond(String(suport).replace('.', ','))
                return;
            } else if (onFocusSecondInput) {
                const volumeSecondFormatted = Number(volumeSecond.replace(',', '.'));

                const answerFormatted = convert(volumeSecondFormatted).from(volumeTypeSecondSelected).to(volumeTypeFirstSelected);

                const suport = Number(answerFormatted.toFixed(3))

                setVolumeFirst(String(suport).replace('.', ','))
                return;
            } else {
                return;
            }
        }
        handleConvertVolume();
    }, [screenIsFocus, volumeFirst, volumeSecond, volumeTypeFirstSelected, volumeTypeSecondSelected]);


    return (
        <ScrollView
            style={{ backgroundColor: theme.colors.backgroud_primary }}
        >
            <Header
                title={t(`Dados do Nitrogênio`)}
            />
            <Options>

                <Option
                    active={option === 'information'}
                    onPress={() => handleOptionChange('information')}
                >
                    <OptionTitle active={option === 'information'}>
                        {t('Informações')}
                    </OptionTitle>
                </Option>
                <Option
                    active={option === 'converter'}
                    onPress={() => handleOptionChange('converter')}
                >
                    <OptionTitle active={option === 'converter'}>
                        {t('Conversor')}
                    </OptionTitle>
                </Option>
            </Options>


            {
                option === 'information' &&
                <ButtonView>
                    <BackButton
                        color={theme.colors.main}
                        onPress={() => navigation.goBack()} />

                </ButtonView>
            }


            {
                option === 'information'
                    ?
                    <OptionContainer>
                        <Content>
                            <InformationBox>
                                <InformationBoxHeader>

                                    <InformationText>
                                        {t('PRINCIPAIS CARACTERÍSTICAS')}
                                    </InformationText>

                                </InformationBoxHeader>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Propriedades organolépticas')}
                                        </InformationTextData>
                                    </InformationTextBox>

                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Incolor, Inodoro e Insípido')}
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Símbolo Químico')}
                                        </InformationTextData>
                                    </InformationTextBox>

                                    <InformationTextBox>
                                        <InformationTextData>
                                            N
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Fórmula Molecular')}
                                        </InformationTextData>
                                    </InformationTextBox>

                                    <InformationTextBox>
                                        <InformationTextData>
                                            N2
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Massa Molar')}
                                        </InformationTextData>
                                    </InformationTextBox>

                                    <InformationTextBox>
                                        <InformationTextData>
                                            28,013 g/mol
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Ponto de Fusão')}
                                        </InformationTextData>
                                    </InformationTextBox>

                                    <InformationTextBox>
                                        <InformationTextData>
                                            -209,86 °C
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Ponto de Ebulição')}
                                        </InformationTextData>
                                    </InformationTextBox>

                                    <InformationTextBox>
                                        <InformationTextData>
                                            -195,8 °C
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Densidade no estado gasoso')}
                                        </InformationTextData>
                                    </InformationTextBox>

                                    <InformationTextBox>
                                        <InformationTextData>
                                            1,2506 g/L (0°C. 1atm)
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Densidade no estado líquido')}
                                        </InformationTextData>
                                    </InformationTextBox>

                                    <InformationTextBox>
                                        <InformationTextData>
                                            0,808 g/cm³ (-195,8 °C)
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                                <InformationBoxData>
                                    <InformationTextBox>
                                        <InformationTextData>
                                            {t('Densidade no estado sólido')}
                                        </InformationTextData>
                                    </InformationTextBox>

                                    <InformationTextBox>
                                        <InformationTextData>
                                            0,8792 g/cm³ (-209,86 °C)
                                        </InformationTextData>
                                    </InformationTextBox>
                                </InformationBoxData>

                            </InformationBox>

                        </Content>
                    </OptionContainer>

                    :

                    <OptionContainer>
                        <ButtonViewBack>
                            <BackButton
                                color={theme.colors.main}
                                onPress={() => navigation.goBack()} />
                            <ClearButton
                                onPress={handleClearAll}
                            />
                        </ButtonViewBack>
                        <Content>

                            <InputWrapper>
                                <InputConverter
                                    iconName={volumeTypeFirstSelected === 'scf' ? 'weather-windy' : 'snowflake-o'}
                                    iconCollection={volumeTypeFirstSelected === 'scf' ? 'MaterialCommunityIcons' : 'FontAwesome'}
                                    keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                    value={volumeFirst}
                                    onChangeText={(text) => setVolumeFirst(text)}
                                    onFocus={() => {
                                        setOnFocusSecondInput(false)
                                        setOnFocusFirstInput(true)
                                    }}
                                    pasteButton={Platform.OS === 'web' ? false : true}
                                    handleCopy={handleCopyToClipboardFirstInput}
                                    handlePaste={handlePasteToClipboardFirstInput}
                                    textAlign='center'
                                />
                                <ListContainer>
                                    <FlatList
                                        data={volumes}
                                        keyExtractor={(item) => item.id}
                                        renderItem={({ item }) => (
                                            <TemperatureSelectorConvert
                                                title={item.name}
                                                active={item.id === volumeTypeFirstSelected}
                                                onPress={() => { handleFirstVolumeTypeSelected(item.id) }}
                                            />)
                                        }
                                        horizontal
                                        showsHorizontalScrollIndicator={false}
                                    />
                                </ListContainer>
                            </InputWrapper>
                            <ExchangeIconView>
                                <MaterialCommunityIcons
                                    name='swap-vertical-bold'
                                    size={40}
                                    color={theme.colors.site_600}
                                />
                            </ExchangeIconView>

                            <InputWrapper>
                                <InputConverter
                                    iconName={volumeTypeSecondSelected === 'scf' ? 'weather-windy' : 'snowflake-o'}
                                    iconCollection={volumeTypeSecondSelected === 'scf' ? 'MaterialCommunityIcons' : 'FontAwesome'}
                                    keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                    value={volumeSecond}
                                    onChangeText={(text) => setVolumeSecond(text)}
                                    onFocus={() => {
                                        setOnFocusFirstInput(false)
                                        setOnFocusSecondInput(true)
                                    }}
                                    pasteButton={Platform.OS === 'web' ? false : true}
                                    handleCopy={handleCopyToClipboardSecondInput}
                                    handlePaste={handlePasteToClipboardSecondInput}
                                    textAlign='center'
                                />
                                <ListContainer>
                                    <FlatList
                                        data={volumes}
                                        keyExtractor={(item) => item.id}
                                        renderItem={({ item }) => (
                                            <TemperatureSelectorConvert
                                                title={item.name}
                                                active={item.id === volumeTypeSecondSelected}
                                                onPress={() => { handleSecondVolumeTypeSelected(item.id) }}
                                            />)
                                        }
                                        horizontal
                                        showsHorizontalScrollIndicator={false}
                                    />
                                </ListContainer>
                            </InputWrapper>
                        </Content>
                    </OptionContainer>





            }
        </ScrollView>

    );
}

