import React from 'react';

import { useNavigation } from '@react-navigation/native';

import { useTheme } from 'styled-components/native';

import { Dimensions } from 'react-native';

import {useTranslation} from 'react-i18next';

import {
    Container,
    Content,
    ContainerCard,
    Footer,
    ContainerCardLast
} from './styles';


import { Header } from '../../components/Header';
import { HomeCard } from '../../components/HomeCard';

import { HomeCardMyCalculations } from '../../components/HomeCardMyCalculations';

export function Home() {

    const navigation = useNavigation();

    const theme = useTheme();

const {t} = useTranslation();

    const maxWidth = 750;

    function handleNavigationPressurizationScreen() {
        navigation.navigate('AppStackThreeRoutes' as never)
    }

    function handleNavigationHydroStaticScreen() {
        navigation.navigate('HydrostaticPressureCalculation' as never)
    }

    function handleNavigationColumnScreen() {
        navigation.navigate('ColumnVolumeCalculation' as never)
    }

    function handleNavigationConvertScreen() {
        navigation.navigate('AppTabRoutes' as never)
    }
    function handleNavigationNitrogenTanks() {
        navigation.navigate('NitrogenTanks' as never)
    }
    function handleNavigationMyCalculations() {
        navigation.navigate('StackMyCalculation' as never)
    }
    function handleNavigationNitrogenData() {
        navigation.navigate('N2Converter' as never)
    }

    return (

        <Container
            showsVerticalScrollIndicator={false}
        >

            <Header
                title={t(`CÁLCULOS NITROGÊNIO`)}
            />
            {
                Dimensions.get('window').width < maxWidth
                    ?
                    <Content

                    >
                        <ContainerCard>
                            <HomeCard
                                title={t('Operações N2')}
                                subtitle={t(`Calcule o nitrogênio necessário para uma operação`)}
                                iconCollection='MaterialCommunityIcons'
                                iconName='gauge'
                                onPress={handleNavigationPressurizationScreen}
                                color={theme.colors.site_900}
                            />
                            <HomeCard
                                title={t(`Pressão Hidrostática`)}
                                subtitle={t('Calcule a pressão de fundo e de cabeça em um poço')}
                                iconCollection='Feather'
                                iconName='download'
                                onPress={handleNavigationHydroStaticScreen}
                                color={theme.colors.site_900}
                            />
                        </ContainerCard>
                        <ContainerCard>
                            <HomeCard
                                title={t('Volume de Coluna')}
                                subtitle={t('Calcule o volume de uma coluna e anular')}
                                iconCollection='MaterialCommunityIcons'
                                iconName='cube-outline'
                                onPress={handleNavigationColumnScreen}
                                color={theme.colors.site_900}
                            />
                            <HomeCard
                                title={t('Conversor de Unidades')}
                                subtitle={t('Converta unidades: temperatura, vazão, pressão, volume...')}
                                iconCollection='FontAwesome5'
                                iconName='exchange-alt'
                                onPress={handleNavigationConvertScreen}
                                color={theme.colors.site_900}
                            />
                        </ContainerCard>
                        <ContainerCard>
                            <HomeCard
                                title={t('Tanques Criogênicos')}
                                subtitle={t('Calcule volume de tanques')}
                                iconCollection='FontAwesome5'
                                iconName='snowflake'
                                onPress={handleNavigationNitrogenTanks}
                                color={theme.colors.site_900}
                            />
                            <HomeCard
                                title={t('Dados do Nitrogênio')}
                                subtitle={t('Principais características do nitrogênio')}
                                iconCollection='MaterialCommunityIcons'
                                iconName='atom'
                                onPress={handleNavigationNitrogenData}
                                color={theme.colors.site_900}
                            />
                        </ContainerCard>
                        <ContainerCardLast>
                            <HomeCardMyCalculations
                                title={t(`Meus Cálculos`)}
                                subtitle={t('Consulte seus cálculos salvos')}
                                iconCollection='MaterialCommunityIcons'
                                iconName='calculator-variant'
                                onPress={handleNavigationMyCalculations}
                                color={theme.colors.site_900}
                            />
                        </ContainerCardLast>
                    </Content>
                    :
                    <Content

                    >
                        <ContainerCard>
                            <HomeCard
                                title={t('Operações N2')}
                                subtitle={t(`Calcule o nitrogênio necessário para uma operação`)}
                                iconCollection='MaterialCommunityIcons'
                                iconName='gauge'
                                onPress={handleNavigationPressurizationScreen}
                                color={theme.colors.site_900}
                            />
                            <HomeCard
                                title={t(`Pressão Hidrostática`)}
                                subtitle={t('Calcule a pressão de fundo e de cabeça em um poço')}
                                iconCollection='Feather'
                                iconName='download'
                                onPress={handleNavigationHydroStaticScreen}
                                color={theme.colors.site_900}
                            />
                            <HomeCard
                                title={t('Volume de Coluna')}
                                subtitle={t('Calcule o volume de uma coluna e anular')}
                                iconCollection='MaterialCommunityIcons'
                                iconName='cube-outline'
                                onPress={handleNavigationColumnScreen}
                                color={theme.colors.site_900}
                            />
                        </ContainerCard>
                        <ContainerCard>

                            <HomeCard
                                title={t('Conversor de Unidades')}
                                subtitle={t('Converta unidades: temperatura, vazão, pressão, volume...')}
                                iconCollection='FontAwesome5'
                                iconName='exchange-alt'
                                onPress={handleNavigationConvertScreen}
                                color={theme.colors.site_900}
                            />
                            <HomeCard
                                title={t('Tanques Criogênicos')}
                                subtitle={t('Calcule volume de tanques')}
                                iconCollection='FontAwesome5'
                                iconName='snowflake'
                                onPress={handleNavigationNitrogenTanks}
                                color={theme.colors.site_900}
                            />
                            <HomeCard
                                title={t('Dados do Nitrogênio')}
                                subtitle={t('Principais características do nitrogênio')}
                                iconCollection='MaterialCommunityIcons'
                                iconName='atom'
                                onPress={handleNavigationNitrogenData}
                                color={theme.colors.site_900}
                            />
                        </ContainerCard>
                        <ContainerCardLast>
                            <HomeCardMyCalculations
                                title={t(`Meus Cálculos`)}
                                subtitle={t('Consulte seus cálculos salvos')}
                                iconCollection='MaterialCommunityIcons'
                                iconName='calculator-variant'
                                onPress={handleNavigationMyCalculations}
                                color={theme.colors.site_900}
                            />
                        </ContainerCardLast>
                    </Content>

            }
            <Footer>

            </Footer>
        </Container>
    );
}