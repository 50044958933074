interface ConvertProps {
    key: string;
    title: string;
    constant: number;
}

const convertInchFrations: ConvertProps[] = [
    {
        key: '0',
        title: '1/32"',
        constant: 0.469

    },
    {
        key: '1',
        title: '1/16"',
        constant: 0.063
    },
    {
        key: '2',
        title: '3/32"',
        constant: 0.094
    },
    {
        key: '3',
        title: '1/8"',
        constant: 0.125
    },
    {
        key: '4',
        title: '5/32"',
        constant: 0.156
    },
    {
        key: '5',
        title: '3/16"',
        constant: 0.188
    },
    {
        key: '6',
        title: '7/32"',
        constant: 0.219
    },
    {
        key: '7',
        title: '1/4"',
        constant: 0.25
    },
    {
        key: '8',
        title: '9/32"',
        constant: 0.281
    },
    {
        key: '9',
        title: '5/16"',
        constant: 0.313
    },
    {
        key: '10',
        title: '11/32"',
        constant: 0.344
    },
    {
        key: '11',
        title: '3/8"',
        constant: 0.375
    },
    {
        key: '12',
        title: '13/32"',
        constant: 0.406
    },
    {
        key: '13',
        title: '7/16"',
        constant: 0.438
    },
    {
        key: '14',
        title: '15/32"',
        constant: 0.469
    },
    {
        key: '15',
        title: '1/2"',
        constant: 0.5
    },
    {
        key: '16',
        title: '17/32"',
        constant: 0.531
    },
    {
        key: '17',
        title: '9/16"',
        constant: 0.563
    },
    {
        key: '18',
        title: '19/32"',
        constant: 0.594
    },
    {
        key: '19',
        title: '5/8"',
        constant: 0.625
    },
    {
        key: '20',
        title: '21/32"',
        constant: 0.656
    },
    {
        key: '21',
        title: '11/16"',
        constant: 0.688
    },
    {
        key: '22',
        title: '23/32"',
        constant: 0.719
    },
    {
        key: '23',
        title: '3/4"',
        constant: 0.75
    },
    {
        key: '24',
        title: '25/32"',
        constant: 0.781
    },
    {
        key: '25',
        title: '13/16"',
        constant: 0.813
    },
    {
        key: '26',
        title: '27/32"',
        constant: 0.844
    },
    {
        key: '27',
        title: '7/8"',
        constant: 0.875
    },
    {
        key: '28',
        title: '29/32"',
        constant: 0.906
    },
    {
        key: '29',
        title: '15/16"',
        constant: 0.938
    },
    {
        key: '30',
        title: '31/32"',
        constant: 0.969
    },

]

export { convertInchFrations }

