import React from 'react';

import {
    Container,
    ScrollContainer,
    ScrollWrapper,
    Message,
    PressableContainer,
    PressableText,
    IconContainer,
    Title,
    PressableContainerCancel
} from './styles';

import { MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';

import { useTheme } from 'styled-components/native';
import { BlurView } from 'expo-blur';

import {useTranslation} from 'react-i18next';

interface Props {
    handleCloseModal: () => void;
    iconName: React.ComponentProps<typeof MaterialCommunityIcons>['name'] | React.ComponentProps<typeof FontAwesome>['name'];
    iconCollection?: 'MaterialCommunityIcons' | 'FontAwesome';
    text: string;
    textFinal?: string;
    title: string;
    textNavigation?: string;
    handleNavigation?: (x) => void;
}


export function ChangeLanguageModal({
    handleCloseModal,
    text,
    textFinal,
    title,
    iconName,
    iconCollection,
    textNavigation,
    handleNavigation
}: Props) {

    const theme = useTheme();

    const {t} = useTranslation();

    return (
        <BlurView intensity={200} style={{ flex: 1 }}>

            <Container showsVerticalScrollIndicator={false}>
                <ScrollContainer showsVerticalScrollIndicator={false}>
                    <ScrollWrapper>
                        <IconContainer >
                            {
                                iconCollection === 'FontAwesome'
                                    ?
                                    <FontAwesome
                                        name={iconName}
                                        size={50}
                                        color={theme.colors.site_500}

                                    />
                                    :
                                    <MaterialCommunityIcons
                                        name={iconName}
                                        size={50}
                                        color={theme.colors.site_500}

                                    />
                            }
                        </IconContainer>
                        <Title>{title}</Title>

                        <Message>
                            {text}
                        </Message>
                        <PressableContainer onPress={() => handleNavigation('en')}>
                            <PressableText style={{color: theme.colors.site_500}}>{t('Inglês')}</PressableText>
                        </PressableContainer>
                        <PressableContainer onPress={() => handleNavigation('pt')}>
                            <PressableText style={{color: theme.colors.site_500}}>{t('Português')}</PressableText>
                        </PressableContainer>
                        <PressableContainer onPress={() => handleNavigation('es')}>
                            <PressableText style={{color: theme.colors.site_500}}>{t('Espanhol')}</PressableText>
                        </PressableContainer>

                        <PressableContainerCancel onPress={handleCloseModal}>
                            <PressableText>{t('CANCELAR')}</PressableText>
                        </PressableContainerCancel>
                    </ScrollWrapper>
                </ScrollContainer>
            </Container>

        </BlurView>
    );
}