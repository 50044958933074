const inchesWaterThree = [
    0.0,
    2.0,
    4.0,
    6.0,
    8.0,
    10.0,
    12.0,
    14.0,
    16.0,
    18.0,
    20.0,
    22.0,
    24.0,
    26.0,
    28.0,
    30.0,
    32.0,
    34.0,
    36.0,
    38.0,
    40.0,
    42.0,
    44.0,
    46.0,
    48.0,
    50.0,
    52.0,
    54.0,
    56.0,
    58.0,
    60.0,
    62.0,
    64.0,
    66.0,
    68.0
]

const volumeGallonThree = [
    0.0,
    21.3,
    61.3,
    116.0,
    179.1,
    253.4,
    332.6,
    421.2,
    512.6,
    612.2,
    712.8,
    820.6,
    927.9,
    1041.6,
    1153.4,
    1270.7,
    1385.0,
    1503.8,
    1618.6,
    1736.8,
    1850.1,
    1961.9,
    2075.5,
    2182.7,
    2290.4,
    2390.9,
    2490.3,
    2581.5,
    2669.9,
    2748.9,
    2822.9,
    2884.0,
    2938.5,
    2979.5,
    2999.7
]

export { volumeGallonThree, inchesWaterThree }