import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

import { Dimensions } from 'react-native';
const maxWidth = 750;

export const Content = styled.View`
margin-top: ${Dimensions.get('window').width < maxWidth ? 10 : 10}px;
margin-bottom: ${Dimensions.get('window').width < maxWidth ? 10 : 10}px;
margin-left: ${Dimensions.get('window').width < maxWidth ? 10 : 100}px;
margin-right: ${Dimensions.get('window').width < maxWidth ? 10 : 100}px;
`;

export const TitleView = styled.View`
align-items: center;
margin-top: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;
border-bottom-width: 1px;
border-bottom-color: ${({ theme }) => theme.colors.line};
`;

export const Title = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
font-family: ${({ theme }) => theme.fonts.primary_500};
color: ${({ theme }) => theme.colors.site_500};
margin-bottom: 10px;
`;

export const PressableContainerJobs = styled.TouchableOpacity`
 width: 100%;

padding: 19px;
justify-content: center;
background-color: ${({ theme }) => theme.colors.site_500};

margin-top: 8px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableText = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(18) : 18}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: justify;
`;

export const TextDataWrapper = styled.View`

`;

export const TextWrapper = styled.View`
flex-direction: row;
align-items: center;
justify-content: space-between;
`;
export const BackButtonWrapper = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const EmptyView = styled.View`
width: ${Dimensions.get('window').width < maxWidth ? RFValue(24) : 24}px;
height: ${Dimensions.get('window').width < maxWidth ? RFValue(24) : 24}px;
padding-top: 10px;
padding-bottom: 10px;
padding-right: 10px;
padding-left: 10px;
margin-top: 10px;
`;

export const EmptyMessage = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.site_500};
text-align: center;
`;

export const EmptyMessageWrapper = styled.View`
height: 300px;
width: 100%;
align-items: center;
justify-content: center;
`;

export const PressableContainerTypeColumn = styled.TouchableOpacity`
width: 100%;
flex-direction: row;
padding: 10px;
align-items: center;
justify-content: space-between;

background-color: ${({ theme }) => theme.colors.site_300};

margin-top: 20px;
margin-bottom: 8px;
border-radius: 5px;

`;


export const PressableTextTypeColumnLineBreake = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
color: ${({ theme }) => theme.colors.site_500};
text-align: justify;
max-width: ${Dimensions.get('window').width < maxWidth ? RFValue(280) : 280}px;
`;
