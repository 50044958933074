import styled from 'styled-components/native';
import { RectButton } from 'react-native-gesture-handler';
import { RFValue } from 'react-native-responsive-fontsize';

interface OptionsProps {
    active?: boolean;
}

export const ButtonTemperature = styled(RectButton) <OptionsProps>`
    background-color: ${({ theme, active }) =>
        active ? theme.colors.main : theme.colors.main};
    width: ${RFValue(40)}px;
    height: ${RFValue(40)}px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin: 5px 5px;
`;
