import { RFValue } from 'react-native-responsive-fontsize';
import styled, { css } from 'styled-components/native';
import { Dimensions } from 'react-native';
const maxWidth = 750;
interface OptionsProps {
        active: boolean;
}

export const Content = styled.View`
margin: 10px 10px;
`;

export const TitleView = styled.View`
align-items: center;

margin-top: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;

`;

export const Title = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
font-family: ${({ theme }) => theme.fonts.primary_500};
color: ${({ theme }) => theme.colors.site_500};
`;

export const PressableContainerJobs = styled.TouchableOpacity`
 width: 100%;

padding: 10px;

justify-content: center;

background-color: ${({ theme }) => theme.colors.site_500};

margin-top: 8px;
margin-bottom: 8px;
border-radius: 5px;

`;

export const PressableContainerJobsErase = styled.View`
 width: 100%;

padding: 19px;

justify-content: center;

background-color: ${({ theme }) => theme.colors.site_300};

margin-top: 8px;
margin-bottom: 8px;
border-radius: 5px;

`;



export const PressableText = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(18) : 18}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: justify;
`;

export const PressableTextTitle = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(19) : 19}px;
color: ${({ theme }) => theme.colors.site_300};
text-align: justify;

`;

export const PressableTextErase = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(18) : 18}px;
color: ${({ theme }) => theme.colors.site_500};
text-align: justify;
`;

export const PressableTextBold = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(18) : 18}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: justify;
`;

export const PressableTextBoldErase = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(18) : 18}px;
color: ${({ theme }) => theme.colors.site_500};
text-align: justify;
margin-top: 5px;
`;


export const BackButtonWrapper = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const EmptyView = styled.View`
width: ${Dimensions.get('window').width < maxWidth ? RFValue(24) : 24}px;
height: ${Dimensions.get('window').width < maxWidth ? RFValue(24) : 24}px;
padding-top: 10px;
padding-bottom: 10px;
padding-right: 10px;
padding-left: 10px;
margin-top: 10px;
`;

export const TextDataWrapper = styled.View`

`;

export const TextWrapper = styled.View`
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-top: 10px;
margin-bottom: 10px;
`;

export const TextWrapperColumn = styled.View`
margin-top: 10px;
margin-bottom: 10px;
`;


export const IconsWrapper = styled.View`
flex-direction: row;
align-items: center;
justify-content: space-around;
`;

export const PressableTextDate = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_400};
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(18) : 18}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: right;
`;

export const Options = styled.View`
border-bottom-width: 1px;
border-bottom-color: ${({ theme }) => theme.colors.line};
border-top-width: 1px;
border-top-color: ${({ theme }) => theme.colors.line};

flex-direction: row;
justify-content: space-between;
margin-bottom: 5px;


`;

export const Option = styled.TouchableOpacity<OptionsProps>`
padding-bottom: 14px;
padding-top:14px ;
width: 33%;
align-items: center;
justify-content:center ;
${({ active }) => active && css`
border-bottom-width: 3px;
border-bottom-color: ${({ theme }) => theme.colors.main};

`}
`;

export const OptionTitle = styled.Text<OptionsProps>`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(14) : 14}px;
font-family: ${({ theme, active }) =>
                active ? theme.fonts.primary_500 : theme.fonts.primary_400};
color: ${({ theme, active }) =>
                active ? theme.colors.site_500 : theme.colors.header};
`;

export const CardFooter = styled.View`
flex-direction: row;
align-items: center;
justify-content: space-between;
`;

export const EmptyMessage = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.site_500};
text-align: center;
`;

export const EmptyMessageWrapper = styled.View`
height: 300px;
width: 100%;
align-items: center;
justify-content: center;
`;