import React from 'react';

import {
    Container,
    Header,
    IconAntDesign,
    IconMaterial,
    IconFontAwesome,
    Title,
    Footer,
    Amount,
    ButtonWrapper,
} from './styles';

import { MaterialCommunityIcons, Feather, FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacityProps } from 'react-native';


interface Props extends TouchableOpacityProps {
    title: string;
    subtitle?: string;
    color?: string;
    iconName: React.ComponentProps<typeof MaterialCommunityIcons>['name'] | React.ComponentProps<typeof Feather>['name'] | React.ComponentProps<typeof FontAwesome5>['name'];
    iconCollection?: 'MaterialCommunityIcons' | 'Feather' | 'FontAwesome5';
}

export function HomeCardMyCalculations({
    title,
    subtitle,
    color,
    iconName,
    iconCollection = 'MaterialCommunityIcons',
    onPress
}: Props) {
    return (

        <Container style={{ shadowColor: '#525E70', elevation: 20 }}>
            <ButtonWrapper onPress={onPress}>
                {
                    iconCollection === 'MaterialCommunityIcons'
                        ?
                        <IconMaterial
                            name={iconName}
                            color={color}
                        />
                        :
                        iconCollection === 'FontAwesome5'
                            ?
                            <IconFontAwesome
                                name={iconName}
                                color={color}
                            />
                            :
                            <IconAntDesign
                                name={iconName}
                                color={color}
                            />
                }
                <Header>
                    <Title>
                        {title}
                    </Title>
                </Header>
                <Footer>
                    <Amount>
                        {subtitle}
                    </Amount>
                </Footer>
            </ButtonWrapper>
        </Container>

    );
}