import React, { useState, useEffect } from 'react';
import {
    FlatList,
    Modal,
    ScrollView,
    View,
} from 'react-native';

import {
    Content,
    TitleView,
    Title,
    PressableContainerJobs,
    PressableText,
    TextWrapper,
    TextDataWrapper,
    BackButtonWrapper,
    EmptyView,
    EmptyMessageWrapper,
    EmptyMessage
} from './styles';

import { useTheme } from 'styled-components/native';

import { BlurView } from 'expo-blur';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { useIsFocused } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';

import {useTranslation} from 'react-i18next';

import { HeaderMyCalculations } from '../../components/HeaderMyCalculations';
import { ForwardArrow } from '../../components/ForwardArrow';
import { DeleteAllCalcModal } from '../../components/Modals/DeleteAllCalcModal';
import { BackButton } from '../../components/BackButton';


export function MyCalculations() {

    const [calculations, setCalculations] = useState([]);
    const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false);

    const [deletedAll, setDeletedAll] = useState(false);

    const theme = useTheme();

    const { navigate } = useNavigation();

    const navigation = useNavigation();

    const screenIsFocus = useIsFocused();

    const {t} = useTranslation();

    function handleNavigationJobs(item) {
        const jobSelected = item;
        navigate('CalculationsFromJob' as never, {
            jobSelected,
        } as never);
    }

    function deleteAllCalculation() {
        try {
            async function deleteAll() {
                AsyncStorage.removeItem('@nitrogencalculations:pressurization_calc:admin')
                AsyncStorage.removeItem('@nitrogencalculations:hydrostatic_calc:admin')
                AsyncStorage.removeItem('@nitrogencalculations:column_calc:admin')
            }
            deleteAll()
            setDeletedAll(true)
            setOpenDeleteAllModal(false)
        } catch (error) {
            console.log(error)
        }

    }



    useEffect(() => {
        async function loadJobNames() {
            const dataKey = `@nitrogencalculations:pressurization_calc:admin`;

            const response = await AsyncStorage.getItem(dataKey);

            const calculations = response ? JSON.parse(response) : [];

            const jobNames = calculations.map(item => item.nameJob)
            //console.log('map', jobNames);
            const jobNamesPressurization = [...new Set(jobNames)];
            //console.log('newSet', uniqueJobNames)


            const dataKeyHydro = `@nitrogencalculations:hydrostatic_calc:admin`;

            const responseHydro = await AsyncStorage.getItem(dataKeyHydro);

            const calculationsHydro = responseHydro ? JSON.parse(responseHydro) : [];

            const jobNamesHydro = calculationsHydro.map(item => item.nameJob)
            //console.log('map', jobNames);
            const jobNamesHydrostatic = [...new Set(jobNamesHydro)];
            //console.log('newSet', uniqueJobNames)

            const dataKeyColumn = `@nitrogencalculations:column_calc:admin`;

            const responseColumn = await AsyncStorage.getItem(dataKeyColumn);

            const calculationsColumn = responseColumn ? JSON.parse(responseColumn) : [];

            const jobNamesColumn = calculationsColumn.map(item => item.nameJob)
            //console.log('map', jobNames);
            const jobNamesVolumeColumn = [...new Set(jobNamesColumn)];
            //console.log('newSet', uniqueJobNames)

            const uniqueJobNames = [...new Set([...jobNamesPressurization, ...jobNamesHydrostatic, ...jobNamesVolumeColumn])];

            setCalculations(uniqueJobNames);
            setDeletedAll(false);
        }
        loadJobNames();
    }, [screenIsFocus, deletedAll])




    return (
        <ScrollView
            style={{ backgroundColor: theme.colors.backgroud_primary }}
            showsVerticalScrollIndicator={false}
        >

            <HeaderMyCalculations
                title={t(`Meus Cálculos`)}
                handleDeleteAll={() => setOpenDeleteAllModal(true)}
            />
            <BackButtonWrapper>
                <BackButton
                    onPress={() => navigation.goBack()}
                    color={theme.colors.main}
                />
                <TitleView>
                    <Title>
                        {t('TRABALHOS')}
                    </Title>
                </TitleView>
                <EmptyView>

                </EmptyView>
            </BackButtonWrapper>
            <Content>

                <FlatList
                    data={calculations}
                    keyExtractor={(item) => item}
                    renderItem={({ item }) => (
                        <PressableContainerJobs onPress={() => handleNavigationJobs(item)}>
                            <TextWrapper>
                                <TextDataWrapper>
                                    <PressableText>{item}</PressableText>
                                </TextDataWrapper>
                                <ForwardArrow
                                    color={theme.colors.backgroud_secondary}
                                />

                            </TextWrapper>
                        </PressableContainerJobs>

                    )
                    }
                    showsVerticalScrollIndicator={false}
                    inverted={true}
                    ListEmptyComponent={(
                        <EmptyMessageWrapper>
                            <EmptyMessage>{t('Nenhum trabalho criado')}</EmptyMessage>
                        </EmptyMessageWrapper>
                    )}
                />


            </Content>
            <Modal
                visible={openDeleteAllModal}
                animationType={'slide'}
                onRequestClose={() => setOpenDeleteAllModal(false)}
                transparent
            >
                <BlurView intensity={200} style={{ flex: 1 }}>

                    <DeleteAllCalcModal
                        title={t('Deletar tudo')}
                        iconName={'trash-can-outline'}
                        text={`${t('Todos os seus cálculos serão deletados!')}${'\n'}${'\n'}${t('Tem certeza que deseja continuar?')}`}
                        handleCloseModal={() => setOpenDeleteAllModal(false)}
                        handleNavigation={deleteAllCalculation}
                    />

                </BlurView>

            </Modal>


        </ScrollView>

    );
}