import React from "react";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { useTheme } from "styled-components/native";

import { MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';

import { TemperatureConverter } from "../screens/TemperatureConverter";
import { VolumeConverter } from "../screens/VolumeConverter";
import { PressureConverter } from "../screens/PressureConverter";
import { FlowConverter } from "../screens/FlowConverter";
import { LengthConverter } from "../screens/LengthConverter";
import { WeightConverter } from "../screens/WeightConverter";
import { Platform } from "react-native";

import {useTranslation} from 'react-i18next';

const { Navigator, Screen } = createBottomTabNavigator();

export function AppTabRoutes() {

    const theme = useTheme();

    const {t} = useTranslation();

    return (
        <Navigator
            initialRouteName="Volume"
            screenOptions={{
                headerShown: false,
                tabBarShowLabel: true,
                tabBarLabelStyle: { fontSize: 14, marginBottom: 5 },
                tabBarStyle: {
                    height: Platform.OS === 'web' ? 108 : 78,
                    paddingVertical: 15,
                    backgroundColor: theme.colors.backgroud_primary,
                    paddingBottom: Platform.OS === 'web' ? 30 : 0
                },
                tabBarActiveTintColor: theme.colors.main,
                tabBarInactiveTintColor: theme.colors.text
            }}
        >
            <Screen
                name="Temperatura"
                component={TemperatureConverter}
                options={{
                title: t('Temperatura'),
                    tabBarIcon: ({ color }) => (
                        <FontAwesome
                            name='thermometer'
                            size={32}
                            color={color}
                        />
                    )
                }}
            />
            <Screen
                name="Volume"
                component={VolumeConverter}
                options={{
                    title: t('Volume'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons
                            name='cube-outline'
                            size={32}
                            color={color}
                        />
                    )
                }}
            />
            <Screen
                name="Pressão"
                component={PressureConverter}
                options={{
                    title: t('Pressão'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons
                            name='gauge'
                            size={32}
                            color={color}
                        />
                    )
                }}
            />
            <Screen
                name="Vazão"
                component={FlowConverter}
                options={{
                    title: t('Vazão'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons
                            name='weather-windy'
                            size={32}
                            color={color}
                        />
                    )
                }}
            />
            <Screen
                name="Comprimento"
                component={LengthConverter}
                options={{
                    title: t('Comprimento'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons
                            name='ruler'
                            size={32}
                            color={color}
                        />
                    )
                }}
            />
            <Screen
                name="Peso"
                component={WeightConverter}
                options={{
                    title: t('Peso'),
                    tabBarIcon: ({ color }) =>
                     (
                        <MaterialCommunityIcons
                            name='weight'
                            size={32}
                            color={color}
                        />
                    )
                }}
            />

        </Navigator>
    )
}