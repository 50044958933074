import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

import { Dimensions } from 'react-native';
const maxWidth = 750;

export const Header = styled.View`
width: 100%;
height: 113px;
justify-content: center;
align-items: center;
padding: 20px 20px;
background-color: ${({ theme }) => theme.colors.main};
`;

export const HeaderTitle = styled.Text`
font-family: ${({ theme }) => theme.fonts.primary_500};
font-size: ${RFValue(30)}px;
color: ${({ theme }) => theme.colors.backgroud_secondary};
`;

export const ButtonView = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const FlatlistWrapper = styled.View`
padding-top: 10px;
`;

export const Content = styled.View`
margin-top: ${Dimensions.get('window').width < maxWidth ? 20 : 20}px;
margin-bottom: ${Dimensions.get('window').width < maxWidth ? 20 : 20}px;
margin-left: ${Dimensions.get('window').width < maxWidth ? 10 : 100}px;
margin-right: ${Dimensions.get('window').width < maxWidth ? 10 : 100}px;
`;

export const InputWrapper = styled.View`
margin-top: 20px;
margin-bottom: 20px;
`;

export const VolumeSelectorWrapper = styled.View`

`;


export const ListContainer = styled.View`
margin: 0 -10px;
`;

export const PressureSelectorWrapper = styled.ScrollView.attrs({
    contentContainerStyle: {
        flexDirection: 'row'
    }
})`

`;
export const ExchangeIconView = styled.View`
align-items: center ;
justify-content:center ;
`;

export const Footer = styled.View`
margin-top: 80px;
margin-bottom: 10px;
`;

export const Empty = styled.Text``;

export const AnswerView = styled.View`
justify-content: center;
align-items: center;
`;

