import { RFValue } from 'react-native-responsive-fontsize';
import styled, { css } from 'styled-components/native';

import { Dimensions } from 'react-native';

const maxWidth = 750;
interface OptionsProps {
        active: boolean;
}

export const Container = styled.ScrollView`
flex: 1;
background-color: ${({ theme }) => theme.colors.backgroud_primary};
`;

export const ButtonView = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const Content = styled.View`
margin: 5px 10px;

`;


export const Footer = styled.View`
margin: 10px 10px;
`;


export const Options = styled.View`
border-bottom-width: 1px;
border-bottom-color: ${({ theme }) => theme.colors.line};

flex-direction: row;
justify-content: space-between;
margin-bottom: 5px;


`;

export const Option = styled.TouchableOpacity<OptionsProps>`
padding-bottom: 14px;
padding-top:14px ;
width: 50%;
align-items: center;
justify-content:center ;
${({ active }) => active && css`
border-bottom-width: 3px;
border-bottom-color: ${({ theme }) => theme.colors.main};

`}
`;

export const OptionTitle = styled.Text<OptionsProps>`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
font-family: ${({ theme, active }) =>
                active ? theme.fonts.primary_500 : theme.fonts.primary_400};
color: ${({ theme, active }) =>
                active ? theme.colors.site_500 : theme.colors.header};
`;

export const OptionContainer = styled.View`
margin-left: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
margin-right: ${Dimensions.get('window').width < maxWidth ? 0 : 100}px;
`;

export const InformationBox = styled.View`
`;

export const InformationText = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(14) : 14}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: center;
`;


export const InformationTextData = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(14) : 14}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.site_500};
text-align: center;
`;



export const InformationBoxHeader = styled.View`
flex-direction: row;
align-items: center;
justify-content: center;
background-color: ${({ theme }) => theme.colors.site_900};
width: 100%;

`;

export const InformationTextBox = styled.View`
border-width: ${Dimensions.get('window').width < maxWidth ? RFValue(1) : 1}px;
border-color: ${({ theme }) => theme.colors.site_600};
padding: ${Dimensions.get('window').width < maxWidth ? RFValue(10) : 10}px;
height: ${Dimensions.get('window').width < maxWidth ? RFValue(50) : 50}px;
width: 25%;
align-items: center;
justify-content: center;
`;

export const InformationBoxData = styled.View`
flex-direction: row;
align-items: center;
justify-content: center;
background-color: ${({ theme }) => theme.colors.backgroud_secondary};
width: 100%;

`;

export const InchWaterContainer = styled.View`
margin-top: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
margin-bottom: ${Dimensions.get('window').width < maxWidth ? RFValue(20) : 20}px;
`;

export const InchWaterWrapper = styled.View`
flex-direction: row;
justify-content: space-between;
align-items: center;
`;

export const InchWaterText = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.header};
text-align: center;
`;

export const InchWaterTitle = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(17) : 17}px;
font-family: ${({ theme }) => theme.fonts.primary_500};
color: ${({ theme }) => theme.colors.site_500};
text-align: center;
`;

export const InchWaterInput = styled.TextInput`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
width: ${Dimensions.get('window').width < maxWidth ? RFValue(50) : 50}px;
height: ${Dimensions.get('window').width < maxWidth ? RFValue(50) : 50}px;
font-family: ${({ theme }) => theme.fonts.primary_500};
color: ${({ theme }) => theme.colors.header};
background-color: ${({ theme }) => theme.colors.backgroud_secondary};
text-align: center;

border-bottom-width: 2px;
border-bottom-color: ${({ theme }) => theme.colors.main};

`;

export const InchWaterResult = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
font-family: ${({ theme }) => theme.fonts.primary_400};
color: ${({ theme }) => theme.colors.header};
text-align: center;
`;

export const InchWaterResultBold = styled.Text`
font-size: ${Dimensions.get('window').width < maxWidth ? RFValue(16) : 16}px;
font-family: ${({ theme }) => theme.fonts.primary_500};
color: ${({ theme }) => theme.colors.header};
text-align: center;
`;

export const InchWaterResultWrapper = styled.View`
align-items: flex-start;
justify-content: center;
`;

export const Separator = styled.View`
height: 1px;
width: 100%;
background-color: ${({ theme }) => theme.colors.text};
margin-top: ${Dimensions.get('window').width < maxWidth ? RFValue(15) : 15}px;
`;

export const InchWaterTitleWrapper = styled.View`
flex-direction: row;
justify-content: space-around;
align-items: center;
`;
