import React, { useState } from 'react';

import { useTheme } from 'styled-components/native';

import { useNavigation, useRoute } from '@react-navigation/native';

import {useTranslation} from 'react-i18next';

import {
    Container,
    Header,
    BackButtonWrapper,
    TemperatureCalculated,
    PressureCalculated,
    VolumeCalculated,
    TimeCalculated,
    HeaderTitleParameters,
    HeaderTitle,
    ResultWrapper,
    CalculationResultGas,
    CalculationResultLiquid,
    CalculationResultGasLost,
    CalculationResultTime,
    Result,
    Separator,
    EmptyView,
    ContainerModal,
    ScrollContainer,
    ScrollWrapper,
    PressableContainer,
    PressableText,
    PressableContainerSave,
    PressableTextSave,
    IconContainer,
    Title,
    Message,
    PressableContainerJobs,
    ContainerModalJobs,
    ScrollContainerJobs,
    EmptyMessageWrapper,
    EmptyMessage
} from './styles';

import AsyncStorage from '@react-native-async-storage/async-storage';
import uuid from 'react-native-uuid';

import { BackButton } from '../../components/BackButton';
import { SaveButton } from '../../components/SaveButton';
import { FlatList, Modal } from 'react-native';

import { BlurView } from 'expo-blur';
import { MaterialIcons } from '@expo/vector-icons';
import { Input } from '../../components/Input';
import { ForwardArrow } from '../../components/ForwardArrow';
interface AnswerProps {
    result: {
        totalVolumeGas: number;
        totalVolumeLiquid: number;
        totalVolumeLiquidWithLost: number;
        timeForPressurizationHour: number;
        timeForPressurizationMinutes: number;
    },
    volume: string;
    pressure: string;
    flow: string;
    volumeTypeSelected: string;
    pressureTypeSelected: string;
    temperatureSelected: string;
}

export function PressurizationResultPlus() {

    const theme = useTheme();

    const {t} = useTranslation();

    const navigation = useNavigation();
    const route = useRoute();
    const {
        result,
        volume,
        pressure,
        flow,
        volumeTypeSelected,
        pressureTypeSelected,
        temperatureSelected
    } = route.params as AnswerProps;

    const [openModalSave, setOpenModalSave] = useState(false);
    const [openChooseJob, setOpenChooseJob] = useState(false);
    const [openChooseCalc, setOpenChooseCalc] = useState(false);


    const [newJob, setNewJob] = useState('');
    const [newCalc, setNewCalc] = useState('');

    const [calculations, setCalculations] = useState([]);

    function handleGoBack() {
        navigation.goBack();
    }

    function handleOpenSaveModal() {
        setOpenModalSave(true);
    }

    async function loadJobNames() {
        const dataKey = `@nitrogencalculations:pressurization_calc:admin`;

        const response = await AsyncStorage.getItem(dataKey);

        const calculations = response ? JSON.parse(response) : [];

        const jobNames = calculations.map(item => item.nameJob)
        //console.log('map', jobNames);
        const jobNamesPressurization = [...new Set(jobNames)];
        //console.log('newSet', uniqueJobNames)


        const dataKeyHydro = `@nitrogencalculations:hydrostatic_calc:admin`;

        const responseHydro = await AsyncStorage.getItem(dataKeyHydro);

        const calculationsHydro = responseHydro ? JSON.parse(responseHydro) : [];

        const jobNamesHydro = calculationsHydro.map(item => item.nameJob)
        //console.log('map', jobNames);
        const jobNamesHydrostatic = [...new Set(jobNamesHydro)];
        //console.log('newSet', uniqueJobNames)

        const dataKeyColumn = `@nitrogencalculations:column_calc:admin`;

        const responseColumn = await AsyncStorage.getItem(dataKeyColumn);

        const calculationsColumn = responseColumn ? JSON.parse(responseColumn) : [];

        const jobNamesColumn = calculationsColumn.map(item => item.nameJob)
        //console.log('map', jobNames);
        const jobNamesVolumeColumn = [...new Set(jobNamesColumn)];
        //console.log('newSet', uniqueJobNames)

        const uniqueJobNames = [...new Set([...jobNamesPressurization, ...jobNamesHydrostatic, ...jobNamesVolumeColumn])];

        setCalculations(uniqueJobNames);
        setOpenChooseJob(true);

    }

    async function loadCalcNames() {
        const dataKey = `@nitrogencalculations:pressurization_calc:admin`;

        const response = await AsyncStorage.getItem(dataKey);

        const calculations = response ? JSON.parse(response) : [];

        const calcNames = calculations.map(item => item.nameCalc);
        const uniqueCalcNames = [...new Set(calcNames)];

        setCalculations(uniqueCalcNames);
        setOpenChooseCalc(true);

    }



    async function handleSaveCalculation() {
        if (!newJob) {
            return alert(t('Selecione ou crie um trabalho!'))
        }
        if (!newCalc) {
            return alert(t('Crie um nome para o cálculo!'))
        }

        const noTime = 'Vazão não fornecida'

        function getDateFomatted() {
            const data = new Date(),
                dia = data.getDate().toString(),
                diaF = (dia.length == 1) ? '0' + dia : dia,
                mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
                mesF = (mes.length == 1) ? '0' + mes : mes,
                anoF = data.getFullYear();
            return diaF + "/" + mesF + "/" + anoF;
        }

        const dateFormatted = getDateFomatted();
        console.log('dateFormatted', dateFormatted)

        const newCalculation = {
            id: String(uuid.v4()),
            nameJob: newJob,
            nameCalc: newCalc,
            totalVolumeGas: result.totalVolumeGas.toFixed(0),
            totalVolumeLiquid: result.totalVolumeLiquid.toFixed(0),
            totalVolumeLiquidWithLost: result.totalVolumeLiquidWithLost.toFixed(0),
            timeForPressurizationHour: result.timeForPressurizationHour ? result.timeForPressurizationHour.toFixed(0) : noTime,
            timeForPressurizationMinutes: result.timeForPressurizationMinutes ? result.timeForPressurizationMinutes.toFixed(0) : noTime,
            volume: volume,
            pressure: pressure,
            flow: flow ? flow : noTime,
            volumeTypeSelected: volumeTypeSelected,
            pressureTypeSelected: pressureTypeSelected,
            temperatureSelected: temperatureSelected,
            date: dateFormatted
        }

        try {
            const dataKey = `@nitrogencalculations:pressurization_calc:admin`;
            const data = await AsyncStorage.getItem(dataKey);
            const currentData = data ? JSON.parse(data) : [];

            const dataFormatted = [
                ...currentData,
                newCalculation
            ]

            await AsyncStorage.setItem(dataKey, JSON.stringify(dataFormatted));
            console.log('Armazenamento realizado com sucesso', dataFormatted);
            setNewJob('');
            setNewCalc('');
            setOpenModalSave(false);
            alert(t('Salvo com sucesso!'));

        } catch (error) {
            console.log('Erro ao armazenar dados');

        }
    }

    return (
        <Container>
            <Header>
                <BackButtonWrapper>
                    <BackButton
                        onPress={handleGoBack}
                        color={theme.colors.backgroud_secondary}
                    />
                    <SaveButton
                        onPress={handleOpenSaveModal}
                        color={theme.colors.backgroud_secondary}

                    />
                </BackButtonWrapper>
                <HeaderTitleParameters>{t('Dados da operação:')}</HeaderTitleParameters>
                <TemperatureCalculated>
                    {'\u21D2'}{t('Temperatura de')} {temperatureSelected}° F
                </TemperatureCalculated>
                <VolumeCalculated>
                    {'\u21D2'}{t('Volume do local pressurizado sendo de')} {volume.replace('.', ',')} {volumeTypeSelected}
                </VolumeCalculated>
                <PressureCalculated>
                    {'\u21D2'}{t('Pressão final de bombeio sendo de')} {pressure.replace('.', ',')} {pressureTypeSelected}
                </PressureCalculated>
                {
                    flow
                        ?
                        <TimeCalculated>
                            {'\u21D2'}{t('Vazão de')} {flow.replace('.', ',')} scfm
                        </TimeCalculated>
                        :
                        <EmptyView></EmptyView>
                }

            </Header>
            <ResultWrapper>
                <HeaderTitle>{t('Resultado: ')}</HeaderTitle>
                <CalculationResultGas
                    selectable
                >
                    {'\u21D2'}{t('O volume para realizar a operação será de ')}<Result>{result.totalVolumeGas.toFixed(0)} scf</Result>
                </CalculationResultGas>
                <Separator />
                <CalculationResultLiquid
                    selectable
                >
                    {'\u21D2'}{t('O volume de nitrogênio líquido bombeado será de')} <Result>{result.totalVolumeLiquid.toFixed(0)} {t('galões')}</Result>
                </CalculationResultLiquid>
                <Separator />
                <CalculationResultGasLost
                    selectable
                >
                    {'\u21D2'}{t('Valor de LN2 necessário considerando 20% de perda e refrigeração da UN será de')} <Result>{result.totalVolumeLiquidWithLost.toFixed(0)} {t('galões')}</Result>
                </CalculationResultGasLost>
                <Separator />
                {flow
                    ?
                    <CalculationResultTime
                        selectable
                    >
                        {'\u21D2'}{t('Tempo de bombeio com a vazão de')} {flow.replace('.', ',')} {t('scfm será de')} {result.timeForPressurizationHour >= 1 && <Result>{result.timeForPressurizationHour.toFixed(0).replace('.', ',')} {Number(result.timeForPressurizationHour.toFixed(0)) >= 2 ? t('horas') : t('hora')} {t('e')} </Result>}<Result>{result.timeForPressurizationMinutes.toFixed(0).replace('.', ',')} {Number(result.timeForPressurizationMinutes.toFixed(0)) >= 2 ? t('minutos') : t('minuto')}</Result>
                    </CalculationResultTime>
                    :
                    <EmptyView></EmptyView>

                }

            </ResultWrapper>
            <Modal
                visible={openModalSave}
                animationType={'slide'}
                onRequestClose={() => setOpenModalSave(false)}

            >


                <ContainerModal showsVerticalScrollIndicator={false}>
                    <ScrollContainer showsVerticalScrollIndicator={false}>
                        <ScrollWrapper>
                            <IconContainer >
                                <MaterialIcons
                                    name='save'
                                    size={50}
                                    color={theme.colors.site_500}

                                />
                            </IconContainer>
                            <Title>{t('Salvar')}</Title>
                            <Message>{t('Escolha ou crie um trabalho para salvar seu cálculo')}</Message>
                            <PressableContainerSave onPress={loadJobNames}>
                                <PressableTextSave>{t('Escolher trabalho existente')}</PressableTextSave>
                                <ForwardArrow />
                            </PressableContainerSave>
                            <Input
                                iconName='label'
                                placeholder={t('Nome trabalho')}
                                keyboardType='default'
                                value={newJob}
                                onChangeText={(text) => setNewJob(text)}
                            />

                            <Message>{t('Dê um nome para esse cálculo (Ex: Deslocamento de DPR):')}</Message>
                            <PressableContainerSave onPress={loadCalcNames}>
                                <PressableTextSave>{t('Escolher nome existente')}</PressableTextSave>
                                <ForwardArrow />
                            </PressableContainerSave>
                            <Input
                                iconName='label'
                                placeholder={t('Nome do cálculo')}
                                keyboardType='default'
                                value={newCalc}
                                onChangeText={(text) => setNewCalc(text)}
                            />



                            <PressableContainer onPress={handleSaveCalculation} >
                                <PressableText>{t('SALVAR E SAIR')}</PressableText>
                            </PressableContainer>

                            <PressableContainerJobs onPress={() => setOpenModalSave(false)}>
                                <PressableText>{t('SAIR SEM SALVAR')}</PressableText>
                            </PressableContainerJobs>
                        </ScrollWrapper>
                    </ScrollContainer>
                </ContainerModal>


                <Modal
                    visible={openChooseJob}
                    animationType={'fade'}
                    onRequestClose={() => setOpenChooseJob(false)}
                    transparent
                >
                    <BlurView intensity={200} style={{ flex: 1 }}>

                        <ContainerModalJobs>
                            <ScrollContainerJobs>
                                <ScrollWrapper>
                                    <IconContainer >
                                        <MaterialIcons
                                            name='save'
                                            size={50}
                                            color={theme.colors.site_500}

                                        />
                                    </IconContainer>
                                    <Title>{t('Trabalhos')}</Title>
                                    <Message>{t('Escolha um dos trabalhos abaixo para vincular seu cálculo')}</Message>

                                    <FlatList
                                        data={calculations}
                                        keyExtractor={(item) => item}
                                        renderItem={({ item }) => (
                                            <PressableContainerJobs onPress={() => {
                                                setNewJob(item)
                                                setOpenChooseJob(false)
                                            }}>
                                                <PressableText>{item}</PressableText>
                                            </PressableContainerJobs>
                                        )
                                        }
                                        showsVerticalScrollIndicator={false}
                                        inverted={true}
                                        ListEmptyComponent={(
                                            <EmptyMessageWrapper>
                                                <EmptyMessage>{t('Nenhum trabalho criado')}</EmptyMessage>
                                            </EmptyMessageWrapper>
                                        )}
                                    />
                                    <PressableContainer onPress={() => setOpenChooseJob(false)}>
                                        <PressableText>{t('VOLTAR')}</PressableText>
                                    </PressableContainer>
                                </ScrollWrapper>
                            </ScrollContainerJobs>
                        </ContainerModalJobs>

                    </BlurView>

                </Modal>

                <Modal
                    visible={openChooseCalc}
                    animationType={'fade'}
                    onRequestClose={() => setOpenChooseCalc(false)}
                    transparent
                >
                    <BlurView intensity={200} style={{ flex: 1 }}>

                        <ContainerModalJobs >
                            <ScrollContainerJobs >
                                <ScrollWrapper>
                                    <IconContainer >
                                        <MaterialIcons
                                            name='save'
                                            size={50}
                                            color={theme.colors.site_500}

                                        />
                                    </IconContainer>
                                    <Title>{t('Operações')}</Title>
                                    <Message>{t('Escolha um dos nomes de operações abaixo para o seu cálculo')}</Message>

                                    <FlatList
                                        data={calculations}
                                        keyExtractor={(item) => item}
                                        renderItem={({ item }) => (
                                            <PressableContainerJobs onPress={() => {
                                                setNewCalc(item)
                                                setOpenChooseCalc(false)
                                            }}>
                                                <PressableText>{item}</PressableText>
                                            </PressableContainerJobs>
                                        )
                                        }
                                        showsVerticalScrollIndicator={false}
                                        ListEmptyComponent={(
                                            <EmptyMessageWrapper>
                                                <EmptyMessage>{t('Não há nomes criados')}</EmptyMessage>
                                            </EmptyMessageWrapper>
                                        )}
                                        inverted={true}
                                    />
                                    <PressableContainer onPress={() => setOpenChooseCalc(false)}>
                                        <PressableText>{t('VOLTAR')}</PressableText>
                                    </PressableContainer>
                                </ScrollWrapper>
                            </ScrollContainerJobs>
                        </ContainerModalJobs>

                    </BlurView>

                </Modal>
            </Modal>
        </Container>
    );
}

