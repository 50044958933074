
interface PressureHeadProps10 {
    key: number;
    title: string;
    constant: number[];
}

const pressureHeadTable10: PressureHeadProps10[] = [
    {
        key: 0,
        title: '0',
        constant: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ]
    },
    {
        key: 500,
        title: '500',
        constant: [
            500, 492, 483, 476, 468, 461, 453, 446, 440, 433, 427, 421, 415, 409, 403, 397, 392, 387, 382, 377, 372, 367, 362, 358, 353, 349, 345, 341, 337, 335, 329, 325, 321, 318, 314, 311, 307, 304, 301, 298, 294, 291, 288, 285, 283, 280, 277, 274, 271, 269, 266, 264, 261, 259, 256, 254, 252, 249, 247, 245, 243
        ]
    },
    {
        key: 1000,
        title: '1000',
        constant: [
            1000, 993, 967, 951, 936, 921, 907, 893, 880, 867, 854, 842, 830, 818, 807, 796, 785, 775, 764, 754, 745, 735, 726, 717, 708, 700, 691, 683, 675, 667, 659, 652, 645, 637, 630, 623, 617, 610, 604, 597, 591, 585, 579, 573, 567, 562, 556, 551, 545, 540, 535, 530, 525, 520, 515, 510, 506, 501, 497, 492, 488
        ]
    },
    {
        key: 1500,
        title: '1500',
        constant: [
            1500, 1475, 1451, 1428, 1405, 1383, 1362, 1341, 1321, 1302, 1283, 1264, 1247, 1229, 1212, 1196, 1180, 1164, 1149, 1134, 1120, 1106, 1092, 1079, 1065, 1053, 1040, 1028, 1016, 1004, 993, 982, 971, 960, 949, 939, 929, 919, 909, 900, 891, 881, 872, 864, 855, 847, 838, 830, 822, 814, 807, 799, 792, 784, 777, 770, 763, 756, 750, 743, 737
        ]
    },
    {
        key: 2000,
        title: '2000',
        constant: [
            2000, 1967, 1935, 1905, 1875, 1846, 1818, 1791, 1764, 1739, 1714, 1689, 1666, 1643, 1621, 1599, 1578, 1557, 1537, 1517, 1498, 1480, 1461, 1443, 1426, 1409, 1392, 1376, 1360, 1345, 1330, 1315, 1300, 1286, 1272, 1258, 1245, 1232, 1219, 1206, 1194, 1182, 1170, 1158, 1147, 1135, 1124, 1114, 1103, 1092, 1082, 1072, 1062, 1052, 1043, 1033, 1024, 1015, 1006, 997, 989
        ]
    },
    {
        key: 2500,
        title: '2500',
        constant: [
            2500, 2460, 2421, 2383, 2346, 2311, 2276, 2242, 2210, 2178, 2147, 2117, 2088, 2060, 2032, 2005, 1979, 1954, 1929, 1904, 1881, 1857, 1835, 1813, 1791, 1770, 1749, 1729, 1709, 1690, 1671, 1652, 1634, 1616, 1599, 1582, 1565, 1549, 1533, 1517, 1502, 1487, 1472, 1457, 1443, 1429, 1415, 1401, 1388, 1375, 1362, 1350, 1337, 1325, 1313, 1301, 1290, 1278, 1267, 1256, 1245
        ]
    },
    {
        key: 3000,
        title: '3000',
        constant: [
            3000, 2953, 2907, 2862, 2819, 2777, 2736, 2697, 2658, 2621, 2484, 2549, 2514, 2481, 2448, 2416, 2385, 2354, 2325, 2296, 2268, 2240, 2213, 2186, 2161, 2135, 2111, 2087, 2063, 2040, 2017, 1995, 1973, 1952, 1931, 1911, 1891, 1871, 1852, 1833, 1815, 1797, 1779, 1761, 1744, 1727, 1711, 1624, 1678, 1663, 1647, 1632, 1617, 1602, 1588, 1574, 1560, 1546, 1533, 1520, 1506
        ]
    },
    {
        key: 3500,
        title: '3500',
        constant: [
            3500, 3446, 3394, 3343, 3294, 3246, 3199, 3154, 3110, 3067, 3025, 2984, 2945, 2906, 2868, 2831, 2795, 2760, 2726, 2692, 2660, 2628, 2596, 2566, 2536, 2507, 2478, 2450, 2423, 2396, 2369, 2344, 2318, 2294, 2269, 2246, 2222, 2199, 2177, 2155, 2133, 2112, 2091, 2071, 2051, 2031, 2012, 1993, 1974, 1956, 1938, 1920, 1903, 1886, 1869, 1852, 1836, 1820, 1804, 1788, 1773
        ]
    },
    {
        key: 4000,
        title: '4000',
        constant: [
            4000, 3940, 3882, 3826, 3771, 3717, 3665, 3614, 3565, 3516, 3469, 3424, 3379, 3335, 3293, 3251, 3210, 3117, 3132, 3094, 3057, 3021, 2985, 2951, 2917, 2883, 2851, 2819, 2788, 2757, 2727, 2698, 2669, 2641, 2613, 2586, 2560, 2534, 2508, 2483, 2458, 2434, 2410, 2387, 2364, 2341, 2319, 2298, 2276, 2255, 2234, 2214, 2194, 2175, 2155, 2136, 2117, 2099, 2081, 2063, 2046
        ]
    },
    {
        key: 4500,
        title: '4500',
        constant: [
            4500, 4435, 4371, 4309, 4249, 4190, 4133, 4077, 4022, 3969, 3917, 3867, 3817, 3769, 3722, 3675, 3630, 3586, 3543, 3501, 3460, 3419, 3380, 3341, 3303, 3266, 3230, 3194, 3159, 3125, 3092, 3059, 3026, 2995, 2964, 2933, 2904, 2874, 2845, 2817, 2789, 2762, 2735, 2709, 2683, 2658, 2633, 2609, 2584, 2561, 2537, 2514, 2492, 2470, 2448, 2426, 2405, 2385, 2364, 2344, 2324
        ]
    },
    {
        key: 5000,
        title: '5000',
        constant: [
            5000, 4930, 4861, 4794, 4729, 4665, 4603, 4542, 4483, 4425, 4369, 4313, 4259, 4207, 4155, 4104, 4055, 4007, 3959, 3913, 3868, 3823, 3780, 3737, 3696, 3655, 3615, 3575, 3537, 3499, 3462, 3426, 3390, 3355, 3321, 3287, 3254, 3221, 3189, 3158, 3127, 3097, 3067, 3038, 3009, 2981, 2953, 2926, 2899, 2873, 2847, 2821, 2796, 2771, 2747, 2723, 2700, 2676, 2654, 2631, 2609
        ]
    },
    {
        key: 5500,
        title: '5500',
        constant: [
            5500, 5425, 5352, 5280, 5210, 5142, 5075, 5010, 4946, 4884, 4823, 4764, 4705, 4648, 4592, 4538, 4484, 4432, 4380, 4330, 4281, 4233, 4185, 4139, 4094, 4049, 4005, 3962, 3920, 3879, 3838, 3799, 3760, 3721, 3634, 3647, 3610, 3575, 3540, 3505, 3472, 3438, 3406, 3373, 3342, 3311, 3280, 3250, 3221, 3192, 3163, 3135, 3107, 2080, 3058, 3021, 3001, 2975, 2950, 2925, 2900
        ]
    },
    {
        key: 6000,
        title: '6000',
        constant: [
            6000, 5921, 5751, 5701, 5686, 5621, 5550, 5480, 5412, 5346, 5281, 5217, 5155, 5094, 5034, 4975, 4918, 4861, 4806, 4752, 4699, 4641, 4590, 4546, 4497, 4449, 4401, 4355, 4310, 4265, 4221, 4178, 4136, 4094, 4053, 4013, 3973, 3935, 3897, 3859, 3822, 3786, 3750, 3715, 3681, 3647, 3614, 3581, 3549, 3517, 3486, 3455, 3425, 3395, 3365, 3337, 3308, 3280, 3252, 3225, 3198
        ]
    },
    {
        key: 6500,
        title: '6500',
        constant: [
            6500, 6417, 6315, 6255, 6177, 6101, 6026, 5952, 5880, 5810, 5741, 5673, 5607, 5542, 5479, 5416, 5344, 5295, 5236, 5178, 5122, 5066, 5012, 4958, 4905, 4854, 4803, 4753, 4704, 4656, 4609, 4563, 4517, 4472, 4428, 4385, 4343, 4301, 4260, 4219, 4179, 4140, 4102, 4064, 4027, 3990, 3954, 3918, 3883, 3849, 3815, 3782, 3749, 3716, 3685, 3653, 3622, 3592, 3562, 3512, 3503
        ]
    },
    {
        key: 7000,
        title: '7000',
        constant: [
            7000, 6913, 6821, 6744, 6662, 6582, 6503, 6426, 6351, 6277, 6204, 6133, 6063, 5994, 5927, 5861, 5796, 5733, 5670, 5609, 5549, 5490, 5432, 5375, 5319, 5264, 5210, 5157, 5105, 5053, 5003, 4953, 4905, 4857, 4810, 4763, 4718, 4673, 4629, 4586, 4543, 4501, 4459, 4419, 4379, 4339, 4300, 4262, 4225, 4187, 4151, 4115, 4080, 4045, 4010, 3976, 3943, 3910, 3878, 3846, 3814
        ]
    },
    {
        key: 7500,
        title: '7500',
        constant: [
            7500, 7409, 7321, 7214, 7148, 7065, 6983, 6902, 6823, 6745, 6669, 6594, 6521, 6449, 6378, 6309, 6241, 6174, 6108, 6043, 5980, 5918, 5856, 5796, 5737, 5679, 5621, 5565, 5510, 5455, 5402, 5349, 5298, 5247, 5196, 5147, 5099, 5051, 5004, 4958, 4912, 4867, 4823, 4780, 4737, 4695, 4653, 4612, 4572, 4532, 4493, 4455, 4417, 4379, 4342, 4306, 4270, 4235, 4200, 4166, 4132
        ]
    },
    {
        key: 8000,
        title: '8000',
        constant: [
            8000, 7906, 7814, 7724, 7635, 7548, 7463, 7379, 7297, 7216, 7136, 7058, 6982, 6907, 6833, 6760, 6688, 6618, 6549, 6481, 6415, 6364, 6285, 6221, 6159, 6098, 6037, 5978, 5920, 5862, 5806, 5750, 5695, 5642, 5589, 5536, 5485, 5434, 5384, 5335, 5287, 5239, 5193, 5146, 5101, 5056, 5012, 4960, 4925, 4883, 4842, 4800, 4760, 4720, 4681, 4642, 4604, 4566, 4529, 4492, 4456
        ]
    },
    {
        key: 8500,
        title: '8500',
        constant: [
            8500, 8403, 8308, 8215, 8123, 8033, 7945, 7858, 7772, 7688, 7606, 7525, 7445, 7366, 7289, 7214, 7139, 7066, 6994, 6923, 6853, 6784, 6717, 6651, 6585, 6521, 6458, 6395, 6334, 6274, 6214, 6156, 6098, 6041, 5985, 5930, 5876, 5823, 5770, 5718, 5667, 5617, 5568, 5519, 5471, 5423, 5376, 5330, 5285, 5240, 5196, 5152, 5109, 5067, 5025, 4984, 4943, 4903, 4864, 4825, 4786
        ]
    },
    {
        key: 9000,
        title: '9000',
        constant: [
            9000, 8900, 8801, 8706, 8612, 8519, 8427, 8337, 8249, 8162, 8077, 7993, 7910, 7828, 7748, 7670, 7592, 7516, 7441, 7367, 7294, 7223, 7153, 7083, 7015, 6948, 6882, 6817, 6752, 6689, 6627, 6566, 6505, 6446, 6387, 6329, 6272, 6216, 6161, 6107, 6053, 6000, 5948, 5896, 5846, 5796, 5746, 5690, 5650, 5602, 5556, 5510, 5464, 5420, 5375, 5332, 5289, 5246, 5205, 5163, 5122
        ]
    },
    {
        key: 9500,
        title: '9500',
        constant: [
            9500, 9398, 9297, 9198, 9101, 9005, 8911, 8818, 8727, 8637, 8549, 8462, 8377, 8293, 8210, 8128, 8048, 7969, 7891, 7814, 7739, 7665, 7591, 7519, 7448, 7378, 7309, 7242, 7175, 7109, 7044, 6980, 6917, 6854, 6793, 6733, 6673, 6615, 6557, 6500, 6443, 6388, 6333, 6279, 6226, 6173, 6121, 6070, 6020, 5970, 5921, 5873, 5825, 5778, 5731, 5685, 5640, 5595, 5551, 5508, 5464
        ]
    },
    {
        key: 10000,
        title: '10000',
        constant: [
            10000, 9895, 9792, 9691, 9591, 9492, 9395, 9300, 9206, 9114, 9023, 8933, 8845, 8758, 8673, 8589, 8506, 8424, 8343, 8264, 8186, 8109, 8033, 7558, 7885, 7812, 7740, 7670, 7600, 7532, 7464, 7398, 7332, 7267, 7203, 7140, 7078, 7017, 6957, 6897, 6838, 6780, 6723, 6667, 6611, 6556, 6502, 6448, 6395, 6343, 6292, 6241, 6119, 6141, 6092, 6044, 5997, 5950, 5903, 5857, 5812
        ]
    }

]

const depthTable10 = [
    0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 7500,
    8000, 8500, 9000, 9500, 10000, 10500, 11000, 11500, 12000, 12500, 13000, 13500, 14000,
    14500, 15000, 15500, 16000, 16500, 17000, 17500, 18000, 18500, 19000, 19500, 20000,
    20500, 21000, 21500, 22000, 22500, 23000, 23500, 24000, 24500, 25000, 25500, 26000,
    26500, 27000, 27500, 28000, 28500, 29000, 29500, 30000
]

const bottomPressureTable10 = [
    0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 7500,
    8000, 8500, 9000, 9500, 10000
]


export { pressureHeadTable10, PressureHeadProps10, depthTable10, bottomPressureTable10 }