export default {
    colors: {
        header: '#1B1B1F',

        backgroud_primary: '#F4F5F6',
        backgroud_secondary: '#FFFFFF',

        text: '#7A7A80',
        text_details: '#AEAEB3',
        title: '#47474D',

        line: '#EBEBF0',

        main: '#CE4423',
        main_light: '#F8DADA',
        site_700: '#815AFF',
        site_900: '#44329A',
        site_600: '#525E70',
        site_500: '#2D3A4D',
        site_300: '#E5E9ED',
        success: '#12A454',

        shape: '#E1E1E8',
        shape_dark: '#29292E',
        shadow: '#525E70'



    },
    fonts: {

        primary_400: 'Rubik_400Regular',
        primary_500: 'Rubik_600SemiBold',

        secondary_400: 'Rubik_600SemiBold',
        secondary_500: 'Rubik_600SemiBold',
        secondary_600: 'Rubik_400Regular',

    }
}