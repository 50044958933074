import React, { useState } from 'react';
import { TextInputProps } from 'react-native';

import { useTheme } from 'styled-components/native';

import { MaterialCommunityIcons } from '@expo/vector-icons';

import {
    Container,
    InputText,
    IconContainer,
    PasteCopy,
    PasteCopyButton,
} from './styles';

interface Props extends TextInputProps {
    iconName: React.ComponentProps<typeof MaterialCommunityIcons>['name'];
    value?: string;
    handlePaste?: () => void;
    pasteButton?: boolean;
}

export function Input(
    { iconName,
        value,
        handlePaste,
        pasteButton = false,
        ...rest

    }: Props
) {

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);


    const theme = useTheme();

    function handleInputFocus() {
        setIsFocused(true)
    }

    function handleInputBlur() {
        setIsFocused(false)
        setIsFilled(!!value)
    }

    return (
        <Container >
            <IconContainer isFocused={isFocused}>
                <MaterialCommunityIcons
                    name={iconName}
                    size={32}
                    color={(isFocused || isFilled) ? theme.colors.site_500 : theme.colors.site_500}

                />
            </IconContainer>
            <InputText
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                isFocused={isFocused}
                value={value}
                {...rest}
            />
            {
                pasteButton &&
                <PasteCopy isFocused={isFocused}>
                    <PasteCopyButton onPress={handlePaste}>
                        <MaterialCommunityIcons
                            name='content-paste'
                            size={25}
                            color={theme.colors.site_700}
                        />
                    </PasteCopyButton>
                </PasteCopy>
            }

        </Container>
    );
}