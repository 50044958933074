import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { Home } from '../screens/Home';
import { HydrostaticPressureCalculation } from "../screens/HydrostaticPressureCalculation";
import { ColumnVolumeCalculation } from "../screens/ColumnVolumeCalculation";
import { AppStackThreeRoutes } from './app.stackthree.routes';
import { AppTabRoutes } from './app.tab.routes';
import { NitrogenTanks } from '../screens/NitrogenTanks';
import { useTheme } from 'styled-components/native';
import { N2Converter } from '../screens/N2Converter';
import { StackMyCalculation } from './app.stackMyCalculation.routes';
import { Settings } from '../screens/Settings';

import {useTranslation} from 'react-i18next';

import { MaterialCommunityIcons, AntDesign, FontAwesome5, Feather } from '@expo/vector-icons';

const Drawer = createDrawerNavigator();

export function AppDrawerRoutes() {

    const theme = useTheme();

    const {t} = useTranslation();

    return (
        <Drawer.Navigator
            screenOptions={{
                headerShown: false,
                drawerActiveBackgroundColor: theme.colors.main_light,
                drawerActiveTintColor: theme.colors.main,
                drawerLabelStyle: {
                    fontSize: 16,
                    fontFamily: theme.fonts.primary_500,
                },
                drawerInactiveTintColor: theme.colors.text,
                drawerContentContainerStyle: {
                    marginTop: 20
                }
            }}
            initialRouteName="Home"
        >
            <Drawer.Screen
                name='Home'
                component={Home}
                options={{
                    title: t('Início'),
                    drawerIcon: ({ focused, size, color }) => (
                        <MaterialCommunityIcons
                            name={focused ? 'home' : 'home-outline'}
                            color={color}
                            size={size}
                        />
                    )
                }}
            />
            <Drawer.Screen
                name='AppStackThreeRoutes'
                component={AppStackThreeRoutes}
                options={{
                    title: t('Operações N2'),
                    drawerIcon: ({ focused, size, color }) => (
                        <MaterialCommunityIcons
                            name={focused ? 'gauge-full' : 'gauge-empty'}
                            color={color}
                            size={size}
                        />
                    )
                }}
            />
            <Drawer.Screen
                name='HydrostaticPressureCalculation'
                component={HydrostaticPressureCalculation}
                options={{
                    title: t('Pressão Hidrostática'),
                    drawerIcon: ({ focused, size, color }) => (
                        <AntDesign
                            name={focused ? 'download' : 'download'}
                            color={color}
                            size={size}
                        />
                    )
                }}
            />
            <Drawer.Screen
                name='ColumnVolumeCalculation'
                component={ColumnVolumeCalculation}
                options={{
                    title: t('Volume de Coluna'),
                    drawerIcon: ({ focused, size, color }) => (
                        <MaterialCommunityIcons
                            name={focused ? 'cube-outline' : 'cube-outline'}
                            color={color}
                            size={size}
                        />
                    )
                }}
            />
            <Drawer.Screen
                name='AppTabRoutes'
                component={AppTabRoutes}
                options={{
                    title: t('Conversor de Unidades'),
                    drawerIcon: ({ focused, size, color }) => (
                        <FontAwesome5
                            name={focused ? 'exchange-alt' : 'exchange-alt'}
                            color={color}
                            size={size}
                        />
                    )
                }}
            />
            <Drawer.Screen
                name='NitrogenTanks'
                component={NitrogenTanks}
                options={{
                    title: t('Tanques Criogênicos'),
                    drawerIcon: ({ focused, size, color }) => (
                        <FontAwesome5
                            name={focused ? 'snowflake' : 'snowflake'}
                            color={color}
                            size={size}
                        />
                    )
                }}
            />
            <Drawer.Screen
                name='N2Converter'
                component={N2Converter}
                options={{
                    title: t('Dados do Nitrogênio'),
                    drawerIcon: ({ focused, size, color }) => (
                        <MaterialCommunityIcons
                            name={focused ? 'atom' : 'atom'}
                            color={color}
                            size={size}
                        />
                    )
                }}
            />

            <Drawer.Screen
                name='StackMyCalculation'
                component={StackMyCalculation}
                options={{
                    title: t('Meus Cálculos'),
                    drawerIcon: ({ focused, size, color }) => (
                        <MaterialCommunityIcons
                            name={focused ? 'calculator-variant' : 'calculator-variant'}
                            color={color}
                            size={size}
                        />
                    )
                }}
            />

            <Drawer.Screen
                name='Settings'
                component={Settings}
                options={{
                    title: t('Configurações'),
                    drawerIcon: ({ focused, size, color }) => (
                        <Feather
                            name={focused ? 'settings' : 'settings'}
                            color={color}
                            size={size}
                        />
                    )
                }}
            />
        </Drawer.Navigator >
    )
}

