import React, { useState, useEffect } from 'react';
import {
    FlatList,
    Keyboard,
    Modal,
    Platform,
    View,
    Dimensions
} from 'react-native';

import {
    ButtonView,
    InputWrapper,
    TotalVolumeWrapper,
    Container,
    PressureSelectorWrapper,
    DepthContainer,
    Content,
    AnswerView,
    ListContainer,
    PasteCopy,
    PasteCopyButton,
    IconPaste,
    Footer,
    TotalVolume,
    Option,
    Options,
    OptionTitle,
    OptionContainer,
    InputView,
    TotalWrapper,
    MessageWrapper,
    ButtonNavigation,
    TextNavigation,
    ListContainerInch,
    HelpContainer,
    VolumeSelectorContainer,
    OptionContainerTotal,
    ContainerModal,
    ScrollContainer,
    ScrollWrapper,
    PressableContainer,
    PressableText,
    IconContainer,
    Title,
    Message,
    PressableContainerJobs,
    Divider,
    ContainerModalTop,
    PressableContainerTypeColumn,
    PressableContainerTypeColumnBack,
    PressableTextTypeColumn,
    OptionTubeType,
    OptionTitleTubeType,
    ButtonViewTypeColumn,
    PressableTextTypeColumnLineBreake,
    EmptyMessageWrapper,
    EmptyMessage,
    PressableContainerSave,
    PressableTextSave,
    Wrapper,
    TextID,
} from './styles';

import { useTheme } from 'styled-components/native';
import { useNavigation, useIsFocused } from '@react-navigation/native';

import {useTranslation} from 'react-i18next';

import { MaterialIcons } from '@expo/vector-icons';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { PressureSelector } from '../../components/PressureSelector';
import { AnswerCard } from '../../components/AnswerCard';
import { ClearButton } from '../../components/ClearButton';

import uuid from 'react-native-uuid';

import * as Clipboard from 'expo-clipboard';
import { TemperatureSelectorConvert } from '../../components/TemperatureSelectorConvert';
import { convertInchFrations } from '../../utils/InchFractionToDecimal';
import { HelpButton } from '../../components/HelpButton';
import { PressurizationScreenModal } from '../../components/Modals/PressurizationScreenModal';
import { ColumnDiameterModal } from '../../components/Modals/ColumnDiameterModal';
import { HeaderHydrostaticPressure } from '../../components/HeaderHydrostaticPressure';
import { BlurView } from 'expo-blur';

import { casingAPI5CT, tubingAPI5CT, drillPipeAPI5DP, coiltube, casingSizes, tubingSizes, drillPipeSizes, coilTubeSizes } from '../../utils/TubingDimensions/APIspec';

import { Feather } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';
import { BackButton } from '../../components/BackButton';
import { TemperatureSelector } from '../../components/TemperatureSelector';
import { ClearTypeColumnButton } from '../../components/ClearTypeColumnButton';
import { ForwardArrow } from '../../components/ForwardArrow';

interface PropsList {
    id: string;
    answerItem: number;
    depthItem: string;
    depthSelectedItem: string;
    diameterItem: string;
    diameterIn?: string;
    typeColumn: string;
    typeColumnIn?: string;

}



export function ColumnVolumeCalculation() {

    const [option, setOption] = useState<'single' | 'anular' | 'total'>('single');

    const [typeColumnOption, setTypeColumnOption] = useState<'casing' | 'tubing' | 'drillPipe' | 'coilTube'>('casing');

    const [typeColumnOptionAnularOutside, setTypeColumnOptionAnularOutside] = useState<'casing' | 'tubing' | 'drillPipe' | 'coilTube'>('casing');

    const [typeColumnOptionAnularInside, setTypeColumnOptionAnularInside] = useState<'casing' | 'tubing' | 'drillPipe' | 'coilTube'>('casing');



    const [diameter, setDiameter] = useState('');
    const [depth, setDepth] = useState('');
    const [answer, setAnswer] = useState(0);

    const {t} = useTranslation();

    const [answerList, setAnswerList] = useState<PropsList[]>([]);

    const [depthTypeSelected, setDepthTypeSelected] = useState<'m' | 'ft'>('m');

    const [pasted, setPasted] = useState(false);
    const [copiedText, setCopiedText] = useState('');

    const [diameterAnularOutside, setDiameterAnularOutside] = useState('');
    const [diameterAnularInside, setDiameterAnularInside] = useState('');
    const [depthAnular, setDepthAnular] = useState('');
    const [answerAnular, setAnswerAnular] = useState(0);


    const [depthTypeSelectedAnular, setDepthTypeSelectedAnular] = useState<'m' | 'ft'>('m');

    const [answerAnularList, setAnswerAnularList] = useState<PropsList[]>([]);

    const [inchFractionSelected, setInchFractionSelected] = useState('');

    const [inchFractionSelectedAnular, setInchFractionSelectedAnular] = useState('');
    const [inchFractionSelectedAnular2, setInchFractionSelectedAnular2] = useState('');

    const [openModalDepth, setOpenModalDepth] = useState(false);
    const [openModalDiameter, setOpenModalDiameter] = useState(false);


    const [openModalDepthAnular, setOpenModalDepthAnular] = useState(false);
    const [openModalDiameterAnularOutside, setOpenModalDiameterAnularOutside] = useState(false);
    const [openModalDiameterAnularInside, setOpenModalDiameterAnularInside] = useState(false);

    const [activeSaveButton, setActiveSaveButton] = useState(false);

    const [openModalSave, setOpenModalSave] = useState(false);
    const [openChooseJob, setOpenChooseJob] = useState(false);
    const [openChooseCalc, setOpenChooseCalc] = useState(false);


    const [newJob, setNewJob] = useState('');
    const [newCalc, setNewCalc] = useState('');

    const [calculations, setCalculations] = useState([]);


    const [modalTypeColumn, setModalTypeColumn] = useState(false);

    const [titleColumnSingle, setTitleColumnSingle] = useState('Tipo de coluna');

    const [modalTypeColumnAnularOutside, setModalTypeColumnAnularOutside] = useState(false);

    const [titleColumnAnularOutside, setTitleColumnAnularOutside] = useState('Tipo de coluna externa');

    const [modalTypeColumnAnularInside, setModalTypeColumnAnularInside] = useState(false);

    const [titleColumnAnularInside, setTitleColumnAnularInside] = useState('Tipo de coluna interna');



    const [sizeSelected, setSizeSelected] = useState('all');
    const [columnTypesDisplaySingle, setColumnTypesDisplaySingle] = useState(casingAPI5CT);


    const [sizeSelectedTubingTypesDisplaySingle, setSizeSelectedTubingTypesDisplaySingle] = useState('all');
    const [tubingTypesDisplaySingle, setTubingTypesDisplaySingle] = useState(tubingAPI5CT);


    const [sizeSelectedDrillPipeTypesDisplaySingle, setSizeSelectedDrillPipeTypesDisplaySingle] = useState('all');
    const [drillPipeTypesDisplaySingle, setDrillPipeTypesDisplaySingle] = useState(drillPipeAPI5DP);

    const [sizeSelectedCoilTubeTypesDisplaySingle, setSizeSelectedCoilTubeTypesDisplaySingle] = useState('all');
    const [coilTubeTypesDisplaySingle, setCoilTubeTypesDisplaySingle] = useState(coiltube);



    const [sizeSelectedAnularOutside, setSizeSelectedAnularOutside] = useState('all');
    const [columnTypesDisplayAnularOutside, setColumnTypesDisplayAnularOutside] = useState(casingAPI5CT);


    const [sizeSelectedTubingTypesDisplayAnularOutside, setSizeSelectedTubingTypesDisplayAnularOutside] = useState('all');
    const [tubingTypesDisplayAnularOutside, setTubingTypesDisplayAnularOutside] = useState(tubingAPI5CT);


    const [sizeSelectedDrillPipeTypesDisplayAnularOutside, setSizeSelectedDrillPipeTypesDisplayAnularOutside] = useState('all');
    const [drillPipeTypesDisplayAnularOutside, setDrillPipeTypesDisplayAnularOutside] = useState(drillPipeAPI5DP);

    const [sizeSelectedCoilTubeTypesDisplayAnularOutside, setSizeSelectedCoilTubeTypesDisplayAnularOutside] = useState('all');
    const [coilTubeTypesDisplayAnularOutside, setCoilTubeTypesDisplayAnularOutside] = useState(coiltube);



    const [sizeSelectedAnularInside, setSizeSelectedAnularInside] = useState('all');
    const [columnTypesDisplayAnularInside, setColumnTypesDisplayAnularInside] = useState(casingAPI5CT);


    const [sizeSelectedTubingTypesDisplayAnularInside, setSizeSelectedTubingTypesDisplayAnularInside] = useState('all');
    const [tubingTypesDisplayAnularInside, setTubingTypesDisplayAnularInside] = useState(tubingAPI5CT);


    const [sizeSelectedDrillPipeTypesDisplayAnularInside, setSizeSelectedDrillPipeTypesDisplayAnularInside] = useState('all');
    const [drillPipeTypesDisplayAnularInside, setDrillPipeTypesDisplayAnularInside] = useState(drillPipeAPI5DP);

    const [sizeSelectedCoilTubeTypesDisplayAnularInside, setSizeSelectedCoilTubeTypesDisplayAnularInside] = useState('all');
    const [coilTubeTypesDisplayAnularInside, setCoilTubeTypesDisplayAnularInside] = useState(coiltube);


    const theme = useTheme();

    const maxWidth = 750;

    const { navigate } = useNavigation();
    const screenIsFocus = useIsFocused();


    const navigation = useNavigation();

    function handleDepthTypeSelected(depthType: 'm' | 'ft') {
        setDepthTypeSelected(depthType);
    };

    function handleCalculation() {
        if (!diameter) {
            alert(t('Insira a diâmetro da coluna!'))
            return;
        } else if (!depth) {
            alert(t('Insira a profundidade!'))
            return;
        }

        const depthFormatted = depthTypeSelected === 'm' ? Number(depth.replace(',', '.')) * 3.281 : Number(depth.replace(',', '.'));
        const diameterFormatted = Number(diameter.replace(',', '.'));
        const answerForCalc = ((diameterFormatted * diameterFormatted) * depthFormatted) / 1029.4;

        const answerFormattedList: PropsList = {
            id: String(uuid.v4()),
            answerItem: answerForCalc,
            depthItem: depth.replace('.', ','),
            depthSelectedItem: depthTypeSelected,
            diameterItem: diameter.replace('.', ','),
            typeColumn: titleColumnSingle
        }

        setAnswerList([...answerList, answerFormattedList])

        setActiveSaveButton(true);

        Keyboard.dismiss();
    }

    function handleDeleteCardAnswerSingle(itemId) {
        const answerErased = answerList.filter(item => item.id !== itemId);
        setAnswerList(answerErased);
        console.log('testeButtonCard')
    }
    async function handleCopyToClipboard(answerCopied: string) {
        Clipboard.setString(answerCopied);

        const text = await Clipboard.getStringAsync();
        setCopiedText(text);
        setPasted(true)

    }

    async function handleCopyTotal() {
        Clipboard.setString(answer.toFixed(2));
        const text = await Clipboard.getStringAsync();
        setCopiedText(text);
        setPasted(true);
    }

    async function handleCopyTotalAnular() {
        Clipboard.setString(answerAnular.toFixed(2));
        const text = await Clipboard.getStringAsync();
        setCopiedText(text);
        setPasted(true);
    }

    async function handleCopyTotalAndAnular() {
        Clipboard.setString((Number(answer.toFixed(2)) + Number(answerAnular.toFixed(2))).toFixed(2));
        const text = await Clipboard.getStringAsync();
        setCopiedText(text);
        setPasted(true);
    }


    function handleOptionChange(optionSelected: 'single' | 'anular' | 'total') {
        setOption(optionSelected)
    }

    function handleTypeColumnOptionChange(optionSelected: 'casing' | 'tubing' | 'drillPipe' | 'coilTube') {
        setTypeColumnOption(optionSelected)
    }

    function handleAnularDepthTypeSelected(depthType: 'm' | 'ft') {
        setDepthTypeSelectedAnular(depthType);
    };

    function handleAnularCalculation() {
        if (!diameterAnularOutside) {
            alert(t('Insira o diâmetro interno da coluna externa!'))
            return;
        } else if (!diameterAnularInside) {
            alert(t('Insira o diâmetro externo da coluna interna!'))
            return;
        }
        else if (!depthAnular) {
            alert(t('Insira a profundidade do poço!'))
            return;
        }

        const depthFormatted = depthTypeSelectedAnular === 'm' ? Number(depthAnular.replace(',', '.')) * 3.281 : Number(depthAnular.replace(',', '.'));
        const diameterOutsideFormatted = Number(diameterAnularOutside.replace(',', '.'));
        const answerOutsideForCalc = ((diameterOutsideFormatted * diameterOutsideFormatted) * depthFormatted) / 1029.4;

        const diameterInsideFormatted = Number(diameterAnularInside.replace(',', '.'));
        const answerInsideForCalc = ((diameterInsideFormatted * diameterInsideFormatted) * depthFormatted) / 1029.4;

        const answerForCalc = answerOutsideForCalc - answerInsideForCalc;

        const answerFormattedList: PropsList = {
            id: String(uuid.v4()),
            answerItem: answerForCalc,
            depthItem: depthAnular.replace('.', ','),
            depthSelectedItem: depthTypeSelectedAnular,
            diameterItem: diameterAnularOutside.replace('.', ','),
            diameterIn: diameterAnularInside.replace('.', ','),
            typeColumn: titleColumnAnularOutside,
            typeColumnIn: titleColumnAnularInside,

        }

        setAnswerAnularList([...answerAnularList, answerFormattedList])

        setActiveSaveButton(true);

        Keyboard.dismiss();
    }

    function handleDeleteCardAnswerAnular(itemId) {
        const answerErased = answerAnularList.filter(item => item.id !== itemId);
        setAnswerAnularList(answerErased);
    }

    function handleNavigationVolumeSingle() {
        const volumeSingleColumn = answer.toFixed(2).replace('.', ',');
        navigate('AppStackThreeRoutes' as never, {
            screen: 'SurfacePressurizationCalculationPlus',
            params: { volumeSingleColumn }
        } as never);
    }

    function handleNavigationVolumeAnular() {
        const volumeAnularColumn = answerAnular.toFixed(2).replace('.', ',');
        navigate('AppStackThreeRoutes' as never, {
            screen: 'SurfacePressurizationCalculationPlus',
            params: { volumeAnularColumn }
        } as never);
    }

    function handleNavigationVolumeSingleAndAnular() {
        const volumeSinglePlusAnularColumn = (Number(answer.toFixed(2)) + Number(answerAnular.toFixed(2))).toFixed(2).replace('.', ',');
        navigate('AppStackThreeRoutes' as never, {
            screen: 'SurfacePressurizationCalculationPlus',
            params: { volumeSinglePlusAnularColumn }
        } as never);
    }

    function handleInchFractionSelected(fractionName: string, decimalNumber: number) {
        setInchFractionSelected(fractionName);
        const addNumber = decimalNumber;
        const diameterInputed = diameter ? Number(diameter) + addNumber : 0 + addNumber;

        setDiameter(String(diameterInputed));

    };

    function handleInchFractionSelectedAnular(fractionName: string, decimalNumber: number) {
        setInchFractionSelectedAnular(fractionName);
        const addNumber = decimalNumber;
        const diameterInputed = diameterAnularOutside ? Number(diameterAnularOutside) + addNumber : 0 + addNumber;

        setDiameterAnularOutside(String(diameterInputed));
    };

    function handleInchFractionSelectedAnular2(fractionName: string, decimalNumber: number) {
        setInchFractionSelectedAnular2(fractionName);
        const addNumber = decimalNumber;
        const diameterInputed = diameterAnularInside ? Number(diameterAnularInside) + addNumber : 0 + addNumber;

        setDiameterAnularInside(String(diameterInputed));
    };

    function handleOpenModalDepth() {
        setOpenModalDepth(true);
    }

    function handleOpenModalDiameter() {
        setOpenModalDiameter(true);
    }

    function handleOpenModalDepthAnular() {
        setOpenModalDepthAnular(true);
    }

    function handleOpenModalDiameterAnularOutside() {
        setOpenModalDiameterAnularOutside(true);
    }

    function handleOpenModalDiameterAnularInside() {
        setOpenModalDiameterAnularInside(true);
    }

    function handleOpenSaveModal() {
        setOpenModalSave(true);
    }

    async function handleSaveCalculation() {
        if (!newJob) {
            return alert(t('Selecione ou crie um trabalho!'))
        }
        if (!newCalc) {
            return alert(t('Crie um nome para o cálculo!'))
        }

        function getDateFomatted() {
            const data = new Date(),
                dia = data.getDate().toString(),
                diaF = (dia.length == 1) ? '0' + dia : dia,
                mes = (data.getMonth() + 1).toString(),
                mesF = (mes.length == 1) ? '0' + mes : mes,
                anoF = data.getFullYear();
            return diaF + "/" + mesF + "/" + anoF;
        }

        const dateFormatted = getDateFomatted();
        console.log('dateFormatted', dateFormatted)

        const newCalculation = {
            id: String(uuid.v4()),
            nameJob: newJob,
            nameCalc: newCalc,
            singleDepth: depth,
            singleDepthType: depthTypeSelected,
            singleDiameter: diameter,
            singleAnswer: answer.toFixed(2).replace('.', ','),
            singleCardsAnswer: answerList,
            anularDepth: depthAnular,
            anularDepthType: depthTypeSelectedAnular,
            anularDiameterOutside: diameterAnularOutside,
            anularDiameterInside: diameterAnularInside,
            anularAnswer: answerAnular.toFixed(2).replace('.', ','),
            anularCardsAnswer: answerAnularList,
            totalVolume: (Number(answer.toFixed(2)) + Number(answerAnular.toFixed(2))).toFixed(2).replace('.', ','),
            date: dateFormatted
        }

        try {
            const dataKey = `@nitrogencalculations:column_calc:admin`;
            const data = await AsyncStorage.getItem(dataKey);
            const currentData = data ? JSON.parse(data) : [];

            const dataFormatted = [
                ...currentData,
                newCalculation
            ]

            await AsyncStorage.setItem(dataKey, JSON.stringify(dataFormatted));
            console.log('Armazenamento realizado com sucesso', dataFormatted);
            setNewJob('');
            setNewCalc('');
            setOpenModalSave(false);
            alert(t('Salvo com sucesso!'))

        } catch (error) {
            console.log('Erro ao armazenar dados');

        }

    }

    async function loadJobNames() {

        const dataKey = `@nitrogencalculations:pressurization_calc:admin`;

        const response = await AsyncStorage.getItem(dataKey);

        const calculations = response ? JSON.parse(response) : [];

        const jobNames = calculations.map(item => item.nameJob)
        //console.log('map', jobNames);
        const jobNamesPressurization = [...new Set(jobNames)];
        //console.log('newSet', uniqueJobNames)


        const dataKeyHydro = `@nitrogencalculations:hydrostatic_calc:admin`;

        const responseHydro = await AsyncStorage.getItem(dataKeyHydro);

        const calculationsHydro = responseHydro ? JSON.parse(responseHydro) : [];

        const jobNamesHydro = calculationsHydro.map(item => item.nameJob)
        //console.log('map', jobNames);
        const jobNamesHydrostatic = [...new Set(jobNamesHydro)];
        //console.log('newSet', uniqueJobNames)

        const dataKeyColumn = `@nitrogencalculations:column_calc:admin`;

        const responseColumn = await AsyncStorage.getItem(dataKeyColumn);

        const calculationsColumn = responseColumn ? JSON.parse(responseColumn) : [];

        const jobNamesColumn = calculationsColumn.map(item => item.nameJob)
        //console.log('map', jobNames);
        const jobNamesVolumeColumn = [...new Set(jobNamesColumn)];
        //console.log('newSet', uniqueJobNames)

        const uniqueJobNames = [...new Set([...jobNamesPressurization, ...jobNamesHydrostatic, ...jobNamesVolumeColumn])];

        setCalculations(uniqueJobNames);
        setOpenChooseJob(true);

    }

    async function loadCalcNames() {
        const dataKey = `@nitrogencalculations:column_calc:admin`;

        const response = await AsyncStorage.getItem(dataKey);

        const calculations = response ? JSON.parse(response) : [];

        const calcNames = calculations.map(item => item.nameCalc);
        const uniqueCalcNames = [...new Set(calcNames)];

        setCalculations(uniqueCalcNames);
        setOpenChooseCalc(true);

    }

    function handleOpenModalTypeColumn() {
        setModalTypeColumn(true);
    }

    function handleOpenModalTypeColumnAnularOutside() {
        setModalTypeColumnAnularOutside(true);
    }

    function handleOpenModalTypeColumnAnularInside() {
        setModalTypeColumnAnularInside(true);
    }

    function handleTypeColumnSelectedSingle(diameterSelected: number, titleSizeSelected: string, titleWeightSelected: string, titleWeightEUSelected?: string, titleWeightIJSelected?: string) {
        const diameterSelectedFormatted = String(diameterSelected);
        const typeColumn = typeColumnOption === 'casing' ? 'casing' : typeColumnOption === 'drillPipe' ? 'drill pipe' : typeColumnOption === 'tubing' ? 'tubing' : 'flexitubo'
        setDiameter(diameterSelectedFormatted);
        if (typeColumnOption === 'tubing') {
            const titleTubing = `${typeColumn} ${titleSizeSelected}" - ${titleWeightSelected} NU ${titleWeightEUSelected ? `| ${titleWeightEUSelected} EU` : ''} ${titleWeightIJSelected ? `| ${titleWeightIJSelected} IJ` : ''} lbs/ft`
            setTitleColumnSingle(titleTubing)
        } else {
            setTitleColumnSingle(`${typeColumn} ${titleSizeSelected}" - ${titleWeightSelected} lbs/ft`)
        }
        setModalTypeColumn(false);
    }

    function handleTypeColumnSelectedAnularOutside(diameterSelected: number, titleSizeSelected: string, titleWeightSelected: string, titleWeightEUSelected?: string, titleWeightIJSelected?: string) {
        const diameterSelectedFormatted = String(diameterSelected);
        const typeColumn = typeColumnOption === 'casing' ? 'casing' : typeColumnOption === 'drillPipe' ? 'drill pipe' : typeColumnOption === 'tubing' ? 'tubing' : 'flexitubo'
        setDiameterAnularOutside(diameterSelectedFormatted);
        if (typeColumnOption === 'tubing') {
            const titleTubing = `${typeColumn} ${titleSizeSelected}" - ${titleWeightSelected} NU ${titleWeightEUSelected ? `| ${titleWeightEUSelected} EU` : ''} ${titleWeightIJSelected ? `| ${titleWeightIJSelected} IJ` : ''} lbs/ft`
            setTitleColumnAnularOutside(titleTubing)
        } else {
            setTitleColumnAnularOutside(`${typeColumn} ${titleSizeSelected}" - ${titleWeightSelected} lbs/ft`)
        }
        setModalTypeColumnAnularOutside(false);
    }

    function handleTypeColumnSelectedAnularInside(diameterSelected: number, titleSizeSelected: string, titleWeightSelected: string, titleWeightEUSelected?: string, titleWeightIJSelected?: string) {
        const diameterSelectedFormatted = String(diameterSelected);
        const typeColumn = typeColumnOption === 'casing' ? 'casing' : typeColumnOption === 'drillPipe' ? 'drill pipe' : typeColumnOption === 'tubing' ? 'tubing' : 'flexitubo'
        setDiameterAnularInside(diameterSelectedFormatted);
        if (typeColumnOption === 'tubing') {
            const titleTubing = `${typeColumn} ${titleSizeSelected}" - ${titleWeightSelected} NU ${titleWeightEUSelected ? `| ${titleWeightEUSelected} EU` : ''} ${titleWeightIJSelected ? `| ${titleWeightIJSelected} IJ` : ''} lbs/ft`
            setTitleColumnAnularInside(titleTubing)
        } else {
            setTitleColumnAnularInside(`${typeColumn} ${titleSizeSelected}" - ${titleWeightSelected} lbs/ft`)
        }
        setModalTypeColumnAnularInside(false);
    }

    function handleCasingSizeSelected(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelected('all');
            setColumnTypesDisplaySingle(casingAPI5CT);
            return
        } else {
            setSizeSelected(sizeSelected);
            const typesDisplay = casingAPI5CT.filter(item => item.titleSize === sizeSelected);
            setColumnTypesDisplaySingle(typesDisplay);
        }
    }

    function handleTubingSizeSelected(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedTubingTypesDisplaySingle('all');
            setTubingTypesDisplaySingle(tubingAPI5CT);
            return
        } else {
            setSizeSelectedTubingTypesDisplaySingle(sizeSelected);
            const typesDisplay = tubingAPI5CT.filter(item => item.titleSize === sizeSelected);
            setTubingTypesDisplaySingle(typesDisplay);
        }
    }

    function handleDrillPipeSizeSelected(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedDrillPipeTypesDisplaySingle('all');
            setDrillPipeTypesDisplaySingle(drillPipeAPI5DP);
            return
        } else {
            setSizeSelectedDrillPipeTypesDisplaySingle(sizeSelected);
            const typesDisplay = drillPipeAPI5DP.filter(item => item.titleSize === sizeSelected);
            setDrillPipeTypesDisplaySingle(typesDisplay);
        }
    }

    function handleCoilTubeSizeSelected(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedCoilTubeTypesDisplaySingle('all');
            setCoilTubeTypesDisplaySingle(coiltube);
            return
        } else {
            setSizeSelectedCoilTubeTypesDisplaySingle(sizeSelected);
            const typesDisplay = coiltube.filter(item => item.titleSize === sizeSelected);
            setCoilTubeTypesDisplaySingle(typesDisplay);
        }
    }



    function handleCasingSizeSelectedAnularOutside(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedAnularOutside('all');
            setColumnTypesDisplayAnularOutside(casingAPI5CT);
            return
        } else {
            setSizeSelectedAnularOutside(sizeSelected);
            const typesDisplay = casingAPI5CT.filter(item => item.titleSize === sizeSelected);
            setColumnTypesDisplayAnularOutside(typesDisplay);
        }
    }

    function handleTubingSizeSelectedAnularOutside(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedTubingTypesDisplayAnularOutside('all');
            setTubingTypesDisplayAnularOutside(tubingAPI5CT);
            return
        } else {
            setSizeSelectedTubingTypesDisplayAnularOutside(sizeSelected);
            const typesDisplay = tubingAPI5CT.filter(item => item.titleSize === sizeSelected);
            setTubingTypesDisplayAnularOutside(typesDisplay);
        }
    }

    function handleDrillPipeSizeSelectedAnularOutside(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedDrillPipeTypesDisplayAnularOutside('all');
            setDrillPipeTypesDisplayAnularOutside(drillPipeAPI5DP);
            return
        } else {
            setSizeSelectedDrillPipeTypesDisplayAnularOutside(sizeSelected);
            const typesDisplay = drillPipeAPI5DP.filter(item => item.titleSize === sizeSelected);
            setDrillPipeTypesDisplayAnularOutside(typesDisplay);
        }
    }

    function handleCoilTubeSizeSelectedAnularOutside(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedCoilTubeTypesDisplayAnularOutside('all');
            setCoilTubeTypesDisplayAnularOutside(coiltube);
            return
        } else {
            setSizeSelectedCoilTubeTypesDisplayAnularOutside(sizeSelected);
            const typesDisplay = coiltube.filter(item => item.titleSize === sizeSelected);
            setCoilTubeTypesDisplayAnularOutside(typesDisplay);
        }
    }



    function handleCasingSizeSelectedAnularInside(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedAnularInside('all');
            setColumnTypesDisplayAnularInside(casingAPI5CT);
            return
        } else {
            setSizeSelectedAnularInside(sizeSelected);
            const typesDisplay = casingAPI5CT.filter(item => item.titleSize === sizeSelected);
            setColumnTypesDisplayAnularInside(typesDisplay);
        }
    }

    function handleTubingSizeSelectedAnularInside(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedTubingTypesDisplayAnularInside('all');
            setTubingTypesDisplayAnularInside(tubingAPI5CT);
            return
        } else {
            setSizeSelectedTubingTypesDisplayAnularInside(sizeSelected);
            const typesDisplay = tubingAPI5CT.filter(item => item.titleSize === sizeSelected);
            setTubingTypesDisplayAnularInside(typesDisplay);
        }
    }

    function handleDrillPipeSizeSelectedAnularInside(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedDrillPipeTypesDisplayAnularInside('all');
            setDrillPipeTypesDisplayAnularInside(drillPipeAPI5DP);
            return
        } else {
            setSizeSelectedDrillPipeTypesDisplayAnularInside(sizeSelected);
            const typesDisplay = drillPipeAPI5DP.filter(item => item.titleSize === sizeSelected);
            setDrillPipeTypesDisplayAnularInside(typesDisplay);
        }
    }

    function handleCoilTubeSizeSelectedAnularInside(sizeSelected: string) {
        if (sizeSelected === 'all') {
            setSizeSelectedCoilTubeTypesDisplayAnularInside('all');
            setCoilTubeTypesDisplayAnularInside(coiltube);
            return
        } else {
            setSizeSelectedCoilTubeTypesDisplayAnularInside(sizeSelected);
            const typesDisplay = coiltube.filter(item => item.titleSize === sizeSelected);
            setCoilTubeTypesDisplayAnularInside(typesDisplay);
        }
    }

    function handleDeleteTypeColumnTitleSingle() {
        setTitleColumnSingle('Tipo de coluna');
    }

    function handleDeleteTypeColumnTitleAnularOutside() {
        setTitleColumnAnularOutside('Tipo de coluna externa');
    }

    function handleDeleteTypeColumnTitleAnularInside() {
        setTitleColumnAnularInside('Tipo de coluna interna');
    }






    function handleClearAll() {
        setDiameter('');
        setDepth('');
        setAnswer(0);
        setAnswerList([]);
        setDepthTypeSelected('m');
        setPasted(false);
        setInchFractionSelected('');
        setTitleColumnSingle('Tipo de coluna');
        setTypeColumnOption('casing');
    }

    function handleClearAnular() {
        setDiameterAnularOutside('');
        setDiameterAnularInside('');
        setDepthAnular('');
        setAnswerAnular(0);
        setAnswerAnularList([]);
        setDepthTypeSelectedAnular('m');
        setPasted(false);
        setInchFractionSelectedAnular('');
        setInchFractionSelectedAnular2('');
        setTypeColumnOption('casing');
        setTitleColumnAnularOutside('Tipo de coluna externa');
        setTitleColumnAnularInside('Tipo de coluna interna');
    }

    function handleClearSingleAndAnular() {
        setDiameter('');
        setDepth('');
        setAnswer(0);
        setAnswerList([]);
        setDepthTypeSelected('m');
        setPasted(false);
        setInchFractionSelected('');
        setDiameterAnularOutside('');
        setDiameterAnularInside('');
        setDepthAnular('');
        setAnswerAnular(0);
        setAnswerAnularList([]);
        setDepthTypeSelectedAnular('m');
        setInchFractionSelectedAnular('');
        setInchFractionSelectedAnular2('');
        setActiveSaveButton(false);
        setTitleColumnSingle('Tipo de coluna');
        setTypeColumnOption('casing');
        setTitleColumnAnularOutside('Tipo de coluna externa');
        setTitleColumnAnularInside('Tipo de coluna interna');
    }

    const ItemDivider = () => {
        return (
            <Divider>
                <Feather
                    name='plus'
                    color={theme.colors.site_600}
                    size={Dimensions.get('window').width < maxWidth ? RFValue(24) : 24}
                />
            </Divider>

        );
    }

    useEffect(() => {
        function SumAnswer() {
            let sumAnswer = 0;
            answerList.map(item => {
                sumAnswer += item.answerItem;
            })
            return sumAnswer;
        };

        const sumFormatted = SumAnswer();

        setAnswer(sumFormatted);
    }, [answerList]);

    useEffect(() => {
        function SumAnswer() {
            let sumAnswer = 0;
            answerAnularList.map(item => {
                sumAnswer += item.answerItem;
            })
            return sumAnswer;
        };

        const sumFormatted = SumAnswer();

        setAnswerAnular(sumFormatted);
    }, [answerAnularList])

    return (
        <Container
        >
            <HeaderHydrostaticPressure
                title={t(`Volume de Coluna`)}
                handleSave={handleOpenSaveModal}
                saveActive={activeSaveButton}
            />
            <Options>

                <Option
                    active={option === 'single'}
                    onPress={() => handleOptionChange('single')}
                >
                    <OptionTitle active={option === 'single'}>
                        {t('Simples')}
                    </OptionTitle>
                </Option>
                <Option
                    active={option === 'anular'}
                    onPress={() => handleOptionChange('anular')}
                >
                    <OptionTitle active={option === 'anular'}>
                        {t('Anular')}
                    </OptionTitle>
                </Option>
                <Option
                    active={option === 'total'}
                    onPress={() => handleOptionChange('total')}
                >
                    <OptionTitle active={option === 'total'}>
                        {t('Total')}
                    </OptionTitle>
                </Option>
            </Options>

            {
                option === 'single'
                    ?
                    <OptionContainer>
                        <ButtonView>
                            <BackButton
                                color={theme.colors.main}
                                onPress={() => navigation.goBack()} />
                            <ClearButton
                                onPress={handleClearAll}
                            />
                        </ButtonView>
                        <Content>
                            <InputWrapper>
                                <DepthContainer>
                                    <Input
                                        iconName='ruler'
                                        placeholder={t('Digite o comprimento da coluna')}
                                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                        value={depth}
                                        onChangeText={(text) => setDepth(text)}
                                    />
                                    <PressureSelectorWrapper>
                                        <VolumeSelectorContainer>
                                            <PressureSelector
                                                title='m'
                                                active={'m' === depthTypeSelected}
                                                onPress={() => { handleDepthTypeSelected('m') }}
                                            />
                                            <PressureSelector
                                                title='ft'
                                                active={'ft' === depthTypeSelected}
                                                onPress={() => { handleDepthTypeSelected('ft') }}
                                            />
                                        </VolumeSelectorContainer>
                                        <HelpContainer>
                                            <HelpButton
                                                helpButton={handleOpenModalDepth}
                                            />
                                        </HelpContainer>
                                    </PressureSelectorWrapper>
                                </DepthContainer>
                                <PressableContainerTypeColumn onPress={handleOpenModalTypeColumn}>
                                    <PressableTextTypeColumnLineBreake>{t(titleColumnSingle)}</PressableTextTypeColumnLineBreake>
                                    <ClearTypeColumnButton onPress={handleDeleteTypeColumnTitleSingle} />
                                </PressableContainerTypeColumn>

                                <Input
                                    iconName='diameter-variant'
                                    placeholder={t('Digite o ID em polegadas')}
                                    keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                    value={diameter}
                                    onChangeText={(text) => setDiameter(text)}
                                />
                                <HelpContainer>
                                    <HelpButton
                                        helpButton={handleOpenModalDiameter}
                                    />
                                </HelpContainer>
                            </InputWrapper>
                        </Content>
                        <ListContainer>
                            <FlatList
                                data={answerList}
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                keyExtractor={item => item.id}
                                renderItem={({ item }) =>
                                (
                                    <AnswerView>
                                        <AnswerCard
                                            iconName='cube'
                                            title={`${item.typeColumn === 'Tipo de coluna' ? `${t('Volume em')} ${item.depthItem} ${item.depthSelectedItem} ${t('de')}${'\n'}${t('tubo com ID de')} ${item.diameterItem}"` : `${t('Volume em')} ${item.depthItem} ${item.depthSelectedItem} ${t('de')}${'\n'}${item.typeColumn}`}`}
                                            depthType={item.depthSelectedItem}
                                            answer={`${item.answerItem.toFixed(2).replace('.', ',')} bbl`}
                                            color={theme.colors.backgroud_secondary}
                                            pasted={pasted && item.answerItem.toFixed(2) === copiedText}
                                            copyButton={() => handleCopyToClipboard(item.answerItem.toFixed(2))}
                                            deleteButton={() => handleDeleteCardAnswerSingle(item.id)}
                                        />
                                    </AnswerView>
                                )

                                }
                                ItemSeparatorComponent={ItemDivider}
                            />
                        </ListContainer>
                        {answer ?
                            <TotalWrapper>
                                <TotalVolumeWrapper>
                                    <TotalVolume>{t('TOTAL: ')}   {answer.toFixed(2).replace('.', ',')} bbl</TotalVolume>
                                    <PasteCopy>
                                        <PasteCopyButton onPress={handleCopyTotal}>
                                            {
                                                pasted && answer.toFixed(2) === copiedText
                                                    ?
                                                    <IconPaste
                                                        name='clipboard-check-multiple-outline'
                                                        color={theme.colors.site_700}
                                                    />
                                                    :
                                                    <IconPaste
                                                        name='clipboard-multiple-outline'
                                                        color={theme.colors.site_700}
                                                    />
                                            }
                                        </PasteCopyButton>
                                    </PasteCopy>
                                </TotalVolumeWrapper>
                                <MessageWrapper>
                                    <ButtonNavigation onPress={handleNavigationVolumeSingle}>
                                        <TextNavigation>{t('Clique aqui para exportar o volume de coluna para a tela de Operações N2!')}          {'>>>'} </TextNavigation>
                                    </ButtonNavigation>
                                </MessageWrapper>
                            </TotalWrapper>
                            : <TotalVolumeWrapper></TotalVolumeWrapper>
                        }


                        <Footer>
                            <Button
                                title={answer ? t('CALCULAR E SOMAR') : t('CALCULAR')}
                                onPress={handleCalculation}
                            />
                        </Footer>
                    </OptionContainer>

                    :
                    option === 'anular'
                        ?

                        <OptionContainer>
                            <ButtonView>
                                <BackButton
                                    color={theme.colors.main}
                                    onPress={() => navigation.goBack()} />
                                <ClearButton
                                    onPress={handleClearAnular}
                                />
                            </ButtonView>
                            <Content>
                                <InputWrapper>
                                    <DepthContainer>
                                        <Input
                                            iconName='ruler'
                                            placeholder={t('Digite o comprimento da coluna')}
                                            keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                            value={depthAnular}
                                            onChangeText={(text) => setDepthAnular(text)}
                                        />
                                        <PressureSelectorWrapper>
                                            <VolumeSelectorContainer>
                                                <PressureSelector
                                                    title='m'
                                                    active={'m' === depthTypeSelectedAnular}
                                                    onPress={() => { handleAnularDepthTypeSelected('m') }}
                                                />
                                                <PressureSelector
                                                    title='ft'
                                                    active={'ft' === depthTypeSelectedAnular}
                                                    onPress={() => { handleAnularDepthTypeSelected('ft') }}
                                                />
                                            </VolumeSelectorContainer>
                                            <HelpContainer>
                                                <HelpButton
                                                    helpButton={handleOpenModalDepthAnular}
                                                />
                                            </HelpContainer>
                                        </PressureSelectorWrapper>
                                    </DepthContainer>
                                    <PressableContainerTypeColumn onPress={handleOpenModalTypeColumnAnularOutside}>
                                        <PressableTextTypeColumnLineBreake>{t(titleColumnAnularOutside)}</PressableTextTypeColumnLineBreake>
                                        <ClearTypeColumnButton onPress={handleDeleteTypeColumnTitleAnularOutside} />
                                    </PressableContainerTypeColumn>
                                    <Input
                                        iconName='diameter-variant'
                                        placeholder={t('Digite o ID da coluna externa')}
                                        keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                        value={diameterAnularOutside}
                                        onChangeText={(text) => setDiameterAnularOutside(text)}
                                    />
                                    <HelpContainer>
                                        <HelpButton
                                            helpButton={handleOpenModalDiameterAnularOutside}
                                        />
                                    </HelpContainer>
                                    <InputView>
                                        <PressableContainerTypeColumn onPress={handleOpenModalTypeColumnAnularInside}>
                                            <PressableTextTypeColumnLineBreake>{t(titleColumnAnularInside)}</PressableTextTypeColumnLineBreake>
                                            <ClearTypeColumnButton onPress={handleDeleteTypeColumnTitleAnularInside} />
                                        </PressableContainerTypeColumn>
                                        <Input
                                            iconName='diameter-variant'
                                            placeholder={t('Digite o OD da coluna interna')}
                                            keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                            value={diameterAnularInside}
                                            onChangeText={(text) => setDiameterAnularInside(text)}
                                        />
                                        <HelpContainer>
                                            <HelpButton
                                                helpButton={handleOpenModalDiameterAnularInside}
                                            />
                                        </HelpContainer>
                                    </InputView>
                                </InputWrapper>
                            </Content>
                            <ListContainer>
                                <FlatList
                                    data={answerAnularList}
                                    horizontal
                                    showsHorizontalScrollIndicator={false}
                                    keyExtractor={item => item.id}
                                    renderItem={({ item }) =>
                                    (
                                        <AnswerView>
                                            <AnswerCard
                                                iconName='cube'
                                                title={`${item.typeColumn === 'Tipo de coluna externa' && item.typeColumnIn === 'Tipo de coluna interna' ? `${t('Volume em anular com')} ${item.depthItem} ${item.depthSelectedItem} ${t('e coluna externa de ID')} ${item.diameterItem}" ${t('e interna de OD')} ${item.diameterIn}"` : item.typeColumn !== 'Tipo de coluna externa' && item.typeColumnIn === 'Tipo de coluna interna' ? `${t('Volume em anular com')} ${item.depthItem} ${item.depthSelectedItem} ${t('entre')} ${item.typeColumn} ${t('e tubo com OD de')} ${item.diameterIn}"` : item.typeColumn === 'Tipo de coluna externa' && item.typeColumnIn !== 'Tipo de coluna interna' ? `${t('Volume em anular com')} ${item.depthItem} ${item.depthSelectedItem} ${t('entre tubo com ID de')} ${item.diameterItem}" ${t('e')} ${item.typeColumnIn}` : `${t('Volume em anular com')} ${item.depthItem} ${item.depthSelectedItem} ${t('entre')} ${item.typeColumn}" ${t('e')} ${item.typeColumnIn}`}`}
                                                depthType={item.depthSelectedItem}
                                                answer={`${item.answerItem.toFixed(2).replace('.', ',')} bbl`}
                                                color={theme.colors.backgroud_secondary}
                                                pasted={pasted && item.answerItem.toFixed(2) === copiedText}
                                                copyButton={() => handleCopyToClipboard(item.answerItem.toFixed(2))}
                                                deleteButton={() => handleDeleteCardAnswerAnular(item.id)}
                                            />
                                        </AnswerView>
                                    )

                                    }
                                    ItemSeparatorComponent={ItemDivider}

                                />
                            </ListContainer>
                            {answerAnular ?
                                <TotalWrapper>
                                    <TotalVolumeWrapper>
                                        <TotalVolume>{t('TOTAL: ')}   {answerAnular.toFixed(2).replace('.', ',')} bbl</TotalVolume>
                                        <PasteCopy>
                                            <PasteCopyButton onPress={handleCopyTotalAnular}>
                                                {
                                                    pasted && answerAnular.toFixed(2) === copiedText
                                                        ?
                                                        <IconPaste
                                                            name='clipboard-check-multiple-outline'
                                                            color={theme.colors.site_700}
                                                        />
                                                        :
                                                        <IconPaste
                                                            name='clipboard-multiple-outline'
                                                            color={theme.colors.site_700}
                                                        />
                                                }
                                            </PasteCopyButton>
                                        </PasteCopy>
                                    </TotalVolumeWrapper>
                                    <MessageWrapper>
                                        <ButtonNavigation onPress={handleNavigationVolumeAnular}>
                                            <TextNavigation>{t('Clique aqui para exportar o volume de coluna para a tela de Operações N2!')}          {'>>>'} </TextNavigation>
                                        </ButtonNavigation>
                                    </MessageWrapper>
                                </TotalWrapper>
                                : <TotalVolumeWrapper></TotalVolumeWrapper>
                            }


                            <Footer>
                                <Button
                                    title={answerAnular ? t('CALCULAR E SOMAR') : t('CALCULAR')}
                                    onPress={handleAnularCalculation}
                                />
                            </Footer>
                        </OptionContainer>
                        :
                        <OptionContainerTotal>
                            <ButtonView>
                                <BackButton
                                    color={theme.colors.main}
                                    onPress={() => navigation.goBack()} />
                                <ClearButton
                                    onPress={handleClearSingleAndAnular}
                                />
                            </ButtonView>
                            <ListContainer>
                                <FlatList
                                    data={answerList}
                                    horizontal
                                    showsHorizontalScrollIndicator={false}
                                    keyExtractor={item => item.id}
                                    renderItem={({ item }) =>
                                    (
                                        <AnswerView>
                                            <AnswerCard
                                                iconName='cube'
                                                title={`${item.typeColumn === 'Tipo de coluna' ? `${t('Volume em')} ${item.depthItem} ${item.depthSelectedItem} ${t('de')}${'\n'}${t('tubo com ID de')} ${item.diameterItem}"` : `${t('Volume em')} ${item.depthItem} ${item.depthSelectedItem} ${t('de')}${'\n'}${item.typeColumn}`}`}
                                                depthType={item.depthSelectedItem}
                                                answer={`${item.answerItem.toFixed(2).replace('.', ',')} bbl`}
                                                color={theme.colors.backgroud_secondary}
                                                pasted={pasted && item.answerItem.toFixed(2) === copiedText}
                                                copyButton={() => handleCopyToClipboard(item.answerItem.toFixed(2))}
                                                deleteButton={() => handleDeleteCardAnswerSingle(item.id)}

                                            />
                                        </AnswerView>
                                    )

                                    }
                                    ItemSeparatorComponent={ItemDivider}

                                />
                            </ListContainer>
                            <ListContainer>
                                <FlatList
                                    data={answerAnularList}
                                    horizontal
                                    showsHorizontalScrollIndicator={false}
                                    keyExtractor={item => item.id}
                                    renderItem={({ item }) =>
                                    (
                                        <AnswerView>
                                            <AnswerCard
                                                iconName='cube'
                                                title={`${item.typeColumn === 'Tipo de coluna externa' && item.typeColumnIn === 'Tipo de coluna interna' ? `${t('Volume em anular com')} ${item.depthItem} ${item.depthSelectedItem} ${t('e coluna externa de ID')} ${item.diameterItem}" ${t('e interna de OD')} ${item.diameterIn}"` : item.typeColumn !== 'Tipo de coluna externa' && item.typeColumnIn === 'Tipo de coluna interna' ? `${t('Volume em anular com')} ${item.depthItem} ${item.depthSelectedItem} ${t('entre')} ${item.typeColumn} ${t('e tubo com OD de')} ${item.diameterIn}"` : item.typeColumn === 'Tipo de coluna externa' && item.typeColumnIn !== 'Tipo de coluna interna' ? `${t('Volume em anular com')} ${item.depthItem} ${item.depthSelectedItem} ${t('entre tubo com ID de')} ${item.diameterItem}" ${t('e')} ${item.typeColumnIn}` : `${t('Volume em anular com')} ${item.depthItem} ${item.depthSelectedItem} ${t('entre')} ${item.typeColumn}" ${t('e')} ${item.typeColumnIn}`}`}
                                                depthType={item.depthSelectedItem}
                                                answer={`${item.answerItem.toFixed(2).replace('.', ',')} bbl`}
                                                color={theme.colors.backgroud_secondary}
                                                pasted={pasted && item.answerItem.toFixed(2) === copiedText}
                                                copyButton={() => handleCopyToClipboard(item.answerItem.toFixed(2))}
                                                deleteButton={() => handleDeleteCardAnswerAnular(item.id)}

                                            />
                                        </AnswerView>
                                    )

                                    }
                                    ItemSeparatorComponent={ItemDivider}

                                />
                            </ListContainer>
                            {answer ?
                                <TotalWrapper>
                                    <TotalVolumeWrapper>
                                        <TotalVolume>{t('TOTAL')} {t('SIMPLES')}:   {answer.toFixed(2).replace('.', ',')} bbl</TotalVolume>
                                        <PasteCopy>
                                            <PasteCopyButton onPress={handleCopyTotal}>
                                                {
                                                    pasted && answer.toFixed(2) === copiedText
                                                        ?
                                                        <IconPaste
                                                            name='clipboard-check-multiple-outline'
                                                            color={theme.colors.site_700}
                                                        />
                                                        :
                                                        <IconPaste
                                                            name='clipboard-multiple-outline'
                                                            color={theme.colors.site_700}
                                                        />
                                                }
                                            </PasteCopyButton>
                                        </PasteCopy>
                                    </TotalVolumeWrapper>
                                </TotalWrapper>
                                : <TotalVolumeWrapper></TotalVolumeWrapper>
                            }
                            {answerAnular ?
                                <TotalWrapper>
                                    <TotalVolumeWrapper>
                                        <TotalVolume>{t('TOTAL')} {t('ANULAR')}:   {answerAnular.toFixed(2).replace('.', ',')} bbl</TotalVolume>
                                        <PasteCopy>
                                            <PasteCopyButton onPress={handleCopyTotalAnular}>
                                                {
                                                    pasted && answerAnular.toFixed(2) === copiedText
                                                        ?
                                                        <IconPaste
                                                            name='clipboard-check-multiple-outline'
                                                            color={theme.colors.site_700}
                                                        />
                                                        :
                                                        <IconPaste
                                                            name='clipboard-multiple-outline'
                                                            color={theme.colors.site_700}
                                                        />
                                                }
                                            </PasteCopyButton>
                                        </PasteCopy>
                                    </TotalVolumeWrapper>
                                </TotalWrapper>
                                : <TotalVolumeWrapper></TotalVolumeWrapper>
                            }
                            {answerAnular && answer ?
                                <TotalWrapper>
                                    <TotalVolumeWrapper>
                                        <TotalVolume>{t('SIMPLES')} + {t('ANULAR')}:   {(Number(answer.toFixed(2)) + Number(answerAnular.toFixed(2))).toFixed(2).replace('.', ',')}</TotalVolume>
                                        <PasteCopy>
                                            <PasteCopyButton onPress={handleCopyTotalAndAnular}>
                                                {
                                                    pasted && (Number(answer.toFixed(2)) + Number(answerAnular.toFixed(2))).toFixed(2) === copiedText
                                                        ?
                                                        <IconPaste
                                                            name='clipboard-check-multiple-outline'
                                                            color={theme.colors.site_700}
                                                        />
                                                        :
                                                        <IconPaste
                                                            name='clipboard-multiple-outline'
                                                            color={theme.colors.site_700}
                                                        />
                                                }
                                            </PasteCopyButton>
                                        </PasteCopy>
                                    </TotalVolumeWrapper>
                                    <MessageWrapper>
                                        <ButtonNavigation onPress={handleNavigationVolumeSingleAndAnular}>
                                            <TextNavigation>{t('Clique aqui para exportar o volume total para a tela de Operações N2!')}         {'>>>'}</TextNavigation>
                                        </ButtonNavigation>
                                    </MessageWrapper>
                                </TotalWrapper>
                                : <TotalVolumeWrapper></TotalVolumeWrapper>
                            }
                        </OptionContainerTotal>

            }
            <Modal
                visible={openModalDepth}
                animationType={'slide'}
                onRequestClose={() => setOpenModalDepth(false)}
                transparent
            >
                <PressurizationScreenModal
                    title={t('Profundidade')}
                    iconName={'ruler'}
                    text={`${t('Nesse campo deve ser inserido a profundidade da coluna.')}${'\n'}${'\n'}${t('A profundidade pode ser inserida em metro (m) ou pés (ft), selecione no botão embaixo da caixa de texto a unidade que deseja utilizar.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                    handleCloseModal={() => setOpenModalDepth(false)}
                />
            </Modal>

            <Modal
                visible={openModalDiameter}
                animationType={'slide'}
                onRequestClose={() => setOpenModalDiameter(false)}
                transparent
            >

                <ColumnDiameterModal
                    title={t('Diâmetro da coluna')}
                    iconName={'diameter-variant'}
                    text={`${t('Nesse campo deve ser inserido o diâmetro interno da coluna.')}${'\n'}${'\n'}${t('O diâmetro interno deve ser inserido em polegada decimal (por exemplo 6.625), caso não saiba o valor em polegada decimal, mas saiba em fracionária (por exemplo 6 5/8), digite o numero inteiro na caixa de texto abaixo e selecione no botão embaixo da caixa de texto o valor da fração desejada.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                    handleCloseModal={() => setOpenModalDiameter(false)}
                    value={diameter}
                    onChangeText={(text) => setDiameter(text)}
                    onPressed={(x, y) => { handleInchFractionSelected(x, y) }}
                />

            </Modal>

            <Modal
                visible={openModalDepthAnular}
                animationType={'slide'}
                onRequestClose={() => setOpenModalDepthAnular(false)}
                transparent
            >
                <PressurizationScreenModal
                    title={t('Profundidade')}
                    iconName={'ruler'}
                    text={`${t('Nesse campo deve ser inserido a profundidade do anular.')}${'\n'}${'\n'}${t('A profundidade pode ser inserida em metro (m) ou pés (ft), selecione no botão embaixo da caixa de texto a unidade que deseja utilizar.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                    handleCloseModal={() => setOpenModalDepthAnular(false)}
                />
            </Modal>

            <Modal
                visible={openModalDiameterAnularOutside}
                animationType={'slide'}
                onRequestClose={() => setOpenModalDiameterAnularOutside(false)}
                transparent
            >
                <ColumnDiameterModal
                    title={t('Diâmetro da coluna')}
                    iconName={'diameter-variant'}
                    text={`${t('Nesse campo deve ser inserido o diâmetro interno (ID) da coluna externa.')}${'\n'}${'\n'}${t('O diâmetro interno deve ser inserido em polegada decimal (por exemplo 6.625), caso não saiba o valor em polegada decimal, mas saiba em fracionária (por exemplo 6 5/8), digite o numero inteiro na caixa de texto abaixo e selecione no botão embaixo da caixa de texto o valor da fração desejada.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                    handleCloseModal={() => setOpenModalDiameterAnularOutside(false)}
                    value={diameterAnularOutside}
                    onChangeText={(text) => setDiameterAnularOutside(text)}
                    onPressed={(x, y) => { handleInchFractionSelectedAnular(x, y) }}
                />
            </Modal>

            <Modal
                visible={openModalDiameterAnularInside}
                animationType={'slide'}
                onRequestClose={() => setOpenModalDiameterAnularInside(false)}
                transparent
            >
                <ColumnDiameterModal
                    title={t('Diâmetro da coluna')}
                    iconName={'diameter-variant'}
                    text={`${t('Nesse campo deve ser inserido o diâmetro externo (OD) da coluna interna.')}${'\n'}${'\n'}${t('O diâmetro externo deve ser inserido em polegada decimal (por exemplo 6.625), caso não saiba o valor em polegada decimal, mas saiba em fracionária (por exemplo 6 5/8), digite o numero inteiro na caixa de texto abaixo e selecione no botão embaixo da caixa de texto o valor da fração desejada.')}${'\n'}${'\n'}${t('Preenchimento obrigatório!')}`}
                    handleCloseModal={() => setOpenModalDiameterAnularInside(false)}
                    value={diameterAnularInside}
                    onChangeText={(text) => setDiameterAnularInside(text)}
                    onPressed={(x, y) => { handleInchFractionSelectedAnular2(x, y) }}
                />
            </Modal>

            <Modal
                visible={openModalSave}
                animationType={'slide'}
                onRequestClose={() => setOpenModalSave(false)}
            >

                <ContainerModalTop showsVerticalScrollIndicator={false}>
                    <ScrollContainer showsVerticalScrollIndicator={false}>
                        <ScrollWrapper>
                            <IconContainer >
                                <MaterialIcons
                                    name='save'
                                    size={50}
                                    color={theme.colors.site_500}

                                />
                            </IconContainer>
                            <Title>{t('Salvar')}</Title>
                            <Message>{t('Escolha ou crie um trabalho para salvar seu cálculo')}</Message>
                            <PressableContainerSave onPress={loadJobNames}>
                                <PressableTextSave>{t('Escolher trabalho existente')}</PressableTextSave>
                                <ForwardArrow />
                            </PressableContainerSave>
                            <Input
                                iconName='label'
                                placeholder={t('Nome trabalho')}
                                keyboardType='default'
                                value={newJob}
                                onChangeText={(text) => setNewJob(text)}
                            />

                            <Message>{t('Dê um nome para esse cálculo (Ex: Deslocamento de DPR):')}</Message>
                            <PressableContainerSave onPress={loadCalcNames}>
                                <PressableTextSave>{t('Escolher nome existente')}</PressableTextSave>
                                <ForwardArrow />
                            </PressableContainerSave>
                            <Input
                                iconName='label'
                                placeholder={t('Nome do cálculo')}
                                keyboardType='default'
                                value={newCalc}
                                onChangeText={(text) => setNewCalc(text)}
                            />



                            <PressableContainer onPress={handleSaveCalculation}>
                                <PressableText>{t('SALVAR E SAIR')}</PressableText>
                            </PressableContainer>

                            <PressableContainerJobs onPress={() => setOpenModalSave(false)}>
                                <PressableText>{t('SAIR SEM SALVAR')}</PressableText>
                            </PressableContainerJobs>
                        </ScrollWrapper>
                    </ScrollContainer>
                </ContainerModalTop>


                <Modal
                    visible={openChooseJob}
                    animationType={'slide'}
                    onRequestClose={() => setOpenChooseJob(false)}
                    transparent
                >
                    <BlurView intensity={200} style={{ flex: 1 }}>

                        <ContainerModal showsVerticalScrollIndicator={false}>
                            <ScrollContainer showsVerticalScrollIndicator={false}>
                                <ScrollWrapper>
                                    <IconContainer >
                                        <MaterialIcons
                                            name='save'
                                            size={50}
                                            color={theme.colors.site_500}
                                        />
                                    </IconContainer>
                                    <Title>{t('Trabalhos')}</Title>
                                    <Message>{t('Escolha um dos trabalhos abaixo para vincular seu cálculo')}</Message>

                                    <FlatList
                                        data={calculations}
                                        keyExtractor={(item) => item}
                                        renderItem={({ item }) => (
                                            <PressableContainerJobs onPress={() => {
                                                setNewJob(item)
                                                setOpenChooseJob(false)
                                            }}>
                                                <PressableText>{item}</PressableText>
                                            </PressableContainerJobs>
                                        )
                                        }
                                        showsVerticalScrollIndicator={false}
                                        ListEmptyComponent={(
                                            <EmptyMessageWrapper>
                                                <EmptyMessage>{t('Nenhum trabalho criado')}</EmptyMessage>
                                            </EmptyMessageWrapper>
                                        )}
                                        inverted={true}
                                    />
                                    <PressableContainer onPress={() => setOpenChooseJob(false)}>
                                        <PressableText>{t('VOLTAR')}</PressableText>
                                    </PressableContainer>
                                </ScrollWrapper>
                            </ScrollContainer>
                        </ContainerModal>

                    </BlurView>

                </Modal>

                <Modal
                    visible={openChooseCalc}
                    animationType={'slide'}
                    onRequestClose={() => setOpenChooseCalc(false)}
                    transparent
                >
                    <BlurView intensity={200} style={{ flex: 1 }}>

                        <ContainerModal showsVerticalScrollIndicator={false}>
                            <ScrollContainer showsVerticalScrollIndicator={false}>
                                <ScrollWrapper>
                                    <IconContainer >
                                        <MaterialIcons
                                            name='save'
                                            size={50}
                                            color={theme.colors.site_500}

                                        />
                                    </IconContainer>
                                    <Title>{t('Operações')}</Title>
                                    <Message>{t('Escolha um dos nomes de operações abaixo para o seu cálculo')}</Message>

                                    <FlatList
                                        data={calculations}
                                        keyExtractor={(item) => item}
                                        renderItem={({ item }) => (
                                            <PressableContainerJobs onPress={() => {
                                                setNewCalc(item)
                                                setOpenChooseCalc(false)
                                            }}>
                                                <PressableText>{item}</PressableText>
                                            </PressableContainerJobs>
                                        )
                                        }
                                        showsVerticalScrollIndicator={false}
                                        ListEmptyComponent={(
                                            <EmptyMessageWrapper>
                                                <EmptyMessage>{t('Não há nomes criados')}</EmptyMessage>
                                            </EmptyMessageWrapper>
                                        )}
                                        inverted={true}
                                    />
                                    <PressableContainer onPress={() => setOpenChooseCalc(false)}>
                                        <PressableText>{t('VOLTAR')}</PressableText>
                                    </PressableContainer>
                                </ScrollWrapper>
                            </ScrollContainer>
                        </ContainerModal>

                    </BlurView>

                </Modal>
            </Modal>
            <Modal
                visible={modalTypeColumn}
                animationType={'slide'}
                onRequestClose={() => setModalTypeColumn(false)}

            >


                <ContainerModalTop showsVerticalScrollIndicator={false} nestedScrollEnabled={true}>

                    <ScrollWrapper>
                        <ButtonViewTypeColumn>
                            <BackButton
                                color={theme.colors.main}
                                onPress={() => setModalTypeColumn(false)} />

                        </ButtonViewTypeColumn>
                        <Options>

                            <OptionTubeType
                                active={typeColumnOption === 'casing'}
                                onPress={() => handleTypeColumnOptionChange('casing')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'casing'}>
                                    Casing
                                </OptionTitleTubeType>
                            </OptionTubeType>
                            <OptionTubeType
                                active={typeColumnOption === 'tubing'}
                                onPress={() => handleTypeColumnOptionChange('tubing')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'tubing'}>
                                    Tubing
                                </OptionTitleTubeType>
                            </OptionTubeType>
                            <OptionTubeType
                                active={typeColumnOption === 'drillPipe'}
                                onPress={() => handleTypeColumnOptionChange('drillPipe')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'drillPipe'}>
                                    Drill Pipe
                                </OptionTitleTubeType>
                            </OptionTubeType>
                            <OptionTubeType
                                active={typeColumnOption === 'coilTube'}
                                onPress={() => handleTypeColumnOptionChange('coilTube')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'coilTube'}>
                                    {t('Flexitubo')}
                                </OptionTitleTubeType>
                            </OptionTubeType>
                        </Options>

                        {
                            typeColumnOption === 'casing'
                                ?
                                <View>
                                    <FlatList
                                        data={casingSizes}
                                        keyExtractor={(item) => item}
                                        ListHeaderComponent={(
                                            <TemperatureSelector
                                                title={'Todos'}
                                                active={'all' === sizeSelected}
                                                onPress={() => { handleCasingSizeSelected('all') }}
                                            />
                                        )}
                                        renderItem={({ item }) => (
                                            <TemperatureSelector
                                                title={item}
                                                active={item === sizeSelected}
                                                onPress={() => { handleCasingSizeSelected(item) }}
                                            />)
                                        }
                                        horizontal
                                        showsHorizontalScrollIndicator={false}
                                    />
                                    <FlatList
                                        data={columnTypesDisplaySingle}
                                        keyExtractor={(item) => item.titleSize + item.titleWeight}
                                        renderItem={({ item }) => (
                                            <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedSingle(item.insideDiameter, item.titleSize, item.titleWeight)}>
                                                <Wrapper>
                                                    <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeight} lbs/ft</PressableTextTypeColumn>
                                                    <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                </Wrapper>
                                                <ForwardArrow />
                                            </PressableContainerTypeColumn>
                                        )
                                        }
                                        showsVerticalScrollIndicator={false}
                                    />
                                </View>
                                :
                                typeColumnOption === 'tubing'
                                    ?
                                    <View>
                                        <FlatList
                                            data={tubingSizes}
                                            keyExtractor={(item) => item}
                                            ListHeaderComponent={(
                                                <TemperatureSelector
                                                    title={'Todos'}
                                                    active={'all' === sizeSelectedTubingTypesDisplaySingle}
                                                    onPress={() => { handleTubingSizeSelected('all') }}
                                                />
                                            )}
                                            renderItem={({ item }) => (
                                                <TemperatureSelector
                                                    title={item}
                                                    active={item === sizeSelectedTubingTypesDisplaySingle}
                                                    onPress={() => { handleTubingSizeSelected(item) }}
                                                />)
                                            }
                                            horizontal
                                            showsHorizontalScrollIndicator={false}
                                        />
                                        <FlatList
                                            data={tubingTypesDisplaySingle}
                                            keyExtractor={(item) => item.titleSize + item.titleWeightNU}
                                            renderItem={({ item }) => (
                                                <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedSingle(item.insideDiameter, item.titleSize, item.titleWeightNU, item.titleWeightEU, item.titleWeightIJ)}>
                                                    <Wrapper>
                                                        <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeightNU} NU {item.titleWeightEU && `| ${item.titleWeightEU} EU`} {item.titleWeightIJ && `| ${item.titleWeightIJ} IJ`} lbs/ft</PressableTextTypeColumn>
                                                        <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                    </Wrapper>
                                                    <ForwardArrow />
                                                </PressableContainerTypeColumn>
                                            )
                                            }
                                            showsVerticalScrollIndicator={false}
                                        />
                                    </View>
                                    :
                                    typeColumnOption === 'drillPipe'
                                        ?
                                        <View>
                                            <FlatList
                                                data={drillPipeSizes}
                                                keyExtractor={(item) => item}
                                                ListHeaderComponent={(
                                                    <TemperatureSelector
                                                        title={'Todos'}
                                                        active={'all' === sizeSelectedDrillPipeTypesDisplaySingle}
                                                        onPress={() => { handleDrillPipeSizeSelected('all') }}
                                                    />
                                                )}
                                                renderItem={({ item }) => (
                                                    <TemperatureSelector
                                                        title={item}
                                                        active={item === sizeSelectedDrillPipeTypesDisplaySingle}
                                                        onPress={() => { handleDrillPipeSizeSelected(item) }}
                                                    />)
                                                }
                                                horizontal
                                                showsHorizontalScrollIndicator={false}
                                            />
                                            <FlatList
                                                data={drillPipeTypesDisplaySingle}
                                                keyExtractor={(item) => item.titleSize + item.titleWeight}
                                                renderItem={({ item }) => (
                                                    <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedSingle(item.insideDiameter, item.titleSize, item.titleWeight)}>
                                                        <Wrapper>
                                                            <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeight} lbs/ft</PressableTextTypeColumn>
                                                            <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                        </Wrapper>
                                                        <ForwardArrow />
                                                    </PressableContainerTypeColumn>
                                                )
                                                }
                                                showsVerticalScrollIndicator={false}
                                            />
                                        </View>
                                        :
                                        <View>
                                            <FlatList
                                                data={coilTubeSizes}
                                                keyExtractor={(item) => item}
                                                ListHeaderComponent={(
                                                    <TemperatureSelector
                                                        title={'Todos'}
                                                        active={'all' === sizeSelectedCoilTubeTypesDisplaySingle}
                                                        onPress={() => { handleCoilTubeSizeSelected('all') }}
                                                    />
                                                )}
                                                renderItem={({ item }) => (
                                                    <TemperatureSelector
                                                        title={item}
                                                        active={item === sizeSelectedCoilTubeTypesDisplaySingle}
                                                        onPress={() => { handleCoilTubeSizeSelected(item) }}
                                                    />)
                                                }
                                                horizontal
                                                showsHorizontalScrollIndicator={false}
                                            />
                                            <FlatList
                                                data={coilTubeTypesDisplaySingle}
                                                keyExtractor={(item) => item.titleSize + item.titleWeight}
                                                renderItem={({ item }) => (
                                                    <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedSingle(item.insideDiameter, item.titleSize, item.titleWeight)}>
                                                        <Wrapper>
                                                            <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeight} lbs/ft</PressableTextTypeColumn>
                                                            <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                        </Wrapper>
                                                        <ForwardArrow />
                                                    </PressableContainerTypeColumn>
                                                )
                                                }
                                                showsVerticalScrollIndicator={false}
                                            />
                                        </View>
                        }



                        <PressableContainerTypeColumnBack onPress={() => setModalTypeColumn(false)}>
                            <PressableText>{t('VOLTAR')}</PressableText>
                        </PressableContainerTypeColumnBack>
                    </ScrollWrapper>

                </ContainerModalTop>
            </Modal>

            <Modal
                visible={modalTypeColumnAnularOutside}
                animationType={'slide'}
                onRequestClose={() => setModalTypeColumnAnularOutside(false)}

            >


                <ContainerModalTop showsVerticalScrollIndicator={false} nestedScrollEnabled={true}>

                    <ScrollWrapper>
                        <ButtonViewTypeColumn>
                            <BackButton
                                color={theme.colors.main}
                                onPress={() => setModalTypeColumnAnularOutside(false)} />

                        </ButtonViewTypeColumn>
                        <Options>

                            <OptionTubeType
                                active={typeColumnOption === 'casing'}
                                onPress={() => handleTypeColumnOptionChange('casing')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'casing'}>
                                    Casing
                                </OptionTitleTubeType>
                            </OptionTubeType>
                            <OptionTubeType
                                active={typeColumnOption === 'tubing'}
                                onPress={() => handleTypeColumnOptionChange('tubing')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'tubing'}>
                                    Tubing
                                </OptionTitleTubeType>
                            </OptionTubeType>
                            <OptionTubeType
                                active={typeColumnOption === 'drillPipe'}
                                onPress={() => handleTypeColumnOptionChange('drillPipe')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'drillPipe'}>
                                    Drill Pipe
                                </OptionTitleTubeType>
                            </OptionTubeType>
                            <OptionTubeType
                                active={typeColumnOption === 'coilTube'}
                                onPress={() => handleTypeColumnOptionChange('coilTube')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'coilTube'}>
                                    {t('Flexitubo')}
                                </OptionTitleTubeType>
                            </OptionTubeType>
                        </Options>
                        {
                            typeColumnOption === 'casing'
                                ?
                                <View>
                                    <FlatList
                                        data={casingSizes}
                                        keyExtractor={(item) => item}
                                        ListHeaderComponent={(
                                            <TemperatureSelector
                                                title={'Todos'}
                                                active={'all' === sizeSelectedAnularOutside}
                                                onPress={() => { handleCasingSizeSelectedAnularOutside('all') }}
                                            />
                                        )}
                                        renderItem={({ item }) => (
                                            <TemperatureSelector
                                                title={item}
                                                active={item === sizeSelectedAnularOutside}
                                                onPress={() => { handleCasingSizeSelectedAnularOutside(item) }}
                                            />)
                                        }
                                        horizontal
                                        showsHorizontalScrollIndicator={false}
                                    />
                                    <FlatList
                                        data={columnTypesDisplayAnularOutside}
                                        keyExtractor={(item) => item.titleSize + item.titleWeight}
                                        renderItem={({ item }) => (
                                            <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedAnularOutside(item.insideDiameter, item.titleSize, item.titleWeight)}>
                                                <Wrapper>
                                                    <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeight} lbs/ft</PressableTextTypeColumn>
                                                    <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                </Wrapper>
                                                <ForwardArrow />
                                            </PressableContainerTypeColumn>
                                        )
                                        }
                                        showsVerticalScrollIndicator={false}
                                    />
                                </View>
                                :
                                typeColumnOption === 'tubing'
                                    ?
                                    <View>
                                        <FlatList
                                            data={tubingSizes}
                                            keyExtractor={(item) => item}
                                            ListHeaderComponent={(
                                                <TemperatureSelector
                                                    title={'Todos'}
                                                    active={'all' === sizeSelectedTubingTypesDisplayAnularOutside}
                                                    onPress={() => { handleTubingSizeSelectedAnularOutside('all') }}
                                                />
                                            )}
                                            renderItem={({ item }) => (
                                                <TemperatureSelector
                                                    title={item}
                                                    active={item === sizeSelectedTubingTypesDisplayAnularOutside}
                                                    onPress={() => { handleTubingSizeSelectedAnularOutside(item) }}
                                                />)
                                            }
                                            horizontal
                                            showsHorizontalScrollIndicator={false}
                                        />
                                        <FlatList
                                            data={tubingTypesDisplayAnularOutside}
                                            keyExtractor={(item) => item.titleSize + item.titleWeightNU}
                                            renderItem={({ item }) => (
                                                <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedAnularOutside(item.insideDiameter, item.titleSize, item.titleWeightNU, item.titleWeightEU, item.titleWeightIJ)}>
                                                    <Wrapper>
                                                        <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeightNU} NU {item.titleWeightEU && `| ${item.titleWeightEU} EU`} {item.titleWeightIJ && `| ${item.titleWeightIJ} IJ`} lbs/ft</PressableTextTypeColumn>
                                                        <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                    </Wrapper>
                                                    <ForwardArrow />
                                                </PressableContainerTypeColumn>
                                            )
                                            }
                                            showsVerticalScrollIndicator={false}
                                        />
                                    </View>
                                    :
                                    typeColumnOption === 'drillPipe'
                                        ?
                                        <View>
                                            <FlatList
                                                data={drillPipeSizes}
                                                keyExtractor={(item) => item}
                                                ListHeaderComponent={(
                                                    <TemperatureSelector
                                                        title={'Todos'}
                                                        active={'all' === sizeSelectedDrillPipeTypesDisplayAnularOutside}
                                                        onPress={() => { handleDrillPipeSizeSelectedAnularOutside('all') }}
                                                    />
                                                )}
                                                renderItem={({ item }) => (
                                                    <TemperatureSelector
                                                        title={item}
                                                        active={item === sizeSelectedDrillPipeTypesDisplayAnularOutside}
                                                        onPress={() => { handleDrillPipeSizeSelectedAnularOutside(item) }}
                                                    />)
                                                }
                                                horizontal
                                                showsHorizontalScrollIndicator={false}
                                            />
                                            <FlatList
                                                data={drillPipeTypesDisplayAnularOutside}
                                                keyExtractor={(item) => item.titleSize + item.titleWeight}
                                                renderItem={({ item }) => (
                                                    <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedAnularOutside(item.insideDiameter, item.titleSize, item.titleWeight)}>
                                                        <Wrapper>
                                                            <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeight} lbs/ft</PressableTextTypeColumn>
                                                            <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                        </Wrapper>
                                                        <ForwardArrow />
                                                    </PressableContainerTypeColumn>
                                                )
                                                }
                                                showsVerticalScrollIndicator={false}
                                            />
                                        </View>
                                        :
                                        <View>
                                            <FlatList
                                                data={coilTubeSizes}
                                                keyExtractor={(item) => item}
                                                ListHeaderComponent={(
                                                    <TemperatureSelector
                                                        title={'Todos'}
                                                        active={'all' === sizeSelectedCoilTubeTypesDisplayAnularOutside}
                                                        onPress={() => { handleCoilTubeSizeSelectedAnularOutside('all') }}
                                                    />
                                                )}
                                                renderItem={({ item }) => (
                                                    <TemperatureSelector
                                                        title={item}
                                                        active={item === sizeSelectedCoilTubeTypesDisplayAnularOutside}
                                                        onPress={() => { handleCoilTubeSizeSelectedAnularOutside(item) }}
                                                    />)
                                                }
                                                horizontal
                                                showsHorizontalScrollIndicator={false}
                                            />
                                            <FlatList
                                                data={coilTubeTypesDisplayAnularOutside}
                                                keyExtractor={(item) => item.titleSize + item.titleWeight}
                                                renderItem={({ item }) => (
                                                    <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedAnularOutside(item.insideDiameter, item.titleSize, item.titleWeight)}>
                                                        <Wrapper>
                                                            <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeight} lbs/ft</PressableTextTypeColumn>
                                                            <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                        </Wrapper>
                                                        <ForwardArrow />
                                                    </PressableContainerTypeColumn>
                                                )
                                                }
                                                showsVerticalScrollIndicator={false}
                                            />
                                        </View>
                        }
                        <PressableContainerTypeColumnBack onPress={() => setModalTypeColumnAnularOutside(false)}>
                            <PressableText>{t('VOLTAR')}</PressableText>
                        </PressableContainerTypeColumnBack>
                    </ScrollWrapper>

                </ContainerModalTop>
            </Modal>

            <Modal
                visible={modalTypeColumnAnularInside}
                animationType={'slide'}
                onRequestClose={() => setModalTypeColumnAnularInside(false)}

            >


                <ContainerModalTop showsVerticalScrollIndicator={false} nestedScrollEnabled={true}>

                    <ScrollWrapper>
                        <ButtonViewTypeColumn>
                            <BackButton
                                color={theme.colors.main}
                                onPress={() => setModalTypeColumnAnularInside(false)} />

                        </ButtonViewTypeColumn>

                        <Options>

                            <OptionTubeType
                                active={typeColumnOption === 'casing'}
                                onPress={() => handleTypeColumnOptionChange('casing')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'casing'}>
                                    Casing
                                </OptionTitleTubeType>
                            </OptionTubeType>
                            <OptionTubeType
                                active={typeColumnOption === 'tubing'}
                                onPress={() => handleTypeColumnOptionChange('tubing')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'tubing'}>
                                    Tubing
                                </OptionTitleTubeType>
                            </OptionTubeType>
                            <OptionTubeType
                                active={typeColumnOption === 'drillPipe'}
                                onPress={() => handleTypeColumnOptionChange('drillPipe')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'drillPipe'}>
                                    Drill Pipe
                                </OptionTitleTubeType>
                            </OptionTubeType>
                            <OptionTubeType
                                active={typeColumnOption === 'coilTube'}
                                onPress={() => handleTypeColumnOptionChange('coilTube')}
                            >
                                <OptionTitleTubeType active={typeColumnOption === 'coilTube'}>
                                    {t('Flexitubo')}
                                </OptionTitleTubeType>
                            </OptionTubeType>
                        </Options>
                        {
                            typeColumnOption === 'casing'
                                ?
                                <View>
                                    <FlatList
                                        data={casingSizes}
                                        keyExtractor={(item) => item}
                                        ListHeaderComponent={(
                                            <TemperatureSelector
                                                title={'Todos'}
                                                active={'all' === sizeSelectedAnularInside}
                                                onPress={() => { handleCasingSizeSelectedAnularInside('all') }}
                                            />
                                        )}
                                        renderItem={({ item }) => (
                                            <TemperatureSelector
                                                title={item}
                                                active={item === sizeSelectedAnularInside}
                                                onPress={() => { handleCasingSizeSelectedAnularInside(item) }}
                                            />)
                                        }
                                        horizontal
                                        showsHorizontalScrollIndicator={false}
                                    />
                                    <FlatList
                                        data={columnTypesDisplayAnularInside}
                                        keyExtractor={(item) => item.titleSize + item.titleWeight}
                                        renderItem={({ item }) => (
                                            <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedAnularInside(item.outsideDiameter, item.titleSize, item.titleWeight)}>
                                                <Wrapper>
                                                    <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeight} lbs/ft</PressableTextTypeColumn>
                                                    <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                </Wrapper>
                                                <ForwardArrow />
                                            </PressableContainerTypeColumn>
                                        )
                                        }
                                        showsVerticalScrollIndicator={false}
                                    />
                                </View>
                                :
                                typeColumnOption === 'tubing'
                                    ?
                                    <View>
                                        <FlatList
                                            data={tubingSizes}
                                            keyExtractor={(item) => item}
                                            ListHeaderComponent={(
                                                <TemperatureSelector
                                                    title={'Todos'}
                                                    active={'all' === sizeSelectedTubingTypesDisplayAnularInside}
                                                    onPress={() => { handleTubingSizeSelectedAnularInside('all') }}
                                                />
                                            )}
                                            renderItem={({ item }) => (
                                                <TemperatureSelector
                                                    title={item}
                                                    active={item === sizeSelectedTubingTypesDisplayAnularInside}
                                                    onPress={() => { handleTubingSizeSelectedAnularInside(item) }}
                                                />)
                                            }
                                            horizontal
                                            showsHorizontalScrollIndicator={false}
                                        />
                                        <FlatList
                                            data={tubingTypesDisplayAnularInside}
                                            keyExtractor={(item) => item.titleSize + item.titleWeightNU}
                                            renderItem={({ item }) => (
                                                <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedAnularInside(item.outsideDiameter, item.titleSize, item.titleWeightNU, item.titleWeightEU, item.titleWeightIJ)}>
                                                    <Wrapper>
                                                        <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeightNU} NU {item.titleWeightEU && `| ${item.titleWeightEU} EU`} {item.titleWeightIJ && `| ${item.titleWeightIJ} IJ`} lbs/ft</PressableTextTypeColumn>
                                                        <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                    </Wrapper>
                                                    <ForwardArrow />
                                                </PressableContainerTypeColumn>
                                            )
                                            }
                                            showsVerticalScrollIndicator={false}
                                        />
                                    </View>
                                    :
                                    typeColumnOption === 'drillPipe'
                                        ?
                                        <View>
                                            <FlatList
                                                data={drillPipeSizes}
                                                keyExtractor={(item) => item}
                                                ListHeaderComponent={(
                                                    <TemperatureSelector
                                                        title={'Todos'}
                                                        active={'all' === sizeSelectedDrillPipeTypesDisplayAnularInside}
                                                        onPress={() => { handleDrillPipeSizeSelectedAnularInside('all') }}
                                                    />
                                                )}
                                                renderItem={({ item }) => (
                                                    <TemperatureSelector
                                                        title={item}
                                                        active={item === sizeSelectedDrillPipeTypesDisplayAnularInside}
                                                        onPress={() => { handleDrillPipeSizeSelectedAnularInside(item) }}
                                                    />)
                                                }
                                                horizontal
                                                showsHorizontalScrollIndicator={false}
                                            />
                                            <FlatList
                                                data={drillPipeTypesDisplayAnularInside}
                                                keyExtractor={(item) => item.titleSize + item.titleWeight}
                                                renderItem={({ item }) => (
                                                    <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedAnularInside(item.outsideDiameter, item.titleSize, item.titleWeight)}>
                                                        <Wrapper>
                                                            <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeight} lbs/ft</PressableTextTypeColumn>
                                                            <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                        </Wrapper>
                                                        <ForwardArrow />
                                                    </PressableContainerTypeColumn>
                                                )
                                                }
                                                showsVerticalScrollIndicator={false}
                                            />
                                        </View>
                                        :
                                        <View>
                                            <FlatList
                                                data={coilTubeSizes}
                                                keyExtractor={(item) => item}
                                                ListHeaderComponent={(
                                                    <TemperatureSelector
                                                        title={'Todos'}
                                                        active={'all' === sizeSelectedCoilTubeTypesDisplayAnularInside}
                                                        onPress={() => { handleCoilTubeSizeSelectedAnularInside('all') }}
                                                    />
                                                )}
                                                renderItem={({ item }) => (
                                                    <TemperatureSelector
                                                        title={item}
                                                        active={item === sizeSelectedCoilTubeTypesDisplayAnularInside}
                                                        onPress={() => { handleCoilTubeSizeSelectedAnularInside(item) }}
                                                    />)
                                                }
                                                horizontal
                                                showsHorizontalScrollIndicator={false}
                                            />
                                            <FlatList
                                                data={coilTubeTypesDisplayAnularInside}
                                                keyExtractor={(item) => item.titleSize + item.titleWeight}
                                                renderItem={({ item }) => (
                                                    <PressableContainerTypeColumn onPress={() => handleTypeColumnSelectedAnularInside(item.outsideDiameter, item.titleSize, item.titleWeight)}>
                                                        <Wrapper>
                                                            <PressableTextTypeColumn>{item.titleSize}" - {item.titleWeight} lbs/ft</PressableTextTypeColumn>
                                                            <TextID>ID: {item.insideDiameter}   OD: {item.outsideDiameter}</TextID>
                                                        </Wrapper>
                                                        <ForwardArrow />
                                                    </PressableContainerTypeColumn>
                                                )
                                                }
                                                showsVerticalScrollIndicator={false}
                                            />
                                        </View>
                        }

                        <PressableContainerTypeColumnBack onPress={() => setModalTypeColumnAnularInside(false)}>
                            <PressableText>{t('VOLTAR')}</PressableText>
                        </PressableContainerTypeColumnBack>
                    </ScrollWrapper>

                </ContainerModalTop>
            </Modal>


        </Container>
    );
}