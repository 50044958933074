import React from 'react';

import {
    Container,
    ScrollContainer,
    ScrollWrapper,
    Message,
    PressableContainer,
    PressableText,
    IconContainer,
    Title,
    ButtonNavigation,
    TextNavigation,
    MessageFinal,
    MessageWrapper,
    ListContainerInch,
    Wrapper
} from './styles';

import { MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';

import { useTheme } from 'styled-components/native';
import { BlurView } from 'expo-blur';
import { Input } from '../../Input';
import { FlatList, Platform } from 'react-native';
import { convertInchFrations } from '../../../utils/InchFractionToDecimal';
import { TextInputProps } from 'react-native';
import { TemperatureSelectorConvert } from '../../TemperatureSelectorConvert';
import { useTranslation } from 'react-i18next';


interface Props extends TextInputProps {
    handleCloseModal: () => void;
    iconName: React.ComponentProps<typeof MaterialCommunityIcons>['name'] | React.ComponentProps<typeof FontAwesome>['name'];
    iconCollection?: 'MaterialCommunityIcons' | 'FontAwesome';
    text: string;
    textFinal?: string;
    title: string;
    textNavigation?: string;
    handleNavigation?: () => void;
    diameter?: string;
    onPressed?: (x, y) => void;
}


export function ColumnDiameterModal({
    handleCloseModal,
    text,
    textFinal,
    title,
    iconName,
    iconCollection,
    textNavigation,
    handleNavigation,
    onPressed,
    ...rest
}: Props) {

    const theme = useTheme();

    const {t} = useTranslation();

    return (
        <BlurView intensity={200} style={{ flex: 1 }}>

            <Container showsVerticalScrollIndicator={false}>
                <ScrollContainer showsVerticalScrollIndicator={false}>
                    <ScrollWrapper>
                        <IconContainer >
                            {
                                iconCollection === 'FontAwesome'
                                    ?
                                    <FontAwesome
                                        name={iconName}
                                        size={50}
                                        color={theme.colors.site_500}

                                    />
                                    :
                                    <MaterialCommunityIcons
                                        name={iconName}
                                        size={50}
                                        color={theme.colors.site_500}

                                    />
                            }
                        </IconContainer>
                        <Title>{title}</Title>

                        <Message>
                            {text}
                        </Message>
                        {
                            textNavigation &&
                            <MessageWrapper>
                                <ButtonNavigation onPress={handleNavigation}>
                                    <TextNavigation>{textNavigation}</TextNavigation>
                                </ButtonNavigation>
                                <MessageFinal>
                                    {textFinal}
                                </MessageFinal>
                            </MessageWrapper>
                        }
                        <Wrapper>
                            <Input
                                iconName='diameter-variant'
                                placeholder={t('Digite o ID em polegadas')}
                                keyboardType={Platform.OS === 'web' ? 'decimal-pad' : 'numeric'}
                                {...rest}
                            />
                            <ListContainerInch>
                                <FlatList
                                    data={convertInchFrations}
                                    keyExtractor={(item) => item.key}
                                    renderItem={({ item }) => (
                                        <TemperatureSelectorConvert
                                            title={item.title}
                                            active={false}
                                            onPress={() => onPressed(item.key, item.constant)}
                                        />)
                                    }
                                    horizontal
                                    showsHorizontalScrollIndicator={false}
                                />
                            </ListContainerInch>
                        </Wrapper>

                        <PressableContainer onPress={handleCloseModal}>
                            <PressableText>{t('FECHAR')}</PressableText>
                        </PressableContainer>
                    </ScrollWrapper>
                </ScrollContainer>
            </Container>

        </BlurView>
    );
}