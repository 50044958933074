import styled from 'styled-components/native';
import { Dimensions, Platform } from 'react-native';

const maxWidth = 750;

export const Container = styled.ScrollView`
    background-color: ${({ theme }) => Platform.OS === 'web' ? theme.colors.shape : theme.colors.backgroud_primary};
    
`;

export const Content = styled.View`
    margin: 10px 10px;
   
`;

export const ContainerCard = styled.View`
    flex-direction:row ;
    justify-content:${Dimensions.get('window').width < maxWidth ? 'space-between' : 'center'} ;
`;

export const ContainerCardLast = styled.View`
    justify-content: center;
    align-items: center;
`;

export const Footer = styled.View`
   width: 100%;
   height: 100px;
   background-color: ${({ theme }) => theme.colors.main};
`;

