

const VolumeMessage: string = `Nesse campo deve ser inserido o volume interno do local a ser pressurizado, como por exemplo, o volume interno de um DPR ou de um vaso da planta de produção.`
const VolumeMessageFinal: string = `O volume pode ser inserido em barril (bbl) ou metro cúbido (m³), selecione no botão embaixo da caixa de texto a unidade que deseja utilizar.${'\n'}${'\n'}Preenchimento Obrigatório!`

const TemperatureMessage: string = `Escolha a temperatura do local a ser pressurizado.${'\n'}${'\n'}Para desalagamento de DPR é comum utilizar a temperatura de 60° F e para vasos de superfície 80° F, porém avalie sua operação para realizar a melhor escolha.${'\n'}${'\n'}Caso não escolha nenhuma opção, será utilizado a temperatura de 60° F!`

const PressureMessage: string = `Nesse campo deve ser inserido a pressão final da operação.${'\n'}${'\n'}Para operações em poços, utilize a pressão hidrostática de cabeça.`
const PressureMessageFinal: string = `${'\n'}O pressão pode ser inserida em psi, kgf/cm² ou bar, selecione no botão embaixo da caixa de texto a unidade que deseja utilizar.${'\n'}${'\n'}Pressão máxima permitida: 15.000 psi.${'\n'}${'\n'}Preenchimento Obrigatório!`

const FlowMessage: string = `Nesse campo deve ser inserido a vazão de nitrogênio em scfm. Com esse dado será possível prever a duração da operação.${'\n'}${'\n'}Caso o campo não seja preenchido, os demais cálculos serão realizados, porém nenhuma estimativa de tempo de bombeio será fornecida.${'\n'}${'\n'}Preenchimento Opcional!`

export { VolumeMessage, VolumeMessageFinal, TemperatureMessage, PressureMessage, PressureMessageFinal, FlowMessage }